import { array, bool } from 'prop-types'

import { useTranslation } from 'i18n'
import { CustomRoom } from 'components'
import * as S from './styles'

const CustomRoomsList = ({ customRooms, canAdmin }) => {
  const { t } = useTranslation('business')

  return (
    <S.CustomRoomsListComponent>
      {customRooms.length > 0 ? (
        customRooms.map((item) => (
          <CustomRoom key={item.id} canAdmin={canAdmin} {...item} />
        ))
      ) : (
        <div>{t('noRooms')}</div>
      )}
    </S.CustomRoomsListComponent>
  )
}

CustomRoomsList.propTypes = {
  customRooms: array,
  canAdmin: bool
}

CustomRoomsList.defaultProps = {
  customRooms: [],
  canAdmin: false
}

export default CustomRoomsList
