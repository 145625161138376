import { useSelector } from 'react-redux'
import * as S from './styles'
import { Footer, Navbar, MaxWidth } from 'components'

const Layout = ({ children, hideNav, showOnlyItems }) => {
  const hasNotification = !!useSelector((state) => state.notification?.type)

  return (
    <S.LayoutComponent>
      <Navbar hideNav={hideNav} showOnlyItems={showOnlyItems} />
      <S.Main hasNotification={hasNotification}>
        <MaxWidth>{children}</MaxWidth>
      </S.Main>
      <Footer />
    </S.LayoutComponent>
  )
}

export default Layout
