import * as S from './styles'

const safelyParseCache = (avatarCache) => {
  try {
    const parsedCache = JSON.parse(avatarCache)
    return parsedCache
  } catch (e) {
    return null
  }
}

const getAvatarUrl = (avatarUrl) => {
  if (typeof window === 'undefined') return ''

  const avatarCache = window.localStorage.getItem('avatarCache')
  const parsedCache = avatarCache ? safelyParseCache(avatarCache) : null
  if (parsedCache) {
    const { file, expiry } = parsedCache
    const expirationDate = new Date(expiry)
    const currentDate = new Date()
    if (expirationDate && currentDate > expirationDate) {
      window.localStorage.removeItem('avatarCache')
    }
    return file
  }
  return avatarUrl
}

const AvatarProfileImage = ({ avatarUrl, largeImage = false }) => {
  return (
    <S.AvatarProfileImage
      largeImage={largeImage}
      src={getAvatarUrl(avatarUrl)}
      alt=""
    />
  )
}

export default AvatarProfileImage
