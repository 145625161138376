import { bool } from 'prop-types'
import { Trans, useTranslation } from 'i18n'
import { useSelector } from 'react-redux'

import formatDate from 'utilities/formatDate'
import useSubscriptionLength from 'hooks/useSubscriptionLength'
import { Panel } from 'components'
import * as S from './styles'

const SubscriptionPanel = ({ isBusiness, canModify }) => {
  const { t } = useTranslation(['common', 'account'])
  const subscription = useSelector((state) =>
    isBusiness
      ? state.api.business.subscription
      : state.api.commerce.subscription
  )

  const renewalDate = formatDate(subscription.nextChargeDate)
  const type = useSubscriptionLength(
    subscription.nextPlanId || subscription.planId
  )

  const ctas =
    isBusiness && !canModify
      ? null
      : [
          {
            id: 'subscription',
            label: t('manage'),
            href: isBusiness ? '/business/subscription' : '/subscription',
            type: 'linkToButton'
          }
        ]

  return (
    <Panel heading={type} ctas={ctas}>
      <Trans i18nKey="account:nextPayment">
        Next payment will occur automatically on
        <S.NoWrap>{{ renewalDate }}</S.NoWrap>.
      </Trans>
    </Panel>
  )
}

SubscriptionPanel.propTypes = {
  canModify: bool,
  isBusiness: bool
}

SubscriptionPanel.defaultProps = {
  canModify: false,
  isBusiness: false
}

export default SubscriptionPanel
