import { Link, Trans, useTranslation } from 'i18n'

import * as S from './styles'

const TransLink = ({ children, href }) => (
  <Link href={href} passHref>
    <a>{children}</a>
  </Link>
)

const SubscriptionCancelled = () => {
  const { t } = useTranslation('account')
  return (
    <S.SubscriptionCancelledComponent>
      <h1>{t('cancelSubscriptionSuccess')}</h1>
      <S.P>{t('cancelSuccess')}</S.P>
      <Trans i18nKey="resubscribeOrBack" t={t}>
        <TransLink href="/upgrade">Resubscribe</TransLink>
        or
        <TransLink href="/">go back to your account</TransLink>.
      </Trans>
    </S.SubscriptionCancelledComponent>
  )
}

export default SubscriptionCancelled
