import * as S from './styles'
import {
  ResendBusinessInviteButton,
  DeleteBusinessInviteButton
} from 'components'

const InvitationActions = ({ invite }) => {
  return (
    <S.InvitationActionsComponent>
      <DeleteBusinessInviteButton invite={invite} />
      <ResendBusinessInviteButton invite={invite} />
    </S.InvitationActionsComponent>
  )
}

export default InvitationActions
