import ReactAvatarEditor from 'react-avatar-editor'
import styled from 'styled-components'

import { Colors, Type } from 'styles'

export const DownsamplingZoomHack = styled.div`
  zoom: 0.5;
`

export const StyledReactAvatarEditor = styled(ReactAvatarEditor)`
  @media (max-width: 500px) {
    max-width: 100%;
    height: auto !important;
  }
`

export const Container = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  max-width: 313px;
  width: 100%;
  gap: 16px;
`

export const UploadArea = styled.div`
  box-sizing: border-box;
  width: 313px;
  max-width: 100%;
  aspect-ratio: 1 / 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  border: 2px dashed;
  border-color: ${(p) => (p.$isDragActive ? Colors.brand : '#ccc')};
  outline: none;
  cursor: pointer;
  transition: border 0.24s ease-in-out;

  &:hover,
  &:focus {
    border-color: ${Colors.grayIcon};
  }
`

export const Slider = styled.input`
  width: 100%;
  height: 2px;
`

export const P = styled.p`
  color: ${Colors.grayIcon};
  font-weight: ${Type.fontWeights.bold};
  margin-bottom: 1rem;
`

export const Info = styled.p`
  color: ${Colors.grayIcon};
  font-size: 0.75rem;
  line-height: 1rem;
`

export const Error = styled.p`
  ${Type.body40};
  font-weight: 700;
  color: ${Colors.red};
  background-color: ${Colors.red10};
  padding: 0.5rem;
  border-radius: 8px;
`

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
`
