import { useRef, useState } from 'react'
import { bool, element, shape, string } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { Form } from 'informed'
import { captureException } from 'utilities/sentry'
import { useRouter } from 'next/router'

import { Trans, useTranslation } from 'i18n'
import { Auth, User, Business, Commerce } from 'api'
import { setDialogVisibility } from 'store/dialog/slice'
import getErrorMessage from 'utilities/getErrorMessage'
import SubscribeLoading from './SubscribeLoading'
import * as S from './styles'

import {
  withChargebee,
  CheckoutTotalContainer,
  AcceptTerms,
  PaymentForm
} from 'components'

const Subscribe = ({
  additionalSections,
  api,
  catalog,
  getChargebeeToken,
  chargebeeLoaded,
  isBusiness
}) => {
  const { t } = useTranslation(['common', 'checkout', 'business', 'account'])
  const cardRef = useRef()
  const dispatch = useDispatch()
  const router = useRouter()

  const [loading, setLoading] = useState(false)

  const businessInfo = useSelector((state) => state.ui?.businessInfo)
  const currentUser = useSelector((state) => state.api.user?.me)
  const hasCurrentUser = currentUser?.id

  const handleSubmit = async (values) => {
    try {
      setLoading(true)
      const token = await getChargebeeToken(cardRef)

      if (isBusiness) {
        const payload = {
          name: values.businessName,
          billing: {
            planId: values.planId,
            countryCode: catalog.countryCode,
            token,
            firstName: values.firstName,
            lastName: values.lastName,
            zipCode: values.zipCode
          },
          invoiceFields: {
            taxID: values.taxID,
            billingAddress: values.billingAddress
          }
        }

        if (!hasCurrentUser) {
          payload.owner = {
            name: `${values.userFirstName.trim()} ${values.userLastName.trim()}`,
            email: businessInfo?.email.toLowerCase(),
            password: values.password,
            clientId: process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID
          }

          unwrapResult(await dispatch(Business[api](payload)))
          unwrapResult(
            await dispatch(
              Auth.signIn({
                username: businessInfo.email,
                password: values.password
              })
            )
          )
          unwrapResult(await dispatch(User.getMe()))
        } else {
          unwrapResult(await dispatch(Business[api](payload)))
        }
      } else {
        const paymentSource = {
          token,
          countryCode: catalog.countryCode,
          ...values
        }

        const result = await dispatch(Commerce[api](paymentSource))
        unwrapResult(result)
      }

      const nextRoute = isBusiness ? '/business/verify' : '/order-complete'
      router.push(nextRoute)
    } catch (error) {
      /**
       * This should be moved to use the redux apiStatus store but we need it to catch
       * getChargebeeToken failures because it's not currently integrated with redux (tech debt)
       */
      console.error('Checkout error on handleSubmit:', error)

      /**
       * Backend returns more specific error message with reason for certain error
       * codes, so those should be returned directly, despite missing translation.
       *
       * Zip code error needs to match based on error.message first b/c error.code
       * returns the wrong error string.
       * If not found, check for an error string matching the error.code.
       */
      let description

      if (
        ['CardVerificationFailed', 'PaymentProcessingFailed'].includes(
          error.code
        )
      ) {
        const delimiter =
          error.code === 'PaymentProcessingFailed'
            ? 'Gateway Error: '
            : 'Error message : '
        const errParts = error.message.split(delimiter)

        /**
         * Specific error reason/info should be contained in errParts[1], although
         * if the message didn't match the expected format, it will be errParts[0];
         * getting last indexed item will ensure we retrieve the string.
         */
        description = t('checkout:paymentErrorReason', {
          reason: errParts[errParts.length - 1]
        })
      } else if (error.code === 'InvalidEmailException') {
        /** Use the specific message returned by backend in this case, which contains details */
        description = error.message
      } else {
        description =
          getErrorMessage(error.message, t) ||
          getErrorMessage(error.code, t) ||
          null
      }

      if (!description) {
        // Only send to Sentry if an irregular error without a message is caught
        captureException(error)
      }

      const options = {
        name: 'ErrorDialog',
        description: description || t('checkout:processingError'),
        showSupportLink: !description
      }

      dispatch(setDialogVisibility(options))
      setLoading(false)
    }
  }

  const didLoadInputs = chargebeeLoaded && catalog && catalog.monthlyPlan

  if (!didLoadInputs) return <SubscribeLoading />

  const businessTermsUrl = t('business:businessTermsUrl')
  const termsOfServiceUrl = t('common:termsUrl')
  const privacyPolicyUrl = t('common:privacyUrl')

  return (
    <S.Wrap>
      <Form
        onSubmit={handleSubmit}
        initialValues={{ planId: catalog?.annualPlan?.id }}
      >
        {additionalSections}
        <PaymentForm isBusiness={isBusiness} catalog={catalog} ref={cardRef} />
        {isBusiness ? (
          <S.BusinessTerms>
            <Trans i18nKey="business:termsOfBusinessService" t={t}>
              By clicking "Purchase" you agree to create a mmhmm for Teams
              account that is governed by the
              <a
                href={businessTermsUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                mmhmm for Teams Agreement
              </a>
              , and the mmhmm
              <a
                href={termsOfServiceUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms of Service
              </a>
              and
              <a
                href={privacyPolicyUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              . You acknowledge that the subscription will auto-renew at the end
              of the term, unless you change the Teams preferences in your
              account.
            </Trans>
          </S.BusinessTerms>
        ) : (
          <CheckoutTotalContainer catalog={catalog} />
        )}
        <S.TermsAndSubmit>
          {!isBusiness && <AcceptTerms />}
          <S.Submit type="submit" loading={loading}>
            {t('checkout:purchase')}
          </S.Submit>
        </S.TermsAndSubmit>
      </Form>
    </S.Wrap>
  )
}

Subscribe.propTypes = {
  api: string.isRequired,
  additionalSections: element,
  catalog: shape({}),
  isBusiness: bool
}

Subscribe.defaultProps = {
  additionalSections: null,
  catalog: {},
  isBusiness: false
}

export default withChargebee(Subscribe)
