import styled from 'styled-components'

import { Type } from 'styles'

export const Container = styled.div`
  max-width: 63.438rem;
`

export const Heading = styled.h1`
  max-width: 38.3125rem;
  text-align: center;
  margin: 1rem auto 3rem;
`

export const Ol = styled.ol`
  max-width: 35rem;
`

export const Li = styled.li`
  ${Type.body10};
  list-style-type: inherit;
  margin-bottom: 1rem;
`
