import * as S from './styles'
import { string, boolean, func } from 'prop-types'
import { Loading, TextField } from 'components'
import { useTranslation } from 'i18n'
import { searchTableFields } from 'data'

const TableSearchBar = ({ onChange, value, loading }) => {
  const { t } = useTranslation(['common'])
  return (
    <S.SearchWrapper>
      <TextField
        {...searchTableFields({ t }).search}
        value={value}
        onChange={onChange}
      />
      {loading && <Loading visible />}
    </S.SearchWrapper>
  )
}

TableSearchBar.propTypes = {
  onChange: func,
  value: string,
  loading: boolean
}

export default TableSearchBar
