import styled from 'styled-components'

import { Button } from '@all-turtles/mmhmm-ui-kit'

export const ResendBusinessInviteButtonComponent = styled(Button)`
  display: flex;
  min-width: 3.25rem;

  svg {
    line-height: 1.1875rem;
    width: 1.1875rem;
    height: 1.1875rem;
  }
`
