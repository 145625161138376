import styled from 'styled-components'

import { Breakpoints, Colors, Inputs, Type } from 'styles'

export const H2 = styled.h2`
  ${Type.header3};
  margin-bottom: 2.25rem;
`

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`

export const FieldRow = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${Breakpoints.tablet}) {
    flex-direction: row;

    ${Field} {
      flex: 1;
      margin-inline-end: 1rem;

      &:last-child {
        margin-inline-end: 0;
      }
    }
  }
`

export const Label = styled.p`
  ${Inputs.label};
`

export const ReadOnlyEmail = styled.p`
  margin: 0.625rem 0 1.688rem;
  color: ${Colors.grayIcon};
  word-break: break-all;
`

export const RequirementsContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: ${(props) => (props.canChangeAccounts ? '1.25rem' : '0.5rem')};
`

export const Requirement = styled.p`
  ${Type.body40};
  color: ${(props) => (props.hasError ? Colors.warning : Colors.grayIcon)};
`
