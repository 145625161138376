import { modelName, apiFetch, createAsyncThunk } from './commerce'

const getPremium = createAsyncThunk(`${modelName}/getPremium`, async (body) => {
  const options = {
    path: '/users/me/premium',
    method: 'GET',
    body
  }
  const response = await apiFetch(options)

  return response
})

getPremium.storeKey = 'premium'

export default getPremium
