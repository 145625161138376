import styled from 'styled-components'
import { Button } from '@all-turtles/mmhmm-ui-kit'

import { Colors, Type } from 'styles'
import { Check, Exclamation, X } from 'components/Icons'

const colors = {
  success: Colors.green025,
  error: Colors.red10,
  warning: Colors.gray10
}

export const NotificationComponent = styled.div`
  min-height: 3.75rem;
  width: 100%;
  background-color: ${(props) => colors[props.type] || Colors.red10};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 28px;
  padding: 0 1.5rem;
`

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 1.875rem;
  width: 1.875rem;
  height: 1.875rem;
  background-color: ${(props) =>
    props.type === 'success' ? Colors.white : Colors.red};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
`

export const MessageWrapper = styled.div`
  padding: 1.125rem 1.5rem;
`

export const Text = styled.p`
  color: ${Colors.midnight};
  font-size: 1.1875rem;
  text-align: center;
`

export const A = styled.a`
  margin-inline-start: 0.5rem;

  &:hover {
    text-decoration: underline;
  }
`

export const SecondaryMessage = styled.p`
  ${Type.body30}
  color: ${Colors.midnight};
  text-align: center;
`

export const StyledCheck = styled(Check)`
  path {
    fill: ${Colors.green};
    stroke: ${Colors.green};
  }
`

export const StyledExclamation = styled(Exclamation)``

export const CtaWrapper = styled.div`
  min-width: 1.875rem;
`

export const StyledButton = styled(Button)`
  opacity: 0.6;
  transition: all 150ms ease;

  &:hover,
  &:active,
  &:focus {
    opacity: 1;
  }
`

export const StyledX = styled(X)`
  width: 1rem;
  height: 1rem;
`
