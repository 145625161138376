import styled from 'styled-components'

import { Check } from 'components/Icons'
import { Colors, Type } from 'styles'

export const InlineSuccessMessageComponent = styled.div`
  display: flex;
  align-items: center;
  color: ${Colors.success};
  ${Type.body30};
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`

export const StyledCheck = styled(Check)`
  width: 1rem;
  height: 1rem;
  margin: 0 0.25rem;
  margin-inline-start: 1rem;
  background-color: transparent;

  path {
    fill: ${Colors.success};
    stroke: ${Colors.success};
  }
`
