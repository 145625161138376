const getPaymentHistory = ({ items }) => {
  if (!items || items.length <= 0) return null

  return items.map((item) => {
    const amount = item.displayAmount

    return {
      id: item.id,
      invoiceId: item.invoiceId,
      date: item.date,
      type: item.type,
      amount,
      status: item.status,
      receipt: ''
    }
  })
}

export default getPaymentHistory
