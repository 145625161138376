import styled from 'styled-components'

export const Title = styled.h3`
  margin-bottom: 1.5rem;
`

export const Wrap = styled.div`
  width: 30rem;
  max-width: 100vw;
`
