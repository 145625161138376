import { useState } from 'react'
import { useDispatch } from 'react-redux'

import { getInvoice } from 'api/commerce'
import { getInvoicePdf } from 'api/business'
import { setDialogVisibility } from 'store/dialog/slice'
import { useTranslation } from 'i18n'
import * as S from './styles'

const DownloadReceipt = ({ isBusiness = false, invoiceId, type }) => {
  const { t } = useTranslation('common')
  const dispatch = useDispatch()
  const [loading, setLoading] = useState()

  const getInvoicePDF = async () => {
    try {
      setLoading(true)
      const getThisInvoice = isBusiness ? getInvoicePdf : getInvoice
      const res = await dispatch(getThisInvoice({ id: invoiceId, type: type }))
      const { url } = res.payload

      window.open(url)
      setLoading(false)
    } catch (error) {
      console.error(error)
      const options = {
        name: 'ErrorDialog',
        description: t('somethingWentWrong'),
        showSupportLink: true
      }

      dispatch(setDialogVisibility(options))
      setLoading(false)
    }
  }

  return (
    <S.DownloadReceiptComponent onClick={getInvoicePDF}>
      <span>PDF</span>
      <S.LoadingSpinner visible={loading} startMargin="0" />
    </S.DownloadReceiptComponent>
  )
}

export default DownloadReceipt
