import { useTranslation } from 'i18n'
import * as S from './styles'

const Hamburger = (props) => {
  const { t } = useTranslation('common')
  const { open, onClick } = props

  return (
    <S.Button
      onClick={onClick}
      aria-label={open ? t('closeMobileMenu') : t('openMobileMenu')}
      aria-expanded={open}
      aria-controls="mobile-menu"
    >
      <S.HamburgerX {...props}>
        <S.FirstLine {...props} />
        <S.SecondLine {...props} />
        <S.ThirdLine {...props} />
      </S.HamburgerX>
    </S.Button>
  )
}

Hamburger.defaultProps = {
  width: 26,
  height: 16,
  open: false,
  strokeWidth: 3,
  borderRadius: 20,
  color: 'white'
}

export default Hamburger
