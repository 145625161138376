import { modelName, apiFetch, createAsyncThunk } from './business'

const updateRoom = createAsyncThunk(`${modelName}/updateRoom`, async (body) => {
  const { id, ...rest } = body
  const options = {
    path: `/businesses/me/admin/rooms/${id}`,
    method: 'PATCH',
    body: { ...rest }
  }
  const response = await apiFetch(options)

  return response
})

updateRoom.fulfilledReducer = (state, action) => {
  const rooms = state.business.customRooms?.items
  const index = rooms.findIndex((x) => x.id === action.meta.arg.id)
  rooms[index] = Object.assign({}, rooms[index], action.meta.arg)

  state.business.customRooms = {
    ...state.business.customRooms?.items,
    items: rooms
  }
}

export default updateRoom
