import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@all-turtles/mmhmm-ui-kit'
import { unwrapResult } from '@reduxjs/toolkit'

import { useTranslation, Trans } from 'i18n'
import { setDialogVisibility } from 'store/dialog/slice'
import * as sso from 'api/auth/sso'
import apiStatus from 'store/selectors/apiStatus'
import { signOut } from 'utilities/auth'
import { getMe } from 'api/user'
import { Dialog, WarningBox } from 'components'
import Loading from 'components/Loading/Loading'

import * as S from './styles'

const isAppleHiddenEmail = (email) => email.endsWith('privaterelay.appleid.com')

const UnlinkAppDialog = ({ visible, unlinkProviderId }) => {
  const { t } = useTranslation(['common', 'account'])
  const dispatch = useDispatch()
  const status = useSelector((state) =>
    apiStatus({
      state,
      resource: 'business',
      api: 'getBusinessIdp'
    })
  )
  const businessRequiredIdpName = useSelector((state) => {
    const businessRequiredIdp = state.api.business?.businessIdp
    const shouldPreventUnlink =
      status.success && unlinkProviderId === businessRequiredIdp.id
    return shouldPreventUnlink ? businessRequiredIdp.readable_name : null
  })
  const email = useSelector((state) => state.api.user?.me?.email)

  const handleContinue = async () => {
    try {
      const result = await dispatch(sso.unlinkProvider())
      unwrapResult(result)
      await signOut({ context: 'connected-apps' })
    } catch (e) {
      dispatch(getMe())
      dispatch(
        setDialogVisibility({
          name: 'ErrorDialog',
          description: t('somethingWentWrong')
        })
      )
    }
  }

  if (status.loading) {
    return (
      <Dialog visible={visible}>
        <S.Content>
          <Loading visible />
        </S.Content>
      </Dialog>
    )
  }

  if (businessRequiredIdpName) {
    return (
      <Dialog visible={visible}>
        <S.Content>
          {t('account:cantUnlinkAppWarning', {
            provider: businessRequiredIdpName
          })}
        </S.Content>
      </Dialog>
    )
  }

  return (
    <Dialog visible={visible}>
      <S.Content>
        <p>{t('account:unlinkAppWarning')}</p>
        {email && isAppleHiddenEmail(email) && (
          <WarningBox>
            <Trans
              t={t}
              i18nKey="account:unlinkAppWarningForAppleHiddenEmail"
              email={email}
            >
              It looks like your email is currently: <bdi>{{ email }}</bdi>
              Consider changing it before clicking Continue below.
            </Trans>
          </WarningBox>
        )}
        <Button onClick={handleContinue}>Continue</Button>
      </S.Content>
    </Dialog>
  )
}

export default UnlinkAppDialog
