import styled from 'styled-components'

import { Type } from 'styles'
import CardIcons from '../CardIcons/CardIcons'

export const CardRow = styled.div`
  display: flex;
  margin-top: 1rem;
`

export const CardIcon = styled(CardIcons)`
  width: 2rem;
  height: 1.375rem;
  margin-inline-end: 0.875rem;
`

export const CardInfo = styled.p`
  ${Type.body30};
`
