import { Button } from '@all-turtles/mmhmm-ui-kit'
import styled from 'styled-components'
import { Colors, Type } from 'styles'

export const ConnectedAppsComponent = styled.div``

export const H1 = styled.h1`
  margin-bottom: 2.5rem;
`
export const H2 = styled.h2`
  ${Type.header4}
  margin-bottom: 2.25rem;
`

export const Section = styled.section`
  max-width: 672px;
`

export const AppsSection = styled.ul`
  display: flex;
  flex-direction: column;
  margin-bottom: 2.25rem;
`

export const AppsSectionTitle = styled.div`
  ${Type.body30}
  font-weight: 700;
  padding-bottom: 20px;
  border-bottom: 1px solid ${Colors.gray10};
`

export const AppItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem 0;
  border-bottom: 1px solid ${Colors.gray10};
`

export const AppItemName = styled.span`
  display: flex;
  align-items: center;
  gap: 1rem;
`

export const DisconnectButton = styled(Button).attrs({ variant: 'link' })`
  padding: 0 1.5rem;

  &,
  &:hover {
    color: ${Colors.warning};
  }
`
