import styled from 'styled-components'
import { Breakpoints, Colors, Type } from 'styles'

export const Wrapper = styled.div`
  min-height: 25.75rem;
  display: flex;
  flex-flow: column wrap;
  position: relative;
  margin: 0 auto;

  @media (min-width: ${Breakpoints.tablet}) {
    min-height: 30.625rem;
    flex-flow: row nowrap;
  }
`

export const FormContainer = styled.div`
  box-sizing: border-box;
  flex-shrink: 0;
  width: 100%;

  @media (min-width: ${Breakpoints.tablet}) {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    width: 25.75rem;
    padding: 2.625rem 2.813rem;
    background-color: ${Colors.blue40};
  }
`

export const Illustration = styled.div`
  width: 100%;
  height: 25.625rem;
  background-image: ${(props) => `url(${props.illustration})`};
  background-color: ${(props) => props.backgroundColor || Colors.brand};
  background-size: cover;
  margin-top: 1.625rem;

  @media (min-width: ${Breakpoints.tablet}) {
    background-position: center;
    margin-top: 0;
    height: auto;
  }

  [dir='rtl'] & {
    transform: scaleX(-1);
  }
`

export const Header = styled.h2`
  ${Type.header3};
  margin-bottom: 2rem;
`
