import { createAsyncThunk } from '@reduxjs/toolkit'

import apiFetch from '../apiFetch'

const redeemUserInviteToken = createAsyncThunk(
  'user/redeemUserInviteToken',
  async ({ verifier }) => {
    const options = {
      path: '/users/me/business',
      method: 'POST',
      body: { verifier }
    }

    const response = await apiFetch(options)

    return response
  }
)

export default redeemUserInviteToken
