import { useState } from 'react'
import { func, string } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { captureException } from 'utilities/sentry'
import { Button } from '@all-turtles/mmhmm-ui-kit'

import {
  getBusinessSettings,
  resetAllLinks,
  resetBusinessLink
} from 'api/business'
import { Trans, useTranslation } from 'i18n'
import viewData from './viewData'
import * as S from './styles'

const InviteMembersLinkConfirm = ({ goBack, view }) => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const { t } = useTranslation(['common', 'account', 'business'])
  const businessName = useSelector(
    (state) => state.ui?.businessInfo?.business?.name
  )

  const ctaApi = view === 'newLink' ? resetBusinessLink : resetAllLinks
  const { bodyTextKey, ctaTextKey } = viewData[view]

  const handleCtaClick = async () => {
    setLoading(true)
    try {
      await dispatch(ctaApi())
      await dispatch(getBusinessSettings())
      goBack()
    } catch (error) {
      setLoading(false)
      captureException(error, (s) => s.setExtra('view', view))
    }
  }

  return (
    <>
      <S.H1>{t('account:areYouSure')}</S.H1>
      {view === 'resetAllLinks' ? (
        <Trans
          i18nKey="business:resetLinksBody"
          t={t}
          businessName={businessName}
        >
          All previous links to join <bdi>{{ businessName }}</bdi> will be
          deactivated. To invite someone, you must share the new link.
        </Trans>
      ) : (
        <p>{t(bodyTextKey, { businessName })}</p>
      )}
      <S.ButtonRow>
        <Button
          variant="danger"
          onClick={handleCtaClick}
          loading={loading}
          type="button"
        >
          {t(ctaTextKey)}
        </Button>
        <Button variant="secondary" onClick={goBack} type="button">
          {t('cancel')}
        </Button>
      </S.ButtonRow>
    </>
  )
}

InviteMembersLinkConfirm.propTypes = {
  view: string,
  goBack: func
}

export default InviteMembersLinkConfirm
