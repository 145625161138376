import styled from 'styled-components'
import { Button } from '@all-turtles/mmhmm-ui-kit'

import { Loading } from 'components'
import { Arrow } from 'components/Icons'
import { Breakpoints, Colors, Type } from 'styles'

export const BusinessMembersComponent = styled.div`
  width: 100%;
`

export const StyledLoading = styled(Loading)`
  margin-top: 5rem;
  margin: 0 auto;
`

export const Title = styled.h2`
  ${Type.header3};
  margin-bottom: 1rem;
  margin-inline-end: 1rem;

  @media (min-width: ${Breakpoints.tablet}) {
    margin-bottom: 0;
  }
`

export const StyledButton = styled(Button)`
  margin-bottom: 1rem;

  @media (min-width: ${Breakpoints.tablet}) {
    margin-bottom: 0;
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  flex-wrap: wrap;
`

export const TableHeading = styled(Header)`
  margin-bottom: 0;
`

export const BorderTableHeading = styled(TableHeading)`
  padding-bottom: 1.25rem;
  border-bottom: 1px solid ${Colors.gray10};
`

export const TableTitle = styled.h3`
  ${Type.header4};
`

export const TableWrap = styled.div`
  margin-bottom: 3rem;
`

export const ArrowLink = styled.a`
  ${Type.body30};
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }
`

export const StyledArrow = styled(Arrow)`
  height: 1.5625rem;
  margin-inline-start: 0.75rem;

  [dir='rtl'] & {
    transform: scaleX(-1);
  }
`
