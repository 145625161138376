const searchTableFields = ({ t }) => ({
  search: {
    field: 'search',
    placeholder: t('common:search'),
    label: t('common:search'),
    visuallyHideLabel: true,
    type: 'text',
    visuallyHideError: true
  }
})

export default searchTableFields
