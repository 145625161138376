import styled from 'styled-components'

export const DialogWrap = styled.div`
  max-width: 33.75rem;
  padding: 0.5rem 1.25rem;
`

export const H1 = styled.h1`
  margin-bottom: 2rem;
`

export const Buttons = styled.div`
  display: flex;
  flex-flow: row-reverse;
  justify-content: center;
  margin-top: 2.75rem;

  & button:not(:last-child) {
    margin-inline-start: 1.5rem;
  }
`
