const Facebook = (props) => {
  return (
    <svg
      width="35"
      height="34"
      viewBox="0 0 35 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby="facebook-title"
      role="img"
      {...props}
    >
      <title id="facebook-title">Facebook</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.8014 24.1207C25.8014 24.5897 25.4213 24.9698 24.9523 24.9698H21.0317V19.0116H23.0316L23.3312 16.6898H21.0317V15.2075C21.0317 14.5352 21.2186 14.0767 22.1825 14.0767L23.4121 14.0763V11.9997C23.1992 11.9712 22.4696 11.9079 21.6205 11.9079C19.8477 11.9079 18.634 12.99 18.634 14.9774V16.6898H16.6291V19.0116H18.634V24.9698H11.2651C10.7961 24.9698 10.416 24.5897 10.416 24.1207V10.4336C10.416 9.96459 10.7961 9.58447 11.2651 9.58447H24.9523C25.4213 9.58447 25.8014 9.96459 25.8014 10.4336V24.1207"
        fill="black"
      />
    </svg>
  )
}

export default Facebook
