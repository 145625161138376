import PropTypes from 'prop-types'
import * as S from './styles'
import { Link, useTranslation } from 'i18n'

const Back = ({ backToAccount, ...rest }) => {
  const { t } = useTranslation('common')

  const text = backToAccount ? t('backToAccount') : t('back')

  return (
    <Link href="/" passHref>
      <S.BackComponent {...rest}>{text}</S.BackComponent>
    </Link>
  )
}

Back.propTypes = {
  /**
   * Determines what the back button should say.
   * If we have any more options for this,
   * we should let the components pass in any string
   * and get rid of this boolean.
   */
  backToAccount: PropTypes.bool
}

Back.defaultProps = {
  backToAccount: false
}

export default Back
