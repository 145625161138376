import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useDispatch, useSelector } from 'react-redux'

import { useTranslation } from 'i18n'
import { Layout } from 'components'
import handleTokens from './handleTokens'
import * as S from './styles'

const EmailVerificationHandler = ({ children }) => {
  const router = useRouter()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const currentUser = useSelector((state) => state.api.user?.me)

  const verifyEmailToken = router.query?.verify
  const businessInviteToken = router.query?.invite
  const joinLinkToken = router.query?.join

  useEffect(() => {
    const doHandleTokens = async () => {
      const nextStep = await handleTokens({
        dispatch,
        verifyEmailToken,
        businessInviteToken,
        joinLinkToken,
        currentUser,
        t
      })

      // If no explicit next route, reload current path without query params
      switch (nextStep) {
        case 'GO_TO_BUSINESS_EMAIL':
          router.push('/business')
          break
        case 'GO_TO_BUSINESS_JOIN':
          router.push('/business/join')
          break
        case 'GO_TO_BUSINESS_CREATE_ACCOUNT':
          router.push('/business/create-account')
          break
        case 'GO_TO_INDEX':
          router.push('/')
          break
        case 'RELOAD':
        default:
          await router.replace(router.pathname)
          break
      }
    }

    if (verifyEmailToken || businessInviteToken || joinLinkToken) {
      doHandleTokens()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessInviteToken, joinLinkToken, verifyEmailToken])

  /**
   * If a verify or invite token exists, we should be actively doing something with it;
   * once action is complete, page should be re-routed or reloaded without query.
   * If no query found, ignore everything else!
   */
  return router.isReady &&
    !verifyEmailToken &&
    !businessInviteToken &&
    !joinLinkToken ? (
    children
  ) : (
    <Layout hideNav>
      <S.NotificationBar>
        <S.StyledLoading visible />
        <S.Text>{t('business:verifyLoading')}</S.Text>
      </S.NotificationBar>
    </Layout>
  )
}

export default EmailVerificationHandler
