import styled from 'styled-components'

import { Colors, Breakpoints, Type, ZIndex } from 'styles'

export const MobileNavComponent = styled.nav`
  @media (min-width: ${Breakpoints.tablet}) {
    display: none;
  }
`

export const Dialog = styled.div`
  position: absolute;
  z-index: ${ZIndex.zMax};
`

export const NavLink = styled.a`
  ${Type.mobileMenuNavItem};
  text-transform: uppercase;
  text-decoration: none;
  margin-bottom: 24px;
  color: ${Colors.black};
  padding: 6px;
  border-radius: 4px;

  &:focus {
    outline: none;
    border: 0;
    box-shadow: 0 0 3px 3px ${Colors.brand};
  }

  &:focus:not(.focus-visible) {
    outline: none;
    border: 0;
    box-shadow: none;
  }
`

export const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
`
