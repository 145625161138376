import * as S from './styles'
import TableHeaders from '../TableHeaders'
import TableRow from '../TableRow'
import sort from '../sort'

const Table = (props) => {
  const {
    data,
    emptyState,
    filteredData,
    columns,
    sortProp,
    sortInverse,
    onRowClick,
    disableRowClick,
    customCellComponents,
    dateKeys,
    setSort,
    table,
    itemId,
    headerOverrides,
    disableSortForColumns,
    dataTransform,
    transformKeys,
    handleSort
  } = props

  if (!data || data.length < 1 || !columns) return <>{emptyState}</>

  const sortedData =
    filteredData && !handleSort
      ? sort(filteredData, sortProp, sortInverse)
      : filteredData

  return (
    <>
      <S.TableWrap>
        <table cellSpacing="0">
          <thead>
            <TableHeaders
              columns={columns}
              sortProp={sortProp}
              sortInverse={sortInverse}
              setSort={setSort}
              headerOverrides={headerOverrides}
              disableSortForColumns={disableSortForColumns}
            />
          </thead>
          <tbody>
            {sortedData &&
              sortedData.map((item, index) => (
                <TableRow
                  key={index}
                  columns={columns}
                  onRowClick={onRowClick}
                  disableRowClick={disableRowClick}
                  rowData={item}
                  index={index}
                  customCellComponents={customCellComponents}
                  dateKeys={dateKeys}
                  tableName={table}
                  itemId={itemId}
                  dataTransform={dataTransform}
                  transformKeys={transformKeys}
                />
              ))}
          </tbody>
        </table>
      </S.TableWrap>
    </>
  )
}

export default Table
