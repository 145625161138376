import styled from 'styled-components'

import { Colors, Type } from 'styles'
import { cellPadding } from '../styles'

export const Row = styled.tr``

export const Cell = styled.td`
  ${Type.body30};
  padding: ${cellPadding};
  white-space: nowrap;
  border-top: 1px solid ${Colors.gray40};

  &:first-of-type {
    padding-inline-start: 0;
  }
`
