import * as S from './styles'

export default function Loading({
  visible,
  color,
  startMargin = '-7px',
  ...rest
}) {
  return (
    <S.LoadingComponent visible={visible} startMargin={startMargin}>
      <S.StyledSpinner color={color} {...rest} />
    </S.LoadingComponent>
  )
}
