import AvatarProfileImage from 'components/AvatarProfileImage/AvatarProfileImage'
import { isEqual } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { useTranslation } from 'i18n'
import { setDialogVisibility } from 'store/dialog/slice'

import * as S from './styles'

export const EditAccountAvatar = () => {
  const { t } = useTranslation('account')
  const dispatch = useDispatch()
  const currentUser = useSelector((state) => state.api.user?.me, isEqual)

  const profilePhotoInfo = currentUser?.profilePhotoInfo
  const avatarUrl = profilePhotoInfo?.urls?.thumbnail
  const isCustomImage = !profilePhotoInfo?.usesAvatar

  const handleAvatarUploadFlow = () => {
    dispatch(
      setDialogVisibility({
        name: 'AvatarUploadDialog'
      })
    )
  }

  const handleAvatarDeleteFlow = () => {
    dispatch(
      setDialogVisibility({
        name: 'AvatarDeleteDialog'
      })
    )
  }

  return (
    <AvatarContainer>
      <Clickable onClick={handleAvatarUploadFlow}>
        <AvatarProfileImage avatarUrl={avatarUrl} />
      </Clickable>
      <S.TextButton onClick={handleAvatarUploadFlow}>
        {t('changeAvatar')}
      </S.TextButton>
      {isCustomImage && (
        <S.TextButton onClick={handleAvatarDeleteFlow} variant="destructive">
          {t('deleteAvatar')}
        </S.TextButton>
      )}
    </AvatarContainer>
  )
}

const Clickable = styled.div`
  cursor: pointer;
`

const AvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 2rem;
  gap: 8px;
  width: min-content;
`
