import PropTypes from 'prop-types'

import * as S from './styles'

const RadioPill = ({
  field,
  onChange,
  label,
  selected,
  containerStyles,
  value,
  ...rest
}) => {
  return (
    <S.RadioPillComponent
      htmlFor={value}
      selected={selected}
      css={containerStyles}
    >
      <S.CheckboxAndLabel>
        <S.CheckboxWrap>
          <S.StyledRadio {...rest} value={value} id={value} />
          <S.Border>
            <S.StyledCheck />
          </S.Border>
        </S.CheckboxWrap>
        {label && <S.Label>{label}</S.Label>}
      </S.CheckboxAndLabel>
    </S.RadioPillComponent>
  )
}

RadioPill.propTypes = {
  /** The name of the radio */
  label: PropTypes.string,

  /** onChange event */
  onChange: PropTypes.func
}

export default RadioPill
