import { useSelector } from 'react-redux'

import { Link, useTranslation } from 'i18n'
import * as S from './styles'

const BillingInfoSection = ({ businessPermissions }) => {
  const { t } = useTranslation(['account', 'common'])
  const billingInfo = useSelector((state) => state.api.business?.billingInfo)

  return (
    <S.Section>
      <S.Content>
        <S.Header>{t('taxIdAddressLong')}</S.Header>
        <S.Body>{t('billingInfoDesc')}</S.Body>
        <S.BillingInfoWrapper>
          {billingInfo?.taxID && (
            <div>
              <S.BillingInfoLabel>{t('taxId')}</S.BillingInfoLabel>
              <S.Body>{billingInfo.taxID}</S.Body>
            </div>
          )}
          {billingInfo?.billingAddress && (
            <div>
              <S.BillingInfoLabel>{t('billingAddress')}</S.BillingInfoLabel>
              <S.Body>{billingInfo.billingAddress}</S.Body>
            </div>
          )}
        </S.BillingInfoWrapper>
      </S.Content>
      {businessPermissions.canModifyBilling && (
        <Link href="/business/billing-info" passHref>
          <S.AnchorBottomArrowLink>
            {t('common:edit')}
            <S.StyledArrow height="20" width="11" role="presentation" />
          </S.AnchorBottomArrowLink>
        </Link>
      )}
    </S.Section>
  )
}

export default BillingInfoSection
