import formatDate from 'utilities/formatDate'
import DownloadReceipt from '../DownloadReceipt/DownloadReceipt'
import * as S from './styles'

const MobilePaymentHistory = ({ data }) => {
  return (
    <S.MobilePaymentHistoryComponent>
      {data.map((item) => {
        const date = formatDate(item.date)
        return (
          <S.Li key={item.id}>
            <S.Wrap>
              <S.Date>{date}</S.Date>
              <S.Id>{item.id}</S.Id>
              <DownloadReceipt {...item} />
            </S.Wrap>
            <S.Amount>{item.amount}</S.Amount>
          </S.Li>
        )
      })}
    </S.MobilePaymentHistoryComponent>
  )
}

export default MobilePaymentHistory
