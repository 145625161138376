import { useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'

import * as S from './styles'
import { useTranslation } from 'i18n'
import apiStatus from 'store/selectors/apiStatus'
import { withInformed, Select, HandleAction } from 'components'
import { Button } from '@all-turtles/mmhmm-ui-kit'
import { businessMemberRoleFields } from 'data'

const apiStatusSelector = (state) =>
  apiStatus({
    state,
    resource: 'business',
    api: 'updateMemberRole'
  })

const UpdateMemberRoleForm = ({ name, email }) => {
  const { t } = useTranslation(['common', 'business'])
  const status = useSelector(apiStatusSelector, isEqual)
  const currentUserRole = useSelector(
    (state) => state.api.user?.me?.businessRole,
    isEqual
  )

  const fields = businessMemberRoleFields({ t, currentUserRole })

  return (
    <S.UpdateMemberRoleFormComponent>
      <HandleAction
        resource="business"
        api="updateMemberRole"
        stayOnPage
        closeAllDialogsOnSuccess
      />
      <S.InfoAndSelect>
        <S.MemberInfo>
          <S.Name>
            <bdi>{name}</bdi>
          </S.Name>
          <S.Email>
            <bdi>{email}</bdi>
          </S.Email>
        </S.MemberInfo>
        <Select {...fields.role} containerStyles={S.roleStyles} />
      </S.InfoAndSelect>
      <Button loading={status?.loading}>{t('save')}</Button>
    </S.UpdateMemberRoleFormComponent>
  )
}

export default withInformed(UpdateMemberRoleForm)
