import { useDispatch, useSelector } from 'react-redux'

import * as S from './styles'
import { Dialog, UpdateMemberRoleForm } from 'components'
import { Trans, useTranslation } from 'i18n'
import { updateMemberRole } from 'api/business'

const BusinessMemberPermissionsDialog = ({ visible, member }) => {
  const { t } = useTranslation('business')
  const dispatch = useDispatch()
  const currentUser = useSelector((state) => state.api.user?.me)
  const businessName = currentUser?.businessName

  const handleSubmit = (values) => {
    dispatch(
      updateMemberRole({
        role: values.role,
        userId: member.id
      })
    )
  }

  return (
    <Dialog visible={visible} label={t('changePermissions')}>
      <S.Wrap>
        <S.Title>{t('changePermissions')}</S.Title>
        <S.P>
          <Trans
            i18nKey="changePermissionsDescription"
            t={t}
            businessName={businessName}
          >
            Set this account's permission level to Member, Admin or Owner of
            <bdi>{{ businessName }}</bdi>.
          </Trans>
        </S.P>
        <UpdateMemberRoleForm
          onSubmit={handleSubmit}
          initialValues={{ role: member.role }}
          {...member}
        />
      </S.Wrap>
    </Dialog>
  )
}

export default BusinessMemberPermissionsDialog
