import { useEffect } from 'react'
import { number, string } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { setPagination } from 'store/pagination/slice'
import { useTranslation } from 'i18n'
import * as S from './styles'

const Pagination = ({ pageCount, name }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('common')
  const pages = Array(pageCount)
    .fill()
    .map((_, index) => index)

  const currentPage =
    useSelector((state) => state.pagination?.[name]?.page) || 0

  const setPage = (page) => {
    dispatch(setPagination({ [name]: { page } }))
  }

  useEffect(() => {
    // Reset pagination to first page on unmount
    return () => {
      setPage(0)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <S.PaginationComponent>
      <S.StyledButton
        onClick={() => setPage(currentPage - 1)}
        disabled={currentPage === 0}
        title={t('previousPage')}
      >
        <S.BackArrow aria-label={t('previousPage')} />
      </S.StyledButton>
      <S.PageButtonsWrapper>
        {pages.map((page) => (
          <S.StyledButton
            key={`page-index-${page}`}
            onClick={() => setPage(page)}
            disabled={currentPage === page}
            activePage={currentPage === page}
          >
            {page + 1}
          </S.StyledButton>
        ))}
      </S.PageButtonsWrapper>
      <S.StyledButton
        onClick={() => setPage(currentPage + 1)}
        disabled={currentPage === pageCount - 1}
        title={t('nextPage')}
      >
        <S.NextArrow aria-label={t('nextPage')} />
      </S.StyledButton>
    </S.PaginationComponent>
  )
}

Pagination.propTypes = {
  /** content key, to be store and accessed in pagination store */
  name: string.isRequired,
  pageCount: number.isRequired
}

export default Pagination
