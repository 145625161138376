import { useSelector } from 'react-redux'

import formatDate from 'utilities/formatDate'
import useSubscriptionLength from 'hooks/useSubscriptionLength'
import { Link, Trans, useTranslation } from 'i18n'
import * as S from './styles'

const SubscriptionSection = ({ businessPermissions, subscription }) => {
  const { t } = useTranslation(['common', 'account', 'business'])

  /**
   * Subscription status may be:
   * - active (bizStatus == active, subStatus == active)
   * - cancelled (currently active but will be deactivated at end of billing cycle;
   *      bizStatus == active, subStatus == non_renewing)
   * - deactivated (no longer active; bizStatus == deactivated, subStatus != active)
   */
  const businessStatus = useSelector(
    (state) => state.api.business?.businessSettings?.status
  )
  let status = 'active'
  if (subscription?.status && subscription.status !== 'active') {
    status = businessStatus === 'active' ? 'cancelled' : 'deactivated'
  }

  const nextDate =
    status !== 'active' ? subscription?.endDate : subscription?.nextChargeDate
  const formattedDate = formatDate(nextDate)

  const subscriptionData = {
    active: {
      header: useSubscriptionLength(
        subscription?.nextPlanId || subscription?.planId
      ),
      body: (
        <Trans i18nKey="account:nextPayment">
          Next payment will occur automatically on
          <S.NoWrap>{{ renewalDate: formattedDate }}</S.NoWrap>.
        </Trans>
      ),
      href: '/business/subscription',
      cta: t('manage')
    },
    cancelled: {
      header: t('account:nonRenewingSub'),
      body: (
        <Trans i18nKey="business:serviceEndsOn">
          Service will end on <S.NoWrap>{{ date: formattedDate }}</S.NoWrap>.
        </Trans>
      ),
      href: '/business/reactivate',
      cta: t('business:reactivateTitle')
    },
    deactivated: {
      header: t('account:cancelled'),
      body: null,
      href: '/business/reactivate',
      cta: t('business:reactivateTitle')
    }
  }

  return (
    <S.Section>
      <S.Content>
        <S.Header>{subscriptionData[status].header}</S.Header>
        <S.Body>{subscriptionData[status].body}</S.Body>
      </S.Content>
      {businessPermissions.canModifyBilling && (
        <Link href={subscriptionData[status].href} passHref>
          <S.ArrowLink>
            {subscriptionData[status].cta}
            <S.StyledArrow height="20" width="11" role="presentation" />
          </S.ArrowLink>
        </Link>
      )}
    </S.Section>
  )
}

export default SubscriptionSection
