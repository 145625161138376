import { useTranslation } from 'i18n'
import { useDispatch, useSelector } from 'react-redux'
import { captureException } from 'utilities/sentry'
import isEqual from 'lodash/isEqual'

import * as S from './styles'
import { requestEduPremium } from 'api/user'
import { setDialogVisibility } from 'store/dialog/slice'
import { setToastVisibility } from 'store/toast/slice'
import getErrorMessage from 'utilities/getErrorMessage'
import { HandleAction } from 'components'
import apiStatus from 'store/selectors/apiStatus'

const apiStatusSelector = (state) =>
  apiStatus({
    state,
    resource: 'user',
    api: 'requestEduPremium'
  })

const EduVerify = () => {
  const dispatch = useDispatch()

  const { t } = useTranslation('edu')

  const title = t('verifyEmail')
  const description = t('verifyDescription')
  const resend = t('resend')
  const request = useSelector((state) => state.api.user?.requestEduPremium)
  const status = useSelector(apiStatusSelector, isEqual)
  const email = request?.requestBody.email

  const handleSubmit = () => {
    dispatch(requestEduPremium(request?.requestBody))
  }

  const handleError = () => {
    const error = request?.error
    const errorMsg = getErrorMessage(error?.code, t)

    captureException(error)

    dispatch(
      setDialogVisibility({
        name: 'ErrorDialog',
        description: errorMsg || t('somethingWentWrong'),
        showSupportLink: !errorMsg
      })
    )
  }

  const handleSuccess = () => {
    dispatch(
      setToastVisibility({
        name: 'SuccessToast',
        title: t('common:success')
      })
    )
  }

  return (
    <S.EduVerifyComponent>
      <HandleAction
        resource="user"
        api="requestEduPremium"
        onError={handleError}
        onSuccess={handleSuccess}
      />
      <S.Title>{title}</S.Title>
      <S.P>{description}</S.P>
      <S.Email>
        <bdi>{email}</bdi>
      </S.Email>
      <S.StyledButton onClick={handleSubmit} isLoading={status.loading}>
        {resend}
      </S.StyledButton>
    </S.EduVerifyComponent>
  )
}

export default EduVerify
