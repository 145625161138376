import { createAsyncThunk } from '@reduxjs/toolkit'

import apiFetch from '../apiFetch'

export const modelName = 'localization'

export const getEmailLocales = createAsyncThunk(
  'localization/getEmailLocales',
  async () => {
    const options = {
      path: '/localization/email/languages',
      method: 'GET'
    }

    const response = await apiFetch(options)

    return response
  }
)

getEmailLocales.storeKey = 'emailLocales'
