import { Auth } from '@aws-amplify/auth'
import apiUrl from './apiUrl'

const apiFetch = async ({
  path,
  absolutePath,
  method,
  body,
  authorized,
  customHeaders,
  isFile,
  noContentAllowed,
  ...rest
}) => {
  const url = absolutePath ? path : `${apiUrl}${path}`

  const headers = customHeaders || {
    'Content-Type': 'application/json'
  }

  if (authorized !== false) {
    const currentSession = await Auth.currentSession()
    headers.Authorization = `Bearer ${currentSession.idToken.jwtToken}`
  }

  const response = await fetch(url, {
    method,
    body: isFile ? body : JSON.stringify(body),
    headers,
    ...rest
  })

  if (response?.status === 204) {
    return {}
  }
  if (response?.status === 200 && noContentAllowed) {
    return {}
  }
  const json = await response.json()

  if (!response.ok) {
    // Hacky way to make this an error actually... for Sentry sake...
    // TODO: Adjust this while refactoring to react-query
    const apiFetchErrorMessage = `APIFetchError (status: ${response.status}, message: ${json?.message}, code: ${json?.code})`
    if (!json) throw new Error(apiFetchErrorMessage)
    // Cannot set the message for all errors, because somewhere in the app it may be used...
    if (json && typeof json === 'object' && !json.message)
      json.message = apiFetchErrorMessage
    // Make it look better in the Sentry
    Object.setPrototypeOf(json, Error.prototype)
    json.stack = new Error('').stack
    throw json
  }

  return json
}

export default apiFetch
