import { useSelector, useDispatch } from 'react-redux'
import isEqual from 'lodash/isEqual'

import { HandleAction, Loading } from 'components'
import apiStatus from 'store/selectors/apiStatus'
import { useRouter } from 'next/router'
import { useTranslation } from 'i18n'
import { sendVerificationTokenEmail } from 'api/user'

const apiStatusSelector = (state) =>
  apiStatus({
    state,
    resource: 'auth',
    api: 'verifyEmailWithToken'
  })

const VerifyEmail = () => {
  const { t } = useTranslation(['common', 'business'])
  const status = useSelector(apiStatusSelector, isEqual)
  const router = useRouter()
  const dispatch = useDispatch()

  const onSuccess = () => {
    router.push('/')
  }

  const onError = (error) => {
    console.error({ error })
  }

  const handleResend = () => {
    dispatch(sendVerificationTokenEmail())
  }

  return (
    <div>
      <HandleAction
        resource="auth"
        api="verifyEmailWithToken"
        onSuccess={onSuccess}
        onError={onError}
        toastDescription={t('verifyEmailSuccess')}
      />
      {status.loading && (
        <div>
          {t('loadingEmailVerification')} <Loading />
        </div>
      )}
      {status.error && (
        <div>
          <div>{t('error')}</div>
          <button onClick={handleResend}>{t('business:resend')}</button>
        </div>
      )}
    </div>
  )
}

export default VerifyEmail
