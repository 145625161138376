import { createAsyncThunk } from '@reduxjs/toolkit'

import apiFetch from '../apiFetch'

const deleteAccount = createAsyncThunk(
  'user/deleteAccount',
  async ({ userId, verificationCode }) => {
    const options = {
      path: `/users/${userId}`,
      method: 'DELETE',
      body: { verificationCode },
      noContentAllowed: true
    }

    let response

    try {
      await apiFetch(options)
      response = { accountDeleted: true }
    } catch (e) {
      response = {
        accountDeleted: false,
        verificationCodeError: e.message
          ? e.message
          : 'VerificationCodeErrorUnknown'
      }
    }

    return { ...response }
  }
)

deleteAccount.storeKey = 'accountDeletion'

export default deleteAccount
