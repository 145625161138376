import styled, { css } from 'styled-components'

import { Breakpoints } from 'styles'

export const PlanSelectorComponent = styled.div`
  width: 340px;
`

export const StyledRadioPill = css`
  @media (min-width: ${Breakpoints.tablet}) {
    margin-bottom: 10px;
  }
`
