import styled from 'styled-components'
import { Button } from '@all-turtles/mmhmm-ui-kit'

import { Arrow } from 'components/Icons'
import { Breakpoints, Type } from 'styles'

export const Container = styled.div`
  padding-bottom: 1rem;
`

export const H2 = styled.h2`
  ${Type.header3};
  margin-bottom: 2.25rem;
`

export const Field = styled.div`
  display: flex;
  flex-direction: column;
`

export const DisclosureField = styled(Field)`
  margin-bottom: 2.25rem;
`

export const PanelWrapper = styled.div`
  width: 100%;
`

export const ToggleButton = styled(Button)`
  display: flex;
  align-items: center;
  margin-bottom: 2.25rem;
`

export const Spacer = styled.span`
  padding-inline-end: 0.75rem;
`

export const StyledArrow = styled(Arrow)`
  height: 1.5625rem;
  transform: rotate(90deg);
  transition: all 300ms ease;

  ${ToggleButton}[data-state='open'] & {
    transform: rotate(270deg);
  }
`

export const Label = styled.label`
  ${Type.header4};
  margin-bottom: 1rem;

  @media (min-width: ${Breakpoints.small}) {
    margin-bottom: 0.5rem;
  }
`

export const Info = styled.p`
  ${Type.body30};
  margin-bottom: 1rem;
`
