import styled from 'styled-components'

import { Button } from '@all-turtles/mmhmm-ui-kit'
import { Breakpoints, Colors, Type } from 'styles'
import LoadingInput from '../LoadingInput/LoadingInput'

export const Wrap = styled.div`
  flex-grow: 1;
  max-width: 33.4375rem;
`

export const Field = styled.div`
  position: relative;
  margin-bottom: 2rem;
`

export const Submit = styled(Button)`
  margin-top: 2.9375rem;
  width: 14.125rem;
  max-width: 90vw;

  @media (min-width: ${Breakpoints.tablet}) {
    margin-top: 0;
    width: auto;
    min-width: 8rem;
    margin-inline-start: auto;
  }
`

export const Fields = styled.div`
  display: flex;

  ${Field} {
    flex: 1;
    margin-inline-start: 1.4375rem;

    &:first-child {
      margin-inline-start: 0;
    }
  }
`

export const Label = styled.label`
  ${Type.body30};
  color: ${Colors.midnight};
  pointer-events: none;
  z-index: 1;
  font-weight: normal;
  transition: all 0.28s ease;
  margin-bottom: 0.625rem;
`

export const Bar = styled.i`
  position: relative;
  border-bottom: 0.0625rem solid #999;
  display: block;

  &:before {
    content: '';
    height: 0.125rem;
    width: 0;
    left: 50%;
    bottom: -0.0625rem;
    position: absolute;
    background: ${Colors.brand};
    transition: left 0.28s ease, width 0.28s ease;
    z-index: 2;
  }
`

export const TermsAndSubmit = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2rem;
  flex-direction: column;

  @media (min-width: ${Breakpoints.tablet}) {
    flex-direction: row;
    gap: 1rem;
  }
`

export const H2 = styled.h2`
  display: none;

  @media (min-width: ${Breakpoints.desktop}) {
    ${Type.header3};
    display: block;
    padding-inline-end: 1.25rem;
  }
`

export const PaymentAndPlan = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.5rem;
`

export const StyledLoadingInput = styled(LoadingInput)`
  margin-bottom: 2.5rem;
`

export const SubscribeLoading = styled.div`
  flex-grow: 1;
  max-width: 33.4375rem;
`
export const PlanSelectorLoading = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex: 1;
`
export const RadioPillLoading = styled.div`
  height: 2.5rem;
  border-radius: 1.5rem;
  border: 1px solid ${Colors.gray};
  width: 80%;
  margin-bottom: 0.625rem;
`

export const BusinessTerms = styled.p`
  ${Type.body30};
`
