import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dialog, AvatarDropzone } from 'components'
import { setDialogVisibility } from 'store/dialog/slice'
import { useTranslation } from 'i18n'
import { mixpanelService, eventTypes } from 'analytics'

import * as S from './styles'
import { getMe, uploadAvatar } from 'api/user'

const AvatarUploadDialog = ({ visible }) => {
  const { t } = useTranslation('account')
  const dispatch = useDispatch()

  const isReplacingDefaultAvatar = useSelector(
    (state) => state.api.user?.me?.profilePhotoInfo?.usesAvatar
  )

  useEffect(() => {
    mixpanelService.track(eventTypes.profilePhotoUpload, {
      replace_existing: !isReplacingDefaultAvatar
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCancel = () => {
    dispatch(setDialogVisibility({ name: null }))
  }

  const handleSave = async (file) => {
    await dispatch(uploadAvatar({ file }))
    await dispatch(getMe())
    dispatch(setDialogVisibility({ name: null }))
  }

  return (
    <Dialog visible={visible} label={t('avatarDialog')}>
      <S.DialogContainer>
        <S.Title>{t('setAvatar')}</S.Title>
        <AvatarDropzone onCancel={handleCancel} onSave={handleSave} />
      </S.DialogContainer>
    </Dialog>
  )
}

export default AvatarUploadDialog
