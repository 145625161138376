import PropTypes from 'prop-types'

import * as S from './styles'

const Checkbox = ({
  field,
  onChange,
  label,
  value,
  containerStyles,
  errorMessage,
  ...rest
}) => {
  if (!field) {
    throw new Error(
      'The "field" attribute is required on the Checkbox component'
    )
  }

  return (
    <S.CheckboxComponent css={containerStyles}>
      <S.CheckboxAndLabel>
        <S.CheckboxWrap>
          <S.StyledCheckbox
            {...rest}
            type="checkbox"
            id={field}
            field={field}
            value={value}
            onChange={(e) => {
              if (onChange) {
                onChange(e.target.checked)
              }
            }}
          />
          <S.Border>
            <S.StyledCheck role="presentation" />
          </S.Border>
        </S.CheckboxWrap>
        {label && <S.Label htmlFor={field}>{label}</S.Label>}
      </S.CheckboxAndLabel>
    </S.CheckboxComponent>
  )
}

Checkbox.propTypes = {
  /** Unique key to identify this form field */
  field: PropTypes.string.isRequired,

  /** The name of the Checkbox */
  label: PropTypes.string,

  /** onChange event */
  onChange: PropTypes.func
}

export default Checkbox
