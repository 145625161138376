import styled from 'styled-components'
import Tippy from '@tippyjs/react'
import { Colors } from 'styles'

export const StyledTippy = styled(Tippy)`
  background-color: ${Colors.white};
  border-radius: 0.25rem;
  box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.25);
  padding: 0.75rem;
  color: ${Colors.black};

  .tippy-arrow::before {
    color: ${Colors.white};
  }
`
