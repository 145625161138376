import { modelName, apiFetch, createAsyncThunk } from './business'

const resetAllLinks = createAsyncThunk(
  `${modelName}/resetAllLinks`,
  async (body) => {
    const options = {
      path: '/businesses/me/admin/joinLink/all',
      method: 'POST',
      body
    }
    const response = await apiFetch(options)

    return response
  }
)

export default resetAllLinks
