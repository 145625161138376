import styled from 'styled-components'

import { Breakpoints, Sizes } from 'styles'
import { Wordmark } from 'components/Icons'
import MaxWidth from '../MaxWidth/MaxWidth'

export const NavbarComponent = styled.header`
  width: 100vw;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`

export const StyledMaxWidth = styled(MaxWidth)`
  display: flex;
  justify-content: space-between;
  height: ${Sizes.navHeightMobile};
  align-items: center;

  @media (min-width: ${Breakpoints.tablet}) {
    height: ${Sizes.navHeight};
  }
`

export const StyledWordmark = styled(Wordmark)`
  width: 5.875rem;

  @media (min-width: ${Breakpoints.tablet}) {
    width: auto;
  }
`

export const Nav = styled.nav`
  display: none;

  @media (min-width: ${Breakpoints.tablet}) {
    display: flex;
    align-items: center;
  }
`
