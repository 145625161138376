import { useTransition } from 'react-spring/web.cjs'

import * as S from './styles'
import CloseToast from '../CloseToast/CloseToast'

function Toast({ children, visible, label, springConfig, test, ...rest }) {
  const transitions = useTransition(visible, null, {
    from: { opacity: 0, y: 10 },
    enter: { opacity: 1, y: 30 },
    leave: { opacity: 0, y: 10 },
    config: { ...springConfig }
  })

  return (
    <>
      {transitions.map(
        ({ item, props: styles }) =>
          item && (
            <S.Content
              key={item}
              style={{
                opacity: styles.opacity,
                transform: styles.y.interpolate(
                  (value) => `translate3d(-50%, ${value}px, 0px)`
                )
              }}
              role="dialog"
              {...rest}
            >
              <S.StyledCheckCircle aria-hidden="true" />
              {children}
              <CloseToast />
            </S.Content>
          )
      )}
    </>
  )
}

export default Toast
