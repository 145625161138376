import { useSelector } from 'react-redux'
import * as S from './styles'
import { useTranslation } from 'i18n'
import { isAppStoreSubscription } from 'utilities/subscription'

import { SubscriptionNav, BillingHistory } from 'components'

const ManageSubscription = () => {
  const { t } = useTranslation('account')
  const subscription = useSelector((state) => state.api.commerce?.subscription)
  const cancelled = subscription?.status === 'non_renewing'

  const subscriptionService = useSelector(
    (state) => state.api.user?.me?.subscriptionService
  )

  return (
    <S.ManageSubscriptionComponent>
      <h1>{t('subscription')}</h1>
      {cancelled && <p>{t('cancelled')}</p>}
      <S.Content>
        {isAppStoreSubscription(subscriptionService) ? (
          <div>
            <S.H2>{t('appleBilling')}</S.H2>
            <S.Links>
              <div>
                <a
                  href="https://support.apple.com/en-us/HT202039"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('common:learnMore')}
                </a>
              </div>
              <div>
                <a
                  href="https://apps.apple.com/account/subscriptions"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('common:manage')}
                </a>
              </div>
            </S.Links>
          </div>
        ) : (
          <>
            <SubscriptionNav />
            <BillingHistory />
          </>
        )}
      </S.Content>
      <S.StyledBack backToAccount />
    </S.ManageSubscriptionComponent>
  )
}

export default ManageSubscription
