import { useState } from 'react'
import { string } from 'prop-types'
import { useDispatch } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { Button } from '@all-turtles/mmhmm-ui-kit'
import { captureException } from 'utilities/sentry'

import getErrorMessage from 'utilities/getErrorMessage'
import { setDialogVisibility } from 'store/dialog/slice'
import { setToastVisibility } from 'store/toast/slice'
import { useTranslation } from 'i18n'
import { addDomain, getDomains, getAvailableDomains } from 'api/business'

const BusinessAddDomainButton = ({ children, domain }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['business', 'common'])

  const [loading, setLoading] = useState(false)

  const handleError = (err) => {
    const errorMsg = err?.code ? getErrorMessage(err.code, t) : null
    dispatch(
      setDialogVisibility({
        name: 'ErrorDialog',
        description: errorMsg || t('common:somethingWentWrong'),
        showSupportLink: !errorMsg
      })
    )
  }

  const displayToast = () => {
    dispatch(
      setToastVisibility({
        name: 'SuccessToast',
        title: t('common:success'),
        description: t('addDomainSuccess')
      })
    )
  }

  const handleAddDomain = () => {
    setLoading(true)

    dispatch(addDomain({ domain }))
      .then(unwrapResult)
      .then(() => {
        dispatch(getDomains())
        dispatch(getAvailableDomains())
        dispatch(setDialogVisibility({ name: null }))
        displayToast()
      })
      .catch((err) => {
        captureException(err)
        handleError(err)
        setLoading(false)
      })
  }

  return (
    <Button type="button" onClick={handleAddDomain} loading={loading}>
      {children}
    </Button>
  )
}

BusinessAddDomainButton.propTypes = {
  children: string.isRequired,
  domain: string.isRequired
}

export default BusinessAddDomainButton
