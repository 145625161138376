import * as S from './styles'

const RadioCard = ({
  field,
  onChange,
  title,
  description,
  selected,
  containerStyles,
  value,
  ...rest
}) => {
  return (
    <S.RadioCardComponent
      htmlFor={value}
      selected={selected}
      css={containerStyles}
    >
      <S.Wrap>
        <S.StyledRadio {...rest} value={value} id={value} />
        <S.Border>
          <S.StyledCheck />
        </S.Border>
      </S.Wrap>
      <S.Text>
        <S.Title>{title}</S.Title>
        <S.Description>{description}</S.Description>
      </S.Text>
    </S.RadioCardComponent>
  )
}

export default RadioCard
