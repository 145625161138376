import { createAsyncThunk } from '@reduxjs/toolkit'

import apiFetch from '../apiFetch'

const requestEduPremium = createAsyncThunk(
  'user/eduRequest',
  async ({ email, website = '', role = '' }) => {
    const body = { email, website, role }
    const options = {
      path: '/users/me/email/education',
      method: 'POST',
      body
    }

    const response = await apiFetch(options)

    return {
      ...response,
      requestBody: body
    }
  }
)

export default requestEduPremium
