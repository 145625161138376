const Users = (props) => {
  return (
    <svg
      width="18"
      height="12"
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.0366 5.5459C13.4014 5.5459 14.5757 4.32715 14.5757 2.74023C14.5757 1.17236 13.395 0.0107422 12.0366 0.0107422C10.6846 0.0107422 9.49756 1.19775 9.50391 2.75293C9.50391 4.32715 10.6782 5.5459 12.0366 5.5459ZM4.73047 5.69189C5.91748 5.69189 6.93945 4.62549 6.93945 3.24805C6.93945 1.8833 5.91113 0.874023 4.73047 0.874023C3.55615 0.874023 2.51514 1.90869 2.52148 3.26074C2.52148 4.62549 3.5498 5.69189 4.73047 5.69189ZM1.19482 11.4238H6.0127C5.35254 10.4653 6.15869 8.53564 7.52979 7.48193C6.81885 7.01221 5.91113 6.66309 4.73047 6.66309C1.86768 6.66309 0.0776367 8.77686 0.0776367 10.5352C0.0776367 11.1064 0.388672 11.4238 1.19482 11.4238ZM8.05029 11.4238H16.0229C17.0195 11.4238 17.375 11.1382 17.375 10.5796C17.375 8.94189 15.3247 6.68213 12.0366 6.68213C8.74219 6.68213 6.69189 8.94189 6.69189 10.5796C6.69189 11.1382 7.04736 11.4238 8.05029 11.4238Z"
        fill="#737473"
      />
    </svg>
  )
}

export default Users
