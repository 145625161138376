import styled, { css } from 'styled-components'

import { Colors } from 'styles'
import Button from '../Button/Button'

export const ButtonDangerComponent = styled(Button)`
  background-color: ${Colors.warning};
  border: 2px solid ${Colors.warning};

  ${(props) =>
    props.isLoading &&
    css`
      cursor: initial;
      background-color: ${Colors.gray};
      border: 2px solid ${Colors.gray};
      color: ${Colors.gray30};
    `}

  &:hover {
    border: 2px solid ${Colors.warning};
  }

  &:focus {
    border-color: ${Colors.warning};
    box-shadow: 0 0 0 3px white, 0 0 0 6px ${Colors.warning};
  }

  &:focus:not(.focus-visible) {
    border-color: ${Colors.warning};
  }
`
