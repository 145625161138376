/**
 * This component renders text that will be completely hidden visually while remaining
 * "visible" to screen readers. This allows us to provide supplemental content to screen reader
 * users, like hidden labels, error details, navigation instructions or additional context.
 * See: https://accessibility.18f.gov/hidden-content/
 */
import * as S from './styles'

const ScreenReaderOnly = (props) => {
  return (
    <S.ScreenReaderOnlyComponent {...props}>
      {props.children}
    </S.ScreenReaderOnlyComponent>
  )
}

export default ScreenReaderOnly
