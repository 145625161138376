import styled from 'styled-components'

import { Type } from 'styles'
import Back from '../Back/Back'

export const CancelSubscriptionComponent = styled.div``

export const Title = styled.h1`
  margin-bottom: 44px;
`

export const P = styled.p`
  margin-bottom: 2rem;
`

export const Date = styled.span`
  font-weight: ${Type.fontWeights.bold};
  margin-bottom: 30px;
`

export const StyledBack = styled(Back)``
