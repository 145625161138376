import { useTranslation } from 'i18n'

function useSubscriptionLength(planId) {
  const { t } = useTranslation('account')
  let subscriptionLength = t('subscription')

  if (planId || false) {
    if (planId.includes('annual')) {
      subscriptionLength = t('annualLong')
    } else if (planId.includes('monthly')) {
      subscriptionLength = t('monthlyLong')
    }
  }

  return subscriptionLength
}

export default useSubscriptionLength
