import styled from 'styled-components'
import { Button } from '@all-turtles/mmhmm-ui-kit'

import { Check } from 'components/Icons'
import { Colors, Type } from 'styles'

export const LinkSection = styled.div`
  border-bottom: 1px solid ${Colors.gray40};
  margin-bottom: 1.625rem;
`

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin: 1.875rem 0;

  button {
    ${Type.body30};
  }
`

export const SuccessButton = styled(Button)``

export const StyledCheck = styled(Check)`
  width: 1rem;
  height: 1rem;
  margin-inline-end: 1rem;
  background-color: ${Colors.success};
  transition: all 200ms ease;

  path {
    fill: ${Colors.white};
    stroke: ${Colors.white};
  }

  ${SuccessButton}:hover & {
    background-color: ${Colors.white};

    path {
      fill: ${Colors.success};
      stroke: ${Colors.success};
    }
  }
`

export const CreateNewWrapper = styled.div`
  display: flex;
`
