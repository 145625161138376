import { useDispatch } from 'react-redux'

import * as S from './styles'
import { setDialogVisibility } from 'store/dialog/slice'
import { useTranslation } from 'i18n'

const CustomRoom = ({
  id,
  name,
  description,
  thumbnailUrl,
  preview,
  type,
  canAdmin
}) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('common')

  const isVideoPreview = type?.startsWith('video/')

  const handleClick = () => {
    dispatch(
      setDialogVisibility({
        name: 'EditRoom',
        id,
        roomName: name,
        description,
        thumbnailUrl: preview || thumbnailUrl,
        state: 'edit'
      })
    )
  }

  return (
    <S.CustomRoomComponent onClick={handleClick}>
      <S.Wrap>
        <S.Thumbnail
          src={preview || thumbnailUrl}
          as={isVideoPreview ? 'video' : 'img'}
        />
        <S.Meta>
          <S.Name>
            <bdi>{name}</bdi>
          </S.Name>
          <S.Description>
            <bdi>{description}</bdi>
          </S.Description>
        </S.Meta>
      </S.Wrap>
      {canAdmin ? (
        <S.EditButton variant="link" onClick={handleClick}>
          {t('edit')}
        </S.EditButton>
      ) : null}
    </S.CustomRoomComponent>
  )
}

export default CustomRoom
