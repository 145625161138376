import { EMAIL, ONE_NUM, ONE_LOWER_AND_UPPER } from 'utilities/regex'

const passwordValidation = (t) => (value) => {
  if (!value || value.length < 8) return t('passwordLengthError')
  if (!ONE_NUM.test(value)) return t('account:oneNumberError')
  if (!ONE_LOWER_AND_UPPER.test(value))
    return t('account:oneLowerAndUpperError')
}

const passwordAgainValidation = (t) => (value, values) => {
  if (value !== values.newPassword) return t('passwordAgainError')
}

const nameValidation = (t) => (value) => {
  if (!value || value.trim().length === 0) return t('common:fieldRequired')
  if (!/^[\p{L}\p{N} ’'.-]+$/u.test(value)) return t('account:nameValidation')
}

export const getAuthFields = (t) => ({
  email: {
    field: 'username',
    label: t('common:email'),
    placeholder: 'emailPlaceholder',
    // type: 'email',
    required: t('common:fieldRequired'),
    validateOnBlur: true,
    validate: (value) => {
      if (!EMAIL.test(value)) return t('account:invalidEmail')
    },
    /** Emails should not be reversed */
    dir: 'ltr'
  },
  password: {
    field: 'password',
    label: t('password'),
    placeholder: '••••••••',
    type: 'password',
    required: t('common:fieldRequired'),
    validateOnBlur: true,
    toggleVisibility: true
  },
  currentPassword: {
    field: 'currentPassword',
    label: t('common:currentPassword'),
    placeholder: 'currentPasswordPlaceholder',
    type: 'password',
    required: t('common:fieldRequired'),
    validateOnBlur: true,
    toggleVisibility: true
  },
  createPassword: {
    field: 'password',
    label: t('common:createPassword'),
    placeholder: 'passwordPlaceholder',
    type: 'password',
    required: true,
    validateOnBlur: true,
    toggleVisibility: true,
    validate: passwordValidation(t),
    autoComplete: 'new-password',
    visuallyHideError: true
  },
  newPassword: {
    field: 'newPassword',
    label: t('newPassword'),
    placeholder: 'passwordPlaceholder',
    type: 'password',
    required: t('fieldRequired'),
    validateOnBlur: true,
    validate: passwordValidation(t),
    toggleVisibility: true,
    autoComplete: 'new-password'
  },
  newPasswordAgain: {
    field: 'newPasswordAgain',
    label: t('newPasswordAgain'),
    placeholder: 'passwordAgainPlaceholder',
    type: 'password',
    required: t('common:fieldRequired'),
    validateOnBlur: true,
    validate: passwordAgainValidation(t),
    toggleVisibility: true,
    autoComplete: 'new-password'
  },
  code: {
    field: 'code',
    label: t('code'),
    placeholder: 'codePlaceholder',
    validateOnBlur: true,
    required: t('common:fieldRequired'),
    /** This field isn't localized (likely just integers) and should not be reversed */
    dir: 'ltr'
  },
  name: {
    field: 'name',
    label: t('name'),
    placeholder: 'namePlaceholder',
    required: t('common:fieldRequired'),
    validateOnBlur: true,
    validate: nameValidation(t)
  },
  firstName: {
    field: 'firstName',
    label: t('common:firstName'),
    placeholder: 'firstNamePlaceholder',
    required: t('common:fieldRequired'),
    validateOnBlur: true,
    validate: nameValidation(t),
    autoComplete: 'given-name'
  },
  lastName: {
    field: 'lastName',
    label: t('common:lastName'),
    placeholder: 'lastNamePlaceholder',
    required: t('common:fieldRequired'),
    validateOnBlur: true,
    validate: nameValidation(t),
    autoComplete: 'family-name'
  },
  zipCode: {
    field: 'zipCode',
    label: t('common:zip'),
    placeholder: 'zipPlaceholder',
    required: t('common:fieldRequired'),
    validateOnBlur: true,
    autoComplete: 'postal-code',
    /** Numbers and zip codes should not be reversed */
    dir: 'ltr'
  }
})
