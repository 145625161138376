const Visa = (props) => {
  return (
    <svg
      width="128"
      height="84"
      viewBox="0 0 128 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Visa card"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 6C0 2.68629 2.68629 0 6 0H122C125.314 0 128 2.68629 128 6V78C128 81.3137 125.314 84 122 84H6C2.68629 84 0 81.3137 0 78V6Z"
        fill="#F4F6F7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 6C0 2.68629 2.68629 0 6 0H122C125.314 0 128 2.68629 128 6V20H0V6Z"
        fill="#4095DB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 64H128V78C128 81.3137 125.314 84 122 84H6C2.68629 84 0 81.3137 0 78V64Z"
        fill="#F4B662"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.4581 31.8268C77.3131 31.3606 75.5124 30.852 73.2691 30.852C67.5439 30.852 63.5138 34.0041 63.4804 38.5172C63.4439 41.8552 66.3562 43.7173 68.5518 44.8269C70.8083 45.9643 71.5664 46.6921 71.5574 47.7091C71.5419 49.2645 69.7563 49.9773 68.0914 49.9773C65.773 49.9773 64.5412 49.6264 62.6368 48.7593L61.8919 48.3896L61.0821 53.5883C62.4324 54.2352 64.9357 54.7967 67.5336 54.8262C73.6182 54.8262 77.5718 51.7125 77.6181 46.8931C77.639 44.2459 76.0959 42.239 72.7541 40.5824C70.7304 39.5057 69.4884 38.7923 69.5026 37.7022C69.504 36.7367 70.5519 35.7036 72.82 35.7036C74.7141 35.6721 76.0853 36.1238 77.1538 36.5957L77.6738 36.8616L78.4581 31.8268ZM51.662 54.4818L55.2847 31.2567H61.0755L57.4531 54.4818H51.662ZM88.4757 39.7561C88.4757 39.7561 86.6488 44.9126 86.1689 46.2476H90.9763C90.7474 45.0865 89.6362 39.5431 89.6362 39.5431L89.2454 37.54C89.0908 38.0002 88.8751 38.6101 88.7111 39.0739L88.711 39.0743L88.7108 39.0749C88.5648 39.488 88.4598 39.7848 88.4757 39.7561ZM93.3129 31.2804H88.8377C87.4496 31.2804 86.4106 31.6913 85.8025 33.2056L77.2028 54.49H83.2851C83.2851 54.49 84.2773 51.6267 84.5026 50.9984C84.8143 50.9984 86.2848 51.001 87.8436 51.0038L87.8442 51.0038L87.8449 51.0038C89.6028 51.007 91.4725 51.0104 91.9196 51.0104C92.0931 51.8209 92.6247 54.49 92.6247 54.49H97.9995L93.3129 31.2804ZM46.8039 31.2763L41.136 47.1102L40.5289 43.8916L38.4996 33.22C38.1495 31.7482 37.132 31.3119 35.8745 31.2605H26.5362L26.459 31.7417C28.7309 32.3432 30.7624 33.2105 32.5428 34.2893L37.6924 54.4616L43.8211 54.4578L52.9404 31.2763H46.8039Z"
        fill="#0071CE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 6C0 2.68629 2.68629 0 6 0H122C125.314 0 128 2.68629 128 6V78C128 81.3137 125.314 84 122 84H6C2.68629 84 0 81.3137 0 78V6Z"
        fill="#E5E5E5"
        fillOpacity="0.01"
      />
    </svg>
  )
}

export default Visa
