import styled from 'styled-components'
import { Button } from '@all-turtles/mmhmm-ui-kit'

import { Breakpoints, Type } from 'styles'

export const BusinessAllInvitationsComponent = styled.div`
  width: 100%;
`

export const Title = styled.h2`
  ${Type.header3};
  margin-bottom: 1rem;
  margin-inline-end: 1rem;

  @media (min-width: ${Breakpoints.tablet}) {
    margin-bottom: 0;
  }
`

export const StyledButton = styled(Button)`
  margin-bottom: 1rem;

  @media (min-width: ${Breakpoints.tablet}) {
    margin-bottom: 0;
  }
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
  flex-wrap: wrap;
`
