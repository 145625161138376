import upperFirst from 'lodash/upperFirst'
import { useTranslation } from 'i18n'

import * as S from './styles'

const SortableHeader = (props) => {
  const {
    headerOverrides,
    column,
    sortProp,
    sortInverse,
    setSort,
    disableSortForColumns
  } = props

  const { t } = useTranslation(['account', 'common'])

  const display = headerOverrides?.[column] || upperFirst(t(column))

  return disableSortForColumns?.indexOf(column) >= 0 ? (
    <S.Unsortable>{display}</S.Unsortable>
  ) : (
    <S.Sortable
      onClick={() => setSort(column)}
      onKeyDown={(e) => {
        if (e.keyCode === 13) setSort(column)
      }}
      role="button"
      tabIndex="0"
      selected={sortProp === column}
      title={t('common:sortBy', { property: display })}
    >
      {display}
      <S.StyledSelectArrow
        inverted={sortInverse}
        selected={sortProp === column}
        aria-label={t('common:sortBy', { property: display })}
      />
    </S.Sortable>
  )
}

export default SortableHeader
