import { modelName, apiFetch, createAsyncThunk } from './business'

const uploadRoom = createAsyncThunk(
  `${modelName}/uploadRoom`,
  async ({ path, file }) => {
    const options = {
      path,
      absolutePath: true,
      method: 'PUT',
      body: file,
      isFile: true,
      noContentAllowed: true,
      customHeaders: {
        'Content-Type': file.type
      },
      authorized: false
    }
    const response = await apiFetch(options)

    return response
  }
)

/**
 * When uploading succeeds, use the local file for the thumbanil preview
 * because the cloud thumbnail is not ready yet.
 */
uploadRoom.fulfilledReducer = (state, action) => {
  const { arg } = action.meta
  const rooms = state.business.customRooms?.items
  const index = rooms.findIndex((x) => x.id === arg.id)

  rooms[index] = Object.assign({}, rooms[index], {
    preview: URL.createObjectURL(arg.file),
    type: arg.file.type
  })

  state.business.customRooms = {
    ...state.business.customRooms?.items,
    items: rooms
  }
}

export default uploadRoom
