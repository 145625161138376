import { modelName, apiFetch, createAsyncThunk } from './business'
import getBusinessSettings from './getBusinessSettings'

const getBusinessIdp = createAsyncThunk(
  `${modelName}/getBusinessIdp`,
  async (_, thunkAPI) => {
    const getBusinessId = () =>
      thunkAPI.getState().api.business?.businessSettings?.id
    if (!getBusinessId()) {
      await thunkAPI.dispatch(getBusinessSettings())
    }
    if (getBusinessId()) {
      return apiFetch({
        path: `/public/businesses/${getBusinessId()}/idp`,
        method: 'GET'
      })
    }
    throw new Error('No business id')
  }
)

getBusinessIdp.storeKey = 'businessIdp'

export default getBusinessIdp
