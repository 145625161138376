import { getPublicUrl } from './getPublicUrl'

const isProd = process.env.NEXT_PUBLIC_DEPLOY_ENV === 'prod'

/**
 * @param {Object} options
 * @param {string} options.path - Path in signin app to navigate to, must include '/';
 *   options: '/sign-in', '/logout', '/forgot-password'
 * @param {string} options.locale - locale code ID; use `i18n.language`; ex: 'fr', 'zh-Hant'
 * @param {string} options.context - optional path for redirect back in account app after
 *   success in signin app; default redirects '/'; ex: 'edit-account'
 * @param {Object} options.query - { key: value } object with additional query params
 * @returns {string} - env-based url string to navigate to signin app;
 *   ex: http://localhost:4040/en/account/forgot-password
 *       https://signin-dev.mmhmm.app/en/account/logout
 */
export const getSignInAppUrl = ({ path, locale, context, query = {} }) => {
  const signInUrl = process.env.NEXT_PUBLIC_SIGNIN_URL
  const keyForSignInApp = process.env.NEXT_PUBLIC_SIGNIN_APP
  const localeParam = locale ? `/${locale}` : ''
  const redirectUrl =
    !isProd && !process.env.NEXT_PUBLIC_URL ? getPublicUrl() : undefined

  const params = { ...query }
  if (redirectUrl) params.redirect_url = redirectUrl
  if (context) params.context = context

  const searchString = new URLSearchParams(params).toString()
  const searchPart = searchString ? `?${searchString}` : ''

  return `${signInUrl}${localeParam}/${keyForSignInApp}${path}${searchPart}`
}
