import { Button } from '@all-turtles/mmhmm-ui-kit'
import { Trans, useTranslation } from 'i18n'
import { getSignInAppUrl } from 'utilities/getSignInAppUrl'
import * as S from './styles'

const AuthError = () => {
  const { t } = useTranslation('common')
  const supportUrl = t('submitRequestUrl')
  const signInRedirectUrl = getSignInAppUrl({ path: '/sign-in' })

  return (
    <S.Wrapper>
      <S.Title>{t('authHandoffError')}</S.Title>
      <S.Description>{t('authHandoffErrorDesc')}</S.Description>
      <Button href={signInRedirectUrl}>Sign In</Button>
      <S.Support>
        <Trans i18nKey="errorAssistanceLink" t={t}>
          If this continues to happen, please
          <a href={supportUrl} target="_blank" rel="noopener noreferrer">
            submit a request for assistance here
          </a>
          .
        </Trans>
      </S.Support>
    </S.Wrapper>
  )
}

export default AuthError
