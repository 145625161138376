import { useEffect } from 'react'
import { useRouter } from 'next/router'
import Image from 'next/image'
import { Button } from '@all-turtles/mmhmm-ui-kit'

import { useTranslation, Trans } from 'i18n'
import starIcon from 'public/images/starIcon.svg'
import * as S from './styles'

const LaunchApp = () => {
  const { t } = useTranslation('common')

  /**
   * - 1 is premium user redirected from checkout
   * - 2 is business user redirected from checkout
   * - If !query.subscribed, should be generic 'Launching' screen
   */
  const { query } = useRouter()
  const status = query?.subscribed
  const headers = {
    1: 'alreadyPremium',
    2: 'alreadyBusiness'
  }

  const deepLink = 'mmhmm:///'
  useEffect(() => {
    window.location = deepLink
  }, [])

  return (
    <S.LaunchAppComponent>
      <Image src={starIcon} alt="" />
      <div>
        <S.H1>{t(headers[status] || 'launching')}</S.H1>
        <S.BodyText>{t('launchInfo1')}</S.BodyText>
        <S.BodyText>
          <Trans i18nKey="launchInfo2" t={t}>
            Don’t see a dialog? Click <S.Bold>Launch mmhmm</S.Bold> below
          </Trans>
        </S.BodyText>
        <Button href={deepLink}>{t('launchMmhmm')}</Button>
      </div>
      <S.Hr />
      <p>
        <Trans i18nKey="dontHaveMmhmm" t={t}>
          Don’t have mmhmm?
          <a href={t('downloadUrl')} target="_blank" rel="noopener noreferrer">
            Download the app
          </a>
        </Trans>
      </p>
    </S.LaunchAppComponent>
  )
}

export default LaunchApp
