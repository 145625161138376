import { useTranslation } from 'i18n'

const EyeSlash = (props) => {
  const { t } = useTranslation('common')

  return (
    <svg
      width="106"
      height="71"
      viewBox="0 0 106 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby="eyeslash-title"
      {...props}
    >
      <title id="eyeslash-title">{t('hidePassword')}</title>
      <path
        d="M82.1289 69.1528C83.4473 70.4712 85.5469 70.5688 86.8652 69.1528C88.2813 67.688 88.1836 65.7348 86.8652 64.4165L23.3887 0.988725C22.0703 -0.329575 19.9219 -0.329575 18.6035 0.988725C17.334 2.25833 17.334 4.45553 18.6035 5.72513L82.1289 69.1528ZM52.832 3.03953C46.4355 3.03953 40.4297 4.06493 34.9609 5.77393L40.7227 11.5356C44.5801 10.4614 48.6328 9.87543 52.832 9.87543C77.4414 9.87543 98.0465 31.7993 98.0465 36.0473C98.0465 39.0258 91.5527 46.8872 81.3965 53.186L86.7187 58.5083C98.6815 50.6469 105.664 40.5395 105.664 36.0473C105.664 28.1372 84.2285 3.03953 52.832 3.03953ZM52.832 69.0552C59.8145 69.0552 66.3086 67.8833 72.1191 65.9302L66.4551 60.2661C62.1582 61.4868 57.6172 62.2192 52.832 62.2192C28.1738 62.2192 7.5684 41.3208 7.5684 36.0473C7.5684 33.6059 14.5996 25.0122 25.5859 18.3715L20.166 13.0004C7.4707 20.9106 0 31.4086 0 36.0473C0 43.9086 21.9238 69.0552 52.832 69.0552ZM72.8516 43.7133C73.7793 41.3208 74.3164 38.7329 74.3164 36.0473C74.3164 24.0356 64.7949 14.6118 52.832 14.6118C50.0977 14.6118 47.5586 15.1001 45.166 15.979L72.8516 43.7133ZM52.832 57.4829C56.0547 57.4829 59.0332 56.7504 61.7187 55.5297L33.252 27.0629C31.9824 29.7973 31.2988 32.8247 31.2988 36.0473C31.3477 47.7172 40.8203 57.4829 52.832 57.4829Z"
        fill="#999"
      />
    </svg>
  )
}

export default EyeSlash
