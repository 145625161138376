import styled from 'styled-components'

import { Colors, Type } from 'styles'
import { ButtonPrimitive } from 'components'

export const CustomRoomComponent = styled(ButtonPrimitive)`
  display: flex;
  justify-content: space-between;
  padding: 0.75rem 0;
  border-bottom: 1px solid ${Colors.gray};
  text-align: start;
  align-items: center;
  transition: background-color 200ms ease;
  gap: 10px;

  &:first-child {
    padding-top: 1rem;
    border-top: 1px solid ${Colors.gray};
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.025);
  }
`

export const Thumbnail = styled.img`
  height: 4.1875rem;
  min-width: 7.5rem;
  background-color: ${Colors.gray10};
`

export const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const Meta = styled.div`
  margin-inline-start: 0.75rem;
`

export const Name = styled.p`
  font-weight: ${Type.fontWeights.bold};
  overflow-wrap: break-word;
  max-width: 600px;
  font-size: 1rem;
`

export const Description = styled.p`
  overflow-wrap: break-word;
  max-width: 600px;
  font-size: 1rem;
`

export const EditButton = styled.div`
  ${Type.body30};
  color: ${Colors.brand};
`
