import styled from 'styled-components'

import { Colors, Type } from 'styles'

export const Payments = styled.div`
  display: block;
  width: 100%;
`

export const NoResults = styled.p`
  ${Type.body30};
  color: ${Colors.gray40};
  padding: 1.25rem 0;
  border-bottom: 1px solid ${Colors.gray};
  border-top: 1px solid ${Colors.gray};
  text-align: center;
`
