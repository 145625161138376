import { modelName, apiFetch, createAsyncThunk } from './business'

const createBusinessWithUser = createAsyncThunk(
  `${modelName}/createBusinessWithUser`,
  async (body) => {
    const options = {
      path: '/auth/businesses',
      method: 'POST',
      body,
      authorized: false
    }

    const response = await apiFetch(options)

    return response
  }
)

createBusinessWithUser.storeKey = 'business'

export default createBusinessWithUser
