import styled, { css } from 'styled-components'
import { Radio } from 'informed'

import { Breakpoints, Colors } from 'styles'

export const Border = styled.div`
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: inset 0px 1.5px 1px rgba(0, 0, 0, 0.15),
    inset 0px 0px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border 175ms ease;
  box-sizing: border-box;
`

export const RadioPillComponent = styled.label`
  height: 2.5rem;
  border-radius: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
  transition: border 175ms ease;

  &:hover {
    @media (min-width: ${Breakpoints.tablet}) {
      border: 1px solid ${Colors.brand};
    }

    ${Border} {
      border: 1px solid ${Colors.brand};
    }
  }

  @media (min-width: ${Breakpoints.tablet}) {
    padding: 0 1.0625rem;
    border: 1px solid ${Colors.brand};

    ${(props) =>
      !props.selected &&
      css`
        border: 1px solid ${Colors.gray};
      `}
  }
`

export const CheckboxWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CheckboxAndLabel = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`

export const StyledCheck = styled.div`
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
  background: ${Colors.brand};
  opacity: 0;
  transition: opacity 175ms ease;
`

export const StyledRadio = styled(Radio)`
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 2;

  &:checked ~ ${Border} {
    border: 1px solid ${Colors.brand};

    ${StyledCheck} {
      opacity: 1;
    }
  }

  &:focus.focus-visible ~ ${Border} {
    outline: 2px solid ${Colors.brand};
    outline-offset: 2px;
  }
`

export const Label = styled.p`
  margin-inline-start: 0.625rem;
  font-size: 0.9375rem;
  cursor: pointer;
`
