import { useDispatch } from 'react-redux'
import { useState } from 'react'
import { unwrapResult } from '@reduxjs/toolkit'

import * as S from './styles'
import { useTranslation } from 'i18n'
import { getInvitations, inviteMembers } from 'api/business'
import { setToastVisibility } from 'store/toast/slice'
import { setDialogVisibility } from 'store/dialog/slice'

const ResendBusinessInviteButton = ({ invite }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['business', 'common'])
  const [loading, setLoading] = useState(false)

  const handleClick = async () => {
    const errorDialogOptions = {
      name: 'ErrorDialog',
      description: t('common:somethingWentWrong'),
      showSupportLink: true
    }

    try {
      setLoading(true)
      const result = await dispatch(inviteMembers({ emails: [invite.email] }))
      const response = unwrapResult(result)

      if (response.success.length > 0) {
        dispatch(
          setToastVisibility({
            name: 'SuccessToast',
            title: t('inviteSent', { email: invite.email })
          })
        )
        dispatch(getInvitations())
      } else {
        dispatch(setDialogVisibility(errorDialogOptions))
      }
      setLoading(false)
    } catch (error) {
      dispatch(setDialogVisibility(errorDialogOptions))
      setLoading(false)
    }
  }

  return (
    <S.ResendBusinessInviteButtonComponent
      onClick={handleClick}
      variant="link"
      loading={loading}
      small
    >
      {loading ? '' : t('resend')}
    </S.ResendBusinessInviteButtonComponent>
  )
}

export default ResendBusinessInviteButton
