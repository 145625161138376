import styled from 'styled-components'

import { Button } from '@all-turtles/mmhmm-ui-kit'
import { Colors, Type, Breakpoints } from 'styles'

export const EduVerifyComponent = styled.div`
  background-color: ${Colors.blue30};
  padding: 10% 8%;
  display: flex;
  flex-direction: column;

  @media (min-width: ${Breakpoints.tablet}) {
    padding: 8.688rem 8.75rem 7.75rem;
    text-align: center;
    align-items: center;
  }
`

export const Title = styled.h1`
  margin-bottom: 2.188rem;
`

export const P = styled.p`
  max-width: 740px;
  ${Type.body10};
`

export const Email = styled.p`
  font-weight: ${Type.fontWeights.bold};
  margin: 1.25rem 0 4.875rem;
  ${Type.body10};
  word-break: break-word;
`

export const StyledButton = styled(Button)`
  filter: drop-shadow(0px 0px 20px rgba(16, 13, 60, 0.2));
`
