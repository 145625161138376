import styled, { css } from 'styled-components'

import { Colors } from 'styles'
import { fontFamily } from 'styles/Type'

export const buttonStyles = css`
  border: 0;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  font-family: ${fontFamily};
  border: 2px solid transparent;

  &:focus {
    outline: none;
    border: 2px solid ${Colors.brand};
  }

  &:focus:not(.focus-visible) {
    outline: none;
    border: 2px solid transparent;
  }
`

export const ButtonPrimitive = styled.button`
  ${buttonStyles};
`

export const A = styled.a`
  ${buttonStyles};

  &:hover {
    text-decoration: underline;
  }
`
