import styled, { css } from 'styled-components'

import { Spinner } from 'components/Icons'

export const LoadingComponent = styled.div`
  transition: all 250ms ease;
  opacity: 0;
  margin-inline-end: 0;
  margin-top: -1px;
  display: flex;
  align-items: center;

  ${(props) =>
    props.visible &&
    css`
      opacity: 1;
      margin-inline-start: ${props.startMargin};
      // width: 28px;
    `}
`

export const StyledSpinner = styled(Spinner)``
