import * as S from './styles'
import { dispatch } from 'store'
import { setDialogVisibility } from 'store/dialog/slice'

const CloseDialog = () => {
  const handleOnClick = () => {
    dispatch(setDialogVisibility({ name: null }))
  }

  return (
    <S.CloseDialogComponent onClick={handleOnClick}>
      <S.StyledX />
    </S.CloseDialogComponent>
  )
}

export default CloseDialog
