import styled from 'styled-components'

import { Breakpoints, Colors, Type } from 'styles'

export const ManageAccountComponent = styled.div``

export const EditButtonWrapper = styled.div`
  align-self: flex-start;
  margin-top: 0.75rem;
`

export const H1 = styled.h1`
  ${Type.header2};
  margin-bottom: 1.25rem;

  @media (min-width: ${Breakpoints.tablet}) {
    margin-bottom: 4.25rem;
  }
`

export const UserInfo = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.blue40};
  padding: 1.5rem 1.25rem;
  margin: 0 -1.25rem 1.25rem;
  flex-wrap: wrap;

  @media (min-width: ${Breakpoints.tablet}) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    padding: 0;
    margin: 0;
  }
`

export const Li = styled.li`
  padding: 1.25rem;
  border-top: 1px solid ${Colors.gray};
  list-style-type: none;
  margin: 0;
  width: 100%;

  @media (min-width: ${Breakpoints.tablet}) {
    padding: 1.25rem 0;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${Breakpoints.tablet}) {
    flex-direction: row;
  }
`

export const StartCol = styled.div`
  flex-grow: 1;

  @media (min-width: ${Breakpoints.tablet}) {
    margin-inline-end: 4.6875rem;
  }

  @media (min-width: ${Breakpoints.desktop}) {
    margin-inline-end: 6.25rem;
  }
`

export const ImageAndInfo = styled.div`
  display: flex;
  align-items: center;
  margin-inline-end: 1rem;
  gap: 1rem;
`

export const EndCol = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 20.9375rem;
  flex-shrink: 0;

  @media (min-width: ${Breakpoints.desktop}) {
    flex-basis: 25rem;
  }
`

export const EndColDesktop = styled(EndCol)`
  display: none;

  @media (min-width: ${Breakpoints.tablet}) {
    display: flex;
  }
`

export const EndColMobile = styled(EndCol)`
  margin: 0 -1.25rem;

  @media (min-width: ${Breakpoints.tablet}) {
    display: none;
  }
`

export const A = styled.a`
  &:hover {
    text-decoration: underline;
  }
`

export const Links = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0.5rem -1.25rem 0 -1.25rem;
  padding: 0;

  @media (min-width: ${Breakpoints.tablet}) {
    margin: 1.875rem 0;
  }
`

export const NameAndEmail = styled.div`
  display: flex;
  flex-direction: column;
`

export const Name = styled.p`
  font-weight: bold;
  margin-bottom: 0.375rem;
`

export const Email = styled.p`
  font-weight: normal;
  font-size: 0.9375rem;
  line-height: 1.1875rem;
`
