import styled from 'styled-components'

import { Type } from 'styles'

export const DialogContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 448px;
  max-width: 100%;
`

export const Title = styled.h1`
  ${Type.header3}
  margin-bottom: 1.5rem;
`

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 14px;
`

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const ImageCropContainer = styled.div`
  zoom: 0.5;
`
