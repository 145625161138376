import React, { Component } from 'react'
import isEqual from 'lodash/isEqual'

import * as S from './styles'
import propTypes from './propTypes'
import TableLoading from './TableLoading'
import Table from './Table'

export default class TableData extends Component {
  static propTypes = propTypes

  static defaultProps = {
    withSearch: true,
    disableRowClick: false
  }

  state = {
    columns: null,
    filteredData: null,
    sortProp: null,
    sortInverse: false
  }

  componentDidMount = () => {
    const { data, defaultSortKey, defaultSortDirection } = this.props
    if (data) {
      this.setColumns()
      this.setState({ filteredData: Object.values(data) })
    }

    if (defaultSortKey) {
      this.setSort(defaultSortKey, defaultSortDirection)
    }
  }

  componentDidUpdate = (prevProps) => {
    const { data } = this.props
    const dataChanged =
      !isEqual(prevProps.data, data) || (!prevProps.data && data)
    if (dataChanged) {
      this.setColumns()
      this.setState({ filteredData: data ? Object.values(data) : null })
    }
  }

  setColumns = () => {
    this.setState({ columns: this.props.includeKeys })
  }

  setSort = (sortProp, sortDirection) => {
    let sortInverse

    if (sortDirection === 'DESC') {
      sortInverse = true
    } else {
      sortInverse =
        sortProp === this.state.sortProp ? !this.state.sortInverse : false
    }

    if (this.props.handleSort) {
      const sortDirection = sortInverse ? 'desc' : 'asc'
      this.props.handleSort(sortProp, sortDirection)
    }

    this.setState({
      sortProp,
      sortInverse
    })
  }

  onHandleSearch = (filteredData) => {
    this.setState({ filteredData })
  }

  render = () => {
    const { loading, title, actions, loadingRows, dataTransform, ...rest } =
      this.props

    const { filteredData, columns, sortInverse, sortProp } = this.state

    return (
      <S.TableDataComponent {...rest}>
        {loading ? (
          <TableLoading rows={loadingRows} />
        ) : (
          <Table
            {...this.props}
            filteredData={filteredData}
            columns={columns}
            handleSearch={this.onHandleSearch}
            setSort={this.setSort}
            sortInverse={sortInverse}
            sortProp={sortProp}
            dataTransform={dataTransform}
          />
        )}
      </S.TableDataComponent>
    )
  }
}
