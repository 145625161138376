import { css } from 'styled-components'

import * as Colors from './Colors'

export const focus = css`
  &:focus {
    outline: none;
    border: 2px solid ${Colors.brand};
  }

  &:focus:not(.focus-visible) {
    outline: none;
    border: none;
  }
`
