import { useState, useEffect } from 'react'

const useAccountDeletionFormFramework = (
  requestInitiated,
  dirty,
  verificationCode
) => {
  const [disableDeleteButton, setDisableDeleteButton] = useState(true)
  const [showResendCode, setShowResendCode] = useState(false)
  const [resendCodeTimer, setResendCodeTimer] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const setRequestInFlight = () => {
    setIsLoading(true)
    setShowResendCode(false)
    setResendCodeTimer(0)
  }

  const setRequestFailed = () => {
    setShowResendCode(true)
    setResendCodeTimer(0)
  }

  const setRequestReset = () => {
    setResendCodeTimer(60)
    setShowResendCode(false)
  }

  const setRequestComplete = () => {
    setIsLoading(false)
  }

  useEffect(() => {
    const verificationCodeEntered = !!verificationCode
    if (dirty && verificationCodeEntered && disableDeleteButton) {
      setDisableDeleteButton(false)
    }
    if (!disableDeleteButton && (!dirty || !verificationCodeEntered)) {
      setDisableDeleteButton(true)
    }
  }, [dirty, verificationCode, disableDeleteButton])

  useEffect(() => {
    if (requestInitiated && resendCodeTimer == null && !showResendCode) {
      // set timer for 1 minute
      setResendCodeTimer(60)
    }

    if (resendCodeTimer <= 0) {
      setShowResendCode(true)
    }

    const intervalId = setInterval(() => {
      setResendCodeTimer(resendCodeTimer - 1)
    }, 1000)

    if (isLoading) {
      clearInterval(intervalId)
    }

    return () => clearInterval(intervalId)
  }, [requestInitiated, resendCodeTimer, showResendCode, isLoading])

  return {
    status: {
      showResendCode,
      isLoading,
      isDisabled: disableDeleteButton,
      isResendAllowed: showResendCode
    },
    methods: {
      setRequestInFlight,
      setRequestFailed,
      setRequestReset,
      setRequestComplete
    }
  }
}

export default useAccountDeletionFormFramework
