import App from 'next/app'
import React, { useEffect } from 'react'
import { Auth } from '@aws-amplify/auth'
import { ThemeProvider } from 'styled-components'
import { Provider, useDispatch } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import 'tippy.js/dist/tippy.css'
import '@reach/dialog/styles.css'
import { appWithTranslation } from 'i18n'

import store from '../store'
import { GlobalStyle } from '../styles/Global'
import { GlobalType } from '../styles/Type'
import { gaService } from 'analytics'
import { DialogController, ToastController } from 'components'
import { HidePurchaseForAppStoreProvider } from 'hooks/useHidePurchaseForAppStore'
import { getMe } from 'api/user'
import configureCognito from 'api/auth/configureCognito'
import { NewAccountAlert } from 'components/NewAccountAlert'

const theme = {
  colors: {
    primary: '#0070f3'
  }
}

// Can be changed to hook after rewrite of App component
const RefreshAvatarSignedUrls = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    const timeout = setInterval(() => {
      dispatch(getMe())
    }, 21600000) // 6h
    return () => clearInterval(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null
}

configureCognito()

class MyApp extends App {
  componentDidMount() {
    gaService.pageView()
  }

  render() {
    const { Component, pageProps } = this.props

    const isClient = typeof window !== 'undefined'

    const GateWrapper = isClient ? PersistGate : React.Fragment

    const gateProps = isClient
      ? {
          loading: null,
          persistor: store.persistor,
          onBeforeLift: async () => {
            const currentAuth = await Auth.currentUserInfo()
            if (store.getState().api?.user?.me?.id !== currentAuth?.username) {
              await store.persistor.purge()
            }
          }
        }
      : null

    return (
      <Provider store={store}>
        <GateWrapper {...gateProps}>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <GlobalType />
            <DialogController />
            <ToastController />
            <RefreshAvatarSignedUrls />
            <HidePurchaseForAppStoreProvider>
              <NewAccountAlert />
              <Component {...pageProps} />
            </HidePurchaseForAppStoreProvider>
          </ThemeProvider>
        </GateWrapper>
      </Provider>
    )
  }
}

export default appWithTranslation(MyApp)
