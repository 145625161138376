import styled, { css } from 'styled-components'

import { Button } from '@all-turtles/mmhmm-ui-kit'

export const ResetPasswordFormComponent = styled.div``

export const SubmitButton = styled(Button)``

export const inputStyles = css`
  margin-bottom: 36px;
`

export const SubmitWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
