import { createAsyncThunk } from '@reduxjs/toolkit'

import apiFetch from '../apiFetch'

const deleteAvatar = createAsyncThunk('user/deleteAvatar', async () => {
  const options = {
    path: '/users/me/photo',
    method: 'DELETE'
  }

  const response = await apiFetch(options)

  window.localStorage.removeItem('avatarCache')

  return { lastUpdated: Date.now(), ...response }
})

export default deleteAvatar
