import styled, { css } from 'styled-components'

import { Colors, Type } from 'styles'

export const BusinessPreferencesFormComponent = styled.div`
  max-width: 30rem;
  margin-bottom: 2.5rem;
  padding-bottom: 1.875rem;
`

export const H2 = styled.h2`
  ${Type.header4};
  margin-bottom: 0.75rem;
`

export const P = styled.p`
  ${Type.body30};
  margin-bottom: 1.875rem;
`

export const SmallInfo = styled.p`
  ${Type.body30};
  margin-bottom: 1.875rem;
  color: ${Colors.gray20};
`

export const Section = styled.div`
  margin-bottom: 1.875rem;
  padding-bottom: 1.875rem;
  border-bottom: 1px solid ${Colors.gray};
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const selectContainer = css`
  padding-bottom: 1rem;
`
