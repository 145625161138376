import Head from 'next/head'
import { getPublicUrl } from 'utilities/getPublicUrl'

const twitterHandle = '@mmhmmapp'

const url = getPublicUrl()
const favicon = `/favicon-${process.env.NEXT_PUBLIC_DEPLOY_ENV}.png`

const Seo = ({ title, description, ogImage }) => {
  const ogImageFullpath = `${url}${ogImage}`

  return (
    <Head>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <link rel="icon" href={favicon} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={ogImageFullpath} />

      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:site" content={twitterHandle} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={ogImageFullpath} />
    </Head>
  )
}

Seo.defaultProps = {
  title: 'mmhmm',
  description: 'A whole new way to educate and entertain',
  ogImage: '/ogImage.png'
}

export default Seo
