import styled, { css } from 'styled-components'

import { Button } from '@all-turtles/mmhmm-ui-kit'
import { Type } from 'styles'

export const SubmitButton = styled(Button)``

export const inputStyles = css`
  margin-bottom: 2rem;
`

export const SubmitWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const A = styled.a`
  ${Type.body30};
  text-decoration: underline;
  display: block;
  margin-bottom: 2rem;
`
