import { css, createGlobalStyle } from 'styled-components'
import 'focus-visible'

import * as Breakpoints from './Breakpoints'
import * as Colors from './Colors'

const sansSerifFallback =
  "'-apple-system', 'BlinkMacSystemFont', 'Helvetica Neue', 'Roboto', 'sans-serif'"
export const fontFamily = `'Mulish', ${sansSerifFallback}`

export const fontWeights = {
  regular: 400,
  bold: 700,
  extraBold: 800
}

export const navItem = css`
  font-size: 0.8125rem;
  line-height: 1rem;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-weight: ${fontWeights.bold};

  [lang='ar'] & {
    letter-spacing: 0;
  }
`

export const mobileMenuNavItem = css`
  font-weight: ${fontWeights.bold};
  font-size: 0.9375rem; /* 15px */
  line-height: 1.1875rem; /* 19px */
`

export const body10 = css`
  font-size: 1.25rem; /* 20px */
  line-height: 1.5625rem; /* 25px */

  @media (min-width: ${Breakpoints.tablet}) {
    font-size: 1.5625rem; /* 25px */
    line-height: 1.875rem; /* 30px */
  }
`

export const body20 = css`
  font-size: 1.125rem; /* 18px */
  line-height: 1.5625rem; /* 25px */

  @media (min-width: ${Breakpoints.tablet}) {
    font-size: 1.25rem; /* 20px */
    line-height: 1.5625rem; /* 25px */
  }
`

export const body30 = css`
  font-size: 0.9375rem; /* 15px */
  line-height: 1.1875rem; /* 19px */
`

export const body40 = css`
  font-size: 0.75rem; /* 12px */
  line-height: 0.9375rem; /* 19px */
`

export const madeWith = css`
  font-weight: ${fontWeights.bold};
  font-size: 0.9375rem; /* 15px */
  line-height: 1.875rem; /* 30px */
`

export const caps = css`
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-size: 0.75rem; /* 12px */
  line-height: 0.9375rem; /* 15px */

  [lang='ar'] & {
    letter-spacing: 0;
  }
`

export const capsMed = css`
  ${caps};
  font-weight: ${fontWeights.regular};

  @media (min-width: ${Breakpoints.tablet}) {
    ${body30};
  }
`

export const cta = css`
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-size: 0.9375rem; /* 15px */
  line-height: 0.9375rem; /* 19px */
  font-weight: ${fontWeights.bold};

  [lang='ar'] & {
    letter-spacing: 0;
  }
`

export const header1 = css`
  font-size: 1.875rem; /* 30px */
  line-height: 2rem;
  font-weight: ${fontWeights.extraBold};
  margin: 0;

  @media (min-width: ${Breakpoints.tablet}) {
    font-size: 2.5rem; /* 40px */
    line-height: 3.125rem; /* 50px */
  }
`

export const header2 = css`
  font-size: 1.5625rem;
  line-height: 1.9375rem; /* 31px */
  font-weight: ${fontWeights.extraBold};
  margin: 0;

  @media (min-width: ${Breakpoints.tablet}) {
    font-size: 2.5rem;
    line-height: 3.125rem;
  }
`

export const header3 = css`
  font-size: 1.375rem;
  line-height: 1.6875rem;
  font-weight: ${fontWeights.extraBold};
  margin: 0;

  @media (min-width: ${Breakpoints.tablet}) {
    font-size: 1.6875rem; /* 27px */
    line-height: 2.125rem; /* 34px */
    letter-spacing: 0.01em;
  }

  [lang='ar'] & {
    letter-spacing: 0;
  }
`

export const header4 = css`
  font-size: 1.125rem;
  line-height: 1.5625rem;
  font-weight: ${fontWeights.bold};
  margin: 0;

  @media (min-width: ${Breakpoints.tablet}) {
    font-size: 1.25rem;
    line-height: 1.6875rem;
  }
`

export const link = css`
  transition: all 300ms ease;
  color: ${Colors.brand};
  cursor: pointer;
  text-decoration: none;
  border-radius: 4px;

  &:hover {
    color: ${Colors.brand};
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${Colors.brand};
  }

  &:focus:not(.focus-visible) {
    outline: none;
    box-shadow: none;
  }
`

export const GlobalType = createGlobalStyle`
  body {
    font-family: ${fontFamily};

    > * {
      ${body20};
    }
  }

  p {
    margin: 0;
  }

  h1 {
    ${header1};
  }

  h2 {
    ${header2};
  }

  h3 {
    ${header3};
  }

  h4 {
    ${header4};
  }

  a {
    ${link}
  }
`
