import { createSlice } from '@reduxjs/toolkit'

import * as Apis from 'api'

const extraReducers = {}

const apiStatusTypes = ['fulfilled', 'rejected', 'pending']

Object.keys(Apis).forEach((Api) => {
  Object.keys(Apis[Api]).forEach((key) => {
    if (key === 'modelName') return

    apiStatusTypes.forEach((apiStatusType) => {
      extraReducers[Apis[Api][key][apiStatusType]] = (state) => {
        if (!state[Apis[Api].modelName]) {
          state[Apis[Api].modelName] = {}
        }
        state[Apis[Api].modelName][key] = apiStatusType
      }
    })
  })
})

const slice = createSlice({
  name: 'apiStatus',
  initialState: {
    signIn: {}
  },
  extraReducers
})

export default slice.reducer
