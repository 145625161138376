import { css } from 'styled-components'

import { Colors, Type } from 'styles'

export const bar = css`
  position: relative;
  border-bottom: 0.0625rem solid #999;
  display: block;

  &:before {
    content: '';
    height: 0.125rem;
    width: 0;
    left: 50%;
    bottom: -0.0625rem;
    position: absolute;
    background: ${Colors.brand};
    transition: left 0.28s ease, width 0.28s ease;
    z-index: 2;
  }
`

export const error = css`
  ${Type.body40};
  color: ${Colors.red};
  position: relative;
  height: 1.25rem;
  margin: 3px 0 0 0;
`

export const label = css`
  ${Type.body30};
  position: relative;
`
