const Wordmark = ({ color = '#000', title = 'mmhmm', ...rest }) => {
  return (
    <svg
      width="101"
      height="18"
      viewBox="0 0 101 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby="wordmark-title"
      {...rest}
    >
      <title id="wordmark-title">{title}</title>
      <path
        d="M15.9779 5.13281C18.627 5.13281 20.3104 6.89893 20.3104 9.68609V17.494C20.3104 17.7387 20.1119 17.9372 19.8672 17.9372H15.5104C15.2656 17.9372 15.0672 17.7387 15.0672 17.494V11.0935C15.0672 10.3484 14.7637 9.74127 14.0186 9.74127C13.4559 9.74127 12.9713 10.1054 12.7921 10.7075C12.7813 10.7437 12.7768 10.7814 12.7768 10.8192V17.494C12.7768 17.7387 12.5783 17.9372 12.3336 17.9372H7.97682C7.73204 17.9372 7.53361 17.7387 7.53361 17.494V11.0935C7.53361 10.3484 7.23005 9.74127 6.48497 9.74127C5.92233 9.74127 5.43773 10.1054 5.25846 10.7075C5.24769 10.7437 5.24317 10.7814 5.24317 10.8192V17.494C5.24317 17.7387 5.04474 17.9372 4.79996 17.9372H0.443214C0.198434 17.9372 0 17.7387 0 17.494V5.85198C0 5.6072 0.198433 5.40877 0.443213 5.40877H4.79996C5.04474 5.40877 5.24317 5.6072 5.24317 5.85198V6.42979C5.90546 5.65712 7.14727 5.13281 8.52705 5.13281C9.97962 5.13281 10.9649 5.57883 11.7252 6.24727C11.9033 6.40387 12.1705 6.41502 12.3609 6.27371C13.1233 5.70815 14.3348 5.13281 15.9779 5.13281Z"
        fill={color}
      />
      <path
        d="M37.9715 5.13281C40.6207 5.13281 42.3041 6.89893 42.3041 9.68609V17.494C42.3041 17.7387 42.1056 17.9372 41.8609 17.9372H37.5041C37.2593 17.9372 37.0609 17.7387 37.0609 17.494V11.0935C37.0609 10.3484 36.7573 9.74127 36.0123 9.74127C35.4496 9.74127 34.965 10.1054 34.7858 10.7075C34.775 10.7437 34.7705 10.7814 34.7705 10.8192V17.494C34.7705 17.7387 34.572 17.9372 34.3272 17.9372H29.9705C29.7257 17.9372 29.5273 17.7387 29.5273 17.494V11.0935C29.5273 10.3484 29.2237 9.74127 28.4787 9.74127C27.916 9.74127 27.4314 10.1054 27.2522 10.7075C27.2414 10.7437 27.2369 10.7814 27.2369 10.8192V17.494C27.2369 17.7387 27.0384 17.9372 26.7936 17.9372H22.4369C22.1921 17.9372 21.9937 17.7387 21.9937 17.494V5.85198C21.9937 5.6072 22.1921 5.40877 22.4369 5.40877H26.7936C27.0384 5.40877 27.2369 5.6072 27.2369 5.85198V6.42979C27.8992 5.65712 29.141 5.13281 30.5207 5.13281C31.9733 5.13281 32.9586 5.57883 33.7189 6.24727C33.897 6.40387 34.1642 6.41502 34.3546 6.27371C35.117 5.70815 36.3285 5.13281 37.9715 5.13281Z"
        fill={color}
      />
      <path
        d="M43.9874 0.443213C43.9874 0.198433 44.1859 0 44.4307 0H48.7874C49.0322 0 49.2306 0.198433 49.2306 0.443213V6.42978C49.9205 5.6571 51.1623 5.1328 52.5697 5.1328C55.3016 5.1328 57.0126 6.89892 57.0126 9.68607V17.4939C57.0126 17.7387 56.8141 17.9372 56.5694 17.9372H52.2126C51.9678 17.9372 51.7694 17.7387 51.7694 17.4939V11.0934C51.7694 10.3484 51.3831 9.74125 50.5552 9.74125C49.939 9.74125 49.4268 10.1054 49.246 10.7075C49.2351 10.7437 49.2306 10.7814 49.2306 10.8192V17.4939C49.2306 17.7387 49.0322 17.9372 48.7874 17.9372H44.4307C44.1859 17.9372 43.9874 17.7387 43.9874 17.4939V0.443213Z"
        fill={color}
      />
      <path
        d="M74.6738 5.13281C77.3229 5.13281 79.0063 6.89893 79.0063 9.68609V17.494C79.0063 17.7387 78.8078 17.9372 78.5631 17.9372H74.2063C73.9615 17.9372 73.7631 17.7387 73.7631 17.494V11.0935C73.7631 10.3484 73.4595 9.74127 72.7145 9.74127C72.1518 9.74127 71.6672 10.1054 71.488 10.7075C71.4772 10.7437 71.4727 10.7814 71.4727 10.8192V17.494C71.4727 17.7387 71.2742 17.9372 71.0294 17.9372H66.6727C66.4279 17.9372 66.2295 17.7387 66.2295 17.494V11.0935C66.2295 10.3484 65.9259 9.74127 65.1809 9.74127C64.6182 9.74127 64.1336 10.1054 63.9544 10.7075C63.9436 10.7437 63.9391 10.7814 63.9391 10.8192V17.494C63.9391 17.7387 63.7406 17.9372 63.4958 17.9372H59.1391C58.8943 17.9372 58.6959 17.7387 58.6959 17.494V5.85198C58.6959 5.6072 58.8943 5.40877 59.1391 5.40877H63.4958C63.7406 5.40877 63.9391 5.6072 63.9391 5.85198V6.42979C64.6014 5.65712 65.8432 5.13281 67.2229 5.13281C68.6755 5.13281 69.6608 5.57883 70.4211 6.24727C70.5992 6.40387 70.8664 6.41502 71.0568 6.27371C71.8192 5.70815 73.0307 5.13281 74.6738 5.13281Z"
        fill={color}
      />
      <path
        d="M96.6675 5.13281C99.3167 5.13281 101 6.89893 101 9.68609V17.494C101 17.7387 100.802 17.9372 100.557 17.9372H96.2C95.9553 17.9372 95.7568 17.7387 95.7568 17.494V11.0935C95.7568 10.3484 95.4533 9.74127 94.7082 9.74127C94.1456 9.74127 93.661 10.1054 93.4817 10.7075C93.4709 10.7437 93.4664 10.7814 93.4664 10.8192V17.494C93.4664 17.7387 93.268 17.9372 93.0232 17.9372H88.6664C88.4217 17.9372 88.2232 17.7387 88.2232 17.494V11.0935C88.2232 10.3484 87.9197 9.74127 87.1746 9.74127C86.612 9.74127 86.1274 10.1054 85.9481 10.7075C85.9373 10.7437 85.9328 10.7814 85.9328 10.8192V17.494C85.9328 17.7387 85.7344 17.9372 85.4896 17.9372H81.1328C80.8881 17.9372 80.6896 17.7387 80.6896 17.494V5.85198C80.6896 5.6072 80.8881 5.40877 81.1328 5.40877H85.4896C85.7344 5.40877 85.9328 5.6072 85.9328 5.85198V6.42979C86.5951 5.65712 87.8369 5.13281 89.2167 5.13281C90.6693 5.13281 91.6546 5.57883 92.4148 6.24727C92.593 6.40387 92.8601 6.41502 93.0506 6.27371C93.8129 5.70815 95.0244 5.13281 96.6675 5.13281Z"
        fill={color}
      />
    </svg>
  )
}

export default Wordmark
