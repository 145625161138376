import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'
import { useRouter } from 'next/router'
import apiStatus from 'store/selectors/apiStatus'
import { isUserOfTypes } from 'store/selectors/userType'
import { getMe } from 'api/user'

const apiStatusSelector = (state) =>
  apiStatus({
    state,
    resource: 'user',
    api: 'getMe'
  })

const withPermissions = (C, acceptedUserTypes) => () => {
  const dispatch = useDispatch()
  const status = useSelector(apiStatusSelector, isEqual)
  const currentUser = useSelector((state) => state.api.user?.me)

  React.useEffect(() => {
    if (!currentUser) dispatch(getMe())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isWithinAcceptedTypes = useSelector(
    (state) => isUserOfTypes(state, [...acceptedUserTypes]),
    isEqual
  )
  const router = useRouter()

  React.useEffect(() => {
    if (!status.loading && !isWithinAcceptedTypes) router.push('/')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWithinAcceptedTypes, status])

  if (currentUser) {
    return <C />
  }

  return null
}

export default withPermissions
