import styled from 'styled-components'

import { Type } from 'styles'

export const CreateAccountWrapper = styled.div`
  margin-bottom: 3.25rem;
`

export const A = styled.a`
  ${Type.body30};
`
