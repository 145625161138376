import { useDispatch, useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'
import { Button } from '@all-turtles/mmhmm-ui-kit'

import formatDate from 'utilities/formatDate'
import { updateSubscription } from 'api/business'
import apiStatus from 'store/selectors/apiStatus'
import { Trans, useTranslation } from 'i18n'
import { HandleAction } from 'components'
import * as S from './styles'

const apiStatusSelector = (state) =>
  apiStatus({
    state,
    resource: 'business',
    api: 'updateSubscription'
  })

const BusinessReactivate = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['account', 'business', 'common'])
  const status = useSelector(apiStatusSelector, isEqual)
  const businessSubscription = useSelector(
    (state) => state.api.business?.subscription
  )

  const date =
    businessSubscription?.endDate && formatDate(businessSubscription.endDate)

  const handleSubmit = () => {
    dispatch(updateSubscription({ status: 'active' }))
  }

  return (
    <S.BusinessReactivateComponent>
      <HandleAction
        resource="business"
        api="updateSubscription"
        toastDescription={t('business:reactivateSuccess')}
        nextRoute="/business/billing"
      />
      <S.Text>
        <Trans i18nKey="business:reactivateBody" date={date} t={t}>
          Reactivate your subscription before <S.Date>{{ date }}</S.Date> to
          ensure that your members retain access to mmhmm Business.
        </Trans>
      </S.Text>
      <Button onClick={handleSubmit} loading={status.loading}>
        {t('business:reactivateBusiness')}
      </Button>
    </S.BusinessReactivateComponent>
  )
}

export default BusinessReactivate
