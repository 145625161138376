import { node, string } from 'prop-types'
import * as S from './styles'

const propTypes = {
  children: node,
  header: string,
  illustration: node,
  backgroundColor: string
}

const defaultProps = {
  header: null
}

const FormIllustrationContainer = ({
  backgroundColor,
  children,
  header,
  illustration
}) => (
  <S.Wrapper>
    <S.FormContainer>
      {header && <S.Header>{header}</S.Header>}
      {children}
    </S.FormContainer>
    <S.Illustration
      illustration={illustration}
      backgroundColor={backgroundColor}
    />
  </S.Wrapper>
)

FormIllustrationContainer.propTypes = propTypes
FormIllustrationContainer.defaultProps = defaultProps

export default FormIllustrationContainer
