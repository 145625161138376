import styled from 'styled-components'

import { Breakpoints } from 'styles'
import Shelves from '../Shelves/Shelves'
import YourOrder from '../YourOrder/YourOrder'
import { Button } from '@all-turtles/mmhmm-ui-kit'

export const OrderCompleteComponent = styled.div``

export const Hero = styled.div`
  display: flex;
  position: relative;
  box-sizing: border-box;
  padding: 7.4% 1.5rem 0 1.5rem;
  justify-content: center;

  @media (min-width: ${Breakpoints.tablet}) {
    flex-direction: row;
    padding: 0;
  }
`

export const H1 = styled.h1`
  text-align: center;
  margin-bottom: 2rem;
  white-space: pre-line;
`

export const StyledButton = styled(Button)`
  display: flex;
  margin-bottom: 1.8rem;

  @media (min-width: ${Breakpoints.tablet}) {
    display: flex;
    margin-bottom: 0;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  justify-content: center;
  margin: 0 0.5rem;
  max-width: 20rem;

  @media (min-width: ${Breakpoints.tablet}) {
    max-width: none;
  }
`

export const P = styled.p`
  text-align: center;
  max-width: 29.4375rem;
  margin-bottom: 1.875rem;

  @media (min-width: ${Breakpoints.tablet}) {
    margin-bottom: 2.6875rem;
  }
`

export const StyledShelves = styled(Shelves)`
  align-self: flex-start;
  height: auto;
  display: none;

  @media (min-width: ${Breakpoints.tablet}) {
    display: block;
  }

  [dir='rtl'] & {
    transform: scaleX(-1);
  }
`

export const StyledYourOrder = styled(YourOrder)`
  margin-top: 3.125rem;
`
