import { useTranslation } from 'i18n'
import { useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'

import formatDate from 'utilities/formatDate'

const useGetDescription = ({ nextPlanId, nextChargeDate }) => {
  const { t } = useTranslation(['account', 'common'])
  const catalog = useSelector((state) => state.api.commerce?.catalog, isEqual)

  const plans = {
    [catalog.monthlyPlan?.id]: {
      price: catalog.monthlyPlan?.displayPrice
    },
    [catalog.annualPlan?.id]: {
      price: catalog.annualPlan?.displayPrice
    }
  }

  if (!nextPlanId) return t('changePlanDescription')

  const date = formatDate(nextChargeDate)
  const price = plans[nextPlanId].price

  const newDuration = nextPlanId.includes('annual')
    ? t('annual').toLowerCase()
    : t('monthly').toLowerCase()

  const currentDuration = nextPlanId.includes('annual')
    ? t('monthly').toLowerCase()
    : t('annual').toLowerCase()

  return t('account:subscriptionStatus', {
    currentDuration,
    newDuration,
    date,
    price
  })
}

export default useGetDescription
