import { useDispatch, useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'

import formatDate from 'utilities/formatDate'
import { useTranslation, Trans } from 'i18n'
import { cancelSubscription } from 'api/commerce'
import { mixpanelService, eventTypes } from 'analytics'
import apiStatus from 'store/selectors/apiStatus'
import {
  CancelSubscriptionForm,
  SubscriptionCancelled,
  HandleAction
} from 'components'
import * as S from './styles'

const apiStatusSelector = (state) =>
  apiStatus({
    state,
    resource: 'commerce',
    api: 'cancelSubscription'
  })

const CancelSubscription = () => {
  const { t } = useTranslation('account')
  const dispatch = useDispatch()
  const subscription = useSelector((state) => state.api.commerce?.subscription)
  const status = useSelector(apiStatusSelector, isEqual)

  if (!subscription || subscription?.status === 'non_renewing') {
    return <SubscriptionCancelled />
  }

  const date = formatDate(subscription.nextChargeDate)

  const handleSubmit = (values) => {
    mixpanelService.track(eventTypes.cancelSubscriptionReason, {
      reason: values?.reason
    })

    dispatch(cancelSubscription(values))
  }

  return (
    <>
      <HandleAction
        resource="commerce"
        api="cancelSubscription"
        toastDescription={t('cancelSubscriptionSuccess')}
      />
      <S.CancelSubscriptionComponent>
        <S.Title>{t('areYouSure')}</S.Title>
        <p>{t('cancelDescription')}</p>
        <S.P>
          <Trans i18nKey="expireOn" t={t} date={date}>
            Your subscription will expire on: <S.Date>{{ date }}</S.Date>
          </Trans>
        </S.P>
        <CancelSubscriptionForm
          onSubmit={handleSubmit}
          isLoading={status.loading}
        />
      </S.CancelSubscriptionComponent>
    </>
  )
}

export default CancelSubscription
