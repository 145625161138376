import * as S from './styles'
import { Colors } from 'styles'
import React, { forwardRef } from 'react'

const Button = forwardRef(({ children, isLoading, as, ...rest }, ref) => {
  return (
    <S.ButtonComponent
      isLoading={isLoading}
      disabled={isLoading}
      {...rest}
      ref={ref}
    >
      <S.StyledLoading visible={isLoading} color={Colors.gray30} />
      {children}
    </S.ButtonComponent>
  )
})

export default Button
