import styled from 'styled-components'

export const BusinessCustomRoomsPageComponent = styled.div`
  width: 100%;
`

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 2rem;
`
