/**
 * Update billing address and tax ID;
 * expects body as:
 * {
 *    "billingAddress" : "Seth Hitchings\n12 Petrini Circle\nNeedham, MA 02492",
 *    "taxID" : "ABC123"
 * }
 */

import { modelName, apiFetch, createAsyncThunk } from './business'

const updateBillingInfo = createAsyncThunk(
  `${modelName}/updateBillingInfo`,
  async (body) => {
    const options = {
      path: '/businesses/me/admin/subscription/billingInfo',
      method: 'POST',
      body
    }
    const response = await apiFetch(options)

    return response
  }
)

updateBillingInfo.storeKey = 'billingInfo'

export default updateBillingInfo
