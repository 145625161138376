const navItems = [
  {
    url: '/change-plan',
    i18nKey: 'changePlan',
    shouldHide: ({ subscription }) => {
      // You can't change a plan on a subscription that's
      // doesn't exist or has already been cancelled.
      if (!subscription?.status) return true
      const cancelled = subscription.status === 'non_renewing'
      return cancelled
    }
  },
  {
    url: '/upgrade',
    i18nKey: 'resubscribe',
    shouldHide: ({ subscription }) => {
      if (!subscription?.status) return true
      const cancelled = subscription.status === 'non_renewing'
      return !cancelled
    }
  },
  {
    url: '/cancel-subscription',
    i18nKey: 'cancelSubscription',
    shouldHide: ({ subscription }) => {
      // You can't cancel a subscription that's
      // doesn't exist or has already been cancelled.
      if (!subscription?.status) return true
      const cancelled = subscription.status === 'non_renewing'
      return cancelled
    }
  },
  {
    url: '/edit-payment',
    i18nKey: 'editPayment'
  },
  {
    url: '/billing',
    i18nKey: 'taxIdAddressShort'
  },
  {
    i18nUrl: 'requestRefundUrl',
    i18nKey: 'requestRefund',
    target: '_blank'
  }
]

export default navItems
