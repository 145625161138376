import { shape, string } from 'prop-types'
import dayjs from 'dayjs'
import { useTranslation } from 'i18n'

import formatDate from 'utilities/formatDate'
import { Button } from 'components'
import * as S from './styles'

const propTypes = {
  premium: shape({
    trial: shape({
      end: string,
      now: string
    })
  }).isRequired
}

const TrialPanel = ({ premium }) => {
  const { t } = useTranslation('account')
  const date = formatDate(premium.trial.end)
  const now = dayjs(premium.trial.now)
  const end = dayjs(premium.trial.end)
  const daysRemaining = dayjs(end).diff(now, 'days')

  return (
    <S.TrialPanelComponent>
      <S.Expiration>{t('trialExpire', { date })}</S.Expiration>
      <S.CopyWrapper>
        <S.Title>{t('daysRemaining', { number: daysRemaining })}</S.Title>
        <Button el="Link" href="/upgrade">
          {t('upgrade')}
        </Button>
      </S.CopyWrapper>
      <S.Illustration />
    </S.TrialPanelComponent>
  )
}

TrialPanel.propTypes = propTypes

export default TrialPanel
