import { modelName, apiFetch, createAsyncThunk } from './business'

const createRoom = createAsyncThunk(`${modelName}/createRoom`, async (body) => {
  const options = {
    path: '/businesses/me/admin/rooms',
    method: 'POST',
    body
  }

  const response = await apiFetch(options)

  return response
})

createRoom.fulfilledReducer = (state, action) => {
  const rooms = state.business.customRooms?.items || []
  rooms.push({ ...action.meta.arg, ...action.payload })

  state.business.customRooms = {
    count: rooms.length,
    items: rooms
  }
}

export default createRoom
