import { useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'

import { useTranslation } from 'i18n'
import { Checkout } from 'components'

const StandardCheckout = () => {
  const { t } = useTranslation('checkout')
  const catalog = useSelector((state) => state.api.commerce?.catalog, isEqual)
  const monthlyAmount = catalog?.monthlyPlan?.displayPrice

  return (
    <Checkout
      header={t('checkoutHeader')}
      description={monthlyAmount ? t('description', { monthlyAmount }) : null}
      catalog={catalog}
      api="createSubscription"
      showTotal
    />
  )
}

export default StandardCheckout
