import { element, oneOfType, string } from 'prop-types'

import * as S from './styles'

import { BusinessNav } from 'components'

const BusinessLayout = ({ children, header }) => (
  <>
    <S.Header>{header}</S.Header>
    <S.Wrap>
      <BusinessNav />
      {children}
    </S.Wrap>
  </>
)

BusinessLayout.propTypes = {
  header: oneOfType([string, element]).isRequired
}

export default BusinessLayout
