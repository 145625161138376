/**
 * Dialog window form for both creating a new business room
 * and editing an existing one
 */
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'i18n'
import * as S from './styles'
import { Dialog, EditRoomForm } from 'components'
import { unwrapResult } from '@reduxjs/toolkit'
import { createRoom, updateRoom, uploadRoom } from 'api/business'
import { string, oneOf } from 'prop-types'
import { setDialogVisibility } from 'store/dialog/slice'

const EditRoom = ({
  visible,
  id,
  roomName,
  description,
  thumbnailUrl,
  state
}) => {
  const { t } = useTranslation('business')
  const dispatch = useDispatch()
  const [file, setFile] = useState()
  const [loading, setLoading] = useState()

  const onFileChange = (file) => {
    setFile(file)
  }

  const onSubmit = async ({ roomName, roomDescription }) => {
    if (loading) return
    setLoading(true)

    try {
      let result
      const room = { name: roomName, description: roomDescription }
      if (file?.name) {
        room.filename = file.name
      }

      if (state === 'edit') {
        result = await dispatch(updateRoom({ id, ...room }))
      } else {
        // Create a room and get a signed file upload URL
        result = await dispatch(createRoom({ ...room }))
      }
      const response = await unwrapResult(result)

      if (file) {
        // Use file upload URL to upload the file to S3
        const fileUploadUrl = response.fileUploadUrl

        await dispatch(
          uploadRoom({
            path: fileUploadUrl,
            file,
            id: response.id
          })
        )
      }

      setLoading(false)

      dispatch(setDialogVisibility({ name: 'null' }))
    } catch (error) {
      // TODO: Show error
      setLoading(false)
    }
  }

  return (
    <Dialog visible={visible} label={t('editRoom')}>
      <S.EditRoomDialog>
        <S.Title>
          {state === 'create' ? t('createRoom') : t('editRoom')}
        </S.Title>
        <EditRoomForm
          initialValues={{
            roomName,
            roomDescription: description
          }}
          thumbnailUrl={thumbnailUrl}
          id={id}
          onSubmit={onSubmit}
          onFileChange={onFileChange}
          loading={loading}
          state={state}
        />
      </S.EditRoomDialog>
    </Dialog>
  )
}

EditRoom.propTypes = {
  roomName: string,
  description: string,
  thumbnailUrl: string,

  /**
   * Are we creating a new room or editing an existing?
   */
  state: oneOf(['create', 'edit'])
}

export default EditRoom
