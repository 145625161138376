import { useTranslation } from 'i18n'

const Eye = (props) => {
  const { t } = useTranslation('common')

  return (
    <svg
      width="106"
      height="71"
      viewBox="0 0 106 71"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby="eye-title"
      {...props}
    >
      <title id="eye-title">{t('showPassword')}</title>
      <path
        d="M52.832 69.0156C84.2285 69.0156 105.664 43.8691 105.664 36.0078C105.664 28.0977 84.2285 3 52.832 3C21.9238 3 0 28.0977 0 36.0078C0 43.8691 21.9238 69.0156 52.832 69.0156ZM52.832 62.1797C28.1738 62.1797 7.5684 41.2813 7.5684 36.0078C7.5684 31.7598 28.1738 9.8359 52.832 9.8359C77.4414 9.8359 98.0465 31.7598 98.0465 36.0078C98.0465 41.2813 77.4414 62.1797 52.832 62.1797ZM52.832 57.4434C64.7949 57.4434 74.3164 47.6777 74.3164 36.0078C74.3164 23.9961 64.7949 14.5723 52.832 14.5723C40.8203 14.5723 31.25 23.9961 31.2988 36.0078C31.3477 47.6777 40.8203 57.4434 52.832 57.4434ZM52.832 43.2832C48.7793 43.2832 45.459 39.9629 45.459 36.0078C45.459 32.0039 48.7793 28.6836 52.832 28.6836C56.8848 28.6836 60.1563 32.0039 60.1563 36.0078C60.1563 39.9629 56.8848 43.2832 52.832 43.2832Z"
        fill="#999"
      />
    </svg>
  )
}

export default Eye
