import { bool, element, shape, string } from 'prop-types'

import { Subscribe } from 'components'
import * as S from './styles'

const Checkout = ({
  additionalSections,
  api,
  catalog,
  description,
  header,
  isBusiness
}) => (
  <div>
    <S.Header>
      <S.H1>{header}</S.H1>
      {description && <S.P>{description}</S.P>}
    </S.Header>
    <Subscribe
      additionalSections={additionalSections}
      catalog={catalog}
      isBusiness={isBusiness}
      api={api}
    />
  </div>
)

Checkout.propTypes = {
  api: string.isRequired,
  header: string.isRequired,
  additionalSections: element,
  catalog: shape({}),
  description: string,
  isBusiness: bool
}

Checkout.defaultProps = {
  additionalSections: null,
  catalog: {},
  description: null,
  isBusiness: false
}

export default Checkout
