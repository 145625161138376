import PropTypes from 'prop-types'
import { Option } from 'informed'

import * as S from './styles'

const Select = ({
  field,
  options,
  label,
  containerStyles,
  inputStyles,
  size,
  disabled
}) => {
  return (
    <S.SelectComponent css={containerStyles}>
      <S.Label htmlFor={field} size={size}>
        {label}
      </S.Label>
      <S.SelectElement
        field={field}
        id={field}
        size={size}
        css={inputStyles}
        disabled={disabled}
      >
        {options.map((option) => (
          <Option {...option} key={option.value}>
            {option.display}
          </Option>
        ))}
      </S.SelectElement>
    </S.SelectComponent>
  )
}

Select.propTypes = {
  size: PropTypes.oneOf(['sm', 'lg'])
}

Select.defaultProps = {
  size: 'sm'
}

export default Select
