import { Component } from 'react'

import * as S from './styles'

const withInformed = (WrappedComponent) => {
  return class HOC extends Component {
    state = {}

    setOnSubmit = (handleSubmit) => {
      this.setState({ handleSubmit })
    }

    render() {
      const { initialValues, ...rest } = this.props

      return (
        <S.StyledForm
          initialValues={initialValues}
          onSubmit={this.props.onSubmit || this.state.handleSubmit}
          allowEmptyStrings
        >
          <WrappedComponent setOnSubmit={this.setOnSubmit} {...rest} />
        </S.StyledForm>
      )
    }
  }
}

export default withInformed
