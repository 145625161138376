import { useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'

import MemberAction from './MemberAction'
import memberActions from './memberActions'
import * as S from './styles'

const MemberActionsContent = ({ member }) => {
  const currentUser = useSelector((state) => state.api.user?.me, isEqual)
  const businessPermissions = currentUser?.businessPermissions

  return (
    <S.MemberActionsContentComponent>
      <ul>
        {memberActions.map((action) => {
          if (action.shouldHide({ member, businessPermissions, currentUser })) {
            return null
          }

          return (
            <S.Li key={action.i18n}>
              <MemberAction member={member} {...action} />
            </S.Li>
          )
        })}
      </ul>
    </S.MemberActionsContentComponent>
  )
}

export default MemberActionsContent
