import store from 'store'
import { Auth } from '@aws-amplify/auth'
import { mixpanelService } from 'analytics'
import { captureException } from 'utilities/sentry'
import { getSignInAppUrl } from 'utilities/getSignInAppUrl'

export const signOut = async ({ context } = {}) => {
  mixpanelService.reset()
  if (process.env.NEXT_PUBLIC_USE_HANDOFF_AUTH === 'true') {
    await Auth.signOut()
  }
  store.persistor.purge()
  await store.dispatch({ type: 'LOGOUT' })
  window.location.href = getSignInAppUrl({ path: '/logout', context })
}

// Remove this function when business flows (sign-up/sign-in) will be moved to `ooo` or `signin` app
export const signOutDEPRECATED = async () => {
  mixpanelService.reset()
  await Auth.signOut()
  store.persistor.purge()
  await store.dispatch({ type: 'LOGOUT' })
}

export const currentSession = async () => Auth.currentSession()

export const captureAuthError = (error) => {
  captureException(error, (s) => {
    s.setFingerprint(['{{default}}', error.code ?? '', error.message ?? ''])
  })
}
