import { modelName, apiFetch, createAsyncThunk } from './commerce'

const getInvoice = createAsyncThunk(
  `${modelName}/getInvoice`,
  async ({ id, type }) => {
    const options = {
      path: `/users/me/invoices/${id}?transactionType=${type}`,
      method: 'GET'
    }
    const response = await apiFetch(options)

    return response
  }
)

getInvoice.storeKey = 'invoice'

export default getInvoice
