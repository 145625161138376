import { useTranslation } from 'i18n'
import * as S from './styles'

const SubscribeLoading = () => {
  const { t } = useTranslation(['account', 'common'])

  return (
    <S.SubscribeLoading>
      <S.PaymentAndPlan>
        <S.H2>{t('payment')}</S.H2>
        <S.PlanSelectorLoading>
          <S.RadioPillLoading />
          <S.RadioPillLoading />
        </S.PlanSelectorLoading>
      </S.PaymentAndPlan>
      <S.StyledLoadingInput />
      <S.StyledLoadingInput />
      <S.StyledLoadingInput />
      <S.StyledLoadingInput />
    </S.SubscribeLoading>
  )
}

export default SubscribeLoading
