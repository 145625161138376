import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import isEqual from 'lodash/isEqual'
import { captureException } from 'utilities/sentry'
import { Button } from '@all-turtles/mmhmm-ui-kit'

import { setDialogVisibility } from 'store/dialog/slice'
import { setNotificationVisibility } from 'store/notification/slice'
import apiStatus from 'store/selectors/apiStatus'
import { inviteMembers, getInvitations } from 'api/business'
import { useTranslation } from 'i18n'
import { ChipInput } from 'components'
import * as S from './styles'

const apiStatusSelector = (state) =>
  apiStatus({
    state,
    resource: 'business',
    api: 'inviteMembers'
  })

const InviteMembersEmailTab = () => {
  const { t } = useTranslation(['business', 'common'])
  const dispatch = useDispatch()
  const status = useSelector(apiStatusSelector, isEqual)

  const [emails, setEmails] = useState([])

  const handleOnChange = (emails) => {
    setEmails(emails)
  }

  const handleError = (error) => {
    captureException(error)
    dispatch(
      setDialogVisibility({
        name: 'ErrorDialog',
        description: t('common:somethingWentWrong'),
        showSupportLink: true
      })
    )
  }

  const handleFullSuccess = () => {
    dispatch(
      setNotificationVisibility({
        type: 'success',
        message: t('inviteMembersSuccess'),
        showDismiss: true
      })
    )

    dispatch(setDialogVisibility({ name: null }))
  }

  const handleFailedSuccess = (failures) => {
    const secondaryMessage = `(${failures.join(', ')})`
    dispatch(
      setNotificationVisibility({
        type: 'error',
        message: t('inviteMembersProblem'),
        secondaryMessage,
        showDismiss: true
      })
    )

    dispatch(setDialogVisibility({ name: null }))
  }

  const handleSubmitEmails = async () => {
    if (status?.loading) return

    if (emails && emails.length > 0) {
      try {
        const res = unwrapResult(await dispatch(inviteMembers({ emails })))
        dispatch(getInvitations())

        // Backend returns success even if some emails failed. Report failures in notif bar:
        if (res.failure.length > 0) {
          handleFailedSuccess(res.failure)
        } else {
          handleFullSuccess()
        }
      } catch (error) {
        // HandleAction will display error dialog for user, if encountered on inviteMembers
        handleError(error)
      }
    }
  }

  return (
    <>
      <S.Description>{t('enterEmail')}</S.Description>
      <ChipInput onChange={handleOnChange} />
      <S.ButtonWrapper>
        <Button
          minWidth="10.5rem"
          loading={status?.loading}
          disabled={!(emails && emails.length > 0)}
          onClick={handleSubmitEmails}
        >
          {t('send')}
        </Button>
      </S.ButtonWrapper>
    </>
  )
}

export default InviteMembersEmailTab
