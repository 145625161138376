import styled from 'styled-components'

export const Content = styled.div`
  padding-top: 32px;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
`
