import styled from 'styled-components'

import { Breakpoints, Colors, Type } from 'styles'

export const BusinessJoinComponent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Title = styled.h1`
  max-width: 39rem;
  margin-bottom: 2rem;
  text-align: center;
`

export const Email = styled.p`
  ${Type.header4};
  margin-bottom: 2.813rem;
  color: ${Colors.gray20};
`

export const Description = styled.p`
  max-width: 35rem;
  text-align: center;
`

export const Hr = styled.hr`
  height: 1px;
  background-color: ${Colors.gray40};
  width: 100%;
  max-width: 960px;
  border: 0;
  margin: 2.5rem 2rem;

  @media (min-width: ${Breakpoints.tablet}) {
    margin: 5rem 2rem 3.625rem;
  }
`

export const Buttons = styled.div`
  width: 32.375rem;
  max-width: 100%;
  display: flex;
  flex-flow: row wrap;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  margin-bottom: 1.625rem;

  @media (min-width: ${Breakpoints.tablet}) {
    margin-bottom: 2.625rem;
  }
`

export const ButtonWrapper = styled.div`
  max-width: 14.875rem;
  display: flex;
  flex-grow: 1;
  margin: 0 0.375rem 1rem;

  a,
  button {
    flex-grow: 1;
    white-space: pre-wrap;
  }
`
