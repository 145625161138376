import styled, { css } from 'styled-components'

import { Breakpoints } from 'styles'

export const LayoutComponent = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`

export const Main = styled.main`
  display: flex;
  justify-content: center;
  margin-top: 0.6875rem;
  margin-bottom: 1.5rem;

  @media (min-width: ${Breakpoints.tablet}) {
    margin-top: 5.3125rem;
    margin-bottom: 1.25rem;

    ${(props) =>
      props.hasNotification &&
      css`
        margin-top: 0.75rem;
      `}
  }
`
