import styled, { css } from 'styled-components'
import { Colors, Type } from 'styles'

export const EditAccountComponent = styled.div`
  max-width: 540px;
`

export const H1 = styled.h1`
  margin-bottom: 2.5rem;
`

export const TextButton = styled.button`
  appearance: none;
  border: none;
  background: none;
  font-family: inherit;
  cursor: pointer;
  height: auto;
  padding: 0;
  color: ${Colors.brand};
  ${Type.body20}

  ${(p) =>
    p.variant === 'destructive' &&
    css`
      color: ${Colors.red};
    `}
`

export const Divider = styled.div`
  margin-top: 3rem;
  padding-top: 2rem;
  border-top: 1px solid ${Colors.gray};
`
