import styled, { css } from 'styled-components'

import Back from '../Back/Back'
import { Type } from 'styles'

export const inputStyles = css`
  margin-bottom: 2.25rem;
  width: 100%;
`

export const inputWithErrMsgStyles = css`
  margin-bottom: 0.8125rem;
`

export const SelectWrapper = styled.div`
  display: flex;
`

export const ActionWrap = styled.div`
  display: flex;
  flex-direction: column;
`

export const ActionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.2em;
`

export const StyledBack = styled(Back)``

export const A = styled.a`
  ${Type.body30};
  text-decoration: underline;
  display: block;
  ${inputStyles};
`
