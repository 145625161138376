export const brand = '#4235F2'
export const midnight = '#000C36'
export const lime = '#CEFD54'
export const green = '#00FFD2'
export const green025 = 'rgba(0, 255, 210, 0.25)'
export const black = '#000'
export const white = '#FFF'
export const offWhite = '#F6F6F6'
export const gray = '#D9D8D9'
export const gray10 = '#EDEDED'
export const gray20 = '#4D4D4D'
export const gray30 = '#3F3F3F'
export const gray40 = '#979797'
export const grayIcon = '#737473'
export const placeholderGray = '#7f7f7f'
export const placeholderGrayDisabled = '#9f9f9f'
export const text20 = '#575757'
export const blue10 = '#3988F7'
export const blue20 = '#55B4F9'
export const blue30 = '#AAD9FC'
export const blue40 = '#F4F7FE'
export const red = '#EB4646'
export const red10 = '#FFCBD5'
export const warning = '#E72323'
export const loading = '#D7D7D7'
export const success = '#69D272'
export const yellow = '#F9DF74'
export const yellowLight = '#FDF5D5'
