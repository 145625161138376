const Mastercard = (props) => {
  return (
    <svg
      width="128"
      height="84"
      viewBox="0 0 128 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Mastercard card"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 6C0 2.68629 2.68629 0 6 0H122C125.314 0 128 2.68629 128 6V78C128 81.3137 125.314 84 122 84H6C2.68629 84 0 81.3137 0 78V6Z"
        fill="#F4F6F7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50 64C62.1503 64 72 54.1503 72 42C72 29.8497 62.1503 20 50 20C37.8497 20 28 29.8497 28 42C28 54.1503 37.8497 64 50 64Z"
        fill="#D8232A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64 58.9714C67.8038 62.1127 72.6815 64 78 64C90.1503 64 100 54.1503 100 42C100 29.8497 90.1503 20 78 20C72.6815 20 67.8038 21.8873 64 25.0286C68.886 29.0638 72 35.1683 72 42C72 48.8317 68.886 54.9362 64 58.9714Z"
        fill="#ED9F2D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M64 25.0288C59.114 29.064 56 35.1684 56 42.0002C56 48.8319 59.114 54.9363 64 58.9715C68.886 54.9363 72 48.8319 72 42.0002C72 35.1684 68.886 29.064 64 25.0288Z"
        fill="#D8232A"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="56"
        y="25"
        width="16"
        height="34"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M64 25.0288C59.114 29.064 56 35.1684 56 42.0002C56 48.8319 59.114 54.9363 64 58.9715C68.886 54.9363 72 48.8319 72 42.0002C72 35.1684 68.886 29.064 64 25.0288Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M54 26H74V28H54V26ZM54 30H74V32H54V30ZM74 34H54V36H74V34ZM54 38H74V40H54V38ZM74 42H54V44H74V42ZM54 46H74V48H54V46ZM74 50H54V52H74V50ZM54 54H74V56H54V54ZM74 58H54V60H74V58Z"
          fill="#ED9F2D"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 6C0 2.68629 2.68629 0 6 0H122C125.314 0 128 2.68629 128 6V78C128 81.3137 125.314 84 122 84H6C2.68629 84 0 81.3137 0 78V6Z"
        fill="#E5E5E5"
        fillOpacity="0.01"
      />
    </svg>
  )
}

export default Mastercard
