import { useState } from 'react'
import { string } from 'prop-types'

import { useTranslation, i18n } from 'i18n'
import { ONE_NUM, ONE_LOWER_AND_UPPER } from 'utilities/regex'
import { TextField } from 'components'
import { getAuthFields } from 'data/authFields'
import langConfig from '../../../locales'
import * as S from './styles'

const CreateBusinessAccount = ({ email }) => {
  const { t } = useTranslation(['business', 'account'])

  // Display "Last Name - First Name" for locales with reverseName specified
  const locale = i18n.language
  const localeConfig = langConfig.find((l) => l.id === locale)
  const reverseName = localeConfig?.reverseName

  /**
   * Check which requirements are valid and display error as highlighted requirement;
   * only run on blur to match bahavior of other fields;
   * start with all valid so user doesn't see errors before entering field
   */
  const [reqsMet, setReqsMet] = useState({
    casing: true,
    number: true,
    short: true
  })
  const handlePasswordBlur = (e) => {
    const { value } = e.target
    if (value) {
      const newReqs = {
        casing: ONE_LOWER_AND_UPPER.test(value),
        number: ONE_NUM.test(value),
        short: value.length > 7
      }

      setReqsMet(newReqs)
    } else {
      setReqsMet({})
    }
  }

  const authFields = getAuthFields(t)
  return (
    <>
      <S.H2>{t('account:createAccountTitle')}</S.H2>
      <S.Label>{t('workEmail')}</S.Label>
      <S.ReadOnlyEmail>
        <bdi>{email}</bdi>
      </S.ReadOnlyEmail>
      <S.FieldRow>
        <S.Field>
          <TextField
            {...authFields[reverseName ? 'lastName' : 'firstName']}
            field={reverseName ? 'userLastName' : 'userFirstName'}
          />
        </S.Field>
        <S.Field>
          <TextField
            {...authFields[reverseName ? 'firstName' : 'lastName']}
            field={reverseName ? 'userFirstName' : 'userLastName'}
          />
        </S.Field>
      </S.FieldRow>
      <S.Field>
        <TextField {...authFields.createPassword} onBlur={handlePasswordBlur} />
        <S.RequirementsContainer>
          <S.Requirement hasError={!reqsMet.casing}>
            {t('account:oneLowerAndUpperReq')}
          </S.Requirement>
          <S.Requirement hasError={!reqsMet.number}>
            {t('account:oneNumberReq')}
          </S.Requirement>
          <S.Requirement hasError={!reqsMet.short}>
            {t('account:passwordLengthReq')}
          </S.Requirement>
        </S.RequirementsContainer>
      </S.Field>
    </>
  )
}

CreateBusinessAccount.propTypes = {
  email: string
}

CreateBusinessAccount.defaultProps = {
  email: ''
}

export default CreateBusinessAccount
