import styled from 'styled-components'

import { Breakpoints, Colors, Type } from 'styles'

export const MobilePaymentHistoryComponent = styled.ul`
  @media (min-width: ${Breakpoints.tablet}) {
    display: none;
  }
`

export const Li = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${Colors.gray};
  margin-bottom: 10px;
  padding-bottom: 10px;
  ${Type.body30};
`

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
`

export const Id = styled.p`
  margin-bottom: 6px;
`

export const Date = styled.p`
  font-weight: ${Type.fontWeights.bold};
  margin-bottom: 6px;
`

export const Amount = styled.p``
