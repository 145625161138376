import { useSelector } from 'react-redux'

import { PendingDeactivationBar } from 'components'
import BillingInfoSection from './BillingInfoSection'
import SubscriptionSection from './SubscriptionSection'
import PaymentMethodSection from './PaymentMethodSection'
import InvoicesSection from './InvoicesSection'
import * as S from './styles'

const BusinessBilling = () => {
  const subscription = useSelector((state) => state.api.business?.subscription)

  const businessPermissions = useSelector(
    (state) => state.api.user?.me?.businessPermissions
  )
  const canView = businessPermissions?.canViewBilling

  if (!canView) return null

  const pendingCancellation = subscription?.status === 'non_renewing'
  const paymentFailure = subscription?.paymentFailed
  const paymentType = subscription?.paymentMethod?.type

  if (paymentType === 'reseller') {
    return (
      <S.BillingWrapper>
        <PaymentMethodSection
          subscription={subscription}
          businessPermissions={businessPermissions}
        />
      </S.BillingWrapper>
    )
  }

  return (
    <S.BillingWrapper>
      {businessPermissions?.canAdmin &&
        (pendingCancellation || paymentFailure) && (
          <PendingDeactivationBar
            warning={
              pendingCancellation ? 'pendingCancellation' : 'paymentFailure'
            }
            endDate={subscription?.endDate}
          />
        )}
      <SubscriptionSection
        subscription={subscription}
        businessPermissions={businessPermissions}
      />
      <PaymentMethodSection
        subscription={subscription}
        businessPermissions={businessPermissions}
      />
      <InvoicesSection />
      <BillingInfoSection businessPermissions={businessPermissions} />
    </S.BillingWrapper>
  )
}

export default BusinessBilling
