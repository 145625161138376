import styled from 'styled-components'
import X from 'components/Icons/X'
import ButtonPrimitive from 'components/ButtonPrimitive/ButtonPrimitive'
import { Colors } from 'styles'

export const CloseToastComponent = styled(ButtonPrimitive)`
  padding: 0.625rem;
  opacity: 0.6;
  transition: all 150ms ease;
  margin-inline-start: auto;
  border: 2px solid transparent;
  display: flex;

  &:hover {
    opacity: 1;
  }

  &:focus {
    outline: none;
    border: 2px solid ${Colors.brand};
  }

  &:focus:not(.focus-visible) {
    outline: none;
    border: 2px solid transparent;
  }
`

export const StyledX = styled(X)`
  width: 0.875rem;
  height: 0.875rem;
`
