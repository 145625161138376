import { modelName, apiFetch, createAsyncThunk } from './business'

const joinBusiness = createAsyncThunk(
  `${modelName}/joinBusiness`,
  async ({ verifier }) => {
    const options = {
      path: '/businesses/me',
      method: 'GET',
      body: { verifier }
    }
    const response = await apiFetch(options)

    return response
  }
)

export default joinBusiness
