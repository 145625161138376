import dayjs from 'dayjs'
import LocalizedFormat from 'dayjs/plugin/localizedFormat'

/**
 * Uses DayJS and its LocalizedFormat plugin to format and localize a given date.
 * - Relies on correct dayjs/locale config file being loaded in i18n/index.js
 * - Accepts a date (required) and date format (optional)
 * - Default `format` 'LLL' is 'MMMM D, YYYY' (eg, 'October 6, 2021')
 * - Additional localized formats: https://day.js.org/docs/en/display/format#localized-formats
 */
const formatDate = (date, format = 'LL') => {
  dayjs.extend(LocalizedFormat)
  return dayjs(date).format(format)
}

export default formatDate
