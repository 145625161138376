import { useDispatch, useSelector } from 'react-redux'
import { captureException } from 'utilities/sentry'

import { useTranslation } from 'i18n'
import { updateBusiness } from 'api/business'
import { getMe } from 'api/user'
import { BusinessPreferencesForm, HandleAction } from 'components'

const BusinessPreferences = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('business')
  const businessSettings = useSelector(
    (state) => state.api.business?.businessSettings
  )

  if (!businessSettings) return null

  const getEnableLinksValue = () => {
    /**
     * There are 3 radio options for sharing join links in the UI, but field's value maps to 2
     * separate bool fields on the backend: enableBusinessJoinLink & enableMemberJoinLinks
     * - everyone = both true
     * - admins = enableBusinessJoinLink true, enableMemberJoinLinks false
     * - none (link sharing is disabled) = both false
     */
    if (businessSettings.enableBusinessJoinLink) {
      return businessSettings.enableMemberJoinLinks ? 'everyone' : 'admins'
    }

    return 'none'
  }

  const initialValues = {
    name: businessSettings.name,
    enableLinksGroup: getEnableLinksValue(),
    sendJoinNotifications: businessSettings.sendJoinNotifications
  }

  const handleSubmit = async ({
    name,
    enableLinksGroup,
    sendJoinNotifications
  }) => {
    const payload = {
      name,
      sendJoinNotifications,
      enableBusinessJoinLink: enableLinksGroup !== 'none',
      enableMemberJoinLinks: enableLinksGroup === 'everyone'
    }

    try {
      await dispatch(updateBusiness(payload))
      dispatch(getMe())
    } catch (error) {
      // HandleAction will display error dialog for user, if encountered on updateBusiness
      captureException(error)
    }
  }

  return (
    <div>
      <HandleAction
        resource="business"
        api="updateBusiness"
        toastDescription={t('settingsSaveSuccess')}
        stayOnPage
      />
      <BusinessPreferencesForm
        onSubmit={handleSubmit}
        initialValues={initialValues}
      />
    </div>
  )
}

export default BusinessPreferences
