import { useDispatch, useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'
import { RadioGroup } from 'informed'
import { Button } from '@all-turtles/mmhmm-ui-kit'

import { useTranslation } from 'i18n'
import { setDialogVisibility } from 'store/dialog/slice'
import apiStatus from 'store/selectors/apiStatus'
import { businessFields } from 'data'
import { withInformed, Radio, ResetInviteLinks, TextField } from 'components'
import * as S from './styles'

const apiStatusSelector = (state) =>
  apiStatus({
    state,
    resource: 'business',
    api: 'updateBusiness'
  })

const BusinessPreferencesForm = () => {
  const { t } = useTranslation(['business', 'common'])
  const dispatch = useDispatch()
  const status = useSelector(apiStatusSelector, isEqual)
  const canAdmin = useSelector(
    (state) => state.api.user?.me?.businessPermissions?.canAdmin
  )

  const { businessName, enableLinksGroup, sendJoinNotificationsGroup } =
    businessFields({ t })

  const handleResetClick = () => {
    dispatch(setDialogVisibility({ name: 'ConfirmResetLinksDialog' }))
  }

  return (
    <S.BusinessPreferencesFormComponent>
      <TextField
        {...businessName}
        field="name"
        label={t('businessName')}
        disabled={!canAdmin}
      />
      <S.PreferencesSection>
        <S.H2>{t('inviteSettings')}</S.H2>
        <RadioGroup field={enableLinksGroup.field} required>
          {enableLinksGroup.fields.map((field) => (
            <Radio key={field.id} {...field} />
          ))}
        </RadioGroup>
        <S.ResetWrapper>
          <ResetInviteLinks handleClick={handleResetClick} />
        </S.ResetWrapper>
      </S.PreferencesSection>
      <S.PreferencesSection>
        <S.H2>{t('notificationSettings')}</S.H2>
        <RadioGroup field={sendJoinNotificationsGroup.field} required>
          {sendJoinNotificationsGroup.fields.map((field) => (
            <Radio key={field.id} {...field} />
          ))}
        </RadioGroup>
      </S.PreferencesSection>
      <Button loading={status.loading} disabled={!canAdmin}>
        {t('common:save')}
      </Button>
    </S.BusinessPreferencesFormComponent>
  )
}

export default withInformed(BusinessPreferencesForm)
