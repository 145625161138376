import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'notification',
  initialState: {},
  reducers: {
    setNotificationVisibility(state, action) {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key]
      })
    }
  }
})

export const { setNotificationVisibility } = slice.actions

export default slice.reducer
