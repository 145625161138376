import { useEffect, useCallback, useState } from 'react'
import debounce from 'lodash/debounce'

const useDebouncedSearchInput = () => {
  const [search, setSearch] = useState()
  const [inputSearch, setInputSearch] = useState()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSetSearch = useCallback(
    debounce((value) => {
      setSearch(value)
    }, 500),
    []
  )

  useEffect(() => {
    handleSetSearch(inputSearch)
  }, [handleSetSearch, inputSearch])

  return {
    searchInputProps: {
      onChange: (e) => setInputSearch(e.target.value),
      value: inputSearch
    },
    search
  }
}

export default useDebouncedSearchInput
