import styled from 'styled-components'

import { Arrow } from 'components/Icons'
import { CardIcons } from 'components'
import { Breakpoints, Colors, Type } from 'styles'

export const BillingWrapper = styled.div`
  width: 100%;
`

export const Section = styled.div`
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  border-bottom: 1px solid ${Colors.gray};

  @media (min-width: ${Breakpoints.small}) {
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
  }
`

export const Content = styled.div`
  margin-bottom: 1rem;

  @media (min-width: ${Breakpoints.small}) {
    margin-inline-end: 2rem;
    margin-bottom: 0;
  }
`

export const InvoicesSection = styled.div`
  display: block;
  margin-bottom: 2rem;
`

export const FlexRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.875rem;
`

export const EndLinks = styled.div`
  display: flex;
  margin-bottom: 0.5rem;
  width: 100%;
  justify-content: space-between;

  @media (min-width: ${Breakpoints.small}) {
    width: auto;
  }
`

export const Header = styled.h2`
  ${Type.header4};
  margin-bottom: 1rem;

  @media (min-width: ${Breakpoints.small}) {
    margin-bottom: 0.5rem;
  }
`

export const Body = styled.p`
  ${Type.body30};
`

export const RefundWrapper = styled.div`
  margin-inline-end: 1rem;

  @media (min-width: ${Breakpoints.small}) {
    padding-inline-end: 1rem;
    border-inline-end: 1px solid ${Colors.gray};
  }

  @media (min-width: ${Breakpoints.tablet}) {
    padding-inline-end: 2rem;
    margin-inline-end: 2rem;
  }
`

export const ArrowLink = styled.a`
  ${Type.body20};
  height: 1.5625rem;
  display: flex;

  &:hover {
    text-decoration: underline;
  }
`

export const AnchorBottomArrowLink = styled(ArrowLink)`
  @media (min-width: ${Breakpoints.small}) {
    align-self: flex-end;
  }
`

export const StyledArrow = styled(Arrow)`
  height: 1.5625rem;
  margin-inline-start: 0.75rem;

  [dir='rtl'] & {
    transform: scaleX(-1);
  }
`

export const NoWrap = styled.span`
  white-space: nowrap;
`

export const CardRow = styled.div`
  display: flex;
  margin-top: 1rem;
`

export const CardIcon = styled(CardIcons)`
  width: 2rem;
  height: 1.375rem;
  margin-inline-end: 0.875rem;
`

export const CardInfo = styled.p`
  ${Type.body30};
`

export const NoResults = styled.p`
  ${Type.body30};
  color: ${Colors.gray40};
  padding: 1.25rem 0;
  border-bottom: 1px solid ${Colors.gray};
  text-align: center;
`

export const BillingInfoWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  div {
    margin-inline-end: 1rem;
  }
`

export const BillingInfoLabel = styled.p`
  font-size: 0.8125rem;
  line-height: 1rem;
  font-weight: ${Type.fontWeights.bold};
  color: ${Colors.grayIcon};
  margin: 1.5rem 0 0.5rem;
`
