import { Button } from '@all-turtles/mmhmm-ui-kit'
import { Dialog } from 'components'
import { useTranslation } from 'i18n'
import { getSignInAppUrl } from 'utilities/getSignInAppUrl'

import * as S from './styles'

const LinkAppDialog = ({ visible, provider, providerName }) => {
  const { t } = useTranslation('account')

  const handleContinue = () => {
    window.location.href = getSignInAppUrl({
      path: '/link-oauth',
      context: 'connected-apps',
      query: { provider }
    })
  }

  return (
    <Dialog visible={visible}>
      <S.Content>
        {t('account:linkAppWarning', { provider: providerName })}
        <Button onClick={handleContinue}>{t('continue')}</Button>
      </S.Content>
    </Dialog>
  )
}

export default LinkAppDialog
