const Shelves = (props) => {
  return (
    <svg
      width="260"
      height="300"
      viewBox="0 0 260 300"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="presentation"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M106 109.667V99.6666H254.667V109.667H106ZM215 186.333H3V176.333H215V186.333ZM202 247.333H8V237.333H202V247.333ZM219.667 296.333H132V286.333H219.667V296.333Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M247.001 99.3334L230.001 99.0967V100H146.334V76.6667H223.001V47.3334L247.001 99.3334ZM103.334 176.667V130L158.667 176.667H103.334ZM136.334 194.667L156.334 238H96.0006V212.333H136.334V194.667ZM46.334 160H88.6673V176.667H46.334V160ZM189.667 176V124.333L213.667 176.333L189.667 176Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M137.334 176.333V123H190.668V176.333H137.334ZM147.001 99.3333C134.114 99.3333 123.668 88.8866 123.668 76C123.668 63.1133 134.114 52.6666 147.001 52.6666C159.888 52.6666 170.334 63.1133 170.334 76C170.334 88.8866 159.888 99.3333 147.001 99.3333ZM65.8242 176.293L98.8275 119.127L131.834 176.293H65.8242ZM94.0009 190.333C106.888 190.333 117.334 200.78 117.334 213.667C117.334 226.553 106.888 237 94.0009 237C81.1142 237 70.6676 226.553 70.6676 213.667C70.6676 200.78 81.1142 190.333 94.0009 190.333Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M170.334 99.3333V46H223.667V99.3333H170.334ZM117.334 193.667H137.334V237H117.334V193.667ZM48.334 176.333C31.7653 176.333 18.334 162.902 18.334 146.333C18.334 129.765 31.7653 116.333 48.334 116.333C64.9027 116.333 78.334 129.765 78.334 146.333C78.335 162.901 64.9053 176.332 48.338 176.333C48.3366 176.333 48.3353 176.333 48.334 176.333Z"
        fill="#FF2F59"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M255.001 102H106.001C104.896 102 104.001 101.105 104.001 100C104.001 98.8953 104.896 98 106.001 98H255.001C256.105 98 257.001 98.8953 257.001 100C257.001 101.105 256.105 102 255.001 102ZM217.001 177C217.001 178.105 216.105 179 215.001 179H3.33398C2.22932 179 1.33398 178.105 1.33398 177C1.33398 175.895 2.22932 175 3.33398 175H215.001C216.105 175 217.001 175.895 217.001 177ZM8.66732 235.667H201.334C202.439 235.667 203.334 236.562 203.334 237.667C203.334 238.771 202.439 239.667 201.334 239.667H8.66732C7.56265 239.667 6.66732 238.771 6.66732 237.667C6.66732 236.562 7.56265 235.667 8.66732 235.667ZM132.001 284.333H219.667C220.772 284.333 221.667 285.229 221.667 286.333C221.667 287.438 220.772 288.333 219.667 288.333H132.001C130.896 288.333 130.001 287.438 130.001 286.333C130.001 285.229 130.896 284.333 132.001 284.333Z"
        fill="black"
      />
    </svg>
  )
}

export default Shelves
