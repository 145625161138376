const Arrow = (props) => {
  return (
    <svg
      width="8"
      height="15"
      viewBox="0 0 8 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.9375 14.2812C1.19531 14.2812 1.40625 14.1875 1.58594 14.0156L7.66406 8.07812C7.875 7.85938 7.98438 7.63281 7.98438 7.35156C7.98438 7.07812 7.88281 6.83594 7.66406 6.63281L1.58594 0.6875C1.40625 0.515625 1.19531 0.421875 0.9375 0.421875C0.414062 0.421875 0.0078125 0.828125 0.0078125 1.34375C0.0078125 1.59375 0.117188 1.83594 0.289062 2.01562L5.76562 7.35938L0.289062 12.6875C0.109375 12.875 0.0078125 13.1016 0.0078125 13.3594C0.0078125 13.875 0.414062 14.2812 0.9375 14.2812Z"
        fill="#4235F2"
      />
    </svg>
  )
}

export default Arrow
