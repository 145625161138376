import dayjs from 'dayjs'
import { Trans, useTranslation } from 'i18n'
import { useSelector } from 'react-redux'

import formatDate from 'utilities/formatDate'
import { Panel } from 'components'
import * as S from './styles'

const SubscriptionEduPanel = () => {
  const { t } = useTranslation(['common', 'account', 'edu'])
  const subscription = useSelector((state) => state.api.commerce?.subscription)
  const renewing = subscription?.nextPlanId
  const endTimestamp = subscription?.endDate || subscription?.nextChargeDate
  const endDate = formatDate(endTimestamp)
  const count = dayjs(endTimestamp).diff(dayjs(), 'month')

  const ctas = renewing
    ? [
        {
          id: 'subscription',
          label: t('manage'),
          href: '/subscription',
          type: 'linkToButton'
        }
      ]
    : [
        {
          id: 'subscription',
          label: t('manage'),
          href: '/subscription',
          type: 'linkToButton'
        }
      ]

  return (
    <Panel heading={t('account:educationLong')} ctas={ctas}>
      <>
        <S.SubscriptionInfo>
          <Trans i18nKey="account:eduExpiration" count={count}>
            Your free education subscription will expire on
            <S.NoWrap>{{ endDate }}</S.NoWrap> ({{ count }} months).
          </Trans>
        </S.SubscriptionInfo>
        {renewing && (
          <S.SubscriptionInfo>
            <Trans i18nKey="account:nextPayment">
              Next payment will occur automatically on
              <S.NoWrap>{{ renewalDate: endDate }}</S.NoWrap>.
            </Trans>
            .
          </S.SubscriptionInfo>
        )}
      </>
    </Panel>
  )
}

export default SubscriptionEduPanel
