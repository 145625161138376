/**
 * id: string
 * - Used to locate localePath directory name (must be set in Smartling)
 * - Used as the url subpath, except where `path` is set
 * - Set as `lang` attribute in <HTML /> so must meet BCP47 convetions
 * - Follow the golden rule of language tags: "keep the tag as short as possible"
 *     (https://www.w3.org/International/articles/language-tags/);
 *   this allows gracefall fallback when a specific region isn't found.
 *   - If only one locale of the language is available, use the two-letter code;
 *   - If multiple locales are available, use one as the default (eg `zh`) to allow
 *   for gracefull fallback and set the others with the region subtag (eg `zh-Hant`)
 *
 *  dayJsLocale: string
 * - Date and time are configured using Day.js library, which provides localization as well
 *   (see https://day.js.org/docs/en/i18n/i18n)
 * - New locale must be set in Day.js on `languageChanged`, which happens in i18n.index.js.
 *   We import the correct locale file for each one, but their locale ID's are sometimes
 *   different than ours, so we specify that with `dayJsLocale`
 * - Available locales: https://github.com/iamkun/dayjs/tree/dev/src/locale
 *
 * smartlingLocale: string
 * - The locale set in Smartling, which is generally as specific as possible
 * - Just for reference and not currently used in this app
 * - (see https://help.smartling.com/hc/en-us/articles/360049532693-Supported-Languages-)
 *
 * title: string
 * - Used in UI by LanguageSelect
 *
 * dir: string (one of: ['ltr', 'rtl'])
 * - Language direction, to be used in HTML as `dir` property
 *
 * reverseName: bool
 * - Some languages present names as Last-First instead of First-Last. When `reverseName: true`,
 * first and last name fields will be ordered in reverse in checkout form.
 */

module.exports = [
  { id: 'ar', dayJsLocale: 'ar', smartlingLocale: 'ar', title: 'العربية', dir: 'rtl' },
  { id: 'de', dayJsLocale: 'de', smartlingLocale: 'de-DE', title: 'Deutsch', dir: 'ltr' },
  { id: 'en', dayJsLocale: 'en', smartlingLocale: 'en-US', title: 'English', dir: 'ltr' },
  { id: 'es', dayJsLocale: 'es', smartlingLocale: 'es-LA', title: 'Español', dir: 'ltr' },
  { id: 'fr', dayJsLocale: 'fr', smartlingLocale: 'fr-FR', title: 'Français', dir: 'ltr' },
  { id: 'he', dayJsLocale: 'he', smartlingLocale: 'he-IL', title: 'עברית', dir: 'rtl' },
  { id: 'it', dayJsLocale: 'it', smartlingLocale: 'it-IT', title: 'Italiano', dir: 'ltr' },
  {
    id: 'ja',
    dayJsLocale: 'ja',
    smartlingLocale: 'ja-JP',
    title: '日本語',
    dir: 'ltr',
    reverseName: true
  },
  {
    id: 'ko',
    dayJsLocale: 'ko',
    smartlingLocale: 'ko-KR',
    title: '한국어',
    dir: 'ltr',
    reverseName: true
  },
  { id: 'pt', dayJsLocale: 'pt-br', smartlingLocale: 'pt-BR', title: 'Português', dir: 'ltr' },
  { id: 'uk', dayJsLocale: 'uk', smartlingLocale: 'uk', title: 'Українська', dir: 'ltr' },
  {
    id: 'zh-Hans',
    dayJsLocale: 'zh-cn',
    smartlingLocale: 'zh-CN',
    title: '简体中文',
    dir: 'ltr',
    reverseName: true
  },
  {
    id: 'zh-Hant',
    dayJsLocale: 'zh-tw',
    smartlingLocale: 'zh-TW',
    title: '繁體中文',
    dir: 'ltr',
    reverseName: true
  }
]
