import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation, Link } from 'i18n'
import { useRouter } from 'next/router'

import { getBusinessSettings, getSubscription } from 'api/business'
import { getProviders } from 'api/auth'
import { Panel } from 'components'
import navItems from './navItems'
import * as S from './styles'

const BusinessNav = () => {
  const router = useRouter()
  const dispatch = useDispatch()
  const { t } = useTranslation(['account', 'business'])

  /**
   * Possible business statuses (business.businessSettings.status):
   * pre-activation, active, deactivated
   * Possible business subscription statuses (business.subscription.status):
   * active, cancellation_pending, deactivated
   */
  const business = useSelector((state) => state.api.business)
  const providers = useSelector((state) => state.api.auth?.providers)
  const hasIdps = providers?.idps?.length > 0
  const status = {
    businessStatus: business?.businessSettings?.status,
    subscriptionStatus: business?.subscription?.status
  }

  const businessPermissions = useSelector(
    (state) => state.api.user?.me?.businessPermissions
  )

  useEffect(() => {
    // If for some reason these haven't been fetched yet, do it now
    if (!business?.businessSettings?.status) {
      dispatch(getBusinessSettings())
    }

    if (!business?.subscription?.status) {
      dispatch(getSubscription())
    }

    if (!providers?.idps) {
      dispatch(getProviders())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <S.BusinessNavComponent>
      <Panel heading={t('business:manageBusiness')} variant="navigation">
        <ul>
          {navItems.map(({ url, i18nUrl, i18nKey, shouldHide, ...rest }) => {
            if (
              shouldHide &&
              shouldHide({ status, businessPermissions, hasIdps })
            )
              return null
            const href = i18nUrl ? t(i18nUrl) : url
            const selected = router.pathname === url
            return (
              <S.Li key={`nav-${i18nKey}`} selected={selected}>
                <Link href={href} passHref>
                  <a selected={selected} {...rest}>
                    {t(i18nKey)}
                  </a>
                </Link>
              </S.Li>
            )
          })}
        </ul>
      </Panel>
    </S.BusinessNavComponent>
  )
}

export default BusinessNav
