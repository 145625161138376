import styled from 'styled-components'
import { Colors } from 'styles'

export const DialogWrap = styled.div`
  width: 33rem;
  display: flex;
  flex-direction: column;
  align-items: left;
`

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 14px;
`

export const VerificationCodeField = styled.input`
  width: '100%';
`

export const ResendCodeBar = styled.div`
  display: flex;
  padding-bottom: 14px;
`

export const H1 = styled.h1`
  margin-bottom: 2rem;
`

export const WarningMessage = styled.span`
  font-size: 1rem;
  margin-bottom: 1rem;
  color: ${Colors.red};
`

export const DescriptionText = styled.span`
  font-size: 1rem;
  margin-bottom: 1.5rem;
`

export const Link = styled.a`
  &:hover {
    text-decoration: underline;
  }
`

export const LoaderOverlay = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: default;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 8px;
  > * {
    &:first-child {
      zoom: 2;
    }
  }
`
