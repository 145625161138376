import styled from 'styled-components'
import { animated } from 'react-spring/web.cjs'
import { DialogOverlay, DialogContent } from '@reach/dialog'

import { Colors } from 'styles'
import CloseDialog from '../CloseDialog/CloseDialog'

const AnimatedDialogOverlay = animated(DialogOverlay)
const AnimatedDialogContent = animated(DialogContent)

export const Overlay = styled(AnimatedDialogOverlay)`
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.77);
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: flex-start;
`

export const Content = styled(AnimatedDialogContent)`
  box-sizing: border-box;
  margin: 0 auto;
  border-radius: 0.5rem;
  padding: 2.5rem;
  max-width: 100%;
  background: ${(props) =>
    props.$transparentContent ? 'transparent' : Colors.white};

  &[data-reach-dialog-content] {
    width: auto;
  }
`

export const StyledCloseDialog = styled(CloseDialog)``
