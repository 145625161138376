import { createAsyncThunk } from '@reduxjs/toolkit'

import apiFetch from '../apiFetch'

const createUser = createAsyncThunk(
  'auth/createUser',
  async ({
    email,
    name,
    business = true,
    businessId,
    verifier,
    password,
    clientId = process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID
  }) => {
    const body = { name, business, businessId, verifier, password, clientId }
    body.email = email.toLowerCase()
    const options = {
      path: '/auth/users',
      method: 'POST',
      body,
      authorized: false
    }

    const response = await apiFetch(options)
    return response
  }
)

export default createUser
