import styled from 'styled-components'

import { Breakpoints } from 'styles'

export const InviteMembersComponent = styled.div`
  width: auto;

  @media (min-width: ${Breakpoints.tablet}) {
    width: 36.25rem;
  }
`

export const Title = styled.h2`
  margin-bottom: 3.25rem;
`
