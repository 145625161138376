import * as S from './styles'
import { useTranslation } from 'i18n'
import { setDialogVisibility } from 'store/dialog/slice'
import { useDispatch } from 'react-redux'

const MemberAction = ({ i18n, dialogName, member }) => {
  const { t } = useTranslation('business')
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(setDialogVisibility({ name: dialogName, member }))
  }

  return (
    <S.MenuItem onClick={handleClick} variant="link" small>
      {t(i18n)}
    </S.MenuItem>
  )
}

export default MemberAction
