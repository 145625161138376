import styled from 'styled-components'
import { Radio } from 'informed'

import { Colors, Type } from 'styles'

export const RadioComponent = styled.div`
  position: relative;
  display: flex;
  min-height: 2.125rem;
  align-items: center;
`

/** Outer circle of visible radio */
export const VisibleRadio = styled.div`
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: inset 0px 1.5px 1px rgba(0, 0, 0, 0.15),
    inset 0px 0px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border 175ms ease;
  box-sizing: border-box;
`

/** Inner blue dot when selected  */
export const SelectedRadioDot = styled.div`
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
  background: ${Colors.brand};
  opacity: 0;
  transition: opacity 175ms ease;
`

/**
 * This is the true radio element, hidden with 0 opacity but technically on top of the visual
 * radio through z-index to retain a11y. When selected/unselected, it updates the visual elements.
 */
export const HiddenRadio = styled(Radio)`
  z-index: 2;
  position: absolute;
  width: 1rem;
  height: 1rem;
  margin: 0;
  opacity: 0;
  cursor: pointer;

  &:checked ~ ${VisibleRadio} {
    border: 1px solid ${Colors.brand};

    ${SelectedRadioDot} {
      opacity: 1;
    }
  }

  &:focus.focus-visible ~ ${VisibleRadio} {
    outline: 2px solid ${Colors.brand};
    outline-offset: 2px;
  }
`

export const Label = styled.label`
  ${Type.body30}
  padding-inline-start: 0.625rem;
  cursor: pointer;
`
