import isEqual from 'lodash/isEqual'
import { useDispatch, useSelector } from 'react-redux'

import * as S from './styles'
import {
  withInformed,
  TextField,
  RoomFileInput,
  HandleAction
} from 'components'
import { useTranslation } from 'i18n'
import { Button } from '@all-turtles/mmhmm-ui-kit'
import { deleteRoom } from 'api/business'
import apiStatus from 'store/selectors/apiStatus'

const apiStatusSelector = (state) =>
  apiStatus({
    state,
    resource: 'business',
    api: 'deleteRoom'
  })

const EditRoomForm = ({ id, thumbnailUrl, onFileChange, loading, state }) => {
  const { t } = useTranslation(['business', 'common'])
  const dispatch = useDispatch()
  const status = useSelector(apiStatusSelector, isEqual)

  const roomName = {
    field: 'roomName',
    label: t('roomName'),
    placeholder: 'business:roomNamePlaceholder',
    required: t('fieldRequired'),
    validate: (value) => {
      if (value && value.length > 200) return t('common:tooLong')
    },
    validateOnBlur: true
  }

  const roomDescription = {
    field: 'roomDescription',
    label: t('roomDescription'),
    validate: (value) => {
      if (value && value.length > 5000) return t('common:tooLong')
    },
    validateOnBlur: true,
    placeholder: 'business:roomDescriptionPlaceholder'
  }

  const handleDeleteRoom = () => {
    dispatch(deleteRoom({ id }))
  }

  return (
    <>
      <HandleAction
        resource="business"
        api="deleteRoom"
        closeAllDialogsOnSuccess
        stayOnPage
        noToast
      />
      <RoomFileInput thumbnailUrl={thumbnailUrl} onFileChange={onFileChange} />
      <S.Fields>
        <TextField {...roomName} />
        <TextField {...roomDescription} />
      </S.Fields>
      <S.Buttons>
        {state === 'edit' && (
          <Button
            type="button"
            onClick={handleDeleteRoom}
            loading={status?.loading}
            variant="danger"
          >
            {t('deleteRoom')}
          </Button>
        )}
        <Button loading={loading}>{t('common:save')}</Button>
      </S.Buttons>
    </>
  )
}

export default withInformed(EditRoomForm)
