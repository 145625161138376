import styled from 'styled-components'

import Back from '../Back/Back'
import Informed from '../Form/Form'

export const EditPaymentComponent = styled.div`
  max-width: 540px;
`

export const H1 = styled.h1`
  margin-bottom: 2.5rem;
`

export const StyledBack = styled(Back)``

export const Form = styled(Informed)``

export const Loading = styled.div`
  height: 20rem;
  display: flex;
  align-items: center;
`

export const Wrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.625rem;
`
