import styled from 'styled-components'

import { Loading } from 'components'
import { Colors } from 'styles'

export const NotificationBar = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 3.75rem;
  width: 100%;
  padding: 1.125rem 3.75rem;
  margin-top: -4.125rem;
  background-color: ${Colors.gray10};
`
export const StyledLoading = styled(Loading)`
  position: absolute;
  left: 0.9375rem;

  [dir='rlt'] & {
    left: unset;
    right: 0.9375rem;
  }
`

export const Text = styled.p`
  color: ${Colors.midnight};
  font-size: 1.1875rem;
`
