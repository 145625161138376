import styled from 'styled-components'

import CircleCheckFilled from '../Icons/CircleCheckFilled'
import { Colors, Type } from 'styles'

export const Body = styled.p`
  ${Type.body30};
  margin-top: 0.625rem;
  margin-bottom: 0.375rem;
  max-width: 33.75rem;
`

export const Table = styled.div`
  margin-top: 1.25rem;
  border-top: 1px solid ${Colors.gray10};
`

export const DomainRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 0;
  border-bottom: 1px solid ${Colors.gray10};
`

export const Domain = styled.div`
  display: flex;
  align-items: center;
  ${Type.body30};
`

export const StyledCheck = styled(CircleCheckFilled)`
  margin-inline-end: 0.75rem;
`
