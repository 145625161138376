/**
 * https://github.com/All-Turtles/mmhmm-service/wiki/Mmhmm-Business#list-users
 *
 * Lists business members; returns:
 * {
 *   count, // number of all members
 *   filteredCount, // number of filtered members
 *   items: [], // array of users
 *   total, // total number of business members, regardless of count returned
 * }
 *
 * Calling without a payload returns all members in the business, but can also
 * paginate (via `offset`), filter, and sort:
 * GET /businesses/me/admin/members?limit=100&offset=0&filter=sam&order_by=email+asc
 *
 * Valid order_by fields are created, email, name and business_role,
 * optionally followed by asc or desc
 */

import { modelName, apiFetch, createAsyncThunk } from './business'

const getMembers = createAsyncThunk(
  `${modelName}/getMembers`,
  async (payload = {}) => {
    const { filter, limit, offset, orderBy, orderDirection } = payload
    const basePath = '/businesses/me/admin/members'

    const offsetQuery = offset ? `&offset=${offset}` : ''
    const filterQuery = filter ? `&filter=${encodeURIComponent(filter)}` : ''

    const orderProp = orderBy === 'role' ? 'business_role' : orderBy
    const orderQuery = orderBy
      ? `&order_by=${encodeURIComponent(orderProp)}+${orderDirection}`
      : ''

    const path = `${basePath}?limit=${
      limit || 15
    }${offsetQuery}${filterQuery}${orderQuery}`

    const options = {
      path,
      method: 'GET'
    }

    const response = await apiFetch(options)

    return response
  }
)

getMembers.storeKey = 'members'

export default getMembers
