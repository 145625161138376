import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { createLogger } from 'redux-logger'
import {
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'

import rootReducer from './rootReducer'

const isDev = process.env.NODE_ENV === 'development'
const isClient = typeof window !== 'undefined'
const withLogger =
  isClient && window.localStorage.getItem('__internal_withLogger') === 'true'

let middleware = [...getDefaultMiddleware()]

if (withLogger) {
  const logger = createLogger({ diff: true })
  middleware.push(logger)
}

let store

if (isClient) {
  const { persistReducer } = require('redux-persist')
  const storage = require('redux-persist/lib/storage').default

  const persistConfig = {
    key: 'root',
    whitelist: ['api', 'ui'],
    stateReconciler: hardSet,
    storage
  }

  middleware = [
    ...getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        ignoredActionPaths: ['error']
      }
    })
  ]

  if (withLogger) {
    const logger = createLogger({ diff: true })
    middleware.push(logger)
  }

  store = configureStore({
    reducer: persistReducer(persistConfig, rootReducer),
    middleware
  })

  store.persistor = persistStore(store)
} else {
  store = configureStore({
    reducer: rootReducer,
    middleware
  })
}

if (isDev && module.hot) {
  module.hot.accept('./rootReducer', () => {
    const newRootReducer = require('./rootReducer').default
    store.replaceReducer(newRootReducer)
  })
}

export const dispatch = store.dispatch

export default store
