import { useDispatch, useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'

import apiStatus from 'store/selectors/apiStatus'
import Display from './Display'
import { changePlan } from 'api/commerce'
import { useTranslation } from 'i18n'
import { HandleAction, Loading } from 'components'
import * as S from './styles'

const apiStatusSelector = (state) =>
  apiStatus({
    state,
    resource: 'commerce',
    api: 'changePlan'
  })

const ChangePlan = () => {
  const dispatch = useDispatch()
  const subscription = useSelector(
    (state) => state.api.commerce?.subscription,
    isEqual
  )
  const catalog = useSelector((state) => state.api.commerce?.catalog, isEqual)
  const status = useSelector(apiStatusSelector, isEqual)
  const { t } = useTranslation('account')

  const { nextPlanId, planId, nextChargeDate } = subscription ?? {}

  const selectedPlanId = nextPlanId || planId

  const initialValues = { planId: selectedPlanId }

  const handleSubmit = (values) => {
    if (nextPlanId) {
      if (values.planId === nextPlanId) {
        return
      }
    }

    if (!nextPlanId) {
      if (values.planId === planId) {
        return
      }
    }

    dispatch(changePlan(values))
  }

  if (!catalog) {
    // Still loading available plan IDs from API
    return (
      <S.ChangePlanComponent>
        <h1>{t('changePlanTitle')}</h1>
        <Loading visible />
      </S.ChangePlanComponent>
    )
  }

  return (
    <>
      <HandleAction
        resource="commerce"
        api="changePlan"
        toastDescription={t('changePlanSuccess')}
      />
      <Display
        initialValues={initialValues}
        nextPlanId={nextPlanId}
        nextChargeDate={nextChargeDate}
        onSubmit={handleSubmit}
        isLoading={status.loading}
        catalog={catalog}
      />
    </>
  )
}

export default ChangePlan
