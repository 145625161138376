import styled from 'styled-components'

import { Colors, Type } from 'styles'

export const BusinessReactivateComponent = styled.div`
  max-width: 33.75rem;
`

export const Text = styled.p`
  margin-bottom: 2.5rem;
  padding-bottom: 2.75rem;
  border-bottom: 1px solid ${Colors.gray10};
`

export const Date = styled.span`
  font-weight: ${Type.fontWeights.bold};
`
