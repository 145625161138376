import styled from 'styled-components'

import { Type } from 'styles'

export const Description = styled.p`
  ${Type.body30};
  margin-bottom: 1.1875rem;
`

export const ButtonWrapper = styled.div`
  text-align: end;
`

export const FailSpan = styled.span`
  ${Type.body30};
  margin-top: 0.5rem;
`
