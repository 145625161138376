import styled from 'styled-components'

import { Colors, Type } from 'styles'
import CardIcons from '../CardIcons/CardIcons'

export const YourOrderComponent = styled.div`
  display: flex;
  justify-content: center;
`

export const MaxWidth = styled.div`
  max-width: 540px;
  width: 100%;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 1rem;
`

export const Section = styled.div`
  border-top: 1px solid ${Colors.gray};
  margin-top: 1.25rem;
  padding-top: 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

export const PaymentSection = styled(Section)`
  align-items: center;
`

export const H2 = styled.h2`
  ${Type.header3};
`

export const SectionHeading = styled.h3`
  ${Type.caps};
  font-weight: ${Type.fontWeights.regular};
  margin-bottom: 0.9375rem;
`

export const SectionHeadingRight = styled(SectionHeading)`
  text-align: end;
  margin-bottom: 0.625rem;
`

export const PaymentDetails = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 44px;
`

export const CardInfo = styled.div`
  display: flex;
  align-items: center;
`

export const CardIcon = styled(CardIcons)`
  width: 2rem;
  height: 1.375rem;
  margin-inline-end: 0.875rem;
`

export const Small = styled.p`
  ${Type.body30};
  max-width: 23.875rem;
`

export const NoWrap = styled.span`
  white-space: nowrap;
`
