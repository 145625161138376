import { useDispatch } from 'react-redux'
import { useTranslation } from 'i18n'

import { changePassword } from 'api/auth'
import { ChangePasswordForm, PasswordRequirements } from 'components'
import * as S from './styles'
import { useState } from 'react'

const ChangePassword = () => {
  const { t } = useTranslation('account')
  const dispatch = useDispatch()
  const [newVal, setNewVal] = useState('')

  const handleSubmit = (values) => {
    dispatch(changePassword(values))
  }

  return (
    <S.ChangePasswordComponent>
      <S.StartCol>
        <S.H1>{t('changePassword')}</S.H1>
        <ChangePasswordForm
          onSubmit={handleSubmit}
          handleFieldChange={(e) => setNewVal(e.target.value)}
        />
      </S.StartCol>
      <S.EndCol>
        <PasswordRequirements newVal={newVal} />
      </S.EndCol>
    </S.ChangePasswordComponent>
  )
}

export default ChangePassword
