import { forwardRef } from 'react'
import { Link } from 'i18n'
import * as S from './styles'

const ButtonPrimitive = forwardRef(({ children, el, href, ...rest }, ref) => {
  if (el === 'a') {
    return (
      <S.A href={href} {...rest}>
        {children}
      </S.A>
    )
  }

  if (el === 'Link') {
    return (
      <Link href={href} passHref>
        <S.A {...rest}>{children}</S.A>
      </Link>
    )
  }

  return (
    <S.ButtonPrimitive {...rest} ref={ref}>
      {children}
    </S.ButtonPrimitive>
  )
})

export default ButtonPrimitive
