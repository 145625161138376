import styled from 'styled-components'

import { Colors } from 'styles'
import { Button } from '@all-turtles/mmhmm-ui-kit'

export const MemberActionsContentComponent = styled.div`
  .tippy-content {
    padding: 0;
  }
`

export const Li = styled.li`
  border-bottom: 1px solid ${Colors.gray10};

  &:last-child {
    border: 0;
  }
`

export const MenuItem = styled(Button)`
  padding: 1rem;
  width: 100%;
  text-align: start;
  justify-content: flex-start;

  &:hover {
    text-decoration: underline;
  }
`
