import { ONE_NUM, ONE_LOWER_AND_UPPER } from 'utilities/regex'

const passwordValidation = ({ t, value }) => {
  if (!value || value.length < 8) return t('common:passwordLengthError')
  if (!ONE_NUM.test(value)) return t('account:oneNumberError')
  if (!ONE_LOWER_AND_UPPER.test(value))
    return t('account:oneLowerAndUpperError')
}

const passwordAgainValidation = ({ t, value, values }) => {
  if (!value || value.length < 8) return t('common:passwordLengthError')
  if (value !== values.newPassword) return t('common:passwordAgainError')
}

const changePasswordFields = ({ t }) => ({
  currentPassword: {
    field: 'currentPassword',
    label: t('common:currentPassword'),
    placeholder: t('common:currentPasswordPlaceholder'),
    type: 'password',
    required: t('common:fieldRequired'),
    validateOnBlur: true,
    toggleVisibility: true
  },
  newPassword: {
    field: 'newPassword',
    label: t('common:newPassword'),
    placeholder: t('common:passwordPlaceholder'),
    type: 'password',
    required: true,
    validateOnBlur: true,
    toggleVisibility: true,
    validate: (value, values) => passwordValidation({ t, value, values }),
    autoComplete: 'new-password'
  },
  newPasswordAgain: {
    field: 'newPasswordAgain',
    label: t('common:newPasswordAgain'),
    placeholder: t('common:passwordAgainPlaceholder'),
    type: 'password',
    required: true,
    validateOnBlur: true,
    toggleVisibility: true,
    validate: (value, values) => passwordAgainValidation({ t, value, values }),
    autoComplete: 'new-password'
  }
})

export default changePasswordFields
