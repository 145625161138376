import { useSelector } from 'react-redux'

import formatDate from 'utilities/formatDate'
import { Link, Trans, useTranslation } from 'i18n'
import * as S from './styles'

const YourOrder = (props) => {
  const { t } = useTranslation(['order-complete', 'account', 'common'])
  const subscriptionInfo = useSelector(
    (state) => state.api.commerce?.subscription
  )

  if (!subscriptionInfo) return null

  const { paymentMethod, displayTotalPrice } = subscriptionInfo
  const { cardType, lastFour } = paymentMethod

  const renewalDate = formatDate(subscriptionInfo.nextChargeDate)

  return (
    <S.YourOrderComponent {...props}>
      <S.MaxWidth>
        <S.Header>
          <S.H2>{t('yourOrder')}</S.H2>
          <Link href="/" passHref>
            <a>{t('myAccount')}</a>
          </Link>
        </S.Header>
        <S.PaymentSection>
          <S.CardInfo>
            <S.CardIcon icon={cardType} />
            <p>{t('cardEnding', { lastFour })}</p>
          </S.CardInfo>
          <div>
            <S.SectionHeadingRight>{t('paid')}</S.SectionHeadingRight>
            <p>{displayTotalPrice}</p>
          </div>
        </S.PaymentSection>
        <S.Section>
          <div>
            <S.SectionHeading>{t('account:taxIdAddress')}</S.SectionHeading>
            <S.Small>{t('account:billingInfoDesc')}</S.Small>
          </div>
          <Link href="/billing" passHref>
            <a>{t('common:edit')}</a>
          </Link>
        </S.Section>
        <S.Section>
          <div>
            <S.SectionHeading>{t('subscription')}</S.SectionHeading>
            <S.Small>
              <Trans i18nKey="account:nextPayment">
                Next payment will occur automatically on
                <S.NoWrap>{{ renewalDate }}</S.NoWrap>.
              </Trans>
            </S.Small>
            <S.Small>{t('cancelAnytime')}</S.Small>
          </div>
          <Link href="/subscription" passHref>
            <a>{t('common:manage')}</a>
          </Link>
        </S.Section>
      </S.MaxWidth>
    </S.YourOrderComponent>
  )
}

export default YourOrder
