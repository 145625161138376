import { createAsyncThunk } from '@reduxjs/toolkit'

import apiFetch from '../apiFetch'

export const getProviders = createAsyncThunk('sso/providers', () =>
  apiFetch({
    path: '/public/idps',
    method: 'GET'
  })
)
getProviders.storeKey = 'providers'

export const unlinkProvider = createAsyncThunk('sso/unlinkProvider', () =>
  apiFetch({
    path: '/users/me/idp/unlink',
    method: 'POST'
  })
)
