import { modelName, apiFetch, createAsyncThunk } from './business'

const getBusiness = createAsyncThunk(
  `${modelName}/getBusiness`,
  async (body) => {
    const options = {
      path: '/businesses/me',
      method: 'GET',
      body
    }
    const response = await apiFetch(options)

    return response
  }
)

getBusiness.storeKey = 'business'

export default getBusiness
