import { useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'
import { RadioGroup, Relevant, useFormState } from 'informed'
import { Button } from '@all-turtles/mmhmm-ui-kit'

import { useTranslation, Trans } from 'i18n'
import apiStatus from 'store/selectors/apiStatus'
import { withInformed, Radio } from 'components'
import Select from 'components/Select/Select'
import businessAuthenticationFields from 'data/businessAuthenticationFields'
import { getSignInAppUrl } from 'utilities/getSignInAppUrl'

import * as S from './styles'

const apiStatusSelector = (state) =>
  apiStatus({
    state,
    resource: 'business',
    api: 'updateBusinessIdp'
  })

const BusinessAuthenticationForm = ({ isSSORequiredInitially }) => {
  const { t } = useTranslation(['business', 'common'])
  const status = useSelector(apiStatusSelector, isEqual)
  const providers = useSelector((state) => state.api.auth?.providers)
  const linkedIdp = useSelector((state) => state.api.user?.me?.linkedIdPID)
  const formState = useFormState()
  const { requireSSO, idps } = businessAuthenticationFields({
    t,
    idps: providers.idps
  })

  const isIdpSelected = !!formState.values[idps.field]
  const hasSelectedIdpLinked = formState.values[idps.field] === linkedIdp
  const canSave = formState.values[requireSSO.field]
    ? isIdpSelected && hasSelectedIdpLinked
    : true
  const provider = providers.idps?.find(
    (provider) => formState.values[idps.field] === provider.id
  )
  const linkProviderUrl = getSignInAppUrl({
    path: '/link-oauth',
    context: 'business/authentication',
    query: { provider: provider?.name }
  })

  return (
    <S.BusinessPreferencesFormComponent>
      <S.Section>
        <S.H2>{t('requireSSOSettings')}</S.H2>
        <S.P>{t('requireSSOInfo')}</S.P>
        <RadioGroup field={requireSSO.field} required>
          <Radio {...requireSSO.fields[0]} />
          <Relevant when={(formState) => formState.values?.requireSSO}>
            <Select
              {...idps}
              containerStyles={S.selectContainer}
              disabled={isSSORequiredInitially}
            />
            {isSSORequiredInitially && (
              <S.SmallInfo>{t('requireSSOChangeProviderInfo')}</S.SmallInfo>
            )}
            {isIdpSelected && !hasSelectedIdpLinked && (
              <S.P>
                <Trans t={t} i18nKey="requireSSOLinkAccount">
                  You don't have SSO enabled for this user account yet. After
                  continuing,
                  <a href={linkProviderUrl}>
                    sign in with {{ provider: provider?.readable_name }}
                  </a>
                  , and make sure that the email on the
                  {{ provider: provider?.readable_name }} account matches the
                  email on your mmhmm account.
                </Trans>
              </S.P>
            )}
          </Relevant>
          <Radio {...requireSSO.fields[1]} />
        </RadioGroup>
      </S.Section>
      <S.ButtonWrapper>
        <Button loading={status.loading} disabled={!canSave}>
          {t('common:save')}
        </Button>
      </S.ButtonWrapper>
    </S.BusinessPreferencesFormComponent>
  )
}

export default withInformed(BusinessAuthenticationForm)
