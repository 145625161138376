import { modelName, apiFetch, createAsyncThunk } from './business'

const inviteMembers = createAsyncThunk(
  `${modelName}/inviteMembers`,
  async (body) => {
    const options = {
      path: '/businesses/me/admin/invitations',
      method: 'POST',
      body
    }
    const response = await apiFetch(options)

    return response
  }
)

export default inviteMembers
