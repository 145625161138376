const viewData = {
  newLink: {
    bodyTextKey: 'business:createNewLinkBody',
    ctaTextKey: 'business:createNewLink'
  },
  resetAllLinks: {
    bodyTextKey: 'business:resetLinksBody',
    ctaTextKey: 'business:resetAllLinks'
  }
}

export default viewData
