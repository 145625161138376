import { modelName, apiFetch, createAsyncThunk } from './business'

const updateBusiness = createAsyncThunk(
  `${modelName}/updateBusiness`,
  async (body) => {
    const options = {
      path: '/businesses/me/admin',
      method: 'PATCH',
      body
    }
    const response = await apiFetch(options)

    return response
  }
)

updateBusiness.storeKey = 'businessSettings'

export default updateBusiness
