import { createAsyncThunk } from '@reduxjs/toolkit'

import apiFetch from '../apiFetch'

const checkBusinessEmail = createAsyncThunk(
  'user/checkBusinessEmail',
  async ({ email }) => {
    const body = { email: email.toLowerCase() }
    const options = {
      path: '/auth/businesses/check',
      method: 'POST',
      body,
      authorized: false
    }

    const response = await apiFetch(options)
    return response
  }
)

export default checkBusinessEmail
