import { modelName, apiFetch, createAsyncThunk } from './business'

const createBusiness = createAsyncThunk(
  `${modelName}/createBusiness`,
  async (body) => {
    const options = {
      path: '/businesses',
      method: 'POST',
      body
    }

    const response = await apiFetch(options)

    return response
  }
)

createBusiness.storeKey = 'business'

export default createBusiness
