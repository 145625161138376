import styled from 'styled-components'

import { Type } from 'styles'

export const ContentWrapper = styled.div`
  max-width: 34.5rem;
`

export const Header = styled.h2`
  ${Type.header3};
`

export const P = styled.p`
  ${Type.body30};
  margin: 0.5rem 0 3rem;
`
