import styled, { css } from 'styled-components'

import Form from '../Form/Form'

export const CancelSubscriptionFormComponent = styled(Form)``

export const selectContainer = css`
  margin-bottom: 2rem;
  width: 18.4375rem;
`
