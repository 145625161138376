import * as S from './styles'
import { useTranslation } from 'i18n'
import { useDispatch } from 'react-redux'
import { setDialogVisibility } from 'store/dialog/slice'

const DeleteBusinessInviteButton = ({ invite }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('business')

  const handleClick = () => {
    dispatch(
      setDialogVisibility({
        name: 'DeleteInviteDialog',
        email: invite.email,
        inviteId: invite.id
      })
    )
  }

  return (
    <S.DeleteBusinessInviteButtonComponent
      variant="link"
      onClick={handleClick}
      small
    >
      {t('remove')}
    </S.DeleteBusinessInviteButtonComponent>
  )
}

export default DeleteBusinessInviteButton
