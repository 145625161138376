import { useTranslation } from 'i18n'

import * as S from './styles'
import { Loading } from 'components'

const VerifyingLoading = () => {
  const { t } = useTranslation('edu')

  return (
    <S.LoadingWrapper>
      <Loading visible startMargin="0" color="white" />
      <S.LoadingCopy>{t('verifyingEligibility')}</S.LoadingCopy>
    </S.LoadingWrapper>
  )
}

export default VerifyingLoading
