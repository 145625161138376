import styled from 'styled-components'
import Back from '../Back/Back'

import { Breakpoints, Type } from 'styles'

export const ManageSubscriptionComponent = styled.div`
  display: flex;
  flex-direction: column;
`

export const Content = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: ${Breakpoints.desktop}) {
    flex-direction: row;
  }
`

export const StyledBack = styled(Back)`
  margin-inline-start: auto;
`

export const H2 = styled.h2`
  ${Type.header4};
  margin-bottom: 1.5rem;
`

export const Links = styled.div`
  a {
    &:hover {
      text-decoration: underline;
    }
  }

  & > * + * {
    margin-top: 1rem;
  }
`
