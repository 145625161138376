import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'pagination',
  initialState: {},
  reducers: {
    setPagination(state, action) {
      Object.keys(action.payload).forEach((key) => {
        state[key] = { ...state[key], ...action.payload[key] }
      })
    }
  }
})

export const { setPagination } = slice.actions

export default slice.reducer
