import styled from 'styled-components'
import { ButtonPrimitive } from 'components'

export const Button = styled(ButtonPrimitive)``

export const HamburgerX = styled.div`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  position: relative;
  transform: rotate(0deg);
`

export const Line = styled.span`
  display: block;
  width: 100%;
  position: absolute;
  background: #4235f2;
  height: ${(props) => props.strokeWidth}px;
  border-radius: ${(props) => props.borderRadius}px;
  transform-origin: center;
  transition-duration: 0.4s;
  transition-timing-function: ease;
`

export const FirstLine = styled(Line)`
  margin-top: -${(props) => props.strokeWidth}px;
  transform: ${(props) =>
    props.open
      ? `translate3d(0px, ${props.height / 2}px, 0px) rotate(45deg)`
      : 'translate3d(0px, 0px, 0px) rotate(0deg)'};
`

export const SecondLine = styled(Line)`
  transition-timing-function: ease-out;
  transition-duration: 0.4s;
  opacity: ${(props) => (props.open ? '0' : '1')};
  top: ${(props) => props.height / 2}px;
  margin-top: -${(props) => props.strokeWidth}px;
`

export const ThirdLine = styled(Line)`
  margin-top: -${(props) => props.strokeWidth}px;
  transform: ${(props) =>
    props.open
      ? `translate3d(0px, ${props.height / 2}px, 0px) rotate(-45deg)`
      : `translate3d(0px, ${props.height}px, 0px) rotate(0deg)`};
`
