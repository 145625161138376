import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'
import dayjs from 'dayjs'

import apiStatus from 'store/selectors/apiStatus'
import { setNotificationVisibility } from 'store/notification/slice'
import useSubscriptionStatus from 'hooks/useSubscriptionStatus'
import formatDate from 'utilities/formatDate'
import { useHidePurchaseForAppStore } from 'hooks/useHidePurchaseForAppStore'
import { useTranslation } from 'i18n'
import { EduEmailVerificationHandler } from 'components'
import Display from './Display'

const apiStatusSelector = (state) =>
  apiStatus({
    state,
    resource: 'commerce',
    api: 'getSubscription'
  })

const ManageAccount = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['common', 'account'])

  const status = useSelector(apiStatusSelector, isEqual)
  const currentUser = useSelector((state) => state.api.user?.me)
  const subscription = useSelector((state) => state.api.commerce?.subscription)
  const payments = useSelector((state) => state.api.commerce?.payments?.items)
  const premium = useSelector((state) => state.api.commerce?.premium)
  const hasIdps = useSelector(
    (state) => state.api.auth?.providers?.idps?.length > 0
  )
  const hidePurchaseForAppStore = useHidePurchaseForAppStore()

  const name = currentUser?.name
  const email = currentUser?.email
  const guest = currentUser?.guest
  const businessMember = currentUser?.business
  const hasLinkedIdp = !!currentUser?.linkedIdPID
  const profilePhotoInfo = currentUser?.profilePhotoInfo || {
    usesAvatar: true
  }
  const avatarUrl = profilePhotoInfo.urls?.thumbnail

  // TODO: Get rid of it, because with new Apple subscription we need to check user.premium
  // to get the info about the current user subscription status
  const { hasSubscription, active, cancelled, hasPayments, eduFreeYear } =
    useSubscriptionStatus(subscription, payments)

  const showLoadingPanel = !subscription && status.loading
  const trial = premium?.trial
  const trialActive = trial?.status === 'active' && trial?.type !== 'daily'

  const showUpgradeUpsell =
    !businessMember &&
    (!currentUser?.premium || trialActive) &&
    !hidePurchaseForAppStore

  useEffect(() => {
    if (trialActive) {
      const now = dayjs(trial.now)
      const end = dayjs(trial.end)
      const daysRemaining = dayjs(end).diff(now, 'days')

      if (daysRemaining <= 7) {
        const date = formatDate(trial.end)

        dispatch(
          setNotificationVisibility({
            type: 'warning',
            message: t('account:trialExpiresIn', {
              date,
              number: daysRemaining
            }),
            actionLink: '/upgrade',
            actionLinkCta: t('account:upgradeNow')
          })
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trialActive])

  return (
    <>
      <EduEmailVerificationHandler />
      <Display
        t={t}
        name={name}
        email={email}
        showLoadingPanel={showLoadingPanel}
        active={active}
        businessMember={businessMember}
        cancelled={cancelled}
        hasPayments={hasPayments}
        eduFreeYear={eduFreeYear}
        hasSubscription={hasSubscription}
        showUpgradeUpsell={showUpgradeUpsell}
        premium={premium}
        hasLinkedIdp={hasLinkedIdp}
        hasIdps={hasIdps}
        subscriptionService={currentUser?.subscriptionService}
        avatar={avatarUrl}
        guest={guest}
      />
    </>
  )
}

export default ManageAccount
