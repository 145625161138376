import { useSelector, useDispatch } from 'react-redux'
import isEqual from 'lodash/isEqual'

import { useTranslation } from 'i18n'
import { EditAccountForm, Loading } from 'components'
import { getEmailLocales } from 'api/localization'
import { User } from 'api'
import * as S from './styles'
import { EditAccountAvatar } from './EditAccountAvatar'
import { setDialogVisibility } from 'store/dialog/slice'

const EditAccount = () => {
  const { t } = useTranslation('account')
  const dispatch = useDispatch()
  const currentUser = useSelector((state) => state.api.user?.me, isEqual)
  const emailLocales = useSelector(
    (state) => state.api.localization?.emailLocales,
    isEqual
  )
  const dialogPresent = !!useSelector((state) => state.dialog?.name, isEqual)

  const handleSubmit = async (values) => {
    if (dialogPresent || !currentUser) {
      return
    }

    const payload = {}
    if (currentUser.locale !== values.locale) payload.locale = values.locale
    if (currentUser.name !== values.name) payload.name = values.name
    if (currentUser.email !== values.username) {
      payload.username = values.username
      payload.currentCredentials = {
        password: values.currentPassword,
        email: currentUser.email
      }
    }

    await dispatch(User.update(payload))
    dispatch(getEmailLocales())
  }

  const { name, email } = currentUser || {}
  const initialValues = {
    name,
    username: email,
    locale: emailLocales?.currentLanguage || 'en'
  }

  return (
    <S.EditAccountComponent>
      <S.H1>{t('editAccount')}</S.H1>
      <EditAccountAvatar />
      {!emailLocales ? (
        <Loading visible />
      ) : (
        <EditAccountForm
          onSubmit={handleSubmit}
          initialValues={initialValues}
          availableLocales={emailLocales?.availableLanguages}
        />
      )}

      {currentUser && !currentUser.business && (
        <>
          <S.Divider />
          <S.TextButton
            variant="destructive"
            onClick={() => {
              dispatch(
                setDialogVisibility({
                  name: 'AccountDeleteDialog'
                })
              )
            }}
          >
            {t('deleteAccount')}
          </S.TextButton>
        </>
      )}
    </S.EditAccountComponent>
  )
}

export default EditAccount
