const Exclamation = ({ className }) => {
  return (
    <svg
      width="5"
      height="17"
      viewBox="0 0 5 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.87726 0.801149C4.11947 1.11788 4.26852 1.50914 4.30578 1.91903C4.32441 2.03082 4.32441 2.14261 4.32441 2.27302V2.27303L3.50463 11.6446C3.46737 11.9613 3.31832 12.2408 3.09474 12.4271C2.8898 12.5948 2.61033 12.7066 2.33086 12.7066C2.01412 12.7066 1.71602 12.5762 1.51108 12.3712C1.32476 12.1849 1.19434 11.9241 1.17571 11.6446L0.355932 2.27303C0.355932 2.23643 0.353935 2.19784 0.351902 2.15855C0.347726 2.07784 0.3434 1.99422 0.355932 1.91903C0.393194 1.49051 0.579508 1.08062 0.821716 0.763886C1.19434 0.298102 1.73465 0 2.33086 0C2.94569 0 3.50463 0.316733 3.87726 0.801149ZM2.34867 16.7682C3.13119 16.7682 3.78329 16.1347 3.78329 15.3335C3.78329 14.5324 3.13119 13.8989 2.34867 13.8989C1.56616 13.8989 0.914057 14.5324 0.914057 15.3335C0.914057 16.1161 1.54752 16.7682 2.34867 16.7682Z"
        fill="white"
      />
    </svg>
  )
}

export default Exclamation
