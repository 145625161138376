import { Link, useTranslation } from 'i18n'
import * as S from './styles'

const paymentTypeInfo = {
  invoice: 'business:invoicing',
  reseller: 'business:reseller'
}

const PaymentMethodSection = ({ businessPermissions, subscription }) => {
  const { t } = useTranslation(['common', 'business'])

  if (!subscription) return null

  const { paymentMethod = {} } = subscription
  const { cardType, lastFour, type } = paymentMethod

  /**
   * If paymentMethod.type: 'invoice' or 'reseller',
   * user is invoiced externally and cannot view or update details online
   */
  const isBilledExternally = type === 'invoice' || type === 'reseller'

  const cardInfo = lastFour
    ? t('account:cardEnding', { lastFour })
    : t('account:noPaymentMethod')

  return (
    <S.Section>
      <S.Content>
        <S.Header>{t('business:paymentMethod')}</S.Header>
        <S.CardRow>
          {cardType && <S.CardIcon icon={cardType} />}
          <S.Body>
            {isBilledExternally ? t(paymentTypeInfo[type]) : cardInfo}
          </S.Body>
        </S.CardRow>
      </S.Content>
      {!isBilledExternally && businessPermissions.canModifyBilling && (
        <Link href="/business/payment-method" passHref>
          <S.ArrowLink>
            {t('edit')}
            <S.StyledArrow height="20" width="11" role="presentation" />
          </S.ArrowLink>
        </Link>
      )}
    </S.Section>
  )
}

export default PaymentMethodSection
