import { useDispatch, useSelector } from 'react-redux'
import { useFormApi } from 'informed'
import { useRouter } from 'next/router'
import isEqual from 'lodash/isEqual'
import { captureException } from 'utilities/sentry'

import { useTranslation } from 'i18n'
import apiStatus from 'store/selectors/apiStatus'
import { setDialogVisibility } from 'store/dialog/slice'
import getErrorMessage from 'utilities/getErrorMessage'
import { TextField, Select, HandleAction, withInformed } from 'components'
import { verifyEduFields } from 'data'
import * as S from './styles'

const apiStatusSelector = (state) =>
  apiStatus({
    state,
    resource: 'user',
    api: 'requestEduPremium'
  })

const VerifyEduForm = () => {
  const { t } = useTranslation(['edu', 'common'])
  const formApi = useFormApi()
  const router = useRouter()
  const dispatch = useDispatch()

  const status = useSelector(apiStatusSelector, isEqual)

  const error = useSelector((state) => state.api.user?.requestEduPremium?.error)

  const handleError = () => {
    const errorMsg = getErrorMessage(error?.code, t)

    if (errorMsg) {
      formApi.setError('email', errorMsg)
    } else {
      captureException(error)

      dispatch(
        setDialogVisibility({
          name: 'ErrorDialog',
          description: t('common:somethingWentWrong'),
          showSupportLink: true
        })
      )
    }
  }

  const handleSuccess = () => {
    router.push('/edu-verify')
  }

  const fields = verifyEduFields({ t })

  return (
    <>
      <HandleAction
        resource="user"
        api="requestEduPremium"
        onError={handleError}
        onSuccess={handleSuccess}
      />
      <TextField {...fields.email} containerStyles={S.emailStyles} />
      <TextField {...fields.website} containerStyles={S.websiteStyles} />
      <Select {...fields.role} containerStyles={S.roleStyles} />
      <S.SubmitButton isLoading={status.loading}>
        {t('common:submit')}
      </S.SubmitButton>
    </>
  )
}

export default withInformed(VerifyEduForm)
