import styled from 'styled-components'
import { Type } from 'styles'

export const NoWrap = styled.span`
  white-space: nowrap;
`

export const SubscriptionInfo = styled.p`
  ${Type.body30};
  margin-top: 1rem;
`
