import React, { useState } from 'react'
import { useTransition, animated } from 'react-spring/web.cjs'
import { DialogOverlay, DialogContent } from '@reach/dialog'

import * as S from './styles'
import { Sizes } from 'styles'
import { Hamburger } from 'components'
import MobileNavItems from './MobileNavItems'

const AnimatedDialogOverlay = animated(DialogOverlay)
const AnimatedDialogContent = animated(DialogContent)

function MobileNav() {
  const [showDialog, setShowDialog] = useState(false)
  const transitions = useTransition(showDialog, null, {
    from: { opacity: 0, y: 10 },
    enter: { opacity: 1, y: 0 },
    leave: { opacity: 0, y: 10 },
    config: { duration: 150 }
  })

  return (
    <S.MobileNavComponent aria-label="Mobile main menu">
      <Hamburger onClick={() => setShowDialog(!showDialog)} open={showDialog} />
      {transitions.map(
        ({ item, props: styles }) =>
          item && (
            <AnimatedDialogOverlay
              key={item}
              style={{
                opacity: styles.opacity,
                top: Sizes.navHeightMobile
              }}
              onDismiss={() => setShowDialog(false)}
            >
              <AnimatedDialogContent
                style={{
                  transform: styles.y.interpolate(
                    (value) => `translate3d(0px, ${value}px, 0px)`
                  ),
                  width: '100vw',
                  height: `calc(100vh - ${Sizes.navHeightMobile}`,
                  boxSizing: 'border-box',
                  margin: '0 auto'
                }}
                aria-labelledby="mobile-menu"
              >
                <S.Nav id="mobile-menu" aria-label="Mobile menu">
                  <MobileNavItems />
                </S.Nav>
              </AnimatedDialogContent>
            </AnimatedDialogOverlay>
          )
      )}
    </S.MobileNavComponent>
  )
}

export default MobileNav
