import { modelName, apiFetch, createAsyncThunk } from './business'

const updateBusinessIdp = createAsyncThunk(
  `${modelName}/updateBusinessIdp`,
  (body) => {
    return apiFetch({
      path: '/businesses/me/idp',
      method: 'PATCH',
      body
    })
  }
)

export default updateBusinessIdp
