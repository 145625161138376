import { bool } from 'prop-types'
import { useSelector } from 'react-redux'
import { useTranslation } from 'i18n'
import { isAppStoreSubscription } from 'utilities/subscription'

import { Panel } from 'components'
import * as S from './styles'

const PaymentPanel = () => {
  const { t } = useTranslation(['common', 'account'])
  const subscriptionInfo = useSelector(
    (state) => state.api.commerce?.subscription
  )
  const subscriptionService = useSelector(
    (state) => state.api.user?.me?.subscriptionService
  )
  const appStoreSubscription = isAppStoreSubscription(subscriptionService)

  if (!subscriptionInfo && !appStoreSubscription) return null

  const { cardType, lastFour } = subscriptionInfo?.paymentMethod ?? {}

  const cardInfo = appStoreSubscription
    ? t('account:appleBilling')
    : lastFour
    ? t('account:cardEnding', { lastFour })
    : t('account:noPaymentMethod')

  const ctas = appStoreSubscription
    ? [
        {
          id: 'manage',
          label: t('manage'),
          href: '/subscription',
          type: 'linkToButton'
        }
      ]
    : [
        {
          id: 'edit-payment',
          label: t('edit'),
          href: '/edit-payment',
          type: 'linkToButton'
        }
      ]

  return (
    <Panel heading={t('account:payment')} ctas={ctas}>
      <S.CardRow>
        {cardType && <S.CardIcon icon={cardType} />}
        <S.CardInfo>{cardInfo}</S.CardInfo>
      </S.CardRow>
    </Panel>
  )
}

PaymentPanel.propTypes = {
  canModify: bool,
  isBusiness: bool
}

PaymentPanel.defaultProps = {
  canModify: false,
  isBusiness: false
}

export default PaymentPanel
