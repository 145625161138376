import { captureMessage } from '@sentry/nextjs'
import PropTypes from 'prop-types'

import {
  Visa,
  Mastercard,
  Jcb,
  DinersClub,
  Amex,
  Discover
} from 'components/Icons'

const Cards = {
  visa: Visa,
  mastercard: Mastercard,
  jcs: Jcb,
  diners_club: DinersClub,
  american_express: Amex,
  discover: Discover
}

const CardIcons = ({ icon, ...rest }) => {
  const Card = Cards[icon]
  if (!Card || Card === undefined) {
    captureMessage(`No icon found for cardType: ${icon}`)
    return null
  }
  return <Card {...rest} />
}

CardIcons.propTypes = {
  /** The name of the card icon to display. */
  icon: PropTypes.oneOf(Object.keys(Cards)).isRequired
}

export default CardIcons
