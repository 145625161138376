import styled, { css } from 'styled-components'

import { Colors, Type } from 'styles'

export const UpdateMemberRoleFormComponent = styled.div``

export const roleStyles = css``

export const InfoAndSelect = styled.div`
  display: flex;
  border-bottom: 1px solid ${Colors.gray10};
  padding-bottom: 1.5rem;
  margin-bottom: 3.75rem;
`

export const MemberInfo = styled.div`
  padding-inline-end: 1rem;
`

export const Name = styled.p`
  font-size: 1.6875rem;
  margin-bottom: 10px;
  font-weight: ${Type.fontWeights.bold};
`

export const Email = styled.p`
  font-size: 1.125rem;
`
