import { Button } from '@all-turtles/mmhmm-ui-kit'
import { eventTypes, mixpanelService } from 'analytics'

import { useTranslation } from 'i18n'
import download from 'public/images/download.png'
import * as S from './styles'

const getTalkAppURL = () =>
  `${process.env.NEXT_PUBLIC_OOO_APP_LINK ?? 'https://ooo.mmhmm.app'}/talk`

const DownloadPanel = () => {
  const { t } = useTranslation('common')

  const handleLaunchClick = () => {
    mixpanelService.track(eventTypes.click, {
      element: 'Launch mmhmm link'
    })
  }

  return (
    <S.DownloadPanelComponent>
      <div>
        <S.Heading>{t('availableFor')}</S.Heading>
        <Button
          href={t('downloadUrl')}
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('download')}
        </Button>
        <S.A href={getTalkAppURL()} onClick={handleLaunchClick}>
          {t('launchMmhmm')}
        </S.A>
      </div>
      <S.Illustration src={download.src} role="presentation" />
    </S.DownloadPanelComponent>
  )
}

export default DownloadPanel
