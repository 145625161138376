import styled, { css } from 'styled-components'

import { Type, Colors } from 'styles'
import { Arrow } from 'components/Icons'
import { cellPadding } from '../styles'

export const Headers = styled.tr``

export const Header = styled.th`
  text-align: start;
  font-size: 0.8125rem;
  line-height: 1rem;
  line-height
  font-weight: ${Type.fontWeights.bold};
  color: ${Colors.grayIcon};

  &:first-of-type div {
    padding-inline-start: 0;
  }
`

const SelectArrowCleanProps = ({ inverted, ...rest }) => <Arrow {...rest} />

export const StyledSelectArrow = styled(SelectArrowCleanProps)`
  transition: all 300ms ease;
  transform: rotate(90deg);
  margin-inline-start: 0.625rem;
  opacity: 0;
  width: 0.375rem;
  height: 0.6875rem;

  ${(props) =>
    props.inverted &&
    css`
      transform: rotate(-90deg);
    `}

  ${(props) =>
    props.selected &&
    css`
      opacity: 1;
    `}
`

export const Unsortable = styled.div`
  padding: ${cellPadding};
`

export const Sortable = styled.div`
  cursor: pointer;
  padding: ${cellPadding};

  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 4px rgba(103, 89, 208, 0.5);
  }

  &:focus:not(.focus-visible) {
    outline: none;
    box-shadow: none;
  }

  &:hover {
    ${StyledSelectArrow} {
      opacity: 0.55;

      ${(props) =>
        props.selected &&
        css`
          opacity: 1;
        `}
    }
  }
`
