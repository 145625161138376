import * as S from './styles'
import { useTranslation } from 'i18n'

const DropzoneContent = ({ file, getInputProps, existingImageUrl }) => {
  const { t } = useTranslation('business')

  if (file?.preview) {
    if (file.type === 'video/mp4') {
      return (
        <>
          <input {...getInputProps()} />
          <S.PreviewVideo src={file.preview} muted controls />
        </>
      )
    }
    return (
      <>
        <input {...getInputProps()} />
        <S.PreviewImage src={file.preview} />
      </>
    )
  }

  if (existingImageUrl) {
    return (
      <>
        <input {...getInputProps()} />
        <S.PreviewImage src={existingImageUrl} />
      </>
    )
  }

  return (
    <>
      <input {...getInputProps()} />
      <S.P>{t('addImageOrVideo')}</S.P>
      <S.Info>{t('roomImageResolution')}</S.Info>
      <S.Info>{t('roomMaxFileSize')}</S.Info>
      <S.Info>{t('roomFileTypes')}</S.Info>
    </>
  )
}

export default DropzoneContent
