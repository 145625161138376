import { string } from 'prop-types'
import * as S from './styles'

const CardIconsDisplay = ({ currencyCode }) => {
  const globalCards = ['visa', 'mastercard', 'american_express']

  const usdCards = ['jcs', 'discover', 'diners_club']

  return (
    <div>
      {globalCards.map((card) => (
        <S.CardIcon icon={card} key={`${card}-icon`} />
      ))}
      {currencyCode === 'USD'
        ? usdCards.map((card) => (
            <S.CardIcon icon={card} key={`${card}-icon`} />
          ))
        : null}
    </div>
  )
}

CardIconsDisplay.propTypes = {
  currencyCode: string.isRequired
}

export default CardIconsDisplay
