import styled from 'styled-components'
import { Type } from 'styles'
import ButtonSecondary from '../ButtonSecondary/ButtonSecondary'

export const Wrap = styled.div`
  max-width: 300px;
`

export const Title = styled.h1`
  font-weight: ${Type.fontWeights.extraBold};
  font-size: 1.125rem;
  margin-bottom: 0.625rem;
`
export const Support = styled.p`
  margin-top: 0.5rem;
`

export const StyledButtonSecondary = styled(ButtonSecondary)`
  margin-top: 1.875rem;
`
