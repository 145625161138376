import { useDispatch, useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'
import { Button } from '@all-turtles/mmhmm-ui-kit'

import { useTranslation } from 'i18n'
import { deleteDomain, getAvailableDomains } from 'api/business'
import apiStatus from 'store/selectors/apiStatus'
import { setDialogVisibility } from 'store/dialog/slice'
import { Dialog, HandleAction } from 'components'
import * as S from './styles'

const apiStatusSelector = (state) =>
  apiStatus({
    state,
    resource: 'business',
    api: 'deleteDomain'
  })

const RemoveDomain = ({ visible, domain }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['business', 'account', 'common'])
  const status = useSelector(apiStatusSelector, isEqual)

  const handleDelete = async () => {
    await dispatch(deleteDomain({ domain }))
    dispatch(getAvailableDomains())
  }

  const handleCancel = () => {
    dispatch(setDialogVisibility({ name: null }))
  }

  return (
    <Dialog visible={visible} label={t('removeDomainDialogLabel')}>
      <HandleAction
        resource="business"
        api="deleteDomain"
        toastDescription={t('removeDomainSuccess')}
        closeAllDialogsOnSuccess
        stayOnPage
      />
      <S.DialogWrap>
        <S.H1>{t('account:areYouSure')}</S.H1>
        <p>{t('removeDomainConfirmationBody')}</p>
        <S.Domain>
          <bdi>{domain}</bdi>
        </S.Domain>
        <S.Buttons>
          <Button onClick={handleCancel} variant="link">
            {t('common:cancel')}
          </Button>
          <Button
            variant="danger"
            loading={status?.loading}
            onClick={handleDelete}
          >
            {t('removeDomain')}
          </Button>
        </S.Buttons>
      </S.DialogWrap>
    </Dialog>
  )
}

export default RemoveDomain
