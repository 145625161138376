import { Footer as UiKitFooter } from '@all-turtles/mmhmm-ui-kit'

import { useTranslation } from 'i18n'
import footerData from 'data/footer'
import { useRouter } from 'next/router'

const Footer = () => {
  const { t, i18n } = useTranslation(['footer', 'common'])
  const router = useRouter()

  const currentLanguage = i18n.language || 'en'
  const handleLanguageSelect = (locale) => {
    const { pathname, query, asPath } = router
    router.replace({ pathname, query }, asPath, { locale })
  }

  /**
   * The columns' links are transformed in the UI kit's Footer component to
   * prepend the urls passed in with the website url (mmhmm.app), but we need
   * to include the locale code in the middle, _except_ when it's English.
   * Pass the locale string to include with the urls to the footer data function.
   */
  const mmhmmLinkLocale = currentLanguage === 'en' ? '' : `/${currentLanguage}`
  const translatedFooterData = footerData({ t, mmhmmLinkLocale })

  return (
    <UiKitFooter
      {...translatedFooterData}
      onLanguageSelect={handleLanguageSelect}
      currentLanguage={currentLanguage}
    />
  )
}

export default Footer
