import { EMAIL } from 'utilities/regex'

const verifyEduFields = ({ t }) => ({
  email: {
    field: 'email',
    label: t('edu:schoolEmail'),
    placeholder: 'your.address@email.com',
    type: 'text',
    required: t('common:fieldRequired'),
    validateOnBlur: true,
    validate: (value) => {
      if (!EMAIL.test(value)) return t('account:invalidEmail')
    },
    /** Emails should not be reversed */
    dir: 'ltr'
  },
  website: {
    field: 'website',
    label: t('edu:orgWebsite'),
    placeholder: 'www.school.edu',
    type: 'text',
    errorMessage: t('common:fieldRequired'),
    validateOnBlur: true,
    /** Websites should not be reversed */
    dir: 'ltr'
  },
  role: {
    field: 'role',
    label: t('edu:role'),
    options: [
      {
        value: '',
        display: t('common:selectOption'),
        disabled: true
      },
      {
        value: 'Student',
        display: t('edu:student')
      },
      {
        value: 'Teacher',
        display: t('edu:teacher')
      },
      {
        value: 'Counselor',
        display: t('edu:counselor')
      },
      {
        value: 'Special Education Teacher',
        display: t('edu:specialEdTeacher')
      },
      {
        value: 'Psychologist',
        display: t('edu:psychologist')
      },
      {
        value: 'Program Director',
        display: t('edu:programDirector')
      },
      {
        value: 'Principal',
        display: t('edu:principal')
      },
      {
        value: 'Supervisor',
        display: t('edu:supervisor')
      },
      {
        value: 'Superintendent',
        display: t('edu:superintendent')
      },
      {
        value: 'Recruiter',
        display: t('edu:recruiter')
      },
      {
        value: 'Educational Assistant',
        display: t('edu:educationalAssistant')
      },
      {
        value: 'Teacher’s Aide',
        display: t('edu:teachersAide')
      },
      {
        value: 'Alumni',
        display: t('edu:alumni')
      },
      {
        value: 'Faculty',
        display: t('edu:faculty')
      },
      {
        value: 'Administrator',
        display: t('edu:administrator')
      },
      {
        value: 'Other',
        display: t('edu:other')
      }
    ]
  }
})

export default verifyEduFields
