import {
  withScope,
  captureException as sentryCaptureException
} from '@sentry/nextjs'
import { sanitizeURLParams, sanitizeValue } from 'utilities/sanitize'
export { flush as flushSentry } from '@sentry/nextjs'

export const sanitizeEvent = (event) => {
  if (event.request?.url) {
    event.request.url = sanitizeURLParams(event.request.url)
  }
  if (event.request?.headers?.Referer) {
    event.request.headers.Referer = sanitizeURLParams(
      event.request.headers.Referer
    )
  }
  return event
}

export const sanitizeBreadcrumb = (breadcrumb) => {
  if (breadcrumb.category !== 'navigation') return breadcrumb

  if (breadcrumb.data?.from) {
    breadcrumb.data.from = sanitizeURLParams(breadcrumb.data.from)
  }
  if (breadcrumb.data?.to) {
    breadcrumb.data.to = sanitizeURLParams(breadcrumb.data.to)
  }

  return breadcrumb
}

// TODO: make auth service & compare error by error instances instead of filtering ALL errors with
//       specific message or code
const isIgnoredAuthError = (error) => {
  const { message, code } = error ?? {}

  const ignoredMessages = [
    'Network error',
    'Invalid user',
    'No current user',
    'The user is not authenticated',
    'Refresh Token has expired',
    'Refresh Token has been revoked',
    'Signout timeout fail'
  ]

  const ignoredCodes = [
    'NotAuthorizedException',
    // Network error can be both in code & message in different errors...
    'Network error'
  ]

  return ignoredMessages.includes(message) || ignoredCodes.includes(code)
}

export const captureException = (error, configureScope) => {
  if (isIgnoredAuthError(error)) return
  withScope((scope) => {
    scope.setExtra('error', sanitizeValue(error))
    if (configureScope) configureScope(scope)

    sentryCaptureException(error)
  })
}
