import styled from 'styled-components'

import { Animations, Breakpoints, Inputs, Colors } from 'styles'

export const LoadingInputComponent = styled.div`
  width: 100%;
  animation: ${Animations.loadingKeyframes} 1.2s ease-in infinite;
  @media (min-width: ${Breakpoints.tablet}) {
    line-height: 3.125rem; /* 50px */
    height: 3.125rem; /* 50px */
  }
`

export const Label = styled.div`
  background-color: ${Colors.loading};
  width: 20%;
  height: 16px;
  margin-bottom: 16px;
  top: 0.1rem;
  position: relative;
`

export const Text = styled.div`
  background-color: ${Colors.loading};
  height: 1.625rem;
  margin-bottom: 1rem;
  top: 0.1rem;
  position: relative;
  width: 70%;
`

export const Bar = styled.div`
  ${Inputs.bar};
`
