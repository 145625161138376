/**
 * https://github.com/All-Turtles/mmhmm-service/wiki/Mmhmm-Business#list-invitations
 *
 * Lists business member invitations; returns:
 * {
 *   count, // number of all invitations
 *   filteredCount, // number of filtered invitations
 *   items: [], // array of invitations
 *   total, // total number of invitations, regardless of count returned
 * }
 *
 * Calling without a payload returns all members in the business, but can also
 * paginate (via `offset`), filter, and sort:
 * GET /businesses/me/admin/invitations?limit=10&offset=0&filter=sam&order_by=created+asc
 *
 * Valid order_by fields are `created` and `email`, optionally followed by asc or desc
 */

import { modelName, apiFetch, createAsyncThunk } from './business'

const getInvitations = createAsyncThunk(
  `${modelName}/getInvitations`,
  async (payload = {}) => {
    const { filter, limit, offset, orderBy, orderDirection } = payload
    const basePath = '/businesses/me/admin/invitations'

    const offsetQuery = offset ? `&offset=${offset}` : ''
    const filterQuery = filter ? `&filter=${encodeURIComponent(filter)}` : ''
    const orderQuery = orderBy
      ? `&order_by=${encodeURIComponent(orderBy)}+${orderDirection}`
      : ''

    const path = `${basePath}?limit=${
      limit || 20
    }${offsetQuery}${filterQuery}${orderQuery}`

    const options = {
      path,
      method: 'GET'
    }
    const response = await apiFetch(options)

    return response
  }
)

getInvitations.storeKey = 'invitations'

export default getInvitations
