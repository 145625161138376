import { oneOf, string } from 'prop-types'
import { useSelector } from 'react-redux'

import formatDate from 'utilities/formatDate'
import { Link, useTranslation } from 'i18n'
import { Exclamation } from 'components/Icons'
import * as S from './styles'

const PendingDeactivationBar = ({ warning, endDate }) => {
  const { t } = useTranslation(['business', 'common'])
  const role = useSelector((state) => state.api.user?.me?.businessRole)

  const date = endDate && formatDate(endDate)

  const data = {
    pendingCancellation: {
      admin: {
        message: t('pendingCancellationAdmin', { date })
      },
      owner: {
        message: t('pendingCancellationOwner', { date }),
        ctaText: t('resumeSubscription'),
        ctaLink: '/business/reactivate'
      }
    },
    paymentFailure: {
      admin: {
        message: t('paymentFailureAdmin')
      },
      owner: {
        message: t('paymentFailureOwner'),
        ctaText: t('updatePayment'),
        ctaLink: '/business/payment-method'
      }
    }
  }

  const notification = data[warning][role]

  return (
    <S.RedBanner>
      <S.Icon>
        <Exclamation />
      </S.Icon>
      <S.MessageWrapper>
        <S.Text>{notification.message}</S.Text>
      </S.MessageWrapper>
      {notification.ctaText && (
        <Link href={notification.ctaLink} passHref>
          <S.StyledLink>{notification.ctaText}</S.StyledLink>
        </Link>
      )}
    </S.RedBanner>
  )
}

PendingDeactivationBar.propTypes = {
  warning: oneOf(['pendingCancellation', 'paymentFailure']).isRequired,
  endDate: string
}

export default PendingDeactivationBar
