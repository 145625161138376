/**
 * Change plan (annual/monthly) - request body: { "planId" : "business-annual-usd" }
 *
 * Cancel subscription - request body: { "status" : "non_renewing" } --> will also include cancellation reason
 *
 * Re-activate non-renewing subscription - Reactivates a cancelled business subscription,
 * Only allowed before the billing period ends and the business is deactivated.
 * request body: { "status" : "active" }
 */
import { modelName, apiFetch, createAsyncThunk } from './business'

const updateSubscription = createAsyncThunk(
  `${modelName}/updateSubscription`,
  async (body) => {
    const options = {
      path: '/businesses/me/admin/subscription',
      method: 'PATCH',
      body
    }
    const response = await apiFetch(options)

    return response
  }
)

updateSubscription.storeKey = 'subscription'

export default updateSubscription
