import { useDispatch, useSelector } from 'react-redux'
import { captureException } from 'utilities/sentry'

import { useTranslation } from 'i18n'
import { getBusinessIdp } from 'api/business'
import { BusinessAuthenticationForm, HandleAction } from 'components'
import updateBusinessIdp from 'api/business/updateBusinessIdp'

const BusinessAuthentication = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('business')
  const businessIdp = useSelector((state) => state.api.business?.businessIdp)
  const idps = useSelector((state) => state.api.auth?.providers?.idps)

  if ((!businessIdp?.id && !businessIdp?.error) || !idps) return null

  const initialValues = {
    requireSSO: !businessIdp.error,
    ssoIdp: !businessIdp.error ? businessIdp.id : null
  }

  const handleSubmit = async ({ ssoIdp }) => {
    try {
      await dispatch(
        updateBusinessIdp({
          idpID: ssoIdp
        })
      )
      dispatch(getBusinessIdp())
    } catch (error) {
      // HandleAction will display error dialog for user, if encountered on updateBusiness
      captureException(error)
    }
  }

  return (
    <div>
      <HandleAction
        resource="business"
        api="updateBusinessIdp"
        toastDescription={t('settingsSaveSuccess')}
        stayOnPage
      />
      <BusinessAuthenticationForm
        onSubmit={handleSubmit}
        initialValues={initialValues}
        isSSORequiredInitially={initialValues.requireSSO}
      />
    </div>
  )
}

export default BusinessAuthentication
