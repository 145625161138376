import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { useTranslation } from 'i18n'
import { getPaymentHistory } from 'api/business'
import getFormattedHistory from 'utilities/getPaymentHistory'
import { MobilePaymentHistory, PaymentHistoryTable, Loading } from 'components'
import * as S from './styles'

const BusinessPaymentHistory = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('account')

  const payments = useSelector((state) => state.api.business?.paymentHistory)

  const businessPermissions = useSelector(
    (state) => state.api.user?.me?.businessPermissions
  )
  const canView = businessPermissions?.canViewBilling

  useEffect(() => {
    if (canView) {
      dispatch(getPaymentHistory())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!payments) return <Loading visible />

  const paymentHistory = getFormattedHistory({ items: payments.items })

  return canView ? (
    <S.Payments>
      {paymentHistory ? (
        <>
          <PaymentHistoryTable paymentHistory={paymentHistory} isBusiness />
          <MobilePaymentHistory data={paymentHistory} />
        </>
      ) : (
        <S.NoResults>{t('noPayments')}</S.NoResults>
      )}
    </S.Payments>
  ) : null
}

export default BusinessPaymentHistory
