import { useDispatch } from 'react-redux'

import * as S from './styles'
import { Dialog, ChangeMemberEmailForm, HandleAction } from 'components'
import { useTranslation } from 'i18n'
import { updateMemberEmail } from 'api/business'

const ChangeBusinessMemberEmailDialog = ({ visible, member }) => {
  const { t } = useTranslation('business')
  const dispatch = useDispatch()

  const handleSubmit = (values) => {
    dispatch(
      updateMemberEmail({
        email: values.username,
        userId: member.id
      })
    )
  }

  return (
    <Dialog visible={visible} label={t('changeEmail')}>
      <HandleAction
        resource="business"
        api="updateMemberEmail"
        stayOnPage
        closeAllDialogsOnSuccess
      />
      <S.Wrap>
        <S.Title>{t('changeEmail')}</S.Title>
        <ChangeMemberEmailForm
          onSubmit={handleSubmit}
          initialValues={{ username: member.email }}
        />
      </S.Wrap>
    </Dialog>
  )
}

export default ChangeBusinessMemberEmailDialog
