import styled from 'styled-components'

import { Colors, Type } from 'styles'

export const Input = styled.input`
  ${Type.body10};
  width: 100%;
  box-sizing: border-box;
  padding: 1rem;
  border: 1px solid ${Colors.black};
  font-family: ${Type.fontFamily};
`
