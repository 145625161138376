import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { Button } from '@all-turtles/mmhmm-ui-kit'
import isEqual from 'lodash/isEqual'

import { User } from 'api'
import { useTranslation, Link, Trans } from 'i18n'
import { setDialogVisibility } from 'store/dialog/slice'
import { setNotificationVisibility } from 'store/notification/slice'
import apiStatus from 'store/selectors/apiStatus'
import getErrorMessage from 'utilities/getErrorMessage'
import { HandleAction } from 'components'
import * as S from './styles'

const apiStatusSelector = (state) =>
  apiStatus({
    state,
    resource: 'user',
    api: 'redeemUserInviteToken'
  })

const BusinessJoin = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['business', 'common'])
  const router = useRouter()

  const status = useSelector(apiStatusSelector, isEqual)

  const businessInfo = useSelector((state) => state.ui?.businessInfo)
  const businessName = businessInfo?.business?.name
  const currentUser = useSelector((state) => state.api.user?.me)

  const termsUrl = t('businessTermsUrl')
  const privacyUrl = t('common:privacyUrl')

  let ignorePath = '/business/link-account'
  let joinPath = '/business/login'
  if (businessInfo?.inviteToken) {
    ignorePath = '/'
  } else if (businessInfo?.emailStatus === 'no-such-user') {
    ignorePath = '/business/purchase'
    joinPath = '/business/verify'
  }

  const handleShowDialog = () => {
    // Trigger dialog with steps to log out and change emails
    dispatch(setDialogVisibility({ name: 'ChangeEmailInfo' }))
  }

  const handleJoin = () => {
    dispatch(User.redeemUserInviteToken({ verifier: businessInfo.inviteToken }))
  }

  const handleInviteSuccess = () => {
    dispatch(
      setNotificationVisibility({
        type: 'success',
        message: t('business:joinSuccess', { businessName })
      })
    )

    router.push('/')
  }

  const handleError = (err) => {
    console.error({ err })
    const errMessage = getErrorMessage(err?.code, t)
    const options = {
      name: 'ErrorDialog',
      buttonText: t('close'),
      description: errMessage || t('common:somethingWentWrong'),
      showSupportLink: !errMessage
    }

    dispatch(setDialogVisibility(options))
  }

  return (
    <S.BusinessJoinComponent>
      <HandleAction
        resource="user"
        api="redeemUserInviteToken"
        onSuccess={handleInviteSuccess}
        onError={handleError}
      />
      <S.Title>
        <Trans i18nKey="joinAccountHeader" t={t} businessName={businessName}>
          You've been invited to join <bdi>{{ businessName }}</bdi>
        </Trans>
      </S.Title>
      <S.Email dir="ltr">{businessInfo?.email}</S.Email>
      <S.Description>
        <Trans i18nKey="joinAccountBody" t={t}>
          Welcome to powerful video communication for modern teams. If you join
          <bdi>{{ businessName }}</bdi>, your account will be subject to
          <a href={termsUrl} target="_blank" rel="noopener noreferrer">
            mmhmm for Teams terms
          </a>
          and
          <a href={privacyUrl} target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
          . Any presentations you create while using this account will belong to
          this account and can be seen by the account’s admin.
          <a
            href="https://help.mmhmm.app/hc/en-us/articles/1500005514382-What-happens-to-my-personal-mmhmm-account-if-I-become-a-member-of-an-mmhmm-Business-account-"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn more about mmhmm for Teams
          </a>
        </Trans>
      </S.Description>
      <S.Hr />
      <S.Buttons>
        <S.ButtonWrapper>
          <Link href={ignorePath}>
            <Button variant="secondary" as="a">
              {t('noThanks')}
            </Button>
          </Link>
        </S.ButtonWrapper>
        <S.ButtonWrapper>
          {currentUser?.id ? (
            <Button
              variant="primary"
              onClick={handleJoin}
              loading={status.loading}
            >
              <Trans i18nKey="join" t={t} businessName={businessName}>
                Join <bdi>{{ businessName }}</bdi>
              </Trans>
            </Button>
          ) : (
            <Link href={joinPath} passHref>
              <Button variant="primary" as="a">
                <Trans i18nKey="join" t={t} businessName={businessName}>
                  Join <bdi>{{ businessName }}</bdi>
                </Trans>
              </Button>
            </Link>
          )}
        </S.ButtonWrapper>
      </S.Buttons>
      <Button variant="link" onClick={handleShowDialog}>
        <Trans i18nKey="dontTransferData" t={t} businessName={businessName}>
          I don't want my data transferred to <bdi>{{ businessName }}</bdi>
        </Trans>
      </Button>
    </S.BusinessJoinComponent>
  )
}

export default BusinessJoin
