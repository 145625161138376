import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'toast',
  initialState: {
    name: null
  },
  reducers: {
    setToastVisibility(state, action) {
      Object.keys(action.payload).forEach((key) => {
        state[key] = action.payload[key]
      })
    }
  }
})

export const { setToastVisibility } = slice.actions

export default slice.reducer
