import styled from 'styled-components'

import { Breakpoints } from 'styles'

export const AvatarProfileImage = styled.img`
  width: ${(props) => (props.largeImage ? '15rem' : '3.125rem')};
  height: ${(props) => (props.largeImage ? '15rem' : '3.125rem')};
  border-radius: 50%;
  flex-shrink: 0;

  @media (min-width: ${Breakpoints.tablet}) {
    width: ${(props) => (props.largeImage ? '25rem' : '5rem')};
    height: ${(props) => (props.largeImage ? '25rem' : '5rem')};
  }
`
