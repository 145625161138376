import { useRouter } from 'next/router'
import { Button } from '@all-turtles/mmhmm-ui-kit'

import { useTranslation } from 'i18n'
import { getAuthFields } from 'data/authFields'
import { withInformed, TextField } from 'components'
import * as S from './styles'

const ForgotPasswordForm = ({ isLoading }) => {
  const router = useRouter()
  const { t } = useTranslation(['account', 'common'])

  const handleBack = () => router.back()

  return (
    <div>
      <TextField {...getAuthFields(t).email} containerStyles={S.inputStyles} />
      <S.SubmitWrap>
        <Button variant="link" type="button" onClick={handleBack}>
          {t('common:back')}
        </Button>
        <Button loading={isLoading}>{t('common:submit')}</Button>
      </S.SubmitWrap>
    </div>
  )
}

export default withInformed(ForgotPasswordForm)
