const getErrorMessage = (error, t) => {
  const errorMap = {
    EducationEmailAlreadyVerified: t('edu:educationEmailAlreadyVerified'),
    EducationEmailDifferentEmailVerified: t(
      'edu:educationEmailDifferentEmailVerified'
    ),
    EducationEmailAddressUnavailable: t('edu:educationEmailAddressUnavailable'),
    SubscriptionAlreadyActive: t('edu:userAlreadyPremium'),
    InvalidParameter: t('account:invalidEmail'),
    InvalidPasswordException: t('account:invalidPassword'),
    UsernameExistsException: t('account:aliasExistsException'),
    CodeMismatchException: t('business:invalidInviteToken'),
    NotAuthorizedException: t('account:invalidCredentials'),
    InvitationExpired: t('business:expiredToken'),
    AlreadyBusinessUser: t('business:alreadyBusinessUser'),
    DomainNotAllowed: t('domainNotAllowed'),
    DomainInUse: t('domainInUse'),
    EmailAddressNotVerified: t('emailNotVerified'),
    DomainNotVerified: t('domainNotVerified'),
    zipCode: t('checkout:zipError'),
    validationFailed: t('checkout:invalidCardDetails'),
    VerificationCodeMismatch: t('account:errorVerificationCodeMismatch'),
    VerificationCodeExpired: t('account:errorVerificationCodeExpired'),
    VerificationCodeErrorUnknown: t('account:errorVerificationCodeErrorUnknown')
  }

  return errorMap[error] || null
}

export default getErrorMessage
