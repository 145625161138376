import styled, { css } from 'styled-components'

import { Colors } from 'styles'

export const NoResults = styled.div`
  margin-top: 1.25rem;
  border-top: 1px solid ${Colors.gray10};

  ${(p) =>
    !p.minimal &&
    css`
      padding-top: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
    `}
`
