import styled, { css } from 'styled-components'

import { Animations, Colors } from 'styles'

export const LoadingRow = styled.tr`
  height: 3.3125rem;
  width: 100%;
`

export const LoadingCell = styled.td`
  border-top: 1px solid ${Colors.gray40};

  ${(props) =>
    props.header &&
    css`
      border-top: 0;
    `}
`

export const LoadingCellContent = styled.div`
  height: 1rem;
  flex: 1;
  width: 90%;
  border-radius: 4px;
  background-color: rgba(28, 36, 71, 0.07);
  animation: ${Animations.loadingKeyframes} 1.5s ease-in infinite;

  ${(props) =>
    props.header &&
    css`
      background-color: rgba(28, 36, 71, 0.18);
      width: 33%;
    `}

  &:last-child {
    margin-inline-end: 0;
  }
`
