import { useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'

import { useTranslation, Link } from 'i18n'
import { Checkout, CreateBusinessAccount, CreateBusiness } from 'components'
import * as S from './styles'

const BusinessCheckout = () => {
  const { t } = useTranslation(['business', 'checkout'])
  const catalog = useSelector((state) => state.api.business?.catalog, isEqual)
  const currentUser = useSelector((state) => state.api.user?.me)
  const businessInfo = useSelector((state) => state.ui?.businessInfo)
  const hasCurrentUser = currentUser?.id

  return (
    <Checkout
      header={t(
        hasCurrentUser
          ? 'business:checkoutHeader'
          : 'business:checkoutHeaderCreateUser'
      )}
      catalog={catalog}
      api={hasCurrentUser ? 'createBusiness' : 'createBusinessWithUser'}
      additionalSections={
        <>
          {!hasCurrentUser && (
            <S.CreateAccountWrapper>
              <CreateBusinessAccount email={businessInfo?.email} />
              <Link href="/business" passHref>
                <S.A>{t('business:signUpExisting')}</S.A>
              </Link>
            </S.CreateAccountWrapper>
          )}
          <CreateBusiness />
        </>
      }
      isBusiness
    />
  )
}

export default BusinessCheckout
