import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@all-turtles/mmhmm-ui-kit'

import { setDialogVisibility } from 'store/dialog/slice'
import { Trans, useTranslation } from 'i18n'
import { Dialog, BusinessAddDomainButton } from 'components'
import * as S from './styles'

const BusinessDomainActivateDialog = ({ visible, domain }) => {
  const { t } = useTranslation(['business', 'common'])
  const business = useSelector((state) => state.api.business)
  const businessName = business?.businessSettings?.name
  const header = t('activateDomainHeader', { domain: `${domain}` })

  const dispatch = useDispatch()
  const handleCancel = () => {
    dispatch(setDialogVisibility({ name: null }))
  }

  return (
    <Dialog visible={visible} label={header}>
      <S.DialogWrap>
        <S.H1>
          <Trans i18nKey="activateDomainHeader" t={t} domain={`${domain}`}>
            Activate <bdi>@{{ domain }}</bdi>?
          </Trans>
        </S.H1>
        <p>
          <Trans i18nKey="activateDomainBody" t={t} businessName={businessName}>
            Anyone with this email domain can automatically join
            <bdi>{{ businessName }}</bdi>. You can disable domains or suspend
            members from <bdi>{{ businessName }}</bdi> any time.
          </Trans>
        </p>
        <S.Buttons>
          <BusinessAddDomainButton domain={domain}>
            {t('activate')}
          </BusinessAddDomainButton>
          <Button onClick={handleCancel} variant="secondary">
            {t('common:cancel')}
          </Button>
        </S.Buttons>
      </S.DialogWrap>
    </Dialog>
  )
}

export default BusinessDomainActivateDialog
