import styled from 'styled-components'

import { Type, Colors } from 'styles'
import check from 'public/images/check.svg'

export const PasswordRequirementsComponent = styled.div`
  background-color: ${Colors.blue40};
  padding: 1.5rem 2rem;
`

export const Label = styled.h2`
  ${Type.caps};
  font-weight: ${Type.fontWeights.regular};
  color: ${Colors.midnight};
  margin-bottom: 1.0625rem;
`

export const ListItem = styled.li`
  ${Type.body30};
  color: ${Colors.midnight};
  margin-bottom: 1rem;
  padding-inline-start: 1rem;
  position: relative;

  /* Display bullet when requirement not met */
  &:before {
    content: '';
    visibility: ${({ isValid }) => (isValid ? 'hidden' : 'visible')};
    width: 0.375rem;
    height: 0.375rem;
    position: absolute;
    background: ${Colors.midnight};
    border-radius: 50%;
    left: 0;
    top: 0.594rem; /* half of li's line-height */
    transform: translateY(-0.125rem); /* about half of bullet */

    [dir='rtl'] & {
      left: unset;
      right: 0;
    }
  }

  /* Display check in place of bullet when req met */
  &:after {
    content: '';
    visibility: ${({ isValid }) => (isValid ? 'visible' : 'hidden')};
    width: 0.75rem;
    height: 0.75rem;
    position: absolute;
    background: ${`url(${check.src}) center/cover`};
    left: -0.1875rem;
    top: 0.594rem; /* half of li's line-height */
    transform: translateY(-0.3rem); /* about half of icon */

    [dir='rtl'] & {
      left: unset;
      right: -0.1875rem;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`
