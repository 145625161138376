import * as S from './styles'

const LoadingInput = (props) => {
  return (
    <S.LoadingInputComponent {...props}>
      <S.Label />
      <S.Text />
      <S.Bar />
    </S.LoadingInputComponent>
  )
}

export default LoadingInput
