import { useEffect, useState } from 'react'
import getChargebeeToken from './getChargebeeToken'

const site = process.env.NEXT_PUBLIC_CHARGEBEE_SITE
const publishableKey = process.env.NEXT_PUBLIC_CHARGEBEE_PUBLISHABLE_KEY

const withChargebee = (WrappedComponent) => {
  return (props) => {
    const [loaded, setLoaded] = useState()

    useEffect(() => {
      /**
       * Chargebee requires loading and initializing its script,
       * but it will stay loaded if the user navigates away and then back without refreshing
       */
      const chargebeeLoaded = document.getElementById('chargebee-js')
      if (chargebeeLoaded) {
        setLoaded(true)
      } else {
        const script = document.createElement('script')
        script.src = 'https://js.chargebee.com/v2/chargebee.js'
        script.id = 'chargebee-js'
        script.defer = true
        script.onload = () => onScriptLoad()
        document.head.appendChild(script)
      }
    }, [])

    const onScriptLoad = () => {
      window.Chargebee.init({
        site,
        publishableKey
      })

      setLoaded(true)
    }

    return (
      <WrappedComponent
        {...props}
        getChargebeeToken={getChargebeeToken}
        chargebeeLoaded={loaded}
      />
    )
  }
}

export default withChargebee
