import styled from 'styled-components'

import { Colors, Type } from 'styles'

export const CheckoutTotalComponent = styled.div``

export const LineItem = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.9375rem;
  margin-bottom: 0.5rem;
`

export const Title = styled.div``

export const Value = styled.div``

export const TotalWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Total = styled.p`
  margin-top: 0.125rem;
  ${Type.body30};
  font-weight: ${Type.fontWeights.bold};
`

export const TotalAmount = styled.div`
  font-size: 1.875rem;
`

export const Hr = styled.hr`
  margin-top: 2rem;
  border: 0;
  border-bottom: 1px solid ${Colors.gray40};
`

export const TotalAndTax = styled.div`
  display: flex;
  flex-direction: column;
`

export const PlusTax = styled.p`
  ${Type.caps};
  margin-top: 0.375rem;
  text-align: end;
  font-weight: ${Type.fontWeights.bold};
`
