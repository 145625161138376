import keys from 'lodash/keys'

const sanitizeValuePatterns = [
  /password/gi,
  /secret/gi,
  /passwd/gi,
  /api_key/gi,
  /apikey/gi,
  /access_token/gi,
  /auth/gi,
  /credentials/gi,
  /mysql_pwd/gi,
  /stripetoken/gi,
  /card/gi,
  /email/gi,
  /(\S+)@(\S+)/gi
]

const sanitizeQueryParams = ['email', 'token', 'verify', 'invite', 'join']

export const sanitizeString = (item) => {
  return sanitizeValuePatterns.reduce((acc, sanitizeValuePattern) => {
    return acc.replace(sanitizeValuePattern, '[Filtered]')
  }, item)
}

const isObject = (v) => v && typeof v === 'object'

export const sanitizeValue = (item, depth = 4) => {
  if (depth === 0) return item

  if (typeof item === 'string') {
    return sanitizeString(item)
  } else if (Array.isArray(item)) {
    return item.map((i) => sanitizeValue(i, depth - 1))
  } else if (isObject(item)) {
    return keys(item).reduce((acc, k) => {
      acc[k] = sanitizeValue(item[k], depth - 1)
      return acc
    }, {})
  }

  return item
}

export const sanitizeURLParams = (urlString) => {
  const [baseURL, search] = urlString.split('?')
  if (!search) return urlString

  const searchParams = new URLSearchParams(search)

  sanitizeQueryParams.forEach((queryKey) => {
    if (searchParams.has(queryKey)) searchParams.set(queryKey, '[Filtered]')
  })

  return `${baseURL}?${searchParams.toString()}`
}
