import styled from 'styled-components'

import { Colors } from 'styles'

export const Wrap = styled.div`
  max-width: 35rem;
`

export const Title = styled.h2`
  margin-bottom: 2.25rem;
`

export const P = styled.p`
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid ${Colors.gray10};
`
