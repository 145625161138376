import { useSelector } from 'react-redux'

import { CustomRoomsList, Loading } from 'components'
import CreateNewButton from './CreateNewButton'
import * as S from './styles'

const BusinessCustomRoomsPage = () => {
  const customRooms = useSelector((state) => state.api.business?.customRooms)
  const canAdmin = useSelector(
    (state) => state.api.user?.me?.businessPermissions?.canAdmin
  )

  if (!customRooms) return <Loading visible />

  return (
    <S.BusinessCustomRoomsPageComponent>
      <S.Header>{canAdmin ? <CreateNewButton /> : null}</S.Header>
      <CustomRoomsList customRooms={customRooms.items} canAdmin={canAdmin} />
    </S.BusinessCustomRoomsPageComponent>
  )
}

export default BusinessCustomRoomsPage
