import styled from 'styled-components'

export const SubscriptionNavComponent = styled.div`
  margin-inline-end: 3.875rem;
  flex-shrink: 0;
  max-width: 22.5rem;
`

export const Li = styled.li`
  margin-bottom: 1.5rem;

  &:last-child {
    margin: 0;
  }
`
