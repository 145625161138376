import styled from 'styled-components'
import { Breakpoints, Type } from 'styles'
import EveryDay from '../EveryDay/EveryDay'

export const TrialPanelComponent = styled.div`
  position: relative;
  box-sizing: border-box;
  padding: 1.75rem 1.75rem 0;
  background: linear-gradient(0deg, #00ffd2, #00ffd2), #4ce9c4;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (min-width: ${Breakpoints.small}) {
    flex-flow: row wrap;
  }

  @media (min-width: ${Breakpoints.tablet}) {
    width: 335px;
    flex-flow: column nowrap;
  }

  @media (min-width: ${Breakpoints.desktop}) {
    width: 400px;
    height: 429px;
  }
`

export const Expiration = styled.p`
  ${Type.body30};
  margin-bottom: 9px;
`

export const CopyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;

  @media (min-width: ${Breakpoints.small}) {
    padding-bottom: 28px;
    margin-inline-end: -30px;
  }

  @media (min-width: ${Breakpoints.tablet}) {
    padding-bottom: 0;
    margin-inline-end: 0;
  }
`

export const Title = styled.h4`
  margin-bottom: 40px;
`

export const Illustration = styled(EveryDay)`
  position: relative;
  height: auto;
  width: 125%;
  max-width: 332px;
  max-height: 174px;
  align-self: flex-end;
  margin-top: 34px;
  right: -40px;
  flex-grow: 1;

  @media (min-width: ${Breakpoints.tablet}) {
    width: 355px;
    left: -28px;
    right: initial;
    max-width: initial;
    max-height: initial;
    align-self: auto;
  }

  @media (min-width: ${Breakpoints.desktop}) {
    width: 440px;
    height: 214px;
    flex-shrink: 0;
  }

  [dir='rtl'] & {
    right: unset;
    left: -40px;

    @media (min-width: ${Breakpoints.tablet}) {
      left: unset;
      right: -28px;
    }
  }
`
