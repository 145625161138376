import styled from 'styled-components'

export const SubscriptionCancelledComponent = styled.div`
  max-width: 34rem;

  h1 {
    margin-bottom: 1rem;
  }
`

export const P = styled.p`
  margin-bottom: 1.2rem;
`
