import * as S from './styles'

const LoadingPanel = () => {
  return (
    <S.LoadingPanelComponent>
      <S.LoadingText />
      <S.LoadingStatusText />
      <S.LoadingTextLong />
      <S.LoadingTextShort />
    </S.LoadingPanelComponent>
  )
}

export default LoadingPanel
