import styled from 'styled-components'

import { Breakpoints, Type, Colors } from 'styles'

export const DownloadPanelComponent = styled.aside`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1.5rem 1.25rem;
  margin-bottom: 1rem;
  background-color: ${Colors.blue40};

  @media (min-width: ${Breakpoints.desktop}) {
    padding: 1.5rem;
  }
`

export const Heading = styled.h2`
  ${Type.body20};
  font-weight: ${Type.fontWeights.bold};
  margin-bottom: 1.25rem;
  color: ${Colors.midnight};
`

export const A = styled.a`
  ${Type.body30};
  display: block;
  margin-top: 1rem;
`

export const Illustration = styled.img`
  width: 6.5rem;

  @media (min-width: ${Breakpoints.small}) {
    width: 8.5rem;
  }

  @media (min-width: ${Breakpoints.desktop}) {
    width: 9.5rem;
  }

  [dir='rtl'] & {
    transform: scaleX(-1);
  }
`
