import { func } from 'prop-types'

import { useTranslation } from 'i18n'
import * as S from './styles'

const ResetInviteLinks = ({ handleClick }) => {
  const { t } = useTranslation('business')

  return (
    <S.StyledButton type="button" onClick={handleClick} variant="link">
      {t('resetInviteLinks')}
    </S.StyledButton>
  )
}

ResetInviteLinks.propTypes = {
  handleClick: func.isRequired
}

export default ResetInviteLinks
