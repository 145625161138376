import { createAsyncThunk } from '@reduxjs/toolkit'

import apiFetch from '../apiFetch'

const verifyEmailWithToken = createAsyncThunk(
  'auth/verifyEmailWithToken',
  async ({ verifier }) => {
    const body = { verifier }
    const options = {
      path: '/auth/users/me/email/verifier',
      method: 'POST',
      body,
      authorized: false
    }

    const response = await apiFetch(options)
    return response
  }
)

export default verifyEmailWithToken
