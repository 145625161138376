const businessFields = ({ t }) => ({
  taxID: {
    field: 'taxID',
    label: t('account:taxId'),
    placeholder: t('common:optional'),
    type: 'text',
    noError: true,
    /** Should not be reversed */
    dir: 'ltr'
  },
  billingAddress: {
    field: 'billingAddress',
    label: t('account:billingAddress'),
    placeholder: t('common:optional'),
    type: 'textarea',
    noError: true
  }
})

export default businessFields
