const businessMemberRoleFields = ({ t, currentUserRole }) => {
  const role = {
    field: 'role',
    label: t('business:role'),
    options: [
      {
        value: 'member',
        display: t('business:member')
      },
      {
        value: 'admin',
        display: t('business:admin')
      }
    ]
  }

  if (currentUserRole === 'owner') {
    role.options.push({ value: 'owner', display: t('business:owner') })
  }

  return { role }
}

export default businessMemberRoleFields
