import styled from 'styled-components'
import { Breakpoints, Type } from 'styles'

export const EducationComponent = styled.div`
  display: flex;
`

export const MainPanel = styled.div`
  max-width: 540px;

  @media (min-width: ${Breakpoints.tablet}) {
    flex-basis: 540px;
    flex-shrink: 0;
  }
`

export const Title = styled.h1`
  ${Type.capsMed};
  margin-bottom: 1rem;
`

export const Heading = styled.h2`
  ${Type.header2}
  margin-bottom: 1.25rem;

  @media (min-width: ${Breakpoints.tablet}) {
    font-size: 3.75rem;
    line-height: 4.688rem;
  }
`

export const Body = styled.p`
  margin-bottom: 3rem;
`
