import * as S from './styles'
import { RadioPill } from 'components'
import { planFields } from 'data'
import { RadioGroup, useFormState } from 'informed'
import { useTranslation } from 'i18n'

import { useRouter } from 'next/router'

const PlanSelector = ({
  catalog = { monthlyPlan: {}, annualPlan: {} },
  isBusiness
}) => {
  const { monthly, yearly } = planFields
  const { t } = useTranslation(isBusiness ? 'business' : 'checkout')
  const formState = useFormState()
  const router = useRouter()
  const value = formState.values.planId

  // TODO: https://github.com/All-Turtles/mmhmm-web-app/issues/371
  if (catalog.error) return null

  const monthlyAmount = catalog.monthlyPlan.displayPrice
  monthly.label = t(planFields.monthly.t, { amount: monthlyAmount })
  monthly.value = catalog.monthlyPlan.id

  const yearlyAmount = catalog.annualPlan.displayPrice
  yearly.label = t(planFields.yearly.t, { amount: yearlyAmount })
  yearly.value = catalog.annualPlan.id

  const qaTester = router.query?.secret === 'turtles'

  const declined = {
    label: 'Declined — 4000 0000 0000 0341',
    amount: 100,
    value: 'declined'
  }

  return (
    <S.PlanSelectorComponent>
      <RadioGroup field="planId" required>
        <RadioPill
          selected={value === yearly.value}
          {...yearly}
          containerStyles={S.StyledRadioPill}
        />
        <RadioPill selected={value === monthly.value} {...monthly} />
        {qaTester && (
          <RadioPill selected={value === declined.value} {...declined} />
        )}
      </RadioGroup>
    </S.PlanSelectorComponent>
  )
}

export default PlanSelector
