/**
 * Expected request body:
 * {
 *    "token" : "chargebeeToken",
 *    "firstName" : "Sam",
 *    "lastName" : "Malone",
 *    "zipCode" : "02492",
 *    "countryCode" : "us"
 * }
 */
import { modelName, apiFetch, createAsyncThunk } from './business'

const updatePaymentMethod = createAsyncThunk(
  `${modelName}/updatePaymentMethod`,
  async (body) => {
    const options = {
      path: '/businesses/me/admin/paymentMethod',
      method: 'POST',
      body
    }

    const response = await apiFetch(options)

    return response
  }
)

updatePaymentMethod.storeKey = 'paymentMethod'

export default updatePaymentMethod
