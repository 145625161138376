import { bool, func, shape, string } from 'prop-types'

import { useTranslation } from 'i18n'
import { businessFields } from 'data'
import { FormIllustrationContainer, TextField, withInformed } from 'components'
import businessLogin from 'public/images/businessLogin.png'
import * as S from './styles'

const BusinessEmailForm = ({ isJoining, loading }) => {
  const { t } = useTranslation(['account', 'common', 'business'])
  const { workEmail } = businessFields({ t })

  return (
    <FormIllustrationContainer
      illustration={businessLogin.src}
      backgroundColor="#FF2F59"
    >
      <>
        <S.FormHeader htmlFor={workEmail.field}>
          {t('business:enterWorkEmail')}
        </S.FormHeader>
        <TextField {...workEmail} minimalStyle />
        {!isJoining && <p>{t('business:workEmailInfo')}</p>}
        <S.BlockButton type="submit" loading={loading}>
          {t('common:continue')}
        </S.BlockButton>
      </>
    </FormIllustrationContainer>
  )
}

BusinessEmailForm.propTypes = {
  loading: bool.isRequired,
  isJoining: bool,

  /* Used by withInformed HOC */
  initialValues: shape({ workEmail: string }),
  onSubmit: func.isRequired
}

BusinessEmailForm.defaultProps = {
  isJoining: false
}

export default withInformed(BusinessEmailForm)
