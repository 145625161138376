const businessNavRoles = ['owner', 'admin']

const navItems = [
  {
    i18nKey: 'account',
    url: '/',
    name: 'Account'
  },
  {
    i18nKey: 'subscription',
    url: '/subscription',
    name: 'Subscription',
    shouldShow: ({ user, payments }) =>
      !user.business && (payments?.length || user.hasSubscription)
  },
  {
    i18nKey: 'business',
    dynamicDisplay: (user) => <bdi>{user.businessName}</bdi>,
    url: '/business/members',
    name: 'Business',
    selected: (pathname) => pathname.includes('business'),
    shouldShow: ({ user }) => businessNavRoles.indexOf(user.businessRole) > -1
  },
  {
    i18nKey: 'download',
    url: 'https://mmhmm.app/downloads',
    name: 'Download',
    target: '_blank',
    external: true
  }
]

export default navItems
