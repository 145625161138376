import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { setNotificationVisibility } from 'store/notification/slice'
import { Link } from 'i18n'
import * as S from './styles'

const icons = {
  success: <S.StyledCheck />,
  error: <S.StyledExclamation />,
  warning: <S.StyledExclamation />
}

const NotificationBar = () => {
  const notif = useSelector((state) => state.notification)
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(
      setNotificationVisibility({
        type: null,
        secondaryMessage: null,
        cta: null,
        showDismiss: false,
        actionLink: null,
        actionLinkCta: null
      })
    )
  }

  useEffect(() => {
    // Cleanup and remove notification when unmounting and navigating away
    return () => {
      handleClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!notif?.type) return null

  const {
    type,
    message,
    secondaryMessage,
    cta,
    showDismiss,
    actionLink,
    actionLinkCta
  } = notif

  const Icon = icons[notif.type] || null

  return (
    <S.NotificationComponent type={type}>
      <S.Icon type={type}>{Icon}</S.Icon>
      <S.MessageWrapper>
        <S.Text>
          {message}
          {actionLink && (
            <Link href={actionLink} passHref>
              <S.A>{actionLinkCta}</S.A>
            </Link>
          )}
        </S.Text>
        {secondaryMessage && (
          <S.SecondaryMessage>{secondaryMessage}</S.SecondaryMessage>
        )}
      </S.MessageWrapper>
      <S.CtaWrapper>
        {showDismiss ? (
          <S.StyledButton onClick={handleClose} variant="link">
            <S.StyledX />
          </S.StyledButton>
        ) : (
          cta
        )}
      </S.CtaWrapper>
    </S.NotificationComponent>
  )
}

export default NotificationBar
