import { useDispatch, useSelector } from 'react-redux'

import { useTranslation } from 'i18n'
import { setDialogVisibility } from 'store/dialog/slice'
import { PendingDeactivationBar } from 'components'
import BusinessMembersContent from './BusinessMembersContent'
import * as S from './styles'

const BusinessMembers = () => {
  const { t } = useTranslation(['business', 'common'])
  const dispatch = useDispatch()

  const businessPermissions = useSelector(
    (state) => state.api.user?.me?.businessPermissions
  )
  const business = useSelector((state) => state.api.business)

  const approvedDomains = business?.approvedDomains
  const availableDomains = business?.availableDomains

  // Show spinner until all page sections are loaded
  const isLoading = !business || !approvedDomains || !availableDomains

  const handleInvite = () => {
    dispatch(setDialogVisibility({ name: 'InviteMembers' }))
  }

  const pendingCancellation = business?.subscription?.status === 'non_renewing'
  const paymentFailure = business?.subscription?.paymentFailed

  return (
    <S.BusinessMembersComponent>
      {businessPermissions?.canAdmin &&
        (pendingCancellation || paymentFailure) && (
          <PendingDeactivationBar
            warning={
              pendingCancellation ? 'pendingCancellation' : 'paymentFailure'
            }
            endDate={business?.subscription?.endDate}
          />
        )}
      <S.Header>
        <S.Title>{t('memberAccess')}</S.Title>
        {businessPermissions?.canInviteMembers && (
          <S.StyledButton variant="secondary" onClick={handleInvite}>
            {t('inviteMembers')}
          </S.StyledButton>
        )}
      </S.Header>

      {isLoading ? (
        <S.StyledLoading visible />
      ) : (
        <BusinessMembersContent
          approvedDomains={approvedDomains}
          availableDomains={availableDomains}
          business={business}
          businessPermissions={businessPermissions}
        />
      )}
    </S.BusinessMembersComponent>
  )
}

export default BusinessMembers
