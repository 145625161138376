/**
 * Role-based permission options for user.businessPermissions:
 * api.user.me.businessPermissions: {
 *  canAdmin: true
 *  canInviteMembers: true
 *  canModifyBilling: true
 *  canModifyBusinessStatus: true
 *  canModifyDomains: true
 *  canModifyMembers: true
 *  canViewBilling: true
 *  canViewBusinessStatus: true
 */

const navItems = [
  {
    url: '/business/members',
    i18nKey: 'business:memberAccess',
    shouldHide: ({ status, businessPermissions }) => {
      if (!businessPermissions?.canAdmin) return true
      const { businessStatus, subscriptionStatus } = status

      const bizIsntActive = !businessStatus || businessStatus === 'deactivated'
      const subIsntActive =
        !subscriptionStatus || subscriptionStatus !== 'active'
      return bizIsntActive && subIsntActive
    }
  },
  {
    url: '/business/custom-rooms',
    i18nKey: 'business:customRoomsTitle',
    shouldHide: ({ businessPermissions }) => {
      return !businessPermissions?.canAdmin
    }
  },
  {
    url: '/business/preferences',
    i18nKey: 'business:businessPreferencesTitle',
    shouldHide: ({ businessPermissions }) => {
      return !businessPermissions?.canAdmin
    }
  },
  {
    url: '/business/authentication',
    i18nKey: 'business:businessAuthenticationTitle',
    shouldHide: ({ businessPermissions, hasIdps }) => {
      return !businessPermissions?.canAdmin || !hasIdps
    }
  },
  {
    url: '/business/billing',
    i18nKey: 'business:billing',
    shouldHide: ({ businessPermissions }) => {
      return !businessPermissions?.canViewBilling
    }
  }
]

export default navItems
