import styled from 'styled-components'

import { Breakpoints, Type } from 'styles'
import { TextInput } from '../TextField/styles'

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  ${TextInput} {
    ${Type.body20}
    padding: 0 0.75rem;
    height: 1.5rem;
    min-width: 300px;

    @media (min-width: ${Breakpoints.tablet}) {
      line-height: 1.875rem;
      height: 3rem;
    }
  }
`
