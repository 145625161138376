import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { useTranslation } from 'i18n'
import { Button } from '@all-turtles/mmhmm-ui-kit'

import { getProviders } from 'api/auth/sso'
import { getBusinessIdp } from 'api/business'
import { setDialogVisibility } from 'store/dialog/slice'
import { getSignInAppUrl } from 'utilities/getSignInAppUrl'
import Google from 'components/Icons/Google'
import Apple from 'components/Icons/Apple'
import Loading from 'components/Loading/Loading'

import * as S from './styles'

const providers = {
  Google: {
    icon: Google,
    signInUrl: getSignInAppUrl({
      path: '/link-oauth',
      context: 'connected-apps',
      query: { provider: 'Google' }
    })
  },
  SignInWithApple: {
    icon: Apple,
    signInUrl: getSignInAppUrl({
      path: '/link-oauth',
      context: 'connected-apps',
      query: { provider: 'SignInWithApple' }
    })
  }
}

const supportedApps = Object.keys(providers)

const ConnectedAppItem = ({ id, name, readableName }) => {
  const { t } = useTranslation('account')
  const dispatch = useDispatch()
  const Icon = providers[name].icon

  const handleClick = () => {
    dispatch(
      setDialogVisibility({
        name: 'UnlinkAppDialog',
        unlinkProviderId: id
      })
    )
  }

  return (
    <S.AppItem>
      <S.AppItemName>
        <Icon width={18} height={18} /> {readableName}
      </S.AppItemName>
      <S.DisconnectButton onClick={handleClick}>
        {t('disconnect')}
      </S.DisconnectButton>
    </S.AppItem>
  )
}

const AvailableAppItem = ({ name, readableName, disabled }) => {
  const { t } = useTranslation('account')
  const dispatch = useDispatch()
  const Icon = providers[name].icon

  const handleClick = () => {
    dispatch(
      setDialogVisibility({
        name: 'LinkAppDialog',
        provider: name,
        providerName: readableName
      })
    )
  }

  return (
    <S.AppItem>
      <S.AppItemName>
        <Icon width={18} height={18} /> {readableName}
      </S.AppItemName>
      <Button variant="secondary" onClick={handleClick} disabled={disabled}>
        {t('connect')}
      </Button>
    </S.AppItem>
  )
}

const ConnectedApps = () => {
  const { t } = useTranslation('account')
  const router = useRouter()
  const dispatch = useDispatch()
  const providers = useSelector((state) => state.api.auth?.providers)
  const linkedIdp = useSelector((state) => state.api.user?.me?.linkedIdPID)
  const businessRequiredIdpId = useSelector((state) =>
    !state.api.business?.businessIdp?.error
      ? state.api.business?.businessIdp?.id
      : null
  )

  React.useEffect(() => {
    dispatch(getProviders())
    dispatch(getBusinessIdp())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (providers?.idps === null) {
      router.push('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providers])

  const connectedApps = providers?.idps?.filter(
    (provider) => provider.id === linkedIdp
  )
  const availableApps = providers?.idps?.filter(
    (provider) =>
      provider.id !== linkedIdp && supportedApps.includes(provider.name)
  )

  if (!providers?.idps) {
    return (
      <S.ConnectedAppsComponent>
        <S.H1>{t('connectedApps')}</S.H1>
        <S.Section>
          <S.H2>{t('singleSignOn')}</S.H2>
          <Loading visible />
        </S.Section>
      </S.ConnectedAppsComponent>
    )
  }

  return (
    <S.ConnectedAppsComponent>
      <S.H1>{t('connectedApps')}</S.H1>
      <S.Section>
        <S.H2>{t('singleSignOn')}</S.H2>
        <S.AppsSection>
          <S.AppsSectionTitle>
            {t('connectedAppsSectionTitle', { count: connectedApps.length })}
          </S.AppsSectionTitle>
          {connectedApps.map((provider) => (
            <ConnectedAppItem
              key={provider.id}
              id={provider.id}
              name={provider.name}
              readableName={provider.readable_name}
            />
          ))}
        </S.AppsSection>
        <S.AppsSection>
          <S.AppsSectionTitle>
            {t('availableAppsSectionTitle', { count: availableApps.length })}
          </S.AppsSectionTitle>
          {availableApps.map((provider) => (
            <AvailableAppItem
              key={provider.id}
              id={provider.id}
              name={provider.name}
              readableName={provider.readable_name}
              disabled={
                businessRequiredIdpId && businessRequiredIdpId !== provider.id
              }
            />
          ))}
        </S.AppsSection>
      </S.Section>
    </S.ConnectedAppsComponent>
  )
}

export default ConnectedApps
