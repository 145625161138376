const DinersClub = (props) => {
  return (
    <svg
      width="128"
      height="84"
      viewBox="0 0 128 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Diners Club card"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 6C0 2.68629 2.68629 0 6 0H122C125.314 0 128 2.68629 128 6V78C128 81.3137 125.314 84 122 84H6C2.68629 84 0 81.3137 0 78V6Z"
        fill="#F4F6F7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 6C0 2.68629 2.68629 0 6 0H122C125.314 0 128 2.68629 128 6V78C128 81.3137 125.314 84 122 84H6C2.68629 84 0 81.3137 0 78V6Z"
        fill="#E5E5E5"
        fillOpacity="0.01"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M69.2535 61.9208C80.0659 61.973 89.9349 53.0225 89.9349 42.1339C89.9349 30.2265 80.0659 21.996 69.2535 22H59.9482C49.0063 21.996 40 30.229 40 42.1339C40 53.0249 49.0063 61.9731 59.9482 61.9208H69.2535Z"
        fill="url(#paint0_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M59.9876 23.6636C49.9892 23.6667 41.8871 31.845 41.8848 41.9385C41.8871 52.0303 49.9891 60.2077 59.9876 60.2109C69.9884 60.2077 78.0921 52.0303 78.0935 41.9385C78.092 31.845 69.9884 23.6667 59.9876 23.6636ZM48.5127 41.9385C48.5221 37.0062 51.5747 32.8003 55.8793 31.1289V52.7456C51.5747 51.0751 48.522 46.8715 48.5127 41.9385ZM64.0943 52.7503V31.1282C68.4005 32.7955 71.4578 37.0039 71.4656 41.9385C71.4578 46.8747 68.4005 51.0798 64.0943 52.7503Z"
        fill="#F4F6F7"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="100.297"
          y1="54.281"
          x2="87.491"
          y2="12.4302"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#009BE0" />
          <stop offset="1" stopColor="#006BA8" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default DinersClub
