import { useDispatch, useSelector } from 'react-redux'

import { setDialogVisibility } from 'store/dialog/slice'
import { useTranslation } from 'i18n'
import { BusinessMembersTable, Pagination } from 'components'
import * as S from './styles'

const itemLimit = 15

const BusinessAllMembers = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('business')

  const businessPermissions = useSelector(
    (state) => state.api.user?.me?.businessPermissions
  )
  const business = useSelector((state) => state.api.business)
  const memberCount = business?.members?.filteredCount || 0

  const handleInvite = () => {
    dispatch(setDialogVisibility({ name: 'InviteMembers' }))
  }

  const pageCount = Math.ceil(memberCount / itemLimit)

  return (
    <S.BusinessAllMembersComponent>
      <S.Header>
        <S.Title>{t('membersCount', { count: memberCount })}</S.Title>
        {businessPermissions?.canInviteMembers && (
          <S.StyledButton variant="secondary" onClick={handleInvite}>
            {t('inviteMembers')}
          </S.StyledButton>
        )}
      </S.Header>
      <BusinessMembersTable limit={itemLimit} withSearch />
      <Pagination pageCount={pageCount} name="getMembers" />
    </S.BusinessAllMembersComponent>
  )
}

export default BusinessAllMembers
