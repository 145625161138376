import defaultAvatar from 'public/images/defaultAvatar.png'
import * as S from './styles'

const AvatarProfileThumbnail = ({ avatarUrl = defaultAvatar.src }) => {
  const onError = ({ currentTarget }) => {
    currentTarget.onError = null
    currentTarget.src = defaultAvatar.src
  }

  return <S.AvatarProfileThumbnail src={avatarUrl} alt="" onError={onError} />
}

export default AvatarProfileThumbnail
