import { useEffect } from 'react'
import { withRouter } from 'next/router'
import { connect } from 'react-redux'

import Verify from 'pages/business/verify'
import { dispatch } from 'store'
import { getMe } from 'api/user'
import * as Auth from 'utilities/auth'
import { Layout, Loading } from 'components'
import { mixpanelService } from 'analytics'
import { getSignInAppUrl } from 'utilities/getSignInAppUrl'

const mapStateToProps = (state) => {
  const user = state.api?.user?.me
  // TODO: Make it better useRef + loading?
  const isAuthenticated = user?.id

  // If business user, check for email verification state
  const isBizEmailVerified = !user?.business || user?.emailVerified

  return { isAuthenticated, isBizEmailVerified }
}

const checkHandlingVerification = (router) =>
  !router.isReady || router.query.verify || router.query.invite

const withAuth = (WrappedComponent) => {
  const Wrapper = (props) => {
    const { router, isAuthenticated, isBizEmailVerified } = props
    const isHandlingVerification = checkHandlingVerification(router)

    useEffect(() => {
      const handle = async () => {
        if (!isHandlingVerification) {
          try {
            await Auth.currentSession()
            const getMeResult = await dispatch(getMe())
            if (getMeResult.error) throw getMeResult.error
            mixpanelService.identify(getMeResult?.payload?.id)
          } catch (error) {
            Auth.captureAuthError(error)

            window.location.href = getSignInAppUrl({
              path: '/sign-in',
              context: router.pathname.slice(1)
            })
          }
        }
      }

      handle()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isHandlingVerification])

    if (!router.isReady || (!isAuthenticated && !isHandlingVerification)) {
      return (
        <Layout>
          <Loading visible />
        </Layout>
      )
    }

    /**
     * Display check/verify email page if business user isn't
     * emailVerified, unless invite or verify token is present,
     * in which case continue so we can run redeem token
     */
    if (!isBizEmailVerified && !isHandlingVerification) return <Verify />

    return <WrappedComponent {...props} />
  }

  return withRouter(connect(mapStateToProps)(Wrapper))
}

export default withAuth
