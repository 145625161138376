import { createAsyncThunk } from '@reduxjs/toolkit'

import apiFetch from '../apiFetch'

const verifyEduEmail = createAsyncThunk(
  'user/verifyEduEmail',
  async ({ verifier }) => {
    const options = {
      path: '/users/me/email/education/verifier',
      method: 'POST',
      body: { verifier }
    }
    const response = await apiFetch(options)

    return response
  }
)

export default verifyEduEmail
