/**
 * Deactivates or reactivates a member/user
 *
 * status - one of 'active', 'inactive-business-subscription-canceled' or 'inactive' (deprecated: 'deactivated')
 *
 * https://github.com/All-Turtles/mmhmm-service/wiki/Mmhmm-Business#deactivate-or-reactivate-user
 */
import { modelName, apiFetch, createAsyncThunk } from './business'

const updateMemberStatus = createAsyncThunk(
  `${modelName}/updateMemberStatus`,
  async ({ userId, status }) => {
    const options = {
      path: `/businesses/me/admin/members/${userId}`,
      method: 'PATCH',
      body: { status }
    }
    const response = await apiFetch(options)

    return response
  }
)

updateMemberStatus.fulfilledReducer = (state, action) => {
  const members = JSON.parse(JSON.stringify(state.business.members?.items))
  const member = members.find((el) => el.id === action.meta.arg.userId)

  const index = members.indexOf(member)
  member.status = action.meta.arg.status

  members[index] = member

  state.business.members.items = members
}

export default updateMemberStatus
