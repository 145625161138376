import { useTranslation } from 'i18n'

import * as S from './styles'

const VerifyingLoading = ({ onClick, title, body }) => {
  const { t } = useTranslation(['edu', 'common'])

  return (
    <S.SuccessWrapper>
      <h1>{title}</h1>
      <S.Body>{body}</S.Body>
      <S.StyledButton onClick={onClick}>{t('common:ok')}</S.StyledButton>
    </S.SuccessWrapper>
  )
}

export default VerifyingLoading
