import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useDispatch } from 'react-redux'
import { captureException } from 'utilities/sentry'
import { useTranslation } from 'i18n'

import { setDialogVisibility } from 'store/dialog/slice'
import { HandleAction } from 'components'
import { verifyEduEmail } from 'api/user'
import { getSubscription, getPremium } from 'api/commerce'
import getErrorMessage from 'utilities/getErrorMessage'

const EduEmailVerificationHandler = () => {
  const router = useRouter()
  const dispatch = useDispatch()
  const token = router.query?.edu
  const { t } = useTranslation('common')

  useEffect(() => {
    if (token) {
      dispatch(setDialogVisibility({ name: 'VerifyingEduDialog' }))
      dispatch(verifyEduEmail({ verifier: token }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSuccess = () => {
    dispatch(getPremium())
    dispatch(getSubscription())
    router.replace('/')
  }

  const onError = (error) => {
    const errorMsg = getErrorMessage(error?.code, t)

    captureException(error)

    dispatch(
      setDialogVisibility({
        name: 'ErrorDialog',
        description: errorMsg || t('somethingWentWrong'),
        showSupportLink: !errorMsg
      })
    )
  }

  return (
    <HandleAction
      resource="user"
      api="verifyEduEmail"
      onSuccess={onSuccess}
      onError={onError}
    />
  )
}

export default EduEmailVerificationHandler
