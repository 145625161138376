import styled from 'styled-components'

import { Type } from 'styles'

export const BillingHistoryComponent = styled.div`
  flex-grow: 1;
  width: 100%;
`

export const H2 = styled.h2`
  ${Type.header4};
  margin-bottom: 1.5rem;
`
