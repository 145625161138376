import { forwardRef } from 'react'
import { useTranslation } from 'i18n'

import { CardInputs, PlanSelector } from 'components'
import * as S from './styles'

const PaymentForm = forwardRef(({ catalog, isBusiness }, ref) => {
  const { t } = useTranslation(['common', 'checkout'])
  const currencyCode = catalog.currency.code

  return (
    <>
      <S.PaymentAndPlan>
        <S.H2 isBusiness={isBusiness}>{t('checkout:payment')}</S.H2>
        <PlanSelector catalog={catalog} isBusiness={isBusiness} />
      </S.PaymentAndPlan>
      <CardInputs
        ref={ref}
        requiredFields={catalog.requiredFields}
        currencyCode={currencyCode}
      />
    </>
  )
})

export default PaymentForm
