import { useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { captureException } from 'utilities/sentry'
import isEqual from 'lodash/isEqual'
import { Button } from '@all-turtles/mmhmm-ui-kit'
import { useTranslation } from 'i18n'

import { Business, Commerce } from 'api'
import apiStatus from 'store/selectors/apiStatus'
import { setDialogVisibility } from 'store/dialog/slice'
import { setToastVisibility } from 'store/toast/slice'
import { CardInputs, withChargebee, Loading } from 'components'
import * as S from './styles'

const apiStatusSelector = (state, isBusiness) =>
  apiStatus({
    state,
    resource: isBusiness ? 'business' : 'commerce',
    api: 'updatePaymentMethod'
  })

const EditPayment = ({
  getChargebeeToken,
  chargebeeLoaded,
  isBusiness,
  canModify
}) => {
  const { t } = useTranslation(['account', 'checkout', 'common'])
  const cardRef = useRef()
  const dispatch = useDispatch()
  const status = useSelector((state) => apiStatusSelector(state, isBusiness))
  const [loading, setLoading] = useState()
  const router = useRouter()
  const catalog = useSelector(
    (state) =>
      isBusiness ? state.api.business?.catalog : state.api.commerce?.catalog,
    isEqual
  )

  const currencyCode = catalog?.currency?.code

  const displayToast = () => {
    dispatch(
      setToastVisibility({
        name: 'SuccessToast',
        title: t('common:success'),
        description: t('account:paymentEditSuccess')
      })
    )
  }

  const handleSubmit = async (values) => {
    try {
      setLoading(true)
      const token = await getChargebeeToken(cardRef)
      const paymentSource = {
        token,
        ...values
      }

      const api = isBusiness ? Business : Commerce
      const res = await dispatch(api.updatePaymentMethod(paymentSource))
      setLoading(false)

      if (res.error) {
        const notSubscribed = res.error.code === 'resource_not_found'
        const description = notSubscribed
          ? t('notSubscribedPaymentError')
          : t('checkout:invalidCardDetails')
        const options = {
          name: 'ErrorDialog',
          description,
          showSupportLink: !notSubscribed
        }

        dispatch(setDialogVisibility(options))
      } else {
        displayToast()
        const nextRoute = isBusiness ? '/business/payment-history' : '/'
        router.push(nextRoute)
      }
    } catch (error) {
      captureException(error)
      const options = {
        name: 'ErrorDialog',
        description: t('checkout:invalidCardDetails'),
        showSupportLink: true
      }

      dispatch(setDialogVisibility(options))
      setLoading(false)
    }
  }

  return (
    <S.EditPaymentComponent>
      {!isBusiness && <S.H1>{t('editPayment')}</S.H1>}
      <S.Form onSubmit={handleSubmit}>
        {chargebeeLoaded ? (
          <CardInputs
            ref={cardRef}
            currencyCode={currencyCode}
            requiredFields={catalog?.requiredFields}
          />
        ) : (
          <S.Loading>
            <Loading visible />
          </S.Loading>
        )}
        <S.Wrap>
          {!isBusiness && <S.StyledBack />}
          <Button
            type="submit"
            loading={loading || status.loading}
            disabled={isBusiness && !canModify}
          >
            {t('common:save')}
          </Button>
        </S.Wrap>
      </S.Form>
    </S.EditPaymentComponent>
  )
}

export default withChargebee(EditPayment)
