import { useDispatch, useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'
import { Button } from '@all-turtles/mmhmm-ui-kit'

import { requestUserEmail, sendVerificationTokenEmail } from 'api/user'
import apiStatus from 'store/selectors/apiStatus'
import { setToastVisibility } from 'store/toast/slice'
import { Trans, useTranslation } from 'i18n'
import { HandleAction } from 'components'
import * as S from './styles'

const apiStatusSelector = (state, isJoining) =>
  apiStatus({
    state,
    resource: 'user',
    api: isJoining ? 'requestUserEmail' : 'sendVerificationTokenEmail'
  })

const CheckEmail = () => {
  const { t } = useTranslation(['business', 'common'])
  const dispatch = useDispatch()

  const uiBusinessInfo = useSelector((state) => state.ui?.businessInfo)
  const uiBusinessInfoEmail = uiBusinessInfo?.email

  const currentUserEmail = useSelector((state) => state.api.user?.me?.email)

  /**
   * A user may be in the process of joining an org through a join link or approved domain,
   * or they may be in the process of verifying an email. If joining, their uiBusinessInfoEmail
   * and a business id should be saved from the previous screen.
   * If joining, they should be sent an invite email ('requestUserEmail')
   * If verifying, they should be sent the verify email ('sendVerificationEmail')
   */
  const isJoining = !!(uiBusinessInfoEmail && uiBusinessInfo.business.name)
  const email = isJoining ? uiBusinessInfoEmail : currentUserEmail

  /**
   * Display different copy for user who starts with business checkout flow and gets
   * to this page because of an auto-approve domain email
   */
  const unsuspectedJoinInvite =
    uiBusinessInfo?.nextStep === 'businessJoinChoiceInvited'
  const unsuspectedJoinAuto =
    isJoining && !(uiBusinessInfo?.viaJoinLink || unsuspectedJoinInvite)
  const status = useSelector(
    (state) => apiStatusSelector(state, isJoining),
    isEqual
  )

  const handleResend = () => {
    if (isJoining) {
      dispatch(requestUserEmail({ email }))
    } else {
      dispatch(sendVerificationTokenEmail())
    }
  }

  const handleSuccess = () => {
    dispatch(
      setToastVisibility({
        name: 'SuccessToast',
        title: t('common:success'),
        description: t('resendSuccess')
      })
    )
  }

  const domain = `@${email?.split('@')[1]}`
  const businessName = uiBusinessInfo?.business?.name

  return (
    <>
      <HandleAction
        resource="user"
        api={isJoining ? 'requestUserEmail' : 'sendVerificationTokenEmail'}
        onSuccess={handleSuccess}
        stayOnPage
      />
      <S.CheckEmailComponent>
        <h1>
          {t(unsuspectedJoinAuto ? 'businessExistsHeader' : 'checkEmailHeader')}
        </h1>
        <S.Body>
          {unsuspectedJoinAuto ? (
            <Trans
              i18nKey="business:businessExistsBody"
              businessName={businessName}
              domain={domain}
              t={t}
            >
              A Business account already exists for anyone with an
              <S.Email>{{ domain }}</S.Email> email address.
              <br />
              Check your email to verify your account and join
              <bdi>{{ businessName }}</bdi>.
            </Trans>
          ) : (
            <Trans i18nKey="business:checkEmailBody" email={email} t={t}>
              A verification email was sent to <S.Email>{{ email }}</S.Email>.
              <br />
              Check your email spam folder if you can't find your email.
            </Trans>
          )}
        </S.Body>
        <Button variant="link" onClick={handleResend} loading={status?.loading}>
          {t(status?.loading ? 'sending' : 'resendEmail')}
        </Button>
      </S.CheckEmailComponent>
    </>
  )
}

export default CheckEmail
