import styled from 'styled-components'

import { Breakpoints, Type } from 'styles'

export const H2 = styled.h2`
  ${Type.header3};
  display: ${(props) => (props.isBusiness ? 'block' : 'none')};
  margin-bottom: 2.25rem;

  @media (min-width: ${Breakpoints.desktop}) {
    display: block;
    padding-inline-end: 20px;
  }
`

export const PaymentAndPlan = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  flex-direction: column;

  @media (min-width: ${Breakpoints.desktop}) {
    flex-direction: row;
  }
`
