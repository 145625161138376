import { useDispatch } from 'react-redux'
import { captureException } from 'utilities/sentry'

import { setDialogVisibility } from 'store/dialog/slice'
import { useTranslation } from 'i18n'
import { Dialog, HandleAction, InviteMembersLinkConfirm } from 'components'
import * as S from './styles'

const ConfirmResetLinksDialog = ({ visible }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['business', 'common'])

  const handleClose = () => {
    dispatch(setDialogVisibility({ name: null }))
  }

  const handleError = (error) => {
    captureException(error)

    dispatch(
      setDialogVisibility({
        name: 'ErrorDialog',
        description: t('common:somethingWentWrong'),
        showSupportLink: true
      })
    )
  }

  return (
    <Dialog visible={visible} label={t('confirmReset')}>
      <HandleAction
        resource="business"
        api="resetAllLinks"
        toastDescription={t('resetLinksSuccessLong')}
        onError={handleError}
        closeAllDialogsOnSuccess
        stayOnPage
      />
      <S.ConfirmResetLinksDialogComponent>
        <InviteMembersLinkConfirm goBack={handleClose} view="resetAllLinks" />
      </S.ConfirmResetLinksDialogComponent>
    </Dialog>
  )
}

export default ConfirmResetLinksDialog
