import { useCallback, useState, useRef } from 'react'
import { useDropzone } from 'react-dropzone'
import { mixpanelService, eventTypes } from 'analytics'
import { useTranslation } from 'i18n'
import formatBytes from 'utilities/formatBytes'
import { Button } from '@all-turtles/mmhmm-ui-kit'

import * as S from './styles'

const MAX_FILE_SIZE_BYTES = 20971520

const AvatarUploadArea = ({ isDragActive }) => {
  const { t } = useTranslation('account')
  return (
    <S.UploadArea $isDragActive={isDragActive}>
      <S.P>{t('avatarAddImage')}</S.P>
      <S.Info>{t('avatarFileTypes')}</S.Info>
    </S.UploadArea>
  )
}

const AvatarEditorArea = ({ file, editorRef }) => {
  const [scale, setScale] = useState(1.1)

  return (
    <>
      <S.DownsamplingZoomHack>
        <S.StyledReactAvatarEditor
          ref={editorRef}
          image={file}
          width={576}
          height={576}
          color={[255, 255, 255, 0.9]} // RGBA
          scale={scale}
          borderRadius={360}
        />
      </S.DownsamplingZoomHack>
      <S.Slider
        name="scale"
        type="range"
        onChange={(evt) => setScale(parseFloat(evt.target?.value) || 1.1)}
        min="1"
        max="3"
        step="0.01"
        defaultValue={scale}
      />
    </>
  )
}

export default function AvatarDropzone({ onCancel, onSave }) {
  const { t } = useTranslation('account')
  const [file, setFile] = useState(null)
  const [isSaving, setIsSaving] = useState(false)
  const editorRef = useRef(null)

  const fileSizeValidator = (file) => {
    if (file.size > MAX_FILE_SIZE_BYTES) {
      return {
        code: 'file-too-large',
        message: t('fileTooLargeError')
      }
    }
    return null
  }

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0]
    if (file && file.type && file.size) {
      mixpanelService.track(eventTypes.profilePhotoUpload, {
        type: file.type,
        size: file.size
      })
    }
    setFile(file)
  }, [])

  const handleSave = async () => {
    setIsSaving(true)
    if (!editorRef.current) return
    const canvas = editorRef.current.getImageScaledToCanvas()
    canvas.toBlob(async (blob) => {
      const file = new File([blob], 'avatar.png', { type: 'image/png' })
      await onSave(file)
      setIsSaving(false)
    })
  }

  const { getRootProps, getInputProps, isDragActive, fileRejections } =
    useDropzone({
      onDrop,
      multiple: false,
      accept: 'image/*',
      validator: fileSizeValidator,
      noClick: !!file
    })

  const rejectedFile = fileRejections[0]?.file
  return (
    <S.Container>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {file ? (
          <AvatarEditorArea file={file} editorRef={editorRef} />
        ) : (
          <AvatarUploadArea isDragActive={isDragActive} />
        )}
      </div>

      {rejectedFile && (
        <S.Error>
          {t('cantUploadFileError')} {rejectedFile.path} -{' '}
          {formatBytes(rejectedFile.size)}
          <br />
          {t('cantUploadFileErrorDetails')}
        </S.Error>
      )}

      <S.Buttons>
        <Button
          variant="link"
          onClick={onCancel}
          style={{ textTransform: 'uppercase', fontSize: 18 }}
        >
          {t('cancelUploadAvatar')}
        </Button>
        {file && (
          <Button
            variant="primary"
            onClick={handleSave}
            loading={isSaving}
            style={{ marginLeft: 'auto' }}
          >
            {t('saveAvatar')}
          </Button>
        )}
      </S.Buttons>
    </S.Container>
  )
}
