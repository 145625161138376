import styled, { css } from 'styled-components'
import { Radio } from 'informed'

import { Colors, Type } from 'styles'

export const RadioCardComponent = styled.label`
  height: 5.9375rem; /* 95px */
  border-radius: 4px;
  display: flex;
  padding: 1.4375rem 1.0625rem 1.0625rem;
  box-sizing: border-box;
  position: relative;
  border: 1px solid ${Colors.brand};
  transition: all 200ms ease;

  &:hover {
    border: 1px solid ${Colors.brand};
  }

  ${(props) =>
    !props.selected &&
    css`
      background-color: ${Colors.gray10};
      border: 1px solid transparent;
    `}
`

export const Wrap = styled.div`
  padding-inline-end: 0.8125rem;
`

export const Text = styled.div``

export const Title = styled.p`
  ${Type.body30};
  font-weight: ${Type.fontWeights.bold};
  margin-bottom: 0.5rem;
`

export const Description = styled.p`
  ${Type.body30};
`

export const StyledCheck = styled.div`
  width: 0.375rem;
  height: 0.375rem;
  border-radius: 50%;
  background: ${Colors.brand};
  opacity: 0;
  transition: opacity 175ms ease;
`

export const Border = styled.div`
  width: 0.875rem;
  height: 0.875rem;
  border-radius: 50%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: inset 0px 1.5px 1px rgba(0, 0, 0, 0.15),
    inset 0px 0px 2px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border 175ms ease;
  box-sizing: border-box;
  background: white;
`

export const StyledRadio = styled(Radio)`
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 2;
  top: 0;
  left: 0;
  margin: 0;

  [dir='rlt'] & {
    left: unset;
    right: 0;
  }

  &:checked ~ ${Border} {
    border: 1px solid ${Colors.brand};

    ${StyledCheck} {
      opacity: 1;
    }
  }

  &:focus.focus-visible ~ ${Border} {
    outline: 2px solid ${Colors.brand};
    outline-offset: 2px;
  }
`
