import { modelName, apiFetch, createAsyncThunk } from './commerce'

const getPayments = createAsyncThunk(
  `${modelName}/getPayments`,
  async (body) => {
    const options = {
      path: '/users/me/paymentHistory',
      method: 'GET',
      body
    }
    const response = await apiFetch(options)

    return response
  }
)

getPayments.storeKey = 'payments'

export default getPayments
