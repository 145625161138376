import styled from 'styled-components'

import { Colors, Type } from 'styles'

export const LaunchAppComponent = styled.div`
  text-align: center;
`

export const H1 = styled.h1`
  margin: 3.125rem auto;
  max-width: 46.75rem;
`

export const BodyText = styled.p`
  ${Type.body20};
  margin-bottom: 0.75rem;

  &:last-of-type {
    margin-bottom: 3.125rem;
  }
`

export const Bold = styled.span`
  font-weight: ${Type.fontWeights.bold};
`

export const Hr = styled.hr`
  border: 0.5px solid ${Colors.gray};
  margin: 3.125rem;
`
