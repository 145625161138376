import { useDispatch, useSelector } from 'react-redux'

import * as S from './styles'
import { useTranslation } from 'i18n'
import { Dialog, HandleAction } from 'components'
import { Button } from '@all-turtles/mmhmm-ui-kit'
import { setDialogVisibility } from 'store/dialog/slice'
import { updateMemberStatus } from 'api/business'
import apiStatus from 'store/selectors/apiStatus'

const apiStatusSelector = (state) =>
  apiStatus({
    state,
    resource: 'business',
    api: 'updateMemberStatus'
  })

const ReactivateAccountDialog = ({ visible, member }) => {
  const { t } = useTranslation('business')
  const dispatch = useDispatch()
  const status = useSelector(apiStatusSelector)

  const handleActivate = () => {
    dispatch(updateMemberStatus({ userId: member.id, status: 'active' }))
  }

  const handleCancel = () => {
    dispatch(setDialogVisibility({ name: null }))
  }

  return (
    <Dialog visible={visible} label="Reactivate member account">
      <HandleAction
        resource="business"
        api="updateMemberStatus"
        toastDescription={t('reactivateAccountSuccess')}
        closeAllDialogsOnSuccess
        stayOnPage
      />
      <S.Wrap>
        <S.H1>{t('activateAccount')}</S.H1>
        <p>{t('reactivateAccountBody')}</p>
        <S.MemberSection>
          <S.Name>
            <bdi>{member.name}</bdi>
          </S.Name>
          <p>
            <bdi>{member.email}</bdi>
          </p>
        </S.MemberSection>
        <S.Buttons>
          <Button onClick={handleCancel} variant="link">
            {t('common:cancel')}
          </Button>
          <Button loading={status?.loading} onClick={handleActivate}>
            {t('activate')}
          </Button>
        </S.Buttons>
      </S.Wrap>
    </Dialog>
  )
}

export default ReactivateAccountDialog
