import styled, { css } from 'styled-components'

import { Breakpoints, Colors, Type } from 'styles'

export const BusinessNavComponent = styled.div`
  width: 100%;
  margin-bottom: 2rem;

  @media (min-width: ${Breakpoints.tablet}) {
    width: auto;
    margin-inline-end: 3.875rem;
    flex-shrink: 0;
  }
`

export const Li = styled.li`
  margin-bottom: 1rem;

  &:last-child {
    margin: 0;
  }

  a:hover {
    color: ${Colors.black};
  }

  ${(props) =>
    props.selected &&
    css`
      a {
        font-weight: ${Type.fontWeights.bold};
        color: ${Colors.black};
      }
    `}

  @media (min-width: ${Breakpoints.tablet}) {
    margin-bottom: 1.5rem;
  }
`
