const businessCancelSubscriptionFields = (t) => ({
  reason: {
    t: 'reason',
    field: 'reason',
    label: t('business:cancelSelectLabel'),
    options: [
      {
        value: '',
        display: t('business:optional'),
        disabled: true
      },
      {
        value: 'It is too expensive',
        display: t('tooExpensive')
      },
      {
        value: "It didn't work properly",
        display: t('didntWork')
      },
      {
        value: 'I no longer use it',
        display: t('notUsing')
      },
      {
        value: "I'm using another app",
        display: t('anotherApp')
      },
      {
        value: 'Other',
        display: t('other')
      }
    ]
  }
})

export default businessCancelSubscriptionFields
