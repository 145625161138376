import styled from 'styled-components'

export const Fields = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
`

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
`
