import { Exclamation } from 'components/Icons'
import * as S from './styles'

const WarningBox = ({ children }) => {
  return (
    <S.Container>
      <S.Icon>
        <Exclamation />
      </S.Icon>
      <S.Message>{children}</S.Message>
    </S.Container>
  )
}

export default WarningBox
