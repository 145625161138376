const verificationCodeFields = ({ t }) => ({
  codeEntry: {
    field: 'verificationCode',
    placeholder: t('accountDeletionPlaceholder'),
    label: t('accountDeletionPlaceholder'),
    visuallyHideLabel: true,
    type: 'text'
  }
})

export default verificationCodeFields
