import { useDispatch } from 'react-redux'

import { Button } from '@all-turtles/mmhmm-ui-kit'
import { useTranslation } from 'i18n'
import { setDialogVisibility } from 'store/dialog/slice'

const CreateNewButton = () => {
  const { t } = useTranslation('business')
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(
      setDialogVisibility({
        name: 'EditRoom',
        id: null,
        roomName: null,
        description: null,
        thumbnailUrl: null,
        state: 'create'
      })
    )
  }

  return (
    <Button variant="secondary" onClick={handleClick}>
      {t('createNew')}
    </Button>
  )
}

export default CreateNewButton
