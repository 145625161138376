import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { Button } from '@all-turtles/mmhmm-ui-kit'

import { useTranslation, Link, Trans } from 'i18n'
import { signOutDEPRECATED } from 'utilities/auth'
import { getSignInAppUrl } from 'utilities/getSignInAppUrl'
import * as S from './styles'

const LinkAccount = () => {
  const { t } = useTranslation(['business', 'common'])
  const router = useRouter()
  const [loading, setLoading] = useState(false)
  const currentUser = useSelector((state) => state.api.user?.me)
  const email = useSelector((state) => state.ui?.businessInfo?.email)

  const termsUrl = t('businessTermsUrl')
  const privacyUrl = t('common:privacyUrl')

  const handleLogout = async () => {
    setLoading(true)
    await signOutDEPRECATED()
    router.push('/business')
  }

  const signInURL = getSignInAppUrl({
    path: '/sign-in',
    query: { email },
    context: 'business/purchase'
  })
  return (
    <S.LinkAccountComponent>
      <S.Title>{t('linkAccountHeader')}</S.Title>
      <S.Email dir="ltr">{email}</S.Email>
      <S.Description>
        <Trans i18nKey="linkAccountBody" t={t}>
          Your Business account will be subject to the
          <a href={termsUrl} target="_blank" rel="noopener noreferrer">
            Business Agreement
          </a>
          and
          <a href={privacyUrl} target="_blank" rel="noopener noreferrer">
            Privacy Policy
          </a>
          and any Content in your account will be owned by the Business.
        </Trans>
      </S.Description>
      <S.Hr />
      <S.Buttons>
        <S.ButtonWrapper>
          <Button variant="secondary" onClick={handleLogout} loading={loading}>
            {t('useDifferentEmail')}
          </Button>
        </S.ButtonWrapper>
        <S.ButtonWrapper>
          <Link
            href={currentUser?.id ? '/business/purchase' : signInURL}
            passHref
          >
            <Button variant="primary" as="a">
              {t('common:continue')}
            </Button>
          </Link>
        </S.ButtonWrapper>
      </S.Buttons>
    </S.LinkAccountComponent>
  )
}

export default LinkAccount
