import { arrayOf, bool, string } from 'prop-types'
import { useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'

import { Link, useTranslation } from 'i18n'
import { MobileNav, NavItems, LogOut } from 'components'
import * as S from './styles'

const Navbar = ({ className, hideNav, showOnlyItems }) => {
  const { t } = useTranslation('nav')
  const currentUser = useSelector((state) => state.api.user?.me?.id, isEqual)

  return (
    <S.NavbarComponent className={className}>
      <S.StyledMaxWidth>
        <Link href={process.env.NEXT_PUBLIC_OOO_APP_LINK}>
          <a>
            <S.StyledWordmark title="mmhmm" />
          </a>
        </Link>
        {!hideNav && (
          <S.Nav aria-label={t('mainMenu')}>
            <NavItems showOnlyItems={showOnlyItems} />
          </S.Nav>
        )}
        {hideNav && currentUser && (
          <S.Nav aria-label={t('mainMenu')}>
            <LogOut variant="linkCaps" />
          </S.Nav>
        )}
        <MobileNav />
      </S.StyledMaxWidth>
    </S.NavbarComponent>
  )
}

Navbar.propTypes = {
  className: string,

  /**
   * When true, the nav items are completely hidden.
   */
  hideNav: bool,

  /**
   * An array of navigation items to show. All others will be hidden.
   * Keyed off of the i18nkey value on each object in navItems.js
   */
  showOnlyItems: arrayOf(string)
}

export default Navbar
