import { useDispatch, useSelector } from 'react-redux'

import { getBillingInfo, updateBillingInfo } from 'api/commerce'
import { useTranslation } from 'i18n'
import { EditBillingForm, HandleAction, Loading } from 'components'
import * as S from './styles'

const EditBilling = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['account'])

  const billingInfo = useSelector((state) => state.api.commerce?.billingInfo)

  const initialValues = {
    billingAddress: billingInfo?.billingAddress,
    taxID: billingInfo?.taxID
  }

  const handleSubmit = async (values) => {
    await dispatch(updateBillingInfo(values))
    dispatch(getBillingInfo())
  }

  return (
    <S.EditBillingComponent>
      <HandleAction
        resource="commerce"
        api="updateBillingInfo"
        toastDescription={t('billingInfoSuccess')}
        stayOnPage
      />
      <S.H1>{t('billing')}</S.H1>
      <S.H2>{t('taxIdAddressLong')}</S.H2>
      <S.P>{t('billingInfoDesc')}</S.P>
      {billingInfo ? (
        <EditBillingForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
        />
      ) : (
        <Loading visible />
      )}
    </S.EditBillingComponent>
  )
}

export default EditBilling
