import styled from 'styled-components'
import { Button } from '@all-turtles/mmhmm-ui-kit'
import { Form } from 'informed'

import { Type } from 'styles'

export const StyledForm = styled(Form)`
  flex-grow: 0;
`

export const PageHeader = styled.h1`
  margin-bottom: 3.125rem;
`

export const BlockButton = styled(Button)`
  width: 100%;
  margin-top: 0.5rem;
`

export const FinePrint = styled.p`
  ${Type.body40};
  margin-top: 0.75rem;
`
