import { createAsyncThunk } from '@reduxjs/toolkit'

import apiFetch from '../apiFetch'

const sendVerificationTokenEmail = createAsyncThunk(
  'user/sendVerificationTokenEmail',
  async () => {
    const options = {
      path: '/users/me/email',
      method: 'POST'
    }

    const response = await apiFetch(options)

    return response
  }
)

export default sendVerificationTokenEmail
