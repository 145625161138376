import styled from 'styled-components'
import { Type } from 'styles'

export const Title = styled.p`
  font-weight: ${Type.fontWeights.bold};
  ${Type.body30};
  margin-bottom: 0.25rem;
`

export const Description = styled.p`
  ${Type.body30};
`
