import { PropTypes as PT } from 'prop-types'

const propTypes = {
  data: PT.array,
  loading: PT.bool,

  /** When true, this table will be searchable */
  withSearch: PT.bool,

  /** Example: { database_key: My header name } */
  headerOverrides: PT.object,

  /** Not all tables should have clickable rows. Use this to turn that default off. */
  disableRowClick: PT.bool,

  /** If specified, the table will initialize sorted by this key */
  defaultSortKey: PT.string,
  defaultSortDirection: PT.oneOf(['DESC', 'ASC']),

  /** Specify which columns should have date formatting */
  dateKeys: PT.arrayOf(PT.string),

  /** These columns will be included in the table */
  includeKeys: PT.arrayOf(PT.string),

  /** When the user clicks on a row, what should happen? */
  onRowClick: PT.func,

  /** The name of the key to use as an ID for each row */
  itemId: PT.string,

  /**
   * Overrides the default cell string with a custom component
   * If you wish to override an existing cell, the key must match exactly.
   * If the keys do not match, a new column will be inserted.
   */
  customCellComponents: PT.object,

  /**
   * For all column keys in the array, the sort functionality is disabled,
   * and the display string is displayed.
   */
  disableSortForColumns: PT.arrayOf(PT.string),

  /**
   * Transform the displayed cell data for any columns passed to transformKeys
   */
  dataTransform: PT.func,

  /**
   * Columns whose values should be passed through dataTransform func for display
   */
  transformKeys: PT.arrayOf(PT.string),

  /**
   * Data sorting is handled by the table be default;
   * to override this and control sort, `handleSort` prop function should expect sortProp
   * and sortDirection on click of a sortable column
   * Ex:
   * (sortProp, sortDirection) => { dispatchAPI({ order_by: sortProp, order_dir: sortDirection})}
   */
  handleSort: PT.func
}

export default propTypes
