import { Link } from 'i18n'
import { arrayOf, node, oneOf, shape, string } from 'prop-types'

import * as S from './styles'

const propTypes = {
  children: node.isRequired,
  heading: string.isRequired,
  variant: oneOf(['info', 'navigation']),
  ctas: arrayOf(
    shape({
      id: string.isRequired,
      label: string.isRequired,
      href: string.isRequired,
      /**
       * - button is always ButtonSecondary
       * - linkToButton is ButtonSecondary on mobile and otherwise Link
       * - link is always Link
       */
      type: oneOf(['button', 'linkToButton', 'link'])
    })
  )
}

const defaultProps = {
  variant: 'info',
  ctas: null
}

const Panel = ({ children, ctas, heading, variant, ...rest }) => {
  const getCta = (label, type) => {
    const Cta = S[type]
    return <Cta>{label}</Cta>
  }

  return (
    <S.PanelComponent {...rest}>
      <S.Heading variant={variant}>{heading}</S.Heading>
      <S.Body variant={variant}>{children}</S.Body>
      {ctas && (
        <S.Buttons>
          {ctas.map(({ id, href, label, type }) => (
            <Link key={id} href={href} passHref>
              {getCta(label, type)}
            </Link>
          ))}
        </S.Buttons>
      )}
    </S.PanelComponent>
  )
}

Panel.propTypes = propTypes
Panel.defaultProps = defaultProps

export default Panel
