import { forwardRef } from 'react'
import { string } from 'prop-types'
import * as S from './styles'

const ReadOnlyInput = forwardRef((props, ref) => {
  const { label, placeholder, value, ...rest } = props

  return (
    <S.Input
      aria-label={label}
      value={value}
      placeholder={placeholder}
      type="text"
      tabIndex="-1"
      readOnly
      ref={ref}
      {...rest}
    />
  )
})

ReadOnlyInput.propTypes = {
  label: string.isRequired,
  placeholder: string,
  value: string
}

ReadOnlyInput.defaultProps = {
  placeholder: '',
  value: null
}

export default ReadOnlyInput
