import { useCallback, useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'

import { useTranslation } from 'i18n'
import formatBytes from 'utilities/formatBytes'
import DropzoneContent from './DropzoneContent'
import * as S from './styles'

export default function Dropzone({ existingImageUrl, onChange }) {
  const { t } = useTranslation('business')
  const [file, setFile] = useState()

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      if (file?.preview) URL.revokeObjectURL(file.preview)
    },
    [file]
  )

  const MAX_FILE_SIZE_BYTES = 20971520

  function fileSizeValidator(file) {
    if (file.size > MAX_FILE_SIZE_BYTES) {
      return {
        code: 'file-too-large',
        message: t('fileTooLargeError')
      }
    }

    return null
  }

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()

      reader.onabort = () => console.warn('File reading was aborted')
      reader.onerror = () => console.error('File reading has failed')
      reader.onload = () => {
        const fileWithPreview = Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
        setFile(fileWithPreview)

        if (onChange) onChange(file)
      }
      reader.readAsArrayBuffer(file)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    fileRejections
  } = useDropzone({
    onDrop,
    accept: 'image/*, video/mp4, video/mov',
    validator: fileSizeValidator
  })

  const fileRejectionItems = fileRejections.map(({ file }) => (
    <S.FileErrors key={file.path}>
      <S.File>
        {t('cantUploadFileError')}
        <br />
        <b>
          {file.path} - {formatBytes(file.size)}
        </b>
      </S.File>
      <S.Error>{t('cantUploadFileErrorDetails')}</S.Error>
    </S.FileErrors>
  ))

  return (
    <>
      <S.Container
        {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      >
        <DropzoneContent
          file={file}
          getInputProps={getInputProps}
          existingImageUrl={existingImageUrl}
        />
        {fileRejectionItems}
      </S.Container>
    </>
  )
}
