import { useDispatch, useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'

import * as S from './styles'
import { deleteInvitation, getInvitations } from 'api/business'
import { Dialog, HandleAction } from 'components'
import { Trans, useTranslation } from 'i18n'
import { Button } from '@all-turtles/mmhmm-ui-kit'
import { setDialogVisibility } from 'store/dialog/slice'
import apiStatus from 'store/selectors/apiStatus'

const apiStatusSelector = (state) =>
  apiStatus({
    state,
    resource: 'business',
    api: 'deleteInvitation'
  })

const DeleteInviteDialog = ({ visible, email, inviteId }) => {
  const { t } = useTranslation(['business', 'common'])
  const dispatch = useDispatch()
  const status = useSelector(apiStatusSelector, isEqual)

  const handleDeleteInvite = async () => {
    await dispatch(deleteInvitation({ inviteId }))
    dispatch(getInvitations())
  }

  const handleCancel = () => {
    dispatch(setDialogVisibility({ name: null }))
  }

  return (
    <Dialog visible={visible} label={t('deleteInvite')}>
      <HandleAction
        resource="business"
        api="deleteInvitation"
        toastDescription={t('deleteInvitationSuccess')}
        closeAllDialogsOnSuccess
        stayOnPage
      />
      <S.DialogWrap>
        <S.Title>
          <Trans i18nKey="areYouSureDeleteInvite" t={t} email={email}>
            Are you sure you want to delete the invitation for
            <S.NowrapBdi>{{ email }}</S.NowrapBdi>
          </Trans>
        </S.Title>
        <S.Buttons>
          <Button onClick={handleCancel} variant="secondary">
            {t('common:cancel')}
          </Button>
          <Button loading={status?.loading} onClick={handleDeleteInvite}>
            {t('delete')}
          </Button>
        </S.Buttons>
      </S.DialogWrap>
    </Dialog>
  )
}

export default DeleteInviteDialog
