import styled from 'styled-components'
import { Colors, Type } from 'styles'
import { Button } from '@all-turtles/mmhmm-ui-kit'

export const Body = styled.p`
  ${Type.body20};
  margin-top: 0.75rem;
`

export const StyledButton = styled(Button)`
  margin-top: 30px;
`

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const SuccessWrapper = styled.div`
  max-width: 460px;
`

export const LoadingCopy = styled.p`
  color: ${Colors.white};
  margin-top: 1rem;
`
