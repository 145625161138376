import { modelName, apiFetch, createAsyncThunk } from './business'

const addDomain = createAsyncThunk(`${modelName}/addDomain`, async (body) => {
  const options = {
    path: '/businesses/me/admin/domains',
    method: 'POST',
    body
  }
  const response = await apiFetch(options)

  return response
})

addDomain.fulfilledReducer = (state, action) => {
  const existingDomains = state.business.approvedDomains?.domains
  const addedDomain = action.meta.arg.domain
  const domains = [...existingDomains, addedDomain]

  state.business.approvedDomains = {
    count: domains.length,
    domains
  }
}

export default addDomain
