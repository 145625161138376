/**
 * 2/9/22 Keep ResetPassword in place for now to use in the Busines Login flow
 * because that has its own routing in place to return the user back to biz login
 * after "forgot password" -> "reset password"
 */
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'

import { Trans, useTranslation, Link } from 'i18n'
import { forgotPasswordSubmit, signIn } from 'api/auth'
import { getMe } from 'api/user'
import { getSignInAppUrl } from 'utilities/getSignInAppUrl'
import { ResetPasswordForm, PasswordRequirements } from 'components'
import * as S from './styles'

const LoginLink = ({ children, nextRoute }) => {
  if (nextRoute) {
    return (
      <Link href={nextRoute} passHref>
        <a>{children}</a>
      </Link>
    )
  } else {
    const signInRedirectUrl = getSignInAppUrl({ path: '/sign-in' })
    return <a href={signInRedirectUrl}>{children}</a>
  }
}

const ResetPassword = () => {
  const { t } = useTranslation(['account', 'common'])
  const dispatch = useDispatch()
  const username = useSelector((state) => state.ui?.forgotPasswordEmail)
  const [newVal, setNewVal] = useState('')
  const nextRoute = useSelector((state) => state.ui?.nextRoute)

  const handleSubmit = async (values) => {
    const data = {
      username,
      code: values.code,
      newPassword: values.newPassword
    }

    try {
      const submitAction = await dispatch(forgotPasswordSubmit(data))
      unwrapResult(submitAction)
      await dispatch(signIn({ username, password: values.newPassword }))
      await dispatch(getMe())
    } catch (err) {
      console.error(`Caught password reset error: ${err.code}`, err)
    }
  }

  return (
    <S.ResetPasswordComponent>
      <S.StartCol>
        <h1>{t('resetPassword')}</h1>
        <S.P>{t('checkEmail')}</S.P>
        <S.P>
          <Trans i18nKey="rememberPassword" t={t}>
            Remember your password?
            <LoginLink nextRoute={nextRoute}>Log in</LoginLink>.
          </Trans>
        </S.P>
        <ResetPasswordForm
          onSubmit={handleSubmit}
          handleFieldChange={(e) => setNewVal(e.target.value)}
        />
      </S.StartCol>
      <S.EndCol>
        <PasswordRequirements newVal={newVal} />
      </S.EndCol>
    </S.ResetPasswordComponent>
  )
}

export default ResetPassword
