import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { dispatch } from 'store'
import { setToastVisibility } from 'store/toast/slice'
import isEqual from 'lodash/isEqual'

import ToastList from './ToastList'

const animationDuration = 250 // ms

const ToastController = () => {
  const [visible, setVisible] = useState(false)
  const [toastName, setToastName] = useState()
  const toastProps = useSelector((state) => state.toast, isEqual)

  useEffect(() => {
    if (toastProps?.name) {
      setToastName(toastProps?.name)
      setVisible(true)
    } else {
      setVisible(false)

      // This is brittle, the animation does not have an explicit duration
      setTimeout(() => {
        setToastName(null)
      }, animationDuration * 2)
    }

    return () => {
      // Cleanup and remove toast when unmounting and navigating away
      if (toastProps?.name) {
        dispatch(setToastVisibility({ name: null }))
      }
    }
  }, [toastProps])

  const Toast = ToastList[toastName]
  if (!toastName) return null

  return <Toast visible={visible} {...toastProps} />
}

export default ToastController
