import { modelName, apiFetch, createAsyncThunk } from './business'

const getAvailableDomains = createAsyncThunk(
  `${modelName}/getAvailableDomains`,
  async (body) => {
    const options = {
      path: '/businesses/me/admin/domains/available',
      method: 'GET',
      body
    }
    const response = await apiFetch(options)

    return response
  }
)

getAvailableDomains.storeKey = 'availableDomains'

export default getAvailableDomains
