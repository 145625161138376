import * as S from './styles'
import SortableHeader from './SortableHeader'

const TableHeaders = (props) => {
  const { columns, ...rest } = props

  return (
    <S.Headers>
      {columns.map((item) => (
        <S.Header key={item}>
          <SortableHeader column={item} {...rest} />
        </S.Header>
      ))}
    </S.Headers>
  )
}

export default TableHeaders
