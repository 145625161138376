import { Auth } from '@aws-amplify/auth'
import { createAsyncThunk } from '@reduxjs/toolkit'

import apiFetch from '../apiFetch'

export const modelName = 'user'

export const update = createAsyncThunk('user/update', async (data) => {
  const { username, currentCredentials, ...rest } = data
  const body = { ...rest }
  if (username) {
    // Updating email needs `signIn` called first because API checks if there was auth performed "recently"
    // https://github.com/All-Turtles/mmhmm-service/blob/17de4d7487db2cd15edbdbecab4ad553d097362f/cmd/api/user.go#L126
    await Auth.signIn({
      username: currentCredentials.email,
      password: currentCredentials.password
    })
    body.email = username
  }

  return await apiFetch({
    path: '/users/me',
    method: 'PATCH',
    body
  })
})

update.storeKey = 'me'

export const getUserIdForToken = createAsyncThunk(
  'user/handoff',
  async ({ token }) => {
    const options = {
      path: '/auth/tokens/handoff/user',
      method: 'POST',
      body: { token },
      authorized: false
    }
    const response = await apiFetch(options)

    return response
  }
)

export const getMe = createAsyncThunk(
  'user/me',
  async (includePermissions = true) => {
    return await apiFetch({
      path: `/users/me?includeBusinessPermissions=${includePermissions}`,
      method: 'GET'
    })
  }
)

getMe.storeKey = 'me'

export { default as requestEduPremium } from './requestEduPremium'
export { default as verifyEduEmail } from './verifyEduEmail'
export { default as sendVerificationTokenEmail } from './sendVerificationTokenEmail'
export { default as requestUserEmail } from './requestUserEmail'
export { default as redeemUserInviteToken } from './redeemUserInviteToken'
export { default as uploadAvatar } from './uploadAvatar'
export { default as deleteAvatar } from './deleteAvatar'
export { default as deleteAccount } from './deleteAccount'
export { default as sendDeletionVerificationCode } from './sendDeletionVerificationCode'
