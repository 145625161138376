import { modelName, apiFetch, createAsyncThunk } from './business'

const requestInviteFromJoinToken = createAsyncThunk(
  `${modelName}/requestInviteFromJoinToken`,
  async (body) => {
    const options = {
      path: '/public/businesses/invitations/me',
      method: 'POST',
      body,
      authorized: false
    }
    const response = await apiFetch(options)

    return response
  }
)

export default requestInviteFromJoinToken
