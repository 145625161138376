import * as S from './styles'
import TableData from '../TableData/TableData'
import DownloadReceipt from '../DownloadReceipt/DownloadReceipt'

const PaymentHistoryTable = ({ paymentHistory, isBusiness = false }) => {
  return (
    <S.PaymentHistoryTableComponent>
      <TableData
        data={paymentHistory}
        table="payment-history"
        itemId="id"
        title="Payment history"
        dateKeys={['date']}
        includeKeys={['id', 'date', 'amount', 'receipt']}
        customCellComponents={{
          receipt: (data) => (
            <DownloadReceipt isBusiness={isBusiness} {...data} />
          )
        }}
      />
    </S.PaymentHistoryTableComponent>
  )
}

export default PaymentHistoryTable
