import { modelName, apiFetch, createAsyncThunk } from './business'

const getInvitationFromToken = createAsyncThunk(
  `${modelName}/getInvitationFromToken`,
  async ({ token }) => {
    const options = {
      path: `/public/businesses/invitations/${token}`,
      method: 'GET',
      authorized: false
    }
    const response = await apiFetch(options)

    return response
  }
)

export default getInvitationFromToken
