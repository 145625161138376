import styled, { css } from 'styled-components'
import { Select } from 'informed'

import chevronDown from 'public/images/chevronDown.svg'
import { Type, Colors, Breakpoints } from 'styles'

export const SelectComponent = styled.div`
  display: inline-block;
`

export const SelectElement = styled(Select)`
  width: 100%;
  font-family: ${Type.fontFamily};
  cursor: pointer;
  border: 1px solid ${Colors.black};
  border-radius: 0;
  appearance: none;
  background: url(${chevronDown.src}) calc(100% - 0.75rem) / 1rem no-repeat;

  [dir='rtl'] & {
    background-position-x: 0.75rem;
  }

  ${(props) =>
    props.size === 'lg'
      ? css`
          ${Type.body10};
          height: 3.3125rem;
          padding: 0.5rem 1.75rem;
          padding-inline-start: 0.75rem;

          @media (min-width: ${Breakpoints.tablet}) {
            height: 4.125rem;
          }

          option {
            ${Type.body30};
          }
        `
      : css`
          ${Type.body30};
          height: 2.5rem;
          padding: 0 1.75rem;
          padding-inline-start: 0.75rem;
        `}

  &:focus {
    outline: 1px solid ${Colors.brand};
    border: 1px solid ${Colors.brand};
  }

  &:disabled {
    border: 1px solid ${Colors.gray40};
    background: none;
  }
`

export const Label = styled.label`
  ${(props) => (props.size === 'lg' ? Type.body30 : Type.body40)};
  display: block;
  margin-bottom: 1rem;
`
