import styled from 'styled-components'

import { Animations, Colors } from 'styles'

const loading = '#D7D7D7'

export const LoadingPanelComponent = styled.div`
  background-color: ${Colors.blue40};
  padding: 1.5rem 2rem;
  flex-shrink: 0;
  animation: ${Animations.loadingKeyframes} 1.2s ease-in infinite;
`

export const LoadingText = styled.div`
  background-color: ${loading};
  width: 6.25rem;
  height: 1rem;
  margin-bottom: 1rem;
`

export const LoadingStatusText = styled.div`
  background-color: #c0c0c0;
  width: 5rem;
  height: 1rem;
  margin-bottom: 1rem;
`

export const LoadingTextLong = styled.div`
  background-color: ${loading};
  width: 80%;
  height: 1rem;
  margin-bottom: 0.5rem;
`

export const LoadingTextShort = styled.div`
  background-color: ${loading};
  width: 50%;
  height: 1rem;
  margin-bottom: 0.5rem;
`
