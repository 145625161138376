import { Auth } from '@aws-amplify/auth'
import { Hub } from '@aws-amplify/core'
import { captureMessage } from '@sentry/nextjs'
import Cookies from 'js-cookie'

const isLocalEnv = process.env.NEXT_PUBLIC_DEPLOY_ENV === 'local'
const cookiesDomain = isLocalEnv
  ? 'localhost'
  : process.env.NEXT_PUBLIC_COGNITO_COOKIES_DOMAIN ??
    process.env.NEXT_PUBLIC_VERCEL_URL
const config = {
  region: process.env.NEXT_PUBLIC_USER_POOL_REGION,
  userPoolId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID,
  cookieStorage: {
    domain: cookiesDomain,
    path: '/',
    sameSite: 'strict',
    secure: process.env.NEXT_PUBLIC_DEPLOY_ENV !== 'local'
  }
}

/**
 * This regex is required for detecting Cognito username encoded in cookie name
 * Detecting cognito username on cookies is needed for removing "other" sessions cookies
 * to avoid 431 HTTP error on Vercel caused by too big cookie header
 *
 * It should be kept up to date with Amplify cookie storage changes in all mmhmm apps.
 * It could cause "Local storage is missing an ID Token, Please authenticate" Cognito error
 *
 * Examples:
 *
 * Apple
 * Cookie: CognitoIdentityServiceProvider.6qtrrql5jtnofng1of9182u8f9.SignInWithApple_001295.68f76caf100e48afa9b7ceb6282f610b.1359.clockDrift
 * username: SignInWithApple_001295.68f76caf100e48afa9b7ceb6282f610b.1359
 *
 * Google
 * Cookie: CognitoIdentityServiceProvider.6qtrrql5jtnofng1of9182u8f9.3994d8a8-d284-408c-aa12-9443784d7e00.refreshToken
 * username: 3994d8a8-d284-408c-aa12-9443784d7e00
 *
 * Email & Password
 * Cookie: CognitoIdentityServiceProvider.6qtrrql5jtnofng1of9182u8f9.d6e69200-7e50-46e7-bb9f-247e06ca9ea9.userData
 * username: d6e69200-7e50-46e7-bb9f-247e06ca9ea9
 */
const cognitoCookieUsernameRegex =
  /^CognitoIdentityServiceProvider\..*?\.(.*)\./

// If you're going to update or remove that function
// you probably want to update it in `signin` & `mmhmm-tv` apps too
const cleanupOtherUsersCookies = (currentUsername) => {
  const removedCookiesAccountIds = new Set()
  Object.keys(Cookies.get()).forEach((c) => {
    const match = c.match(cognitoCookieUsernameRegex)
    const username = match && match[1]
    if (username && username !== currentUsername) {
      Cookies.remove(c, {
        domain: cookiesDomain
      })
      removedCookiesAccountIds.add(username)
    }
  })

  if (removedCookiesAccountIds.size > 0) {
    captureMessage(
      `Other user session detected (current: ${currentUsername}, others: ${Array.from(
        removedCookiesAccountIds
      ).join(', ')}). Removed cookies.`,
      {
        fingerprint: ['otherUserSessionDetected']
      }
    )
  }
}

const configureCognito = () => {
  Auth.configure(config)
  Hub.listen('auth', ({ payload }) => {
    switch (payload.event) {
      case 'signIn': {
        cleanupOtherUsersCookies(payload.data.username)
        break
      }
    }
  })
}

export default configureCognito
