import { bool, func } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import { updateBusiness } from 'api/business'
import ShareLinkSettingsForm from './ShareLinkSettingsForm'

const ShareLinkSettings = ({
  handleConfirmView,
  linksDisabled,
  resetSuccess
}) => {
  const dispatch = useDispatch()
  const businessSettings = useSelector(
    (state) => state.api.business?.businessSettings
  )

  const getEnableLinksValue = () => {
    // Map the UI's 3 radio options for sharing join links to the backend's 2 options
    if (businessSettings?.enableBusinessJoinLink) {
      return businessSettings.enableMemberJoinLinks ? 'everyone' : 'admins'
    }

    return 'none'
  }

  const initialValues = { enableLinksGroup: getEnableLinksValue() }

  const handleSubmit = ({ enableLinksGroup }) => {
    const payload = {
      enableBusinessJoinLink: enableLinksGroup !== 'none',
      enableMemberJoinLinks: enableLinksGroup === 'everyone'
    }

    dispatch(updateBusiness(payload))
  }

  return (
    <ShareLinkSettingsForm
      initialValues={initialValues}
      onSubmit={handleSubmit}
      linksDisabled={linksDisabled}
      handleConfirmView={handleConfirmView}
      resetSuccess={resetSuccess}
    />
  )
}

ShareLinkSettings.propTypes = {
  handleConfirmView: func,
  linksDisabled: bool,
  resetSuccess: bool
}

export default ShareLinkSettings
