import * as S from './styles'

const TableLoading = (props) => (
  <table cellSpacing="0">
    <tbody>
      {[...Array(props.rows || 7).keys()].map((_, index) => (
        <S.LoadingRow key={index}>
          {[...Array(3).keys()].map((_, i) => (
            <S.LoadingCell header={index === 0} key={i}>
              <S.LoadingCellContent header={index === 0} />
            </S.LoadingCell>
          ))}
        </S.LoadingRow>
      ))}
    </tbody>
  </table>
)

export default TableLoading
