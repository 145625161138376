import styled, { css } from 'styled-components'
import { BasicText } from 'informed'

import { Breakpoints, Colors, Inputs, Type } from 'styles'
import ButtonPrimitive from 'components/ButtonPrimitive/ButtonPrimitive'

export const TextFieldComponent = styled.div`
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.45;
    `}
`

const BasicTextCleanProps = ({
  noError,
  theme,
  toggleVisibility,
  minimalStyle,
  forwardRef,
  ...rest
}) => <BasicText {...rest} forwardedRef={forwardRef} />

export const InputWrapper = styled.div`
  position: relative;
`

export const TextInput = styled(BasicTextCleanProps)`
  ${Type.body10};
  box-sizing: border-box;
  display: block;
  width: 100%;
  flex-grow: 1;
  background: transparent;
  border-radius: 0;
  border: none;
  padding: 0.5rem 0;
  /* Accomodate absolute-positioned eyeball toggle button if present */
  padding-inline-end: ${(props) => (props.toggleVisibility ? '2.375rem' : 0)};
  border-width: 0;
  border-color: transparent;
  color: ${Colors.black};
  font-family: ${Type.fontFamily};
  font-weight: 500;
  transition: 0.2s;
  cursor: text;
  transition: all 0.28s ease;
  box-shadow: none;

  ::placeholder {
    opacity: 1;
    color: ${Colors.placeholderGray};
  }

  &:-webkit-autofill::first-line {
    ${Type.body10};
    color: ${Colors.black};
    font-family: ${Type.fontFamily};
    font-weight: 500;
  }

  @media (min-width: ${Breakpoints.tablet}) {
    line-height: 3.625rem; /* 58px */
    height: 3.625rem; /* 58px */
  }

  &:focus {
    border: 0;
    outline: 0;
    box-shadow: 0;
  }

  ${(props) =>
    !props.minimalStyle &&
    css`
      border: 1px solid ${Colors.black};
      padding: 0 1rem;
      padding-inline-end: ${(props) =>
        props.toggleVisibility ? '2.875rem' : '1rem'};
      height: 3.3125rem;

      @media (min-width: ${Breakpoints.tablet}) {
        line-height: 1.875rem;
        height: 4.125rem;
      }

      &:focus {
        border: 1px solid ${Colors.brand};
        outline: 1px solid ${Colors.brand};
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      ::placeholder {
        color: ${Colors.placeholderGrayDisabled};
      }
    `}
`

export const EyeToggle = styled(ButtonPrimitive)`
  position: absolute;
  right: 0;
  /* Center with half of input height minus half of icon height */
  top: calc(2.656rem / 2 - 0.773rem);
  padding: 0.125rem;
  line-height: 0.7;
  border-radius: 4px;

  [dir='rtl'] & {
    left: 0;
    right: unset;
  }

  path {
    transition: fill 200ms ease;
  }

  svg {
    width: 1.563rem;
    height: auto;
  }

  &:hover {
    path {
      fill: ${Colors.gray30};
    }
  }

  @media (min-width: ${Breakpoints.tablet}) {
    top: calc(3.625rem / 2 - 0.875rem);

    svg {
      width: 1.875rem;
    }
  }

  ${(props) =>
    !props.minimalStyle &&
    css`
      right: 0.5rem;
      top: calc(3.3125rem / 2 - 0.773rem);

      [dir='rtl'] & {
        left: 0.5rem;
        right: unset;
      }

      @media (min-width: ${Breakpoints.tablet}) {
        top: calc(4.125rem / 2 - 0.875rem);
      }
    `}
`

export const Error = styled.p`
  ${Inputs.error};
`

export const Labels = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 0 0.1875rem;
  height: 1.25rem;
  margin-inline-start: 0.25rem;
`

export const Label = styled.label`
  ${Inputs.label};

  ${(props) =>
    !props.minimalStyle &&
    css`
      margin-bottom: 1rem;
    `}
`

export const Bar = styled.span`
  ${Inputs.bar};

  ${TextInput}:focus ~ &::before {
    width: 100%;
    left: 0;
  }
`
