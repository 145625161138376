import { brand } from 'styles/Colors'

const CheckCircleFilled = ({ color = brand, ...rest }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="presentation"
      {...rest}
    >
      <circle cx="8" cy="8" r="8" fill={color} />
      <path
        d="M6.79004 12.9131C7.13184 12.9131 7.40039 12.7812 7.58594 12.5078L12.1318 5.5791C12.2637 5.37891 12.3174 5.18848 12.3174 5.0127C12.3174 4.53906 11.9561 4.1875 11.4678 4.1875C11.1357 4.1875 10.9258 4.30957 10.7207 4.62695L6.77051 10.8574L4.76855 8.40137C4.58301 8.17676 4.37793 8.0791 4.09473 8.0791C3.60156 8.0791 3.24512 8.43066 3.24512 8.90918C3.24512 9.12402 3.30859 9.30957 3.49414 9.51953L6.01367 12.5469C6.22363 12.7959 6.46777 12.9131 6.79004 12.9131Z"
        fill="white"
      />
    </svg>
  )
}

export default CheckCircleFilled
