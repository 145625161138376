import styled, { css } from 'styled-components'
import { Breakpoints, Inputs, Type } from 'styles'
import { Button } from '@all-turtles/mmhmm-ui-kit'

export const SubmitButton = styled(Button)`
  margin: 3.5rem auto 0;

  @media (min-width: ${Breakpoints.tablet}) {
    margin-inline-start: 0;
  }
`

export const emailStyles = css`
  margin-bottom: 0.5rem;

  label {
    ${Type.body30};
    font-weight: ${Type.fontWeights.bold};
    margin-bottom: 1rem;

    @media (min-width: ${Breakpoints.tablet}) {
      ${Type.body20};
    }
  }
`

export const websiteStyles = css`
  margin-bottom: 0.5rem;
`

export const roleStyles = css`
  width: 100%;

  label {
    ${Inputs.label};
    margin-bottom: 1rem;
  }
`
