import { modelName, apiFetch, createAsyncThunk } from './business'

const deleteDomain = createAsyncThunk(
  `${modelName}/deleteDomain`,
  async ({ domain }) => {
    const options = {
      path: `/businesses/me/admin/domains/${domain}`,
      method: 'DELETE'
    }
    const response = await apiFetch(options)

    return response
  }
)

deleteDomain.fulfilledReducer = (state, action) => {
  const domains = state.business.approvedDomains?.domains
  const filteredDomains = domains?.filter((el) => el !== action.meta.arg.domain)

  state.business.approvedDomains = {
    count: filteredDomains.length,
    domains: filteredDomains
  }
}

export default deleteDomain
