import styled from 'styled-components'

import { Colors, Type, Inputs } from 'styles'
import { ButtonPrimitive } from 'components'

const chipMargin = '0.2rem'

export const Chip = styled.div`
  font-size: 1.125rem; /* 18px */
  line-height: 1.5625rem; /* 25px */
  background-color: ${Colors.blue30};
  border-radius: 4px;
  padding: 0.1875rem 0.375rem;
  display: inline-flex;
  align-items: center;
  margin: ${chipMargin};
`

export const Chips = styled.div`
  margin-top: 0.25rem;
  margin-inline-start: -${chipMargin};
`

export const Input = styled.input`
  ${Type.body20};
  display: block;
  width: 100%;
  flex-grow: 1;
  background: transparent;
  border-radius: 0;
  border: 1px solid ${Colors.black};
  padding: 0.375rem 0.5rem;
  box-sizing: border-box;
  color: ${Colors.black};
  font-family: ${Type.fontFamily};
  font-weight: 500;
  transition: 0.2s;
  cursor: text;
  transition: all 0.28s ease;
  box-shadow: none;

  &:focus {
    border-color: ${Colors.brand};
    outline: 0;
    box-shadow: 0;
    border-radius: 0;
  }
`

export const Error = styled.p`
  ${Inputs.error};
`

export const Remove = styled(ButtonPrimitive)`
  background-color: ${Colors.blue40};
  border-radius: 10px;
  width: 1rem;
  height: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-inline-start: 0.25rem;
  font-weight: ${Type.fontWeights.bold};
`
