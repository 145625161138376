import { modelName, apiFetch, createAsyncThunk } from './business'

const deleteInvitation = createAsyncThunk(
  `${modelName}/deleteInvitation`,
  async ({ inviteId }) => {
    const options = {
      path: `/businesses/me/admin/invitations/${inviteId}`,
      method: 'DELETE'
    }
    const response = await apiFetch(options)

    return response
  }
)

deleteInvitation.fulfilledReducer = (state, action) => {
  const invitations = state.business.invitations?.items
  const items = invitations.filter((el) => el.id !== action.meta.arg.inviteId)

  state.business.invitations = {
    count: items.count,
    items
  }
}

export default deleteInvitation
