import { bool, func } from 'prop-types'
import { useSelector } from 'react-redux'
import { Button } from '@all-turtles/mmhmm-ui-kit'

import { useTranslation } from 'i18n'
import { Select, withInformed } from 'components'
import fields from 'data/businessCancelSubscriptionFields'
import * as S from './styles'

const BusinessCancelSubscriptionForm = ({ isLoading }) => {
  const { t } = useTranslation(['account', 'business'])
  const canModify = useSelector(
    (state) => state.api.user?.me?.businessPermissions?.canModifyBusinessStatus
  )

  return (
    <>
      <Select {...fields(t).reason} containerStyles={S.selectContainer} />
      <Button
        type="submit"
        variant="danger"
        loading={isLoading}
        disabled={!canModify}
      >
        {t('business:cancelBusiness')}
      </Button>
    </>
  )
}

BusinessCancelSubscriptionForm.propTypes = {
  /** Used by Informed via withInformed */
  onSubmit: func.isRequired,

  isLoading: bool.isRequired
}

export default withInformed(BusinessCancelSubscriptionForm)
