import styled from 'styled-components'
import { Button } from '@all-turtles/mmhmm-ui-kit'

import { Arrow } from 'components/Icons'
import { Colors, Type } from 'styles'

export const PanelWrapper = styled.div`
  width: 100%;
`

export const Body = styled.p`
  ${Type.body30};
  margin-top: 0.625rem;
  max-width: 33.75rem;
`

export const Table = styled.div`
  margin-top: 1.25rem;
  border-top: 1px solid ${Colors.gray10};
`

export const DomainRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 0;
  border-bottom: 1px solid ${Colors.gray10};

  &:last-child {
    border-bottom: 0;
    padding-bottom: 0;
  }
`

export const Domain = styled.p`
  ${Type.body30};
`

export const ToggleButton = styled(Button)`
  display: flex;
  align-items: center;
`

export const Spacer = styled.span`
  padding-inline-end: 0.75rem;
`

export const StyledArrow = styled(Arrow)`
  height: 1.5625rem;
  transform: rotate(90deg);
  transition: all 300ms ease;

  ${ToggleButton}[data-state='open'] & {
    transform: rotate(270deg);
  }
`
