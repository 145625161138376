import styled from 'styled-components'

import { Breakpoints, Sizes } from 'styles'

export const MaxWidthComponent = styled.div`
  width: 100vw;
  max-width: 100%;
  padding: 0 ${Sizes.xPaddingMobile};
  box-sizing: border-box;

  @media (min-width: ${Breakpoints.small}) {
    max-width: 90vw;
  }

  @media (min-width: ${Breakpoints.tablet}) {
    padding: 0 ${Sizes.xPadding};
    max-width: calc(${Sizes.maxWidth} + (${Sizes.xPadding} * 2));
  }
`
