import { combineReducers } from 'redux'

import dialog from './dialog/slice'
import toast from './toast/slice'
import api from './api/slice'
import apiStatus from './apiStatus/slice'
import ui from './ui/slice'
import notification from './notification/slice'
import pagination from './pagination/slice'
import { PURGE } from 'redux-persist'
/* hygen inject: import slice */

const appReducer = combineReducers({
  /* hygen inject: combineReducers */
  pagination,
  notification,
  dialog,
  toast,
  api,
  apiStatus,
  ui
})

const rootReducer = (state = {}, action) => {
  const stateCopy = JSON.parse(JSON.stringify(state))

  if (action.type === PURGE) {
    return appReducer(undefined, action)
  }

  if (action.type === 'LOGOUT') {
    delete stateCopy.api
    delete stateCopy.ui.businessInfo
  }
  return appReducer(stateCopy, action)
}

export default rootReducer
