import { useSelector } from 'react-redux'

import { Link, useTranslation } from 'i18n'
import getFormattedHistory from 'utilities/getPaymentHistory'
import { Loading, MobilePaymentHistory, PaymentHistoryTable } from 'components'
import * as S from './styles'

const InvoicesSection = () => {
  const { t } = useTranslation(['business', 'account'])
  const payments = useSelector((state) => state.api.business?.paymentHistory)

  const getPaymentsTable = () => {
    if (!payments) {
      return <Loading visible />
    }

    const paymentHistory =
      payments.count > 0 &&
      getFormattedHistory({ items: payments.items.slice(0, 3) })

    return paymentHistory ? (
      <>
        <PaymentHistoryTable paymentHistory={paymentHistory} isBusiness />
        <MobilePaymentHistory data={paymentHistory} />
      </>
    ) : (
      <S.NoResults>{t('account:noPayments')}</S.NoResults>
    )
  }

  return (
    <S.InvoicesSection>
      <S.FlexRow>
        <S.Header>{t('invoices')}</S.Header>
        <S.EndLinks>
          <S.RefundWrapper>
            <S.ArrowLink
              href={t('account:requestRefundUrl')}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('account:requestRefund')}
            </S.ArrowLink>
          </S.RefundWrapper>
          <Link href="/business/payment-history" passHref>
            <S.ArrowLink>
              {t('viewAll')}
              <S.StyledArrow height="20" width="11" role="presentation" />
            </S.ArrowLink>
          </Link>
        </S.EndLinks>
      </S.FlexRow>
      {getPaymentsTable()}
    </S.InvoicesSection>
  )
}

export default InvoicesSection
