import styled from 'styled-components'

import { Colors, Type } from 'styles'

export const TextBadgeComponent = styled.p`
  height: 100%;
  line-height: 1rem;
  font-size: 0.8125rem;
  letter-spacing: 0.04rem;
  font-weight: ${Type.fontWeights.bold};
  text-transform: uppercase;
  color: ${Colors.midnight};
  background-color: ${Colors.green};
  border-radius: 3px;
  padding: 0.25rem 0.625rem;

  [lang='ar'] & {
    letter-spacing: 0;
  }
`
