import { useSelector, useDispatch } from 'react-redux'
import { shape } from 'prop-types'
import isEqual from 'lodash/isEqual'
import { captureException } from 'utilities/sentry'
import { useFormApi } from 'informed'
import { Button } from '@all-turtles/mmhmm-ui-kit'
import { useTranslation } from 'i18n'
import { getAuthFields } from 'data/authFields'
import apiStatus from 'store/selectors/apiStatus'
import { setDialogVisibility } from 'store/dialog/slice'
import { getSignInAppUrl } from 'utilities/getSignInAppUrl'
import { Select, TextField, HandleAction, withInformed } from 'components'
import * as S from './styles'

const validateCurrentPassword = (t, currentEmail) => (value, values) =>
  currentEmail !== values.username && !value
    ? t('common:fieldRequired')
    : undefined

const apiStatusSelector = (state) =>
  apiStatus({
    state,
    resource: 'user',
    api: 'update'
  })

const EditAccountForm = (props) => {
  const { t } = useTranslation(['account', 'common'])
  const status = useSelector(apiStatusSelector, isEqual)
  const currentUser = useSelector((state) => state.api.user?.me, isEqual)
  const formApi = useFormApi()
  const dispatch = useDispatch()

  const hasLinkedIdp = !!currentUser?.linkedIdPID
  const cantUpdateEmail = currentUser?.businessRole === 'member' || hasLinkedIdp

  const authFields = getAuthFields(t)

  const nameField = {
    ...authFields.name,
    label: t('name'),
    placeholder: t('namePlaceholder')
  }

  const localeOptions = Object.keys(props.availableLocales).map((locale) => ({
    value: locale,
    display: props.availableLocales[locale]
  }))

  const languageField = {
    field: 'locale',
    label: t('emailLanguage'),
    options: localeOptions
  }

  const handleError = () => {
    if (currentUser.error?.message?.startsWith('AliasExists')) {
      formApi.setError('username', t('aliasExistsException'))
    } else if (currentUser.error?.name === 'NotAuthorizedException') {
      formApi.setError('currentPassword', t('invalidPassword'))
    } else {
      captureException(currentUser.error)

      const options = {
        name: 'ErrorDialog',
        description: t('common:somethingWentWrong'),
        showSupportLink: true
      }

      dispatch(setDialogVisibility(options))
    }
  }

  const resetPasswordUrl = getSignInAppUrl({
    path: '/forgot-password',
    context: 'edit-account'
  })

  return (
    <>
      <HandleAction
        resource="user"
        api="update"
        toastDescription={t('accountEditSuccess')}
        onError={handleError}
      />
      <div>
        <TextField
          {...nameField}
          containerStyles={S.inputStyles}
          disabled={hasLinkedIdp}
        />
        <TextField
          {...authFields.email}
          containerStyles={S.inputWithErrMsgStyles}
          disabled={cantUpdateEmail}
        />
        <Select {...languageField} containerStyles={S.inputStyles} size="lg" />
        {!hasLinkedIdp && (
          <>
            <TextField
              {...authFields.currentPassword}
              validate={validateCurrentPassword(t, currentUser?.email)}
            />
            <S.A href={resetPasswordUrl}>{t('forgotPassword')}</S.A>
          </>
        )}
        <S.ActionWrap>
          <S.ActionRow>
            <S.StyledBack />
            <Button isLoading={status.loading}>{t('common:save')}</Button>
          </S.ActionRow>
        </S.ActionWrap>
      </div>
    </>
  )
}

EditAccountForm.propTypes = {
  availableLocales: shape({})
}

export default withInformed(EditAccountForm)
