import * as S from './styles'
import { useTranslation } from 'i18n'
import { ForgotPasswordForm } from 'components'

const ForgotPassword = ({ isLoading, handleSubmit }) => {
  const { t } = useTranslation('account')

  return (
    <S.ForgotPasswordComponent>
      <S.H1>{t('forgotPassword')}</S.H1>
      <S.P>{t('forgotPasswordInstructions')}</S.P>
      <ForgotPasswordForm onSubmit={handleSubmit} isLoading={isLoading} />
    </S.ForgotPasswordComponent>
  )
}

export default ForgotPassword
