import styled from 'styled-components'

import { Colors } from 'styles'

export const cellPadding = '18px 16px'

export const TableDataComponent = styled.div`
  width: 100%;

  table {
    width: 100%;
  }
`

export const Title = styled.h3`
  color: ${Colors.brand};
`

export const TableHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
