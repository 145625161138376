const Discover = (props) => {
  return (
    <svg
      width="128"
      height="84"
      viewBox="0 0 128 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="Discover card"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 6C0 2.68629 2.68629 0 6 0H122C125.314 0 128 2.68629 128 6V78C128 81.3137 125.314 84 122 84H6C2.68629 84 0 81.3137 0 78V6Z"
        fill="url(#paint0_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 6C0 2.68629 2.68629 0 6 0H122C125.314 0 128 2.68629 128 6V78C128 81.3137 125.314 84 122 84H6C2.68629 84 0 81.3137 0 78V6Z"
        fill="#E5E5E5"
        fillOpacity="0.01"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.5582 45.565C24.6575 46.3838 23.4871 46.741 21.6346 46.741H20.8651V36.9598H21.6346C23.4871 36.9598 24.6115 37.2935 25.5582 38.1564C26.5498 39.0444 27.146 40.4215 27.146 41.8381C27.146 43.2581 26.5498 44.677 25.5582 45.565ZM22.2087 34.4541H18V49.2444H22.1866C24.4127 49.2444 26.0205 48.7164 27.4311 47.537C29.1081 46.141 30.0997 44.0364 30.0997 41.8598C30.0997 37.4947 26.8587 34.4541 22.2087 34.4541Z"
        fill="#212D34"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.418 49.2445H34.2853V34.4536H31.418V49.2445Z"
        fill="#212D34"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.2964 40.1295C39.5757 39.4889 39.0708 39.0666 39.0708 38.2672C39.0708 37.3358 39.9709 36.6278 41.2067 36.6278C42.0653 36.6278 42.7718 36.9832 43.5186 37.8255L45.019 35.8489C43.7861 34.7638 42.3112 34.2095 40.6995 34.2095C38.0985 34.2095 36.1149 36.0266 36.1149 38.4472C36.1149 40.4849 37.0383 41.5283 39.7313 42.5032C40.854 42.902 41.4253 43.1672 41.7132 43.3455C42.2862 43.722 42.573 44.2552 42.573 44.8763C42.573 46.0746 41.6263 46.9626 40.3469 46.9626C38.9794 46.9626 37.8776 46.274 37.2177 44.9895L35.3652 46.7843C36.6862 48.7358 38.2729 49.6003 40.4548 49.6003C43.434 49.6003 45.5244 47.6066 45.5244 44.7438C45.5244 42.3935 44.5579 41.33 41.2964 40.1295Z"
        fill="#212D34"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.4297 41.8596C46.4297 46.2071 49.8223 49.5779 54.1884 49.5779C55.4224 49.5779 56.4798 49.3339 57.7832 48.7162V45.3202C56.6371 46.4745 55.6217 46.9402 54.3218 46.9402C51.434 46.9402 49.3845 44.8333 49.3845 41.8379C49.3845 38.9985 51.4988 36.7585 54.1884 36.7585C55.5559 36.7585 56.5911 37.2493 57.7832 38.4225V35.0282C56.5247 34.3859 55.49 34.1196 54.2559 34.1196C49.9121 34.1196 46.4297 37.5591 46.4297 41.8596Z"
        fill="#212D34"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M80.517 44.389L76.5974 34.4541H73.4648L79.7027 49.6244H81.2457L87.5959 34.4541H84.4878L80.517 44.389Z"
        fill="#212D34"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M88.8926 49.2445H97.0238V46.7405H91.7576V42.7479H96.8301V40.2428H91.7576V36.9599H97.0238V34.4536H88.8926V49.2445Z"
        fill="#212D34"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M102.623 41.2633H101.786V36.7839H102.669C104.455 36.7839 105.425 37.5365 105.425 38.9759C105.425 40.4628 104.455 41.2633 102.623 41.2633ZM108.376 38.8205C108.376 36.0513 106.48 34.4536 103.174 34.4536H98.9219V49.2445H101.786V43.3028H102.16L106.129 49.2445H109.655L105.027 43.0136C107.187 42.5713 108.376 41.085 108.376 38.8205Z"
        fill="#212D34"
      />
      <g filter="url(#filter0_i)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M74.3582 41.9002C74.3582 46.2459 70.8571 49.7688 66.5382 49.7688C62.2193 49.7688 58.7188 46.2459 58.7188 41.9002C58.7188 37.5545 62.2193 34.0322 66.5382 34.0322C70.8571 34.0322 74.3582 37.5545 74.3582 41.9002Z"
          fill="url(#paint1_linear)"
        />
      </g>
      <defs>
        <filter
          id="filter0_i"
          x="58.7188"
          y="34.0322"
          width="15.6394"
          height="15.7366"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="2" dy="2" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.314068 0 0 0 0 0.00146228 0 0 0 0 0.00146228 0 0 0 0.5 0"
          />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow" />
        </filter>
        <linearGradient
          id="paint0_linear"
          x1="-64"
          y1="42"
          x2="13.0621"
          y2="159.428"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#EDF1F3" />
          <stop offset="1" stopColor="#D3DADF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="76.1175"
          y1="43.4043"
          x2="66.8381"
          y2="32.4298"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFA700" />
          <stop offset="1" stopColor="#E75A10" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Discover
