import { modelName, apiFetch, createAsyncThunk } from './business'

const getCustomRooms = createAsyncThunk(
  `${modelName}/getCustomRooms`,
  async (body) => {
    const options = {
      path: '/businesses/me/admin/rooms',
      method: 'GET',
      body
    }
    const response = await apiFetch(options)

    return response
  }
)

getCustomRooms.storeKey = 'customRooms'

export default getCustomRooms
