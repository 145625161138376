import styled, { css } from 'styled-components'

export const ChangePlanFormComponent = styled.div``

export const StyledRadioPill = css`
  margin-bottom: 1.25rem;
`

export const BackWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
