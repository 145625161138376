import { modelName, apiFetch, createAsyncThunk } from './business'

const getBusinessSettings = createAsyncThunk(
  `${modelName}/getBusinessSettings`,
  async (body) => {
    const options = {
      path: '/businesses/me/admin',
      method: 'GET',
      body
    }
    const response = await apiFetch(options)

    return response
  }
)

getBusinessSettings.storeKey = 'businessSettings'

export default getBusinessSettings
