import styled from 'styled-components'

import { Colors, Type } from 'styles'

export const DialogWrap = styled.div`
  max-width: 28rem;
`

export const H1 = styled.h1`
  margin-bottom: 2rem;
`

export const Domain = styled.p`
  font-weight: ${Type.fontWeights.bold};
  padding: 1.5rem 0;
  margin: 1.5rem 0;
  border-bottom: 1px solid ${Colors.gray10};
  border-top: 1px solid ${Colors.gray10};
`

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
`
