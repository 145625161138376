import styled from 'styled-components'
import { Tooltip } from 'components'

export const StyledTooltip = styled(Tooltip)`
  padding: 0;

  .tippy-content {
    padding: 0;
  }
`
