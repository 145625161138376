import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Title = styled.h1`
  max-width: 39rem;
  margin-bottom: 2rem;
  text-align: center;
`

export const Description = styled.p`
  margin-bottom: 2rem;
`

export const Support = styled.p`
  margin-top: 5rem;
`
