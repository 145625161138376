import * as S from './styles'
import { useTranslation } from 'i18n'
import { ButtonDanger, Select } from 'components'
import fields from 'data/cancelSubscriptionFields'

const CancelSubscriptionForm = ({ onSubmit, isLoading }) => {
  const { t } = useTranslation('account')

  return (
    <S.CancelSubscriptionFormComponent onSubmit={onSubmit}>
      <Select containerStyles={S.selectContainer} {...fields(t).reason} />
      <ButtonDanger type="submit" isLoading={isLoading}>
        {t('cancelSubscription')}
      </ButtonDanger>
    </S.CancelSubscriptionFormComponent>
  )
}

export default CancelSubscriptionForm
