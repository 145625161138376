import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'
import { useRouter } from 'next/router'
import { getMe } from 'api/user'
import apiStatus from 'store/selectors/apiStatus'
import { isAppStoreSubscription } from 'utilities/subscription'

const apiStatusSelector = (state) =>
  apiStatus({
    state,
    resource: 'user',
    api: 'getMe'
  })

const withAppStoreBillingGuard = (C) => () => {
  const dispatch = useDispatch()
  const status = useSelector(apiStatusSelector, isEqual)

  const subscriptionService = useSelector(
    (state) => state.api.user?.me?.subscriptionService
  )
  const currentUser = useSelector((state) => state.api.user?.me)

  const router = useRouter()
  const appStoreSubscription = isAppStoreSubscription(subscriptionService)

  React.useEffect(() => {
    if (!currentUser) dispatch(getMe())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    if (appStoreSubscription && !status.loading) router.push('/')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appStoreSubscription, status.loading])

  return !currentUser || appStoreSubscription ? null : <C />
}

export default withAppStoreBillingGuard
