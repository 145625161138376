import { createSlice } from '@reduxjs/toolkit'

const slice = createSlice({
  name: 'ui',
  initialState: {
    hideAvailableDomains: false
  },
  reducers: {
    setForgotPasswordEmail(state, action) {
      state.forgotPasswordEmail = action.payload
    },
    setBusinessInfo(state, action) {
      state.businessInfo = action.payload
    },
    setNextRoute(state, action) {
      state.nextRoute = action.payload
    },
    setHideAvailableDomains(state, action) {
      state.hideAvailableDomains = action.payload
    }
  }
})

export const {
  setForgotPasswordEmail,
  setBusinessInfo,
  setNextRoute,
  setHideAvailableDomains
} = slice.actions

export default slice.reducer
