import { useTranslation } from 'i18n'

const X = (props) => {
  const { t } = useTranslation('common')
  return (
    <svg
      {...props}
      width="102"
      height="102"
      viewBox="0 0 102 102"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby="close-title"
    >
      <title id="close-title">{t('close')}</title>
      <path
        d="M2.0081 89.3738C-0.628596 91.9617 -0.726296 96.6492 2.0569 99.3836C4.7913 102.069 9.43 102.02 12.0179 99.4324L50.6897 60.7605L89.3617 99.3836C92.0467 102.069 96.6367 102.118 99.3717 99.3348C102.057 96.6004 102.106 92.0594 99.4207 89.3738L60.7483 50.702L99.4207 12.0301C102.106 9.3445 102.106 4.7547 99.3717 2.0691C96.5877 -0.665195 92.0467 -0.665193 89.3617 2.02031L50.6897 40.6434L12.0179 1.9715C9.43 -0.616398 4.7425 -0.714093 2.0569 2.02031C-0.677396 4.80351 -0.628596 9.3934 2.0081 12.0301L40.68 50.702L2.0081 89.3738Z"
        fill="black"
      />
    </svg>
  )
}

export default X
