import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react'
import isEqual from 'lodash/isEqual'

import { setDialogVisibility } from 'store/dialog/slice'
import usePrevious from 'hooks/usePrevious'
import apiStatus from 'store/selectors/apiStatus'
import { forgotPassword } from 'api/auth'
import { setForgotPasswordEmail } from 'store/ui/slice'
import Display from './Display'
import { useRouter } from 'next/router'
import { useTranslation } from 'i18n'

const apiStatusSelector = (state) =>
  apiStatus({
    state,
    resource: 'auth',
    api: 'forgotPassword'
  })

const Container = () => {
  const dispatch = useDispatch()
  const status = useSelector(apiStatusSelector, isEqual)
  const prevStatus = usePrevious(status)
  const router = useRouter()
  const { t } = useTranslation('common')

  const handleSubmit = (values) => {
    dispatch(forgotPassword(values))
    dispatch(setForgotPasswordEmail(values.username))
  }

  useEffect(() => {
    if (prevStatus?.loading && status.error) {
      const options = {
        name: 'ErrorDialog',
        description: t('common:somethingWentWrong'),
        showSupportLink: true
      }

      dispatch(setDialogVisibility(options))
    }

    if (prevStatus?.loading && status.success) {
      router.push('/new-password')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  return <Display isLoading={status.loading} handleSubmit={handleSubmit} />
}

export default Container
