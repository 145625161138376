import PropTypes from 'prop-types'
import { useTransition } from 'react-spring/web.cjs'

import * as S from './styles'
import { dispatch } from 'store'
import { setDialogVisibility } from 'store/dialog/slice'

function Dialog({
  children,
  visible,
  label,
  springConfig,
  transparentContent,
  disableClose
}) {
  const transitions = useTransition(visible, null, {
    from: { opacity: 0, y: 10 },
    enter: { opacity: 1, y: 60 },
    leave: { opacity: 0, y: 10 },
    config: { ...springConfig }
  })

  const handleOnDismiss = () => {
    if (disableClose) return
    dispatch(setDialogVisibility({ name: null }))
  }

  return (
    <>
      {transitions.map(
        ({ item, props: styles }) =>
          item && (
            <S.Overlay
              key={item}
              style={{ opacity: styles.opacity }}
              onDismiss={handleOnDismiss}
            >
              <S.Content
                $transparentContent={transparentContent}
                aria-labelledby={label}
                style={{
                  transform: styles.y.interpolate(
                    (value) => `translate3d(0px, ${value}px, 0px)`
                  )
                }}
              >
                {!disableClose && <S.StyledCloseDialog />}
                {children}
              </S.Content>
            </S.Overlay>
          )
      )}
    </>
  )
}

Dialog.propTypes = {
  /** The name of the Dialog */
  label: PropTypes.string.isRequired,

  /** Show and hide the dialog */
  visible: PropTypes.bool,

  /** Optional extra animation config */
  springConfig: PropTypes.object,

  /** Sets the background of the content to transparent instead of white */
  transparentContent: PropTypes.bool,

  /**
   * Prevents the user from closing the dialog manually.
   * Hides the "X" button and disables clicking outside to close.
   */
  disableClose: PropTypes.bool
}

Dialog.defaultProps = {
  visible: false,
  label: '',
  springConfig: {},
  transparentContent: false,
  disableClose: false
}

export default Dialog
