import { createAsyncThunk } from '@reduxjs/toolkit'

import apiFetch from '../apiFetch'

const sendDeletionVerificationCode = createAsyncThunk(
  'user/sendDeletionVerificationCode',
  async ({ userId }) => {
    const options = {
      path: `/users/${userId}/initiate-deletion`,
      method: 'POST',
      noContentAllowed: true
    }

    const response = await apiFetch(options)

    return { requestInitiated: Date.now(), ...response }
  }
)

sendDeletionVerificationCode.storeKey = 'accountDeletion'

export default sendDeletionVerificationCode
