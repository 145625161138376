import { useEffect, useState } from 'react'
import { bool, func } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { RadioGroup, useFormApi, useFormState } from 'informed'
import isEqual from 'lodash/isEqual'
import { captureException } from 'utilities/sentry'

import { getMe } from 'api/user'
import apiStatus from 'store/selectors/apiStatus'
import { businessFields } from 'data'
import { useTranslation } from 'i18n'
import {
  HandleAction,
  InlineSuccessMessage,
  Radio,
  ResetInviteLinks,
  withInformed
} from 'components'
import * as S from './styles'

const apiStatusSelector = (state, api) =>
  apiStatus({
    state,
    resource: 'business',
    api
  })

const ShareLinkSettingsForm = ({
  linksDisabled,
  handleConfirmView,
  resetSuccess
}) => {
  const formApi = useFormApi()
  const formState = useFormState()
  const dispatch = useDispatch()
  const { t } = useTranslation('business')

  const status = useSelector(
    (state) => apiStatusSelector(state, 'updateBusiness'),
    isEqual
  )

  const canAdmin = useSelector(
    (state) => state.api.user?.me?.businessPermissions?.canAdmin
  )
  const { enableLinksGroup } = businessFields({ t })

  const [savedSuccess, setSavedSuccess] = useState(false)

  useEffect(() => {
    /**
     * If saved success has been set, set timeout to remove after 5 seconds,
     * to only show success message on button temporarily
     */
    const timeout = setTimeout(() => {
      if (savedSuccess) setSavedSuccess(false)
    }, 5000)

    return () => clearTimeout(timeout)
  }, [savedSuccess])

  const handleSuccess = async () => {
    setSavedSuccess(true)
    try {
      await dispatch(getMe())
      // Reset formState so it's no longer "dirty" to set button state based on updates
      formApi.reset()
    } catch (error) {
      captureException(error)
    }
  }

  const handleResetClick = () => {
    handleConfirmView('resetAllLinks')
  }

  const getButtonCopy = () => {
    if (status?.loading) {
      return t('common:saving')
    } else if (savedSuccess) {
      return (
        <span>
          <S.StyledCheck />
          {t('common:saved')}
        </span>
      )
    }

    return t('common:save')
  }

  const handleError = (error) => {
    captureException(error)
  }

  return (
    <>
      <HandleAction
        resource="business"
        api="updateBusiness"
        onSuccess={handleSuccess}
        onError={handleError}
        stayOnPage
      />
      <RadioGroup field={enableLinksGroup.field} required>
        {enableLinksGroup.fields.map((field) => (
          <Radio key={field.id} {...field} />
        ))}
      </RadioGroup>
      <S.ButtonRow>
        <S.SaveButton
          type="submit"
          variant={savedSuccess ? 'success' : 'primary'}
          loading={status?.loading}
          disabled={!canAdmin || !(formState.dirty || savedSuccess)}
          minWidth="10.5rem"
        >
          {getButtonCopy()}
        </S.SaveButton>
        {!linksDisabled && (
          <S.ResetWrapper>
            <ResetInviteLinks handleClick={handleResetClick} />
            <InlineSuccessMessage
              visible={resetSuccess}
              message={t('resetLinksSuccessShort')}
            />
          </S.ResetWrapper>
        )}
      </S.ButtonRow>
    </>
  )
}

ShareLinkSettingsForm.propTypes = {
  handleConfirmView: func,
  linksDisabled: bool,
  resetSuccess: bool
}

export default withInformed(ShareLinkSettingsForm)
