import styled from 'styled-components'

import { Breakpoints, Type } from 'styles'
import GetSupportIllustration from '../GetSupportIllustration/GetSupportIllustration'

export const GetSupportComponent = styled.div`
  position: relative;
  box-sizing: border-box;
  padding-bottom: 0.75rem;
  padding-inline-end: 7.5rem;

  @media (min-width: ${Breakpoints.tablet}) {
    max-width: 30rem;
  }
`

export const Title = styled.p`
  ${Type.body30};
  margin-top: 0.5rem;
`

export const A = styled.a`
  &:hover {
    text-decoration: underline;
  }
`

export const Illustration = styled(GetSupportIllustration)`
  position: absolute;
  bottom: -2.75rem;
  right: 0;
  width: 7.25rem;
  height: 7.25rem;

  [dir='rtl'] & {
    left: 0;
    right: unset;
    transform: scaleX(-1);
  }
`
