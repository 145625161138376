import styled from 'styled-components'
import ButtonPrimitive from '../ButtonPrimitive/ButtonPrimitive'
import Loading from '../Loading/Loading'

import { Colors, Type } from 'styles'

export const DownloadReceiptComponent = styled(ButtonPrimitive)`
  ${Type.body30};
  color: ${Colors.brand};
  display: flex;

  &:hover {
    text-decoration: underline;
  }
`

export const LoadingSpinner = styled(Loading)`
  width: 1.125rem;
  height: 1.125rem;
  margin-inline-start: 0.1875rem;
`
