import styled from 'styled-components'
import X from 'components/Icons/X'
import ButtonPrimitive from 'components/ButtonPrimitive/ButtonPrimitive'
import { Colors } from 'styles'

export const CloseDialogComponent = styled(ButtonPrimitive)`
  padding: 0.625rem;
  position: absolute;
  top: 0.875rem;
  right: 0.875rem;
  opacity: 0.6;
  transition: all 150ms ease;
  display: flex;

  &:hover {
    opacity: 1;
  }

  &:focus {
    outline: none;
    border: 2px solid ${Colors.brand};
  }

  &:focus:not(.focus-visible) {
    outline: none;
    border: none;
  }

  [dir='rtl'] & {
    right: unset;
    left: 0.875rem;
  }
`

export const StyledX = styled(X)`
  width: 0.875rem;
  height: 0.875rem;
`
