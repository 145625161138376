import { number, bool } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'

import apiStatus from 'store/selectors/apiStatus'
import { setPagination } from 'store/pagination/slice'
import { getInvitations } from 'api/business'
import { useTranslation } from 'i18n'
import { TableData, InvitationActions, TableSearchBar } from 'components'
import useFetchWithPagination from 'hooks/useFetchWithPagination'
import useDebouncedSearchInput from 'hooks/useDebouncedSearchInput'
import NoResults from '../BusinessMembersTable/NoResults'

const apiStatusSelector = (state) =>
  apiStatus({
    state,
    resource: 'business',
    api: 'getInvitations'
  })

const BusinessInvitationsTable = ({ limit, withSearch, minimalNoResults }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['common', 'business'])
  const { search, searchInputProps } = useDebouncedSearchInput()
  const businessInvitations = useSelector(
    (state) => state.api.business?.invitations?.items
  )
  const status = useSelector(apiStatusSelector, isEqual)

  useFetchWithPagination({
    fetchAction: getInvitations,
    paginationKey: 'getInvitations',
    limit,
    search
  })

  if (!businessInvitations) return null

  const transformStatus = (data) => {
    // data column is a bool for "expired" so update displayed value to reflect invite's "Status"
    return t(`business:${data.value ? 'expired' : 'sent'}`)
  }

  const data =
    limit && businessInvitations?.length > limit
      ? businessInvitations.slice(0, limit)
      : businessInvitations

  const handleSort = (orderBy, orderDirection) => {
    // Update stored sort for use in pagination component
    dispatch(
      setPagination({ getInvitations: { orderBy, orderDirection, page: 0 } })
    )
  }

  return (
    <>
      {withSearch && (
        <TableSearchBar {...searchInputProps} loading={status.loading} />
      )}
      {data.length > 0 ? (
        <TableData
          data={data}
          table="business-invitations"
          itemId="id"
          title="Invitations"
          dateKeys={['created']}
          includeKeys={['email', 'created', 'expired', 'actions']}
          customCellComponents={{
            actions: (invite) => <InvitationActions invite={invite} />
          }}
          headerOverrides={{
            email: t('email'),
            created: t('business:invited'),
            expired: t('business:status')
          }}
          disableSortForColumns={['actions', 'expired']}
          transformKeys={['expired']}
          dataTransform={transformStatus}
          handleSort={handleSort}
        />
      ) : (
        <NoResults
          subtitle={search ? t('business:noInvitationsFound') : null}
          loading={status.loading}
          noText={minimalNoResults}
        />
      )}
    </>
  )
}

BusinessInvitationsTable.propTypes = {
  limit: number,
  withSearch: bool,
  minimalNoResults: bool
}

BusinessInvitationsTable.defaultProps = {
  limit: 20,
  withSearch: false,
  minimalNoResults: false
}

export default BusinessInvitationsTable
