import { captureException } from 'utilities/sentry'

const getChargebeeToken = async (cardRef) => {
  try {
    const data = await cardRef.current.tokenize({})
    return data.token
  } catch (error) {
    console.error('tokenize error:', error)
    if (error.code !== 'validationFailed') {
      error.code = 'chargebeeTokenizeError'
      captureException(error)
    }

    throw error
  }
}

export default getChargebeeToken
