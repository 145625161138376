import { modelName, apiFetch, createAsyncThunk } from './business'

const updateMemberEmail = createAsyncThunk(
  `${modelName}/updateMemberEmail`,
  async ({ userId, email }) => {
    const options = {
      path: `/businesses/me/admin/members/${userId}`,
      method: 'PATCH',
      body: { email: email.toLowerCase() }
    }
    const response = await apiFetch(options)

    return response
  }
)

updateMemberEmail.fulfilledReducer = (state, action) => {
  const members = JSON.parse(JSON.stringify(state.business.members?.items))
  const member = members.find((el) => el.id === action.meta.arg.userId)

  const index = members.indexOf(member)
  member.email = action.meta.arg.email

  members[index] = member

  state.business.members.items = members
}

export default updateMemberEmail
