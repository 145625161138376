import styled from 'styled-components'
import { Checkbox } from 'informed'

import { Colors } from 'styles'
import { Check } from 'components/Icons'

export const CheckboxComponent = styled.div``

export const Border = styled.div`
  width: 1.0625rem;
  height: 1.0625rem;
  border: 3px solid ${Colors.brand};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CheckboxWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const CheckboxAndLabel = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover ${Border} {
    border: 3px solid ${Colors.brand};
  }
`

export const StyledCheck = styled(Check)`
  width: 0.875rem;
  height: 0.875rem;
  opacity: 0;
  transition: opacity 100ms ease;
`

export const StyledCheckbox = styled(Checkbox)`
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 2;

  &:checked ~ ${Border} {
    border: 3px solid ${Colors.brand};

    ${StyledCheck} {
      opacity: 1;
    }
  }

  &:focus.focus-visible ~ ${Border} {
    outline: 2px solid ${Colors.brand};
    outline-offset: 2px;
  }
`

export const Label = styled.label`
  margin-inline-start: 0.625rem;
  cursor: pointer;
`
