import { useEffect, useState } from 'react'
import { string } from 'prop-types'
import { useTranslation } from 'i18n'

import { ONE_NUM, ONE_LOWER_AND_UPPER } from 'utilities/regex'
import * as S from './styles'

const propTypes = {
  /* Requirements are only checked against input value of new password */
  newVal: string
}

const defaultProps = {
  newVal: ''
}

const PasswordRequirements = ({ newVal }) => {
  const { t } = useTranslation('account')

  const [reqsMet, setReqsMet] = useState({})

  useEffect(() => {
    if (newVal) {
      const newReqs = {
        casing: ONE_LOWER_AND_UPPER.test(newVal),
        number: ONE_NUM.test(newVal),
        length: newVal.length > 7
      }

      setReqsMet(newReqs)
    } else {
      setReqsMet({})
    }
  }, [newVal])

  return (
    <S.PasswordRequirementsComponent>
      <S.Label>{t('passwordRequirements')}</S.Label>
      <ul>
        <S.ListItem isValid={reqsMet.casing}>
          {t('oneLowerAndUpperReq')}
        </S.ListItem>
        <S.ListItem isValid={reqsMet.number}>{t('oneNumberReq')}</S.ListItem>
        <S.ListItem isValid={reqsMet.length}>
          {t('passwordLengthReq')}
        </S.ListItem>
      </ul>
    </S.PasswordRequirementsComponent>
  )
}

PasswordRequirements.propTypes = propTypes
PasswordRequirements.defaultProps = defaultProps

export default PasswordRequirements
