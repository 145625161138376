import styled from 'styled-components'

import { Colors, Type } from 'styles'

export const RedBanner = styled.div`
  background-color: ${Colors.red10};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-bottom: 2rem;
  padding: 0 1.5rem;
`
export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 1.875rem;
  width: 1.875rem;
  height: 1.875rem;
  background-color: ${Colors.red};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
`

export const MessageWrapper = styled.div`
  padding: 1.125rem 2rem;
`

export const Text = styled.p`
  ${Type.body30};
  color: ${Colors.midnight};
  text-align: center;
`

export const StyledLink = styled.a`
  font-size: 0.8125rem;
  line-height: 1rem;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }
`
