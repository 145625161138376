import styled from 'styled-components'

import { Type } from 'styles'

export const CheckEmailComponent = styled.div`
  max-width: 37.5rem;
  text-align: center;
  margin: 0 auto;
`

export const Body = styled.div`
  margin: 3.438rem auto 1.188rem;
`

export const Email = styled.bdi`
  font-weight: ${Type.fontWeights.bold};
  white-space: nowrap;
`
