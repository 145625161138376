import { Link, useTranslation } from 'i18n'
import * as S from './styles'

const UpgradeUpsell = () => {
  const { t } = useTranslation('account')

  return (
    <S.UpgradeUpsellComponent>
      <Link href="/upgrade" passHref>
        <S.A>{t('upgrade')}</S.A>
      </Link>
    </S.UpgradeUpsellComponent>
  )
}

export default UpgradeUpsell
