import { modelName, apiFetch, createAsyncThunk } from './business'

const getSubscription = createAsyncThunk(
  `${modelName}/getSubscription`,
  async (body) => {
    const options = {
      path: '/businesses/me/admin/subscription',
      method: 'GET',
      body
    }
    const response = await apiFetch(options)

    return response
  }
)

getSubscription.storeKey = 'subscription'

export default getSubscription
