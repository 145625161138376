import styled from 'styled-components'
import { Type, Colors } from 'styles'

export const Header = styled.h1`
  ${Type.header3}

  padding: 10px 0;
  margin: 0;
`

export const ErrorPageContainer = styled.div`
  max-width: 680px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

export const Content = styled.div`
  ${Type.body20}

  a {
    color: ${Colors.brand};
    text-decoration: underline;
  }
`
