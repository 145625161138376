import { bool, oneOfType, string } from 'prop-types'

import * as S from './styles'

const Radio = ({ field, id, label, value }) => {
  const radioId = id || `${field}-${value}`
  return (
    <S.RadioComponent>
      <S.HiddenRadio id={radioId} value={value} />
      <S.VisibleRadio>
        <S.SelectedRadioDot />
      </S.VisibleRadio>
      <S.Label htmlFor={radioId}>{label}</S.Label>
    </S.RadioComponent>
  )
}

Radio.propTypes = {
  /** The name of the radio */
  label: string.isRequired,
  value: oneOfType([string, bool]).isRequired,
  /** name of radio group */
  field: string,
  id: string
}

export default Radio
