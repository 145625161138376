import { oneOf } from 'prop-types'
import { Button } from '@all-turtles/mmhmm-ui-kit'

import { signOut } from 'utilities/auth'
import { useTranslation } from 'i18n'

const LogOut = ({ variant }) => {
  const { t } = useTranslation('common')

  return (
    <Button variant={variant} onClick={signOut}>
      {t('logOut')}
    </Button>
  )
}

LogOut.propTypes = {
  variant: oneOf(['link', 'linkCaps'])
}

LogOut.defaultProps = {
  variant: 'link'
}

export default LogOut
