import styled from 'styled-components'

import { Breakpoints, Colors, Type } from 'styles'

export const Panel = styled.div`
  color: ${Colors.midnight};
  background-color: ${Colors.blue40};
  padding: 1.5rem 1.25rem;
  margin-bottom: 1rem;

  @media (min-width: ${Breakpoints.desktop}) {
    padding: 1.5rem;
  }
`

export const Heading = styled.h2`
  ${Type.body20};
  font-weight: ${Type.fontWeights.bold};
`

export const Body = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: top;
  margin-top: 1.25rem;
  padding-top: 1.25rem;
  border-top: 1px solid ${Colors.gray};
`

export const P = styled.p`
  ${Type.body20};
`

export const MemberRow = styled.div`
  display: flex;
  vertical-align: center;
  align-items: center;
  margin-top: 0.5rem;
`

export const Small = styled.p`
  font-size: 0.8125rem;
  line-height: 1rem;
  color: ${Colors.grayIcon};
  margin-inline-start: 0.25rem;
`

export const Ctas = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 1.25rem;
  padding-top: 1.25rem;
  border-top: 1px solid ${Colors.gray};

  a:hover {
    text-decoration: underline;
  }
`
