import { asField } from 'informed'

import { useTranslation } from 'i18n'
import { ScreenReaderOnly } from 'components'
import * as S from './styles'

const TextAreaField = asField(
  ({
    fieldState,
    fieldApi,
    disabled,
    containerStyles,
    label,
    field,
    placeholder,
    noError,
    visuallyHideError,
    errorMessage,
    ...props
  }) => {
    const { t } = useTranslation('common')

    const defaultError = fieldState.error
    const Error = visuallyHideError ? ScreenReaderOnly : S.Error

    return (
      <S.TextAreaComponent disabled={disabled} css={containerStyles}>
        {label && <S.Label htmlFor={field}>{label}</S.Label>}
        <S.TextArea
          field={field}
          id={field}
          placeholder={placeholder}
          disabled={disabled}
          fieldState={fieldState}
          fieldApi={fieldApi}
          {...props}
        />
        {!noError && (
          <Error>
            {defaultError && (
              <ScreenReaderOnly as="span">{t('error')}:</ScreenReaderOnly>
            )}
            {defaultError ? defaultError || errorMessage : null}
          </Error>
        )}
      </S.TextAreaComponent>
    )
  }
)

export default TextAreaField
