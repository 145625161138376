import { useState } from 'react'
import { asField } from 'informed'

import { useTranslation } from 'i18n'
import { ScreenReaderOnly } from 'components'
import { Eye, EyeSlash } from 'components/Icons'
import * as S from './styles'

const TextField = asField(
  ({
    label,
    noError,
    visuallyHideError = false,
    disabled,
    containerStyles,
    errorMessage,
    field,
    toggleVisibility,
    minimalStyle,
    visuallyHideLabel = false,
    ...rest
  }) => {
    const [isShown, setIsShown] = useState(false)
    const { t } = useTranslation('common')

    const defaultError = rest.fieldState.error
    const Error = visuallyHideError ? ScreenReaderOnly : S.Error

    const placeholder = t(rest.placeholder)
    const toggleText = isShown ? t('hidePassword') : t('showPassword')
    const EyeIcon = isShown ? EyeSlash : Eye

    return (
      <S.TextFieldComponent disabled={disabled} css={containerStyles}>
        {label &&
          (visuallyHideLabel ? (
            <ScreenReaderOnly as="label" htmlFor={field}>
              {label}
            </ScreenReaderOnly>
          ) : (
            <S.Label htmlFor={field} minimalStyle={minimalStyle}>
              {label}
            </S.Label>
          ))}
        <S.InputWrapper>
          <S.TextInput
            disabled={disabled}
            field={field}
            {...rest}
            id={field}
            placeholder={placeholder}
            type={toggleVisibility && isShown ? 'text' : rest.type || 'text'}
            toggleVisibility={toggleVisibility}
            minimalStyle={minimalStyle}
          />
          {toggleVisibility && (
            <S.EyeToggle
              onClick={() => setIsShown(!isShown)}
              isShown={isShown}
              type="button"
              aria-label={toggleText}
              aria-disabled={disabled}
              minimalStyle={minimalStyle}
            >
              <EyeIcon aria-hidden="true" />
            </S.EyeToggle>
          )}
          {minimalStyle && <S.Bar />}
        </S.InputWrapper>
        {!noError && (
          <Error>
            {defaultError && (
              <ScreenReaderOnly as="span">{t('error')}:</ScreenReaderOnly>
            )}
            {defaultError ? defaultError || errorMessage : null}
          </Error>
        )}
      </S.TextFieldComponent>
    )
  }
)

export default TextField
