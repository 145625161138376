import { useDispatch, useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'

import formatDate from 'utilities/formatDate'
import { mixpanelService, eventTypes } from 'analytics'
import { updateSubscription } from 'api/business'
import apiStatus from 'store/selectors/apiStatus'
import { Trans, useTranslation } from 'i18n'
import { BusinessCancelSubscriptionForm, HandleAction } from 'components'
import * as S from './styles'

const apiStatusSelector = (state) =>
  apiStatus({
    state,
    resource: 'business',
    api: 'updateSubscription'
  })

const BusinessCancelSubscription = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['common', 'business'])
  const status = useSelector(apiStatusSelector, isEqual)
  const businessSubscription = useSelector(
    (state) => state.api.business?.subscription
  )

  const chargeDate = formatDate(businessSubscription?.nextChargeDate)
  const endDate = formatDate(businessSubscription?.endDate)

  const handleSubmit = ({ reason }) => {
    mixpanelService.track(eventTypes.cancelBusinessSubscriptionReason, {
      reason
    })

    dispatch(updateSubscription({ status: 'non_renewing', reason }))
  }

  return (
    <S.BusinessCancelSubscriptionComponent>
      <HandleAction
        resource="business"
        api="updateSubscription"
        toastDescription={t('business:cancelSuccess', { date: endDate })}
        nextRoute="/business/billing"
      />
      <p>
        <Trans i18nKey="business:cancelBody" date={chargeDate} t={t}>
          If your cancel your subscription, your mmhmm Business service will end
          at the end of your subscription on
          <S.Date>{{ date: chargeDate }}</S.Date>. Individual member accounts
          will be suspended and unable to use mmhmm. You will still be
          responsible for any new users who become active on your Business
          account before this date.
        </Trans>
      </p>
      <BusinessCancelSubscriptionForm
        onSubmit={handleSubmit}
        isLoading={status.loading}
      />
    </S.BusinessCancelSubscriptionComponent>
  )
}

export default BusinessCancelSubscription
