import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import { captureException } from 'utilities/sentry'

import { Auth, User } from 'api'
import { useTranslation, Trans } from 'i18n'
import { setNotificationVisibility } from 'store/notification/slice'
import { setDialogVisibility } from 'store/dialog/slice'
import { setBusinessInfo } from 'store/ui/slice'
import getErrorMessage from 'utilities/getErrorMessage'
import { CreateBusinessAccount, FormIllustrationContainer } from 'components'
import businessBigLegs from 'public/images/businessBigLegs.png'
import * as S from './styles'

const BusinessCreateAccountPage = () => {
  const { t } = useTranslation(['business', 'common', 'account'])
  const dispatch = useDispatch()
  const router = useRouter()
  const businessInfo = useSelector((state) => state.ui.businessInfo)
  const [loading, setLoading] = useState(false)

  const businessName = businessInfo?.business?.name || 'Business'
  const missingInfo =
    !businessInfo?.inviteToken || !businessInfo.business || !businessInfo.email

  const handleSuccess = () => {
    dispatch(setBusinessInfo({ inviteToken: null }))
    dispatch(
      setNotificationVisibility({
        type: 'success',
        message: t('joinSuccess', { businessName })
      })
    )
    router.push('/')
  }

  const handleError = (err) => {
    setLoading(false)
    const errorMsg =
      err?.code && err.code !== 'InvalidParameter'
        ? getErrorMessage(err.code, t)
        : null

    dispatch(
      setDialogVisibility({
        name: 'ErrorDialog',
        description: errorMsg || t('common:somethingWentWrong'),
        showSupportLink: !errorMsg
      })
    )

    captureException(err)
  }

  const handleSubmit = async (values) => {
    setLoading(true)
    const payload = {
      email: businessInfo.email,
      name: `${values.userFirstName.trim()} ${values.userLastName.trim()}`,
      businessId: businessInfo.business.id,
      verifier: businessInfo.inviteToken,
      password: values.password
    }

    try {
      const createUserRes = await dispatch(Auth.createUser(payload))
      if (createUserRes.meta.requestStatus === 'rejected') {
        handleError(createUserRes.error)
        return
      }

      const signInRes = await dispatch(
        Auth.signIn({
          username: businessInfo.email,
          password: values.password
        })
      )
      if (signInRes.meta.requestStatus === 'rejected') {
        handleError(signInRes.error)
        return
      }

      const getUserRes = await dispatch(User.getMe())
      if (getUserRes.meta.requestStatus === 'rejected') {
        handleError(getUserRes.error)
        return
      }

      handleSuccess()
    } catch (err) {
      handleError(err)
    }

    setLoading(false)
  }

  const termsUrl = t('common:termsUrl')
  const privacyUrl = t('common:privacyUrl')

  return (
    <>
      <S.PageHeader>
        <Trans i18nKey="joinMmhmmFor" t={t} businessName={businessName}>
          Join mmhmm for <bdi>{{ businessName }}</bdi>
        </Trans>
      </S.PageHeader>
      <FormIllustrationContainer
        illustration={businessBigLegs.src}
        backgroundColor="#FEFC5B"
      >
        <S.StyledForm onSubmit={handleSubmit}>
          <CreateBusinessAccount email={businessInfo?.email} />
          <S.BlockButton type="submit" loading={loading} disabled={missingInfo}>
            {t('common:continue')}
          </S.BlockButton>
          <S.FinePrint>
            <Trans i18nKey="createAccountTOS" t={t}>
              By clicking "Continue" you agree that your account shall be
              subject to the mmhmm
              <a href={termsUrl} target="_blank" rel="noopener noreferrer">
                Terms of Service
              </a>
              and
              <a href={privacyUrl} target="_blank" rel="noopener noreferrer">
                Privacy Policy
              </a>
              .
            </Trans>
          </S.FinePrint>
        </S.StyledForm>
      </FormIllustrationContainer>
    </>
  )
}

export default BusinessCreateAccountPage
