import styled from 'styled-components'

import { Type } from 'styles'

export const EditBillingComponent = styled.div`
  max-width: 540px;
`

export const H1 = styled.h1`
  margin-bottom: 3rem;
`

export const H2 = styled.h2`
  ${Type.header3};
  margin-bottom: 0.5rem;
`

export const P = styled.p`
  ${Type.body30};
  margin-bottom: 2.625rem;
`

export const Loading = styled.div`
  height: 20rem;
  display: flex;
  align-items: center;
`
