/**
 * Accepts either a join token or a business ID and returns the business name
 * associated with the ID or token
 */

import { modelName, apiFetch, createAsyncThunk } from './business'

const getBusinessFromJoinToken = createAsyncThunk(
  `${modelName}/getBusinessFromJoinToken`,
  async ({ token }) => {
    const options = {
      path: `/public/businesses/name/${token}`,
      method: 'GET',
      authorized: false
    }
    const response = await apiFetch(options)

    return response
  }
)

export default getBusinessFromJoinToken
