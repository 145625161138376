import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'
import usePrevious from 'hooks/usePrevious'

import DialogList from './DialogList'

const animationDuration = 250 // ms

const DialogController = () => {
  const [visible, setVisible] = useState(false)
  const [dialogName, setDialogName] = useState()
  const dialogProps = useSelector((state) => state.dialog, isEqual)
  const prevDialogProps = usePrevious(dialogProps)

  useEffect(() => {
    if (dialogProps?.name) {
      setDialogName(dialogProps?.name)
      setVisible(true)
    }

    if (prevDialogProps?.name && !dialogProps?.name) {
      setVisible(false)

      // This is brittle, the animation does not have an explicit duration
      setTimeout(() => {
        setDialogName(null)
      }, animationDuration * 2)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogProps])

  const Dialog = DialogList[dialogName]
  if (!dialogName || !Dialog) return null

  return <Dialog visible={visible} {...dialogProps} />
}

export default DialogController
