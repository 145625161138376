import { useState, useEffect } from 'react'
import { Button } from '@all-turtles/mmhmm-ui-kit'
import { Dialog, AvatarProfileImage } from 'components'
import { setDialogVisibility } from 'store/dialog/slice'
import { useSelector, useDispatch } from 'react-redux'
import { deleteAvatar, getMe } from 'api/user'
import { useTranslation } from 'i18n'
import { mixpanelService, eventTypes } from 'analytics'

import * as S from './styles'

const AvatarDeleteDialog = ({ visible }) => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const handleDelete = async () => {
    setIsLoading(true)
    await dispatch(deleteAvatar())
    await dispatch(getMe())
    dispatch(setDialogVisibility({ name: null }))

    mixpanelService.track(eventTypes.profilePhotoDelete, {
      avatarDeleted: true
    })
  }

  useEffect(() => {
    mixpanelService.track(eventTypes.profilePhotoDelete, {
      deleteModalOpened: true
    })
  }, [])

  const avatar = useSelector(
    (state) => state.api.user?.me?.profilePhotoInfo?.urls?.full
  )

  const { t } = useTranslation('account')

  return (
    <Dialog visible={visible} label={t('avatarDeleteDialog')}>
      <S.DialogWrap>
        <AvatarProfileImage largeImage avatarUrl={avatar} />
        <Button variant="danger" loading={isLoading} onClick={handleDelete}>
          {t('deleteAvatar')}
        </Button>
      </S.DialogWrap>
    </Dialog>
  )
}

export default AvatarDeleteDialog
