import styled from 'styled-components'

export const H1 = styled.h1`
  margin-bottom: 2rem;
`

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-top: 3.375rem;
`
