import * as S from './styles'
import { useTranslation } from 'i18n'

const GetSupport = () => {
  const { t } = useTranslation('account')

  return (
    <S.GetSupportComponent>
      <S.A href="https://help.mmhmm.app">{t('getSupportCta')}</S.A>
      <S.Title>{t('getSupportTitle')}</S.Title>
      <S.Illustration />
    </S.GetSupportComponent>
  )
}

export default GetSupport
