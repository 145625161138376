const memberActions = [
  {
    dialogName: 'ChangeBusinessMemberEmailDialog',
    i18n: 'changeEmail',
    shouldHide: ({ member, businessPermissions }) => {
      // admins can edit admin and member emails but not owner
      const unauthorized = !businessPermissions.canModifyMembers
      const isOwner = member.role === 'owner'
      return unauthorized || isOwner
    }
  },
  {
    dialogName: 'BusinessMemberPermissionsDialog',
    i18n: 'changeRole',
    shouldHide: ({ member, businessPermissions, currentUser }) => {
      // A user cannot change their own role
      const isSelf = member.id === currentUser.id
      if (currentUser.businessRole === 'owner' && !isSelf) return false

      const notAdmin = !businessPermissions.canModifyMembers
      // Admins can only change Members to Admins
      const adminToAdmin = member.role !== 'member'
      return notAdmin || adminToAdmin || isSelf
    }
  },
  {
    dialogName: 'SuspendAccountDialog',
    i18n: 'suspendAccount',
    shouldHide: ({ member, businessPermissions, currentUser }) => {
      const unauthorized = !businessPermissions.canModifyMembers
      const memberIsNotActive = member.status !== 'active'

      // A user cannot suspend themself and an admin cannot suspend an owner
      const isSelf = member.id === currentUser.id
      const adminToOwner =
        currentUser.businessRole !== 'owner' && member.role === 'owner'
      return unauthorized || isSelf || adminToOwner || memberIsNotActive
    }
  },
  {
    dialogName: 'ReactivateAccountDialog',
    i18n: 'reactivateAccount',
    shouldHide: ({ member, businessPermissions }) => {
      const unauthorized = !businessPermissions.canModifyMembers
      const memberAlreadyActive = member.status === 'active'
      return unauthorized || memberAlreadyActive
    }
  }
]
export default memberActions
