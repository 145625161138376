import { useSelector } from 'react-redux'

import { Trans, useTranslation } from 'i18n'
import { Dialog } from 'components'
import * as S from './styles'

const ChangeEmailInfo = ({ visible }) => {
  const { t } = useTranslation('business')
  const businessInfo = useSelector((state) => state.ui?.businessInfo)
  const businessName = businessInfo?.business?.name

  return (
    <Dialog visible={visible} label={t('changeEmailOnAccount')}>
      <S.Container>
        <S.Heading>{t('changeEmailOnAccount')}</S.Heading>
        <S.Ol>
          <S.Li>{t('changeEmail1')}</S.Li>
          <S.Li>{t('changeEmail2')}</S.Li>
          <S.Li>{t('changeEmail3')}</S.Li>
          <S.Li>{t('changeEmail4')}</S.Li>
          <S.Li>{t('changeEmail5')}</S.Li>
          <S.Li>
            <Trans i18nKey="changeEmail6" t={t} businessName={businessName}>
              Join mmhmm Business for <bdi>{{ businessName }}</bdi> with the
              desired work email
            </Trans>
          </S.Li>
        </S.Ol>
      </S.Container>
    </Dialog>
  )
}

export default ChangeEmailInfo
