import {
  Twitter,
  Instagram,
  LinkedIn,
  Facebook,
  YouTube
} from 'components/Icons'

const socialLinks = (t) => [
  {
    icon: Twitter,
    url: t('twitterUrl'),
    name: 'Twitter'
  },
  {
    icon: Instagram,
    url: 'https://instagram.com/mmhmmapp',
    name: 'Instagram'
  },
  {
    icon: LinkedIn,
    url: 'https://www.linkedin.com/company/mmhmmapp/',
    name: 'LinkedIn'
  },
  {
    icon: Facebook,
    url: t('facebookUrl'),
    name: 'Facebook'
  },
  {
    icon: YouTube,
    url: 'https://www.youtube.com/channel/UC_vA4GOnvEmEyuD0iTg4xzA',
    name: 'YouTube'
  }
]

export default socialLinks
