/** Gets all auto-approve domains for current user business */
import { modelName, apiFetch, createAsyncThunk } from './business'

const getDomains = createAsyncThunk(`${modelName}/getDomains`, async (body) => {
  const options = {
    path: '/businesses/me/admin/domains',
    method: 'GET',
    body
  }
  const response = await apiFetch(options)

  return response
})

getDomains.storeKey = 'approvedDomains'

export default getDomains
