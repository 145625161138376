import * as S from './styles'
import { Trans, useTranslation } from 'i18n'
import { Checkbox } from 'components'
import { useFieldState } from 'informed'

const AcceptTerms = () => {
  const { t } = useTranslation(['checkout', 'common'])

  /**
   * Note: this triggers a warning in the console on initial render from Informed:
   * "Attempting to get field acceptedTerms but it does not exist"
   * Once the form loads, the field does exist and it's fine, so this warning
   * can be safely ignored. There's a closed issue in Informed about this and
   * it's also happening on their example, so doesn't look like they plan to fix.
   * https://github.com/joepuzzo/informed/issues/287
   */
  const fieldState = useFieldState('acceptedTerms')

  const termsUrl = t('common:termsUrl')
  const privacyUrl = t('common:privacyUrl')
  const errorMessage = t('common:fieldRequired')

  return (
    <S.AcceptTermsComponent>
      <S.Wrap>
        <Checkbox
          containerStyles={S.checkboxWrapperStyles}
          field="acceptedTerms"
          required
          errorMessage={errorMessage}
        />
        <S.Label htmlFor="acceptedTerms">
          <Trans i18nKey="acceptTerms" t={t}>
            I accept the
            <a href={termsUrl} target="_blank" rel="noopener noreferrer">
              Terms of Service
            </a>
            and
            <a href={privacyUrl} target="_blank" rel="noopener noreferrer">
              Privacy Policy
            </a>
            . I understand that this subscription will auto-renew at the end of
            the term, unless I cancel the auto-renewal in my account.
          </Trans>
        </S.Label>
      </S.Wrap>
      {fieldState.error && <S.Error>{errorMessage}</S.Error>}
    </S.AcceptTermsComponent>
  )
}

export default AcceptTerms
