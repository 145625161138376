import styled, { css } from 'styled-components'

import { Type, Inputs } from 'styles'

export const AcceptTermsComponent = styled.div`
  position: relative;
`

export const Wrap = styled.div`
  display: flex;
  white-space: pre-wrap;
  align-items: center;
`

export const checkboxWrapperStyles = css`
  margin-inline-end: 0.625rem;
`

export const Label = styled.label`
  ${Type.body30};
`

export const Error = styled.p`
  ${Inputs.error};
  margin-top: 0.5rem;
  position: absolute;
  bottom: -2rem;
  left: 0;

  [dir='rtl'] & {
    left: unset;
    right: 0;
  }
`
