import styled from 'styled-components'

export const DialogWrap = styled.div`
  max-width: 25rem;
`

export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Title = styled.p`
  margin-bottom: 2rem;
`

export const NowrapBdi = styled.bdi`
  white-space: nowrap;
`
