import { unwrapResult } from '@reduxjs/toolkit'
import { captureException, flushSentry } from 'utilities/sentry'

import { verifyEmailWithToken } from 'api/auth'
import { getMe } from 'api/user'
import { getInvitationFromToken, getBusinessFromJoinToken } from 'api/business'
import { setNotificationVisibility } from 'store/notification/slice'
import { setDialogVisibility } from 'store/dialog/slice'
import { setBusinessInfo } from 'store/ui/slice'

const handleTokens = async ({
  dispatch,
  verifyEmailToken,
  businessInviteToken,
  joinLinkToken,
  currentUser,
  t
}) => {
  /**
   * Handle token from email link to verify an existing user's email address;
   * submits token for email verification, then reloads same route with query token removed
   */
  if (verifyEmailToken) {
    try {
      const result = await dispatch(
        verifyEmailWithToken({ verifier: verifyEmailToken })
      )
      await unwrapResult(result)
      await dispatch(getMe())

      dispatch(
        setNotificationVisibility({
          type: 'success',
          message: t('business:verificationSuccess')
        })
      )
    } catch (error) {
      captureException(error)
      dispatch(
        setNotificationVisibility({
          type: 'error',
          message: t('business:verificationError')
        })
      )
      await flushSentry()
    }

    // After verification or error, update to same route with query token removed
    return 'RELOAD'
  }

  /**
   * Handle token from shared join link for a user to join a business;
   * retrieves businessName using token, then takes user to business email
   * form to begin process of joining the business
   */
  if (joinLinkToken) {
    try {
      const result = await dispatch(
        getBusinessFromJoinToken({ token: joinLinkToken })
      )
      const response = await unwrapResult(result)
      // Set businessInfo
      dispatch(
        setBusinessInfo({
          business: {
            name: response.businessName
          },
          joinLinkToken
        })
      )

      return 'GO_TO_BUSINESS_EMAIL'
    } catch (error) {
      captureException(error)
      dispatch(
        setNotificationVisibility({
          type: 'error',
          message:
            error.code === 'CodeExpiredException'
              ? t('business:expiredToken')
              : t('business:invalidInviteToken')
        })
      )
    }
  }

  /**
   * Handle token from invite link in email for user to join a business;
   * retrieves business and email associated with invite, then routes based on
   * whether or not there's already a user account with that email address
   */
  if (businessInviteToken) {
    try {
      const result = await dispatch(
        getInvitationFromToken({ token: businessInviteToken })
      )
      const response = await unwrapResult(result)

      if (currentUser?.email && currentUser.email !== response.email) {
        // If there is a current user and it doesn't match the invite, redirect home
        dispatch(
          setDialogVisibility({
            name: 'ErrorDialog',
            buttonText: t('close'),
            description: t('business:currentAccountInviteError')
          })
        )

        return 'GO_TO_INDEX'
      }

      dispatch(
        setBusinessInfo({
          inviteToken: businessInviteToken,
          business: {
            id: response.businessId,
            name: response.businessName
          },
          email: response.email,
          existingUser: response.existingUser
        })
      )

      if (response.existingUser) {
        return 'GO_TO_BUSINESS_JOIN'
      } else {
        return 'GO_TO_BUSINESS_CREATE_ACCOUNT'
      }
    } catch (error) {
      captureException(error)

      // If error is 400 CodeExpiredException - The invitation has expired
      dispatch(
        setNotificationVisibility({
          type: 'error',
          message:
            error.code === 'CodeExpiredException'
              ? t('business:expiredToken')
              : t('business:invalidInviteToken')
        })
      )

      return 'RELOAD'
    }
  }
}

export default handleTokens
