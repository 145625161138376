import { func } from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { useRouter } from 'next/router'
import { useFormApi } from 'informed'
import { captureException } from 'utilities/sentry'

import { setDialogVisibility } from 'store/dialog/slice'
import { setToastVisibility } from 'store/toast/slice'
import { setNextRoute } from 'store/ui/slice'
import apiStatus from 'store/selectors/apiStatus'
import { useTranslation } from 'i18n'
import { getAuthFields } from 'data/authFields'
import { withInformed, TextField, HandleAction, Back } from 'components'
import * as S from './styles'

const propTypes = {
  handleFieldChange: func.isRequired
}

const ResetPasswordForm = ({ handleFieldChange }) => {
  const router = useRouter()
  const formApi = useFormApi()
  const dispatch = useDispatch()
  const { t } = useTranslation(['account', 'common'])

  const status = useSelector((state) =>
    apiStatus({ state, resource: 'auth', api: 'forgotPasswordSubmit' })
  )
  const error = useSelector(
    (state) => state.api.auth?.forgotPasswordSubmit?.error
  )
  const nextRoute = useSelector((state) => state.ui?.nextRoute)

  const authFields = getAuthFields(t)
  const codeField = {
    ...authFields.code,
    label: t('account:code'),
    placeholder: t('account:codePlaceholder')
  }

  const handleError = () => {
    captureException(error)

    const dialogOptions = {
      name: 'ErrorDialog',
      description: error.message || t('common:somethingWentWrong'),
      showSupportLink: true
    }

    switch (error.code) {
      case 'CodeMismatchException':
        formApi.setError('code', t('account:codeMismatchException'))
        break
      case 'InvalidPasswordException':
        formApi.setError('newPassword', t('account:oneLowerAndUpper'))
        formApi.setError('newPasswordAgain', t('account:oneLowerAndUpper'))
        break
      default:
        dispatch(setDialogVisibility(dialogOptions))
    }
  }

  const handleGetMeSuccess = () => {
    dispatch(
      setToastVisibility({
        name: 'SuccessToast',
        title: t('common:success'),
        description: t('account:resetPasswordSuccess')
      })
    )
    router.push(nextRoute || '/')
    if (nextRoute) {
      dispatch(setNextRoute(null))
    }
  }

  return (
    <>
      <HandleAction
        resource="auth"
        api="forgotPasswordSubmit"
        onError={handleError}
        onSuccess={() => {}}
      />
      <HandleAction
        resource="user"
        api="getMe"
        onSuccess={handleGetMeSuccess}
      />
      <S.ResetPasswordFormComponent>
        <TextField {...codeField} containerStyles={S.inputStyles} />
        <TextField
          {...authFields.newPassword}
          onChange={handleFieldChange}
          containerStyles={S.inputStyles}
        />
        <TextField
          {...authFields.newPasswordAgain}
          containerStyles={S.inputStyles}
        />
        <S.SubmitWrap>
          <Back />
          <S.SubmitButton isLoading={status.loading}>
            {t('account:save')}
          </S.SubmitButton>
        </S.SubmitWrap>
      </S.ResetPasswordFormComponent>
    </>
  )
}

ResetPasswordForm.propTypes = propTypes

export default withInformed(ResetPasswordForm)
