const Check = (props) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>Check mark</title>
      <path
        d="M11.5105 2.94513L11.5104 2.94496L11.5041 2.95138L5.95305 8.6292L3.16801 5.77522L3.1629 5.76999L3.15738 5.7652C2.91457 5.55477 2.65897 5.44872 2.36564 5.44872C1.73259 5.44872 1.22539 5.97706 1.22539 6.60338C1.22539 6.90706 1.34323 7.19673 1.54974 7.4036C1.54995 7.40381 1.55016 7.40403 1.55038 7.40424L5.15085 11.0767L5.15583 11.0818L5.1612 11.0865C5.40401 11.2969 5.65961 11.403 5.95293 11.403C6.25818 11.403 6.54499 11.2842 6.76316 11.0828L6.76331 11.083L6.76945 11.0767L13.1216 4.59492C13.1218 4.5947 13.122 4.59448 13.1223 4.59426C13.3121 4.40409 13.442 4.12063 13.4744 3.81238L13.4756 3.801L13.4753 3.78957C13.4586 3.13872 12.9545 2.62499 12.3207 2.62499C12.0155 2.62499 11.7287 2.74374 11.5105 2.94513Z"
        fill="#4235F2"
        stroke="#4235F2"
        strokeWidth="0.35"
      />
    </svg>
  )
}

export default Check
