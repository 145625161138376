import { useState, useEffect } from 'react'

import * as S from './styles'
import { EMAIL, EMAILS } from 'utilities/regex'
import { useTranslation } from 'i18n'

const ChipInput = ({ onChange }) => {
  const [items, setItems] = useState([])
  const [error, setError] = useState()
  const [value, setValue] = useState('')
  const { t } = useTranslation('business')

  useEffect(() => {
    onChange(items)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items])

  const isInList = (email) => items.includes(email)

  const isEmail = (email) => EMAIL.test(email)

  const isValid = (email) => {
    let error = null
    if (!isEmail(email)) {
      error = t('emailNotValidError', { email })
    } else if (isInList(email)) {
      error = t('emailAlreadyAddedError', { email })
    }

    if (error) {
      setError(error)
      return false
    }

    return true
  }

  const setNewItem = () => {
    const newValue = value.trim()

    if (newValue && isValid(value)) {
      setItems([...items, value])
      setValue('')
    }
  }

  const handleKeyDown = (evt) => {
    if (['Enter', ' ', ','].includes(evt.key)) {
      evt.preventDefault()
      setNewItem()
    }
  }

  const handlePaste = (evt) => {
    evt.preventDefault()

    const paste = evt.clipboardData.getData('text')
    const emails = paste.match(EMAILS)

    if (emails) {
      const toBeAdded = emails.filter((email) => !isInList(email))

      setItems([...items, ...toBeAdded])
    }
  }

  const handleChange = (evt) => {
    setValue(evt.target.value)
    setError(null)
  }

  const handleDelete = (item) => {
    setItems(items.filter((i) => i !== item))
  }

  const handleBlur = (evt) => {
    evt.stopPropagation()
    setNewItem()
  }

  return (
    <div>
      <S.Input
        value={value}
        placeholder="employee@company.com"
        onKeyDown={handleKeyDown}
        onChange={handleChange}
        onPaste={handlePaste}
        onBlur={handleBlur}
        dir="ltr"
      />

      <S.Error>{error}</S.Error>
      <S.Chips>
        {items.map((item) => (
          <S.Chip key={item}>
            {item}
            <S.Remove
              type="button"
              aria-label={t('removeEmail')}
              onClick={() => handleDelete(item)}
            >
              &times;
            </S.Remove>
          </S.Chip>
        ))}
      </S.Chips>
    </div>
  )
}

export default ChipInput
