import styled from 'styled-components'

import { Breakpoints, Type, Colors } from 'styles'
import { buttonStyles } from '../ButtonSecondary/styles'
import { buttonStyles as primitiveStyles } from '../ButtonPrimitive/styles'

export const PanelComponent = styled.div`
  background-color: ${Colors.blue40};
  padding: 1.5rem 1.25rem;
  margin-bottom: 1rem;

  @media (min-width: ${Breakpoints.tablet}) {
    padding: 1.5rem 2rem;
  }
`

export const Heading = styled.h2`
  ${(props) => (props.variant === 'navigation' ? Type.caps : Type.body20)};
  font-weight: ${(props) =>
    props.variant === 'navigation'
      ? Type.fontWeights.regular
      : Type.fontWeights.bold};
`

export const Body = styled.div`
  margin-top: 1rem;
  ${(props) => (props.variant === 'navigation' ? Type.body20 : Type.body30)};
`

export const Buttons = styled.div`
  display: flex;
  margin-top: 1.5rem;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`

export const button = styled.a`
  ${buttonStyles}
`

export const linkToButton = styled.a`
  ${buttonStyles}
  width: fit-content;

  /* mobile is button style but larger is link style, so reset to link */
  @media (min-width: ${Breakpoints.tablet}) {
    ${primitiveStyles};
    ${Type.body20};
    ${Type.link};
    height: auto;
    border: 0;
    text-transform: none;
    font-weight: ${Type.fontWeights.regular};
    letter-spacing: initial;
    filter: none;

    &:hover {
      border: 0;
      text-decoration: underline;
    }

    &:focus,
    &:focus:not(.focus-visible) {
      border: 0;
    }
  }
`

export const link = styled.a`
  padding: 0.625rem 0;
`
