import Image from 'next/image'

import error from 'public/images/error.svg'

import * as S from './styles'

const ErrorPageLayout = (props) => {
  return (
    <S.ErrorPageContainer>
      <Image src={error} alt="" width={250} height={153} />
      <S.Header>{props.title}</S.Header>
      <S.Content>{props.children}</S.Content>
    </S.ErrorPageContainer>
  )
}

export default ErrorPageLayout
