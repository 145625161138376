import { modelName, apiFetch, createAsyncThunk } from './business'

const updateMemberRole = createAsyncThunk(
  `${modelName}/updateMemberRole`,
  async ({ userId, role }) => {
    const options = {
      path: `/businesses/me/admin/members/${userId}`,
      method: 'PATCH',
      body: { role }
    }
    const response = await apiFetch(options)

    return response
  }
)

updateMemberRole.fulfilledReducer = (state, action) => {
  const members = JSON.parse(JSON.stringify(state.business.members?.items))
  const member = members.find((el) => el.id === action.meta.arg.userId)

  const index = members.indexOf(member)
  member.role = action.meta.arg.role

  members[index] = member

  state.business.members.items = members
}

export default updateMemberRole
