import { func } from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { useFormApi } from 'informed'

import { useTranslation } from 'i18n'
import { setDialogVisibility } from 'store/dialog/slice'
import apiStatus from 'store/selectors/apiStatus'
import changePasswordFields from 'data/changePasswordFields'
import { getSignInAppUrl } from 'utilities/getSignInAppUrl'
import { Back, TextField, HandleAction, withInformed } from 'components'
import * as S from './styles'

const propTypes = {
  handleFieldChange: func.isRequired
}

const ChangePasswordForm = ({ handleFieldChange }) => {
  const { t } = useTranslation(['account', 'common'])
  const formApi = useFormApi()
  const status = useSelector((state) =>
    apiStatus({ state, resource: 'auth', api: 'changePassword' })
  )
  const error = useSelector((state) => state.api.auth?.changePassword?.error)
  const dispatch = useDispatch()

  const handleError = () => {
    if (error?.name === 'LimitExceededException') {
      const dialogOptions = {
        name: 'ErrorDialog',
        description: error.message
      }

      dispatch(setDialogVisibility(dialogOptions))
    } else {
      formApi.setError('currentPassword', t('invalidPassword'))
    }
  }

  const fields = changePasswordFields({ t })

  const resetPasswordUrl = getSignInAppUrl({ path: '/forgot-password' })

  return (
    <>
      <HandleAction
        resource="auth"
        api="changePassword"
        onError={handleError}
        toastDescription={t('changePasswordSuccess')}
      />
      <>
        <TextField {...fields.currentPassword} />
        <S.A href={resetPasswordUrl}>{t('forgotPassword')}</S.A>
        <TextField
          {...fields.newPassword}
          onChange={handleFieldChange}
          containerStyles={S.inputStyles}
        />
        <TextField
          {...fields.newPasswordAgain}
          containerStyles={S.inputStyles}
        />
        <S.SubmitWrap>
          <Back />
          <S.SubmitButton isLoading={status.loading}>
            {t('common:save')}
          </S.SubmitButton>
        </S.SubmitWrap>
      </>
    </>
  )
}

ChangePasswordForm.propTypes = propTypes

export default withInformed(ChangePasswordForm)
