import styled, { css } from 'styled-components'
import { BasicTextArea } from 'informed'

import { Colors, Inputs, Type } from 'styles'

export const TextAreaComponent = styled.div`
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`

export const Bar = styled.span`
  ${Inputs.bar};
`

export const TextArea = styled(BasicTextArea)`
  display: block;
  box-sizing: border-box;
  width: 100%;
  background: transparent;
  padding: 1.125rem 1rem;
  ${Type.body10};
  color: ${Colors.black};
  border: 1px solid ${Colors.black};
  font-family: ${Type.fontFamily};
  font-weight: 500;
  transition: all 0.28s ease;
  box-shadow: none;
  min-height: 7.25rem;

  ::placeholder {
    opacity: 1;
    color: ${Colors.placeholderGray};
  }

  &:-webkit-autofill::first-line {
    ${Type.body10};
    color: ${Colors.black};
    font-family: ${Type.fontFamily};
    font-weight: 500;
  }

  &:focus {
    border: 1px solid ${Colors.brand};
    outline: 1px solid ${Colors.brand};
    box-shadow: none;
  }

  ${(props) =>
    props.disabled &&
    css`
      ::placeholder {
        color: ${Colors.placeholderGrayDisabled};
      }
    `}
`

export const Label = styled.label`
  ${Inputs.label};
  display: block;
  padding-bottom: 1rem;
`

export const Error = styled.p`
  ${Inputs.error};
`
