import { useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'

import * as S from './styles'
import { useTranslation } from 'i18n'
import apiStatus from 'store/selectors/apiStatus'
import { withInformed, TextField } from 'components'
import { getAuthFields } from 'data/authFields'
import { Button } from '@all-turtles/mmhmm-ui-kit'

const apiStatusSelector = (state) =>
  apiStatus({
    state,
    resource: 'business',
    api: 'updateMemberEmail'
  })

const ChangeMemberEmailForm = () => {
  const { t } = useTranslation('common')
  const status = useSelector(apiStatusSelector, isEqual)

  return (
    <div>
      <TextField {...getAuthFields(t).email} containerStyles={S.inputStyles} />
      <Button loading={status?.loading}>{t('save')}</Button>
    </div>
  )
}

export default withInformed(ChangeMemberEmailForm)
