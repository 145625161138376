import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import isEqual from 'lodash/isEqual'

import formatDate from 'utilities/formatDate'
import { updateSubscription } from 'api/business'
import apiStatus from 'store/selectors/apiStatus'
import { useTranslation, Trans } from 'i18n'
import { BusinessSubscriptionForm, HandleAction, Loading } from 'components'
import * as S from './styles'

const apiStatusSelector = (state) =>
  apiStatus({
    state,
    resource: 'business',
    api: 'updateSubscription'
  })

const BusinessSubscription = () => {
  const dispatch = useDispatch()
  const router = useRouter()
  const { t } = useTranslation(['business', 'account', 'common'])

  const status = useSelector(apiStatusSelector, isEqual)
  const catalog = useSelector((state) => state.api.business?.catalog, isEqual)
  const subscription = useSelector(
    (state) => state.api.business?.subscription,
    isEqual
  )

  const { nextPlanId, planId, nextChargeDate } = subscription

  /**
   * If paymentMethod.type: 'invoice', user is invoiced by support team and the only
   * subscription option is manual. In that case, "Cancel" is the only option available
   * to user on this form for managing subscription.
   */
  const isPayByInvoice = subscription.paymentMethod?.type === 'invoice'
  const renewalDate = formatDate(nextChargeDate)

  const selectedPlanId = nextPlanId || planId
  const initialValues = { planId: selectedPlanId }

  const handleSubmit = (values) => {
    const selectedSamePlan = nextPlanId
      ? values.planId === nextPlanId
      : values.planId === planId
    if (selectedSamePlan) return

    if (values.planId === 'cancel') {
      router.push('/business/cancel-subscription')
      return
    }

    dispatch(updateSubscription({ planId: values.planId }))
  }

  return (
    <S.BusinessSubscriptionComponent>
      <HandleAction
        resource="business"
        api="updateSubscription"
        toastDescription={t('subscriptionUpdateSuccess')}
        stayOnPage
      />
      {isPayByInvoice ? (
        <p>
          <Trans i18nKey="account:nextPayment">
            Next payment will occur automatically on
            <S.NoWrap>{{ renewalDate }}</S.NoWrap>.
          </Trans>
        </p>
      ) : (
        <p>{t('subscriptionBody')}</p>
      )}
      {catalog && catalog.monthlyPlan ? (
        <BusinessSubscriptionForm
          initialValues={initialValues}
          nextChargeDate={nextChargeDate}
          catalog={catalog}
          onSubmit={handleSubmit}
          isLoading={status.loading}
          isPayByInvoice={isPayByInvoice}
        />
      ) : (
        <Loading visible />
      )}
    </S.BusinessSubscriptionComponent>
  )
}

export default BusinessSubscription
