import socialLinks from 'data/socialLinks'
import langConfig from '../../locales'

const footer = ({ t, mmhmmLinkLocale }) => ({
  description: t('description'),
  socialIcons: socialLinks(t),
  language: {
    languageAriaLabel: t('languageSelect'),
    languages: langConfig
  },
  legal: {
    items: [
      { display: t('privacyPolicy'), url: t('common:privacyUrl') },
      { display: t('termsOfService'), url: t('common:termsUrl') }
    ]
  },
  columns: [
    {
      title: t('company'),
      items: [
        { display: t('product'), url: `${mmhmmLinkLocale}${t('productUrl')}` },
        { display: t('about'), url: `${mmhmmLinkLocale}${t('aboutUrl')}` },
        { display: t('support'), url: t('supportUrl'), external: true },
        { display: t('jobs'), url: `${mmhmmLinkLocale}${t('jobsUrl')}` },
        { display: t('blog'), url: `${mmhmmLinkLocale}${t('blogUrl')}` }
      ]
    },
    {
      title: t('contact'),
      items: [
        {
          display: t('creativeServices'),
          url: `${mmhmmLinkLocale}${t('creativeServicesUrl')}`
        },
        {
          display: t('getInTouch'),
          url: t('common:submitRequestUrl'),
          external: true
        },
        {
          display: t('pressInquiries'),
          url: 'mailto:press@mmhmm.app',
          mailTo: true
        }
      ]
    }
  ]
})

export default footer
