import styled from 'styled-components'

import { Arrow } from 'components/Icons'
import { ButtonPrimitive } from 'components'
import { Colors, Type } from 'styles'

export const PaginationComponent = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
`

export const StyledButton = styled(ButtonPrimitive)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 2.5rem;
  height: 2.5rem;
  margin: 1rem 0.25rem 0;
  font-weight: ${Type.fontWeights.bold};
  font-size: 0.9375rem;
  line-height: 1.125rem;
  color: ${Colors.brand};
  border: 1px solid ${Colors.brand};
  transition: all 200ms ease;

  &:focus {
    border: 1px solid ${Colors.brand};
    outline: 1px solid ${Colors.brand};
  }

  &:focus:not(.focus-visible),
  &:active {
    border: 1px solid;
    border-color: ${(props) =>
      props.activePage ? Colors.gray40 : Colors.brand};
    outline: 1px solid transparent;
  }

  &:disabled {
    background-color: transparent;
    color: ${Colors.black};
    border-color: ${Colors.gray40};
    opacity: ${(props) => (props.activePage ? 1 : 0.3)};
    cursor: default;

    svg path {
      fill: ${Colors.black};
    }
  }
`

export const NextArrow = styled(Arrow)`
  [dir='rtl'] & {
    transform: rotate(180deg);
  }
`

export const BackArrow = styled(Arrow)`
  [dir='ltr'] & {
    transform: rotate(180deg);
  }
`

export const PageButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0 1rem;
`
