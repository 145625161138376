import { useDispatch, useSelector } from 'react-redux'

import { setDialogVisibility } from 'store/dialog/slice'
import { useTranslation } from 'i18n'
import { BusinessInvitationsTable, Pagination } from 'components'
import * as S from './styles'

const itemLimit = 20

const BusinessAllInvitations = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('business')

  const businessPermissions = useSelector(
    (state) => state.api.user?.me?.businessPermissions
  )
  const business = useSelector((state) => state.api.business)
  const inviteCount = business.invitations?.filteredCount || 0

  const handleInvite = () => {
    dispatch(setDialogVisibility({ name: 'InviteMembers' }))
  }

  const pageCount = Math.ceil(inviteCount / itemLimit)

  return (
    <S.BusinessAllInvitationsComponent>
      <S.Header>
        <S.Title>{t('invitesCount', { count: inviteCount })}</S.Title>
        {businessPermissions?.canInviteMembers && (
          <S.StyledButton variant="secondary" onClick={handleInvite}>
            {t('inviteMembers')}
          </S.StyledButton>
        )}
      </S.Header>
      <BusinessInvitationsTable limit={itemLimit} withSearch />
      <Pagination name="getInvitations" pageCount={pageCount} />
    </S.BusinessAllInvitationsComponent>
  )
}

export default BusinessAllInvitations
