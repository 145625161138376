import { useSelector } from 'react-redux'
import { useTranslation, Link } from 'i18n'

import { Panel } from 'components'
import navItems from './navItems'
import * as S from './styles'

const SubscriptionNav = () => {
  const { t } = useTranslation('account')
  const subscription = useSelector((state) => state.api.commerce?.subscription)

  return (
    <S.SubscriptionNavComponent>
      <Panel heading={t('manageSubscription')} variant="navigation">
        <ul>
          {navItems.map(
            ({ url, i18nUrl, i18nKey, shouldHide, ...rest }, index) => {
              if (shouldHide && shouldHide({ subscription })) return null
              return i18nUrl ? (
                <S.Li key={index}>
                  <a href={t(i18nUrl)} {...rest}>
                    {t(i18nKey)}
                  </a>
                </S.Li>
              ) : (
                <S.Li key={index}>
                  <Link href={url} passHref>
                    <a {...rest}>{t(i18nKey)}</a>
                  </Link>
                </S.Li>
              )
            }
          )}
        </ul>
      </Panel>
    </S.SubscriptionNavComponent>
  )
}

export default SubscriptionNav
