import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setPagination } from 'store/pagination/slice'
import usePrevious from './usePrevious'

const useFetchWithPagination = ({
  limit,
  search,
  fetchAction,
  paginationKey
}) => {
  const dispatch = useDispatch()
  const pagination = useSelector((state) => state.pagination?.[paginationKey])
  const currentPage = pagination?.page || 0
  const previousSearch = usePrevious(search)
  const shouldResetCurrentPage = currentPage !== 0 && previousSearch !== search

  const handleFetch = useCallback(() => {
    const payload = {
      offset: shouldResetCurrentPage ? 0 : currentPage * limit,
      limit
    }

    if (pagination?.orderBy) {
      payload.orderBy = pagination.orderBy
      payload.orderDirection = pagination.orderDirection
    }

    if (search) {
      payload.filter = search
    }
    dispatch(fetchAction(payload))
    if (shouldResetCurrentPage) {
      // TODO: This produces additional API request because currentPage does change
      //       Maybe setup some ref to avoid it
      dispatch(setPagination({ [paginationKey]: { ...pagination, page: 0 } }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    shouldResetCurrentPage,
    currentPage,
    pagination?.orderBy,
    pagination?.orderDirection,
    search
  ])

  useEffect(() => {
    handleFetch()
  }, [handleFetch])
}

export default useFetchWithPagination
