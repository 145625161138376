import { bool } from 'prop-types'
import { useTranslation, Trans } from 'i18n'
import { useSelector } from 'react-redux'

import formatDate from 'utilities/formatDate'
import { Panel } from 'components'
import * as S from './styles'

const SubscriptionRenewalPanel = ({ cancelled }) => {
  const { t } = useTranslation(['common', 'account'])
  const timestamp = useSelector(
    (state) => state.api.commerce?.subscription?.endDate
  )
  const date = timestamp && formatDate(timestamp)

  const ctas = [
    {
      id: 'upgrade',
      label: t('account:resubscribe'),
      href: '/upgrade',
      type: 'button'
    },
    {
      id: 'subscription',
      label: t('manage'),
      href: '/subscription',
      type: 'link'
    }
  ]

  return (
    <Panel
      heading={
        cancelled
          ? t('account:nonRenewingSub')
          : t('account:subscriptionExpired')
      }
      ctas={ctas}
    >
      {cancelled ? (
        <Trans i18nKey="account:expireOn" t={t} date={date}>
          Your subscription will expire on <S.NoWrap>{{ date }}</S.NoWrap>
        </Trans>
      ) : (
        t('account:subscriptionExpiredDescription')
      )}
    </Panel>
  )
}

SubscriptionRenewalPanel.propTypes = {
  cancelled: bool
}

export default SubscriptionRenewalPanel
