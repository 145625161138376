const businessAuthenticationFields = ({ t, idps }) => ({
  requireSSO: {
    field: 'requireSSO',
    fields: [
      { label: t('business:requireSSO'), value: true, id: 'requireSSO' },
      { label: t('business:notRequireSSO'), value: false, id: 'notRequireSSO' }
    ]
  },
  idps: {
    field: 'ssoIdp',
    options: [
      {
        value: '',
        label: t('business:requireSSOSelect')
      },
      ...idps.map((idp) => ({ value: idp.id, display: idp.readable_name }))
    ]
  }
})

export default businessAuthenticationFields
