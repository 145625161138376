import { AvatarProfileThumbnail } from 'components'
import * as S from './styles'

const NameWithAvatar = ({ avatarUrl, name }) => {
  return (
    <S.NameWithAvatar>
      <AvatarProfileThumbnail avatarUrl={avatarUrl} />
      {name}
    </S.NameWithAvatar>
  )
}

export default NameWithAvatar
