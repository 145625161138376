import { useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'

import { useTranslation, Link } from 'i18n'
import navItems from 'data/navItems'
import apiStatus from 'store/selectors/apiStatus'
import { getSignInAppUrl } from 'utilities/getSignInAppUrl'
import * as S from './styles'

const apiStatusSelector = (state) =>
  apiStatus({
    state,
    resource: 'user',
    api: 'getMe'
  })

const NavItems = () => {
  const { t } = useTranslation(['nav', 'common'])
  const currentUser = useSelector((state) => state.api.user?.me, isEqual)
  const userStatus = useSelector(apiStatusSelector, isEqual)
  const payments = useSelector((state) => state.api.commerce?.payments?.items)

  if (!currentUser?.id) {
    /**
     * Don't show "Login" link while auth handoff is happening post-sign in,
     * but do show if user there's an error so user can get to sign in again
     */
    if (userStatus?.error) {
      const signInRedirectUrl = getSignInAppUrl({ path: '/sign-in' })
      return <S.NavLink href={signInRedirectUrl}>{t('common:logIn')}</S.NavLink>
    } else {
      return null
    }
  }

  return (
    <>
      {navItems.map((item) => {
        const hasHideCheck =
          item.shouldShow && typeof item.shouldShow === 'function'
        if (
          hasHideCheck &&
          !item.shouldShow({
            user: currentUser,
            payments
          })
        ) {
          return null
        }

        const display = item.dynamicDisplay
          ? item.dynamicDisplay(currentUser)
          : t(item.i18nKey)

        return (
          <Link
            href={item.url}
            passHref
            name={`${item.name} (Mobile)`}
            external={item.external}
            key={`mobile-nav-${item.i18nKey}`}
          >
            <S.NavLink>{display}</S.NavLink>
          </Link>
        )
      })}
    </>
  )
}

export default NavItems
