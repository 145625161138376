import * as S from './styles'
import { useTranslation } from 'i18n'

import { ChangePlanForm, Form } from 'components'
import useGetDescription from './useGetDescription'

const ChangePlan = ({
  initialValues,
  onSubmit,
  isLoading,
  nextPlanId,
  nextChargeDate,
  catalog
}) => {
  const { t } = useTranslation('account')

  const description = useGetDescription({ nextPlanId, nextChargeDate })

  return (
    <S.ChangePlanComponent>
      <h1>{t('changePlanTitle')}</h1>
      <S.P>{description}</S.P>
      <Form initialValues={initialValues} onSubmit={onSubmit}>
        <ChangePlanForm isLoading={isLoading} catalog={catalog} />
      </Form>
    </S.ChangePlanComponent>
  )
}

export default ChangePlan
