import { modelName, apiFetch, createAsyncThunk } from './business'

const getPaymentHistory = createAsyncThunk(
  `${modelName}/getPaymentHistory`,
  async (body) => {
    const options = {
      path: '/businesses/me/admin/invoices',
      method: 'GET',
      body
    }
    const response = await apiFetch(options)

    return response
  }
)

getPaymentHistory.storeKey = 'paymentHistory'

export default getPaymentHistory
