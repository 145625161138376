/**
 * id is invoiceId - the ID of the invoice to get - invoiceId field in the payment history item response
 * type - one of payment or refund - type field in the payment history item response
 */
import { modelName, apiFetch, createAsyncThunk } from './business'

const getInvoicePdf = createAsyncThunk(
  `${modelName}/getInvoicePdf`,
  async ({ id, type }) => {
    const options = {
      path: `/businesses/me/admin/invoices/${id}?transactionType=${type}`,
      method: 'GET'
    }

    const response = await apiFetch(options)

    return response
  }
)

export default getInvoicePdf
