import * as S from './styles'
import { Toast } from 'components'

const titleId = 'toastTitle'
const descId = 'toastDesc'

const SuccessToast = ({ visible, title, description }) => (
  <Toast visible={visible} aria-labelledby={titleId} aria-describedby={descId}>
    <div>
      <S.Title id={title}>{title}</S.Title>
      <S.Description id={descId}>{description}</S.Description>
    </div>
  </Toast>
)

export default SuccessToast
