import { bool } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import {
  Disclosure,
  DisclosurePanel,
  DisclosureButton
} from '@reach/disclosure'
import { Button } from '@all-turtles/mmhmm-ui-kit'

import { setHideAvailableDomains } from 'store/ui/slice'
import { setDialogVisibility } from 'store/dialog/slice'
import { useTranslation } from 'i18n'
import * as S from './styles'

const BusinessAvailableDomains = ({ canAdmin }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['business', 'common'])

  const business = useSelector((state) => state.api.business)
  const availableDomains = business?.availableDomains
  const domainCount = availableDomains?.domains?.length
  const showText = t(domainCount > 0 ? 'common:showAll' : 'common:showDetails')

  const hideAvailableDomains = useSelector(
    (state) => state.ui.hideAvailableDomains
  )

  const toggleDisclosure = () => {
    dispatch(setHideAvailableDomains(!hideAvailableDomains))
  }

  const handleConfirmDialog = (domain) => {
    dispatch(
      setDialogVisibility({ name: 'BusinessDomainActivateDialog', domain })
    )
  }

  return (
    <Disclosure open={hideAvailableDomains} onChange={toggleDisclosure}>
      <DisclosureButton as={S.ToggleButton} variant="link" small>
        {hideAvailableDomains ? t('common:hide') : showText}
        <S.Spacer />
        <S.StyledArrow />
      </DisclosureButton>
      <DisclosurePanel as={S.PanelWrapper}>
        <>
          <S.Body>{t('availableDomainsBody')}</S.Body>
          {domainCount > 0 && (
            <S.Table>
              {availableDomains.domains.map((domain) => (
                <S.DomainRow key={`available-${domain}`}>
                  <S.Domain dir="ltr">@{domain}</S.Domain>
                  {canAdmin && (
                    <Button onClick={() => handleConfirmDialog(domain)}>
                      {t('activate')}
                    </Button>
                  )}
                </S.DomainRow>
              ))}
            </S.Table>
          )}
        </>
      </DisclosurePanel>
    </Disclosure>
  )
}

BusinessAvailableDomains.propTypes = {
  canAdmin: bool
}

export default BusinessAvailableDomains
