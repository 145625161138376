import styled from 'styled-components'

import { Colors, Type } from 'styles'

export const Wrap = styled.div`
  max-width: 35rem;
`

export const H1 = styled.h1`
  margin-bottom: 2rem;
`

export const MemberSection = styled.div`
  padding: 1.5rem 0;
  margin: 1.5rem 0;
  border-bottom: 1px solid ${Colors.gray10};
  border-top: 1px solid ${Colors.gray10};
`

export const Name = styled.p`
  ${Type.header3};
  margin-bottom: 0.625rem;
`

export const Buttons = styled.div`
  display: flex;
  margin-top: 2rem;
  justify-content: space-between;
`
