import styled, { css } from 'styled-components'

import { Colors, Type } from 'styles'

import ButtonPrimitive from '../ButtonPrimitive/ButtonPrimitive'

export const buttonStyles = css`
  background-color: ${Colors.white};
  border-radius: 1.40625rem; /* 45 / 2 = 22.5px */
  height: 2.8125rem; /* 45px */
  color: ${Colors.brand};
  padding: 0 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${Type.fontWeights.bold};
  border: 2px solid transparent;
  box-sizing: border-box;
  transition: all 200ms ease;
  ${Type.cta};
  filter: drop-shadow(0px 0px 20px rgba(16, 13, 60, 0.2));

  &:hover {
    border: 2px solid ${Colors.brand};
  }

  ${(props) =>
    props.disabled &&
    css`
      cursor: initial;
    `}

  &:focus {
    outline: none;
    border: 2px solid ${Colors.brand};
  }

  &:focus:not(.focus-visible) {
    outline: none;
    border: 2px solid transparent;
  }
`

export const ButtonSecondaryComponent = styled(ButtonPrimitive)`
  ${buttonStyles}
`
