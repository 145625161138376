import { modelName, apiFetch, createAsyncThunk } from './business'

export const getPublicCatalog = createAsyncThunk(
  `${modelName}/getPublicCatalog`,
  async (body) => {
    const options = {
      path: '/public/subscription/catalog/business',
      method: 'GET',
      body,
      authorized: false
    }
    const response = await apiFetch(options)

    return response
  }
)

getPublicCatalog.storeKey = 'catalog'

export default getPublicCatalog
