import { createAsyncThunk } from '@reduxjs/toolkit'

import apiFetch from '../apiFetch'

// This will send a verification email to the user
const requestUserEmail = createAsyncThunk(
  'user/requestUserEmail',
  async (email) => {
    const options = {
      path: '/public/businesses/invitations/me',
      method: 'POST',
      body: email,
      authorized: false
    }
    const response = await apiFetch(options)

    return response
  }
)

export default requestUserEmail
