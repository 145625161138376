import styled from 'styled-components'

import { Colors, Type } from 'styles'

export const DropzoneComponent = styled.div``

const getColor = (props) => {
  if (props.isDragAccept) {
    return Colors.brand
  }
  if (props.isDragReject) {
    return Colors.red
  }
  if (props.isDragActive) {
    return Colors.brand
  }
  return '#ccc'
}

export const Container = styled.div`
  padding: 1.25rem;
  border-width: 2px;
  border-radius: 24px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  cursor: pointer;

  &:hover {
    border-color: ${Colors.grayIcon};
  }
`

export const AvatarContainer = styled.div`
  padding: ${(props) => (props.hasImage ? '0rem;' : '8rem 6rem;')}
  border-width: 2px;
  border-radius: 24px;
  border-color: ${(props) => getColor(props)};
  border-style: ${(props) => (props.hasImage ? 'none;' : 'dashed;')}
  background-color: ${(props) => (props.hasImage ? '#000000' : '#fafafa;')}
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
  cursor: pointer;

  &:hover {
    border-color: ${Colors.grayIcon};
  }
`

export const P = styled.p`
  color: ${Colors.grayIcon};
  font-weight: ${Type.fontWeights.bold};
  margin-bottom: 1rem;
`

export const Info = styled.p`
  color: ${Colors.grayIcon};
  font-size: 0.75rem;
  line-height: 1rem;
`

export const PreviewImage = styled.img`
  height: 15.3125rem;
  background-color: ${Colors.gray10};
`

export const PreviewVideo = styled.video`
  height: 15.3125rem;
  background-color: ${Colors.gray10};
`

export const File = styled.p`
  ${Type.body30};
  color: ${Colors.black};
`

export const Error = styled.p`
  ${Type.body30};
  color: ${Colors.red};
`

export const FileErrors = styled.div`
  margin-top: 1rem;
`

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 14px;
`
