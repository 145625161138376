import { useEffect } from 'react'
import { Button } from '@all-turtles/mmhmm-ui-kit'
import { Dialog, TextField, Loading, withInformed } from 'components'
import { useFormState } from 'informed'
import { setDialogVisibility } from 'store/dialog/slice'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation, Trans } from 'i18n'
import VerificationCodeFields from 'data/verificationCodeFields'
import { deleteAccount, sendDeletionVerificationCode } from 'api/user'
import { signOut } from 'utilities/auth'
import getErrorMessage from 'utilities/getErrorMessage'
import useAccountDeletionFormFramework from 'hooks/useAccountDeletionFormFramework'

import * as S from './styles'

const AccountDeleteDialog = ({ visible }) => {
  const dispatch = useDispatch()
  const { dirty, values } = useFormState()
  const { verificationCode = null } = values
  const currentUser = useSelector((state) => state.api.user?.me)
  const accountDeletionObject = useSelector(
    (state) => state.api.user?.accountDeletion
  )
  const verificationCodeError = useSelector(
    (state) => state.api.user?.accountDeletion?.verificationCodeError
  )

  const { requestInitiated } = accountDeletionObject || {
    requestInitiated: null
  }

  const { status, methods } = useAccountDeletionFormFramework(
    requestInitiated,
    dirty,
    verificationCode
  )

  const { isLoading, isDisabled, isResendAllowed } = status

  const {
    setRequestInFlight,
    setRequestFailed,
    setRequestReset,
    setRequestComplete
  } = methods

  const userId = currentUser?.id
  const email = currentUser?.email

  useEffect(() => {
    // get initial verification code for account deletion
    dispatch(sendDeletionVerificationCode({ userId }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closeModal = () => dispatch(setDialogVisibility({ name: null }))

  const triggerDeleteAccount = async () => {
    setRequestInFlight()
    const { payload } = (await dispatch(
      deleteAccount({ userId, verificationCode })
    )) || { payload: null }

    if (payload.accountDeleted) {
      closeModal()
      await signOut()
    } else {
      setRequestFailed()
    }
    setRequestComplete()
  }

  const reRequestVerificationCode = async () => {
    await dispatch(sendDeletionVerificationCode({ userId }))
    setRequestReset()
  }

  const { t } = useTranslation(['account', 'common'])

  return (
    <Dialog visible={visible} label={t('accountDeleteDialog')}>
      <S.DialogWrap>
        <S.H1>{t('confirmAccountDeletion')}</S.H1>
        <S.DescriptionText>
          <Trans i18nKey="accountDeletionInstructions" t={t}>
            We've just sent a verification code to <strong>{{ email }}</strong>.
            Enter the code below to confirm you want to delete your account.
          </Trans>
        </S.DescriptionText>
        <TextField
          {...VerificationCodeFields({ t }).codeEntry}
          containerStyles={S.VerificationCodeField}
        />
        {verificationCodeError && (
          <S.WarningMessage>
            {getErrorMessage(verificationCodeError, t) ||
              t('errorVerificationCodeErrorUnknown')}
          </S.WarningMessage>
        )}
        {isResendAllowed && (
          <S.ResendCodeBar>
            <S.Link onClick={reRequestVerificationCode}>
              {t('resendVerificationCode')}
            </S.Link>
          </S.ResendCodeBar>
        )}
        <S.DescriptionText>
          <Trans i18nKey="accountDeletionDescription" t={t}>
            All data associated with your account will be permanently deleted,
            including any mmhmm videos you've created. We recommend
            <a
              href="https://help.mmhmm.app/hc/articles/5954260864919"
              target="_blank"
              rel="noopener noreferrer"
            >
              downloading your videos from mmhmm TV
            </a>
            before deleting your account. You'll receive an email once your
            account has been fully deleted.
          </Trans>
        </S.DescriptionText>
        <S.Buttons>
          <Button variant="secondary" onClick={closeModal}>
            {t('cancel')}
          </Button>
          <Button
            disabled={isDisabled}
            variant="danger"
            onClick={triggerDeleteAccount}
          >
            {t('deleteAccount')}
          </Button>
        </S.Buttons>
        {isLoading && (
          <S.LoaderOverlay>
            <Loading visible color="white" />
          </S.LoaderOverlay>
        )}
      </S.DialogWrap>
    </Dialog>
  )
}

export default withInformed(AccountDeleteDialog)
