import styled, { css } from 'styled-components'

import { Breakpoints, Colors, Inputs } from 'styles'
import { fontFamily } from 'styles/Type'

export const Wrap = styled.div`
  flex-grow: 1;
`

export const Error = styled.p`
  ${Inputs.error};
`

export const Field = styled.div`
  position: relative;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.hasError &&
    css`
      margin-bottom: 0.75rem;
    `}

  .ex1-input {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 1rem;
    border: 1px solid ${Colors.black};
    height: 3.3125rem;

    @media (min-width: ${Breakpoints.tablet}) {
      height: 4.125rem;
    }

    &:focus,
    &.focus {
      border: 1px solid ${Colors.brand};
      outline: 1px solid ${Colors.brand};
    }
  }

  .CardWrap {
    overflow: hidden;
  }

  .ex1-label,
  .ex1-wrap {
    overflow: hidden;
  }
`

export const Fields = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${Breakpoints.tablet}) {
    flex-direction: row;
  }

  ${Field} {
    flex: 1;
    margin-inline-start: 0;
  }
`

export const FieldsRow = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${Breakpoints.tablet}) {
    flex-direction: row;

    ${Field} {
      margin-inline-end: 1.5rem;
      flex-basis: 9rem;
      flex-grow: 1;

      &:last-child {
        margin-inline-end: 0;
      }
    }
  }
`

export const Label = styled.label`
  ${Inputs.label};
  margin-bottom: 1rem;
`

export const NoWrapLabel = styled(Label)`
  white-space: nowrap;
`

export const chargebeeInputStyle = {
  color: '#000',
  fontWeight: '500',
  fontFamily,
  fontSize: '25px',
  fontSmoothing: 'antialiased',

  ':focus': {
    color: '#000'
  },

  '::placeholder': {
    color: 'rgba(0, 0, 0, 0.5)'
  }
}

export const chargebeeInvalidStyle = {
  color: Colors.red,

  ':focus': {
    color: Colors.red
  },
  '::placeholder': {
    color: Colors.red
  }
}

export const Spacer = styled.div`
  display: none;

  @media (min-width: ${Breakpoints.tablet}) {
    display: block;
    width: 1.5rem;
  }
`
