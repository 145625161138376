import { useEffect, useRef, useState } from 'react'
import { bool, func, string } from 'prop-types'
import { Button } from '@all-turtles/mmhmm-ui-kit'

import { useTranslation } from 'i18n'
import { InlineSuccessMessage, ReadOnlyInput } from 'components'

import * as S from './styles'

const ShareLinkCopy = ({ createNewSuccess, handleConfirmView, joinLink }) => {
  const linkRef = useRef()
  const { t } = useTranslation(['business', 'common'])

  const [copySuccess, setCopySuccess] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (copySuccess) setCopySuccess(false)
    }, 5000)

    return () => clearTimeout(timeout)
  }, [copySuccess])

  const handleCopyLink = () => {
    navigator.clipboard.writeText(joinLink)
    linkRef.current.select()
    setCopySuccess(true)
  }

  const handleCreateNewClick = () => {
    handleConfirmView('newLink')
  }

  return (
    <S.LinkSection>
      <ReadOnlyInput
        value={joinLink}
        placeholder="https://account.mmhmm.app/business/login?join=abc123"
        label={t('shareableLink')}
        ref={linkRef}
        dir="ltr"
      />
      <S.ButtonRow>
        {copySuccess ? (
          <S.SuccessButton
            type="button"
            variant="success"
            onClick={handleCopyLink}
            minWidth="10.5rem"
          >
            <S.StyledCheck />
            {t('copied')}
          </S.SuccessButton>
        ) : (
          <Button
            type="button"
            variant="secondary"
            onClick={handleCopyLink}
            minWidth="10.5rem"
          >
            {t('copyLink')}
          </Button>
        )}
        <S.CreateNewWrapper>
          <Button type="button" variant="link" onClick={handleCreateNewClick}>
            {t('createNewLink')}
          </Button>
          <InlineSuccessMessage
            visible={createNewSuccess}
            message={t('createNewLinkSuccess')}
          />
        </S.CreateNewWrapper>
      </S.ButtonRow>
    </S.LinkSection>
  )
}

ShareLinkCopy.propTypes = {
  handleConfirmView: func.isRequired,
  createNewSuccess: bool,
  joinLink: string
}

export default ShareLinkCopy
