import { bool, string } from 'prop-types'
import { useTranslation } from 'i18n'
import * as S from './styles'

const InlineSuccessMessage = ({ message, visible }) => {
  const { t } = useTranslation('common')

  return (
    <S.InlineSuccessMessageComponent visible={visible}>
      <S.StyledCheck />
      <span>{message || t('success')}</span>
    </S.InlineSuccessMessageComponent>
  )
}

InlineSuccessMessage.propTypes = {
  message: string,
  visible: bool
}

InlineSuccessMessage.defaultProps = {
  message: null,
  visible: false
}

export default InlineSuccessMessage
