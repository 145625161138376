import styled from 'styled-components'

import { Type } from 'styles'

export const BusinessCancelSubscriptionComponent = styled.div`
  max-width: 33.75rem;
`

export const Date = styled.span`
  font-weight: ${Type.fontWeights.bold};
`
