const apiStatusSelector = ({ state, resource, api }) => {
  const status = state.apiStatus?.[resource]?.[api]

  return {
    loading: status === 'pending',
    error: status === 'rejected',
    success: status === 'fulfilled'
  }
}

export default apiStatusSelector
