import { Link } from 'i18n'
import { Button } from '@all-turtles/mmhmm-ui-kit'
import { isAppStoreSubscription } from 'utilities/subscription'

import {
  LogOut,
  UpgradeUpsell,
  GetSupport,
  SubscriptionPanel,
  SubscriptionRenewalPanel,
  PaymentPanel,
  LoadingPanel,
  SubscriptionEduPanel,
  SubscriptionBusinessPanel,
  DownloadPanel,
  AvatarProfileImage
} from 'components'
import * as S from './styles'

const ManageAccount = ({
  t,
  name,
  email,
  showLoadingPanel,
  active,
  businessMember,
  cancelled,
  hasPayments,
  eduFreeYear,
  hasSubscription,
  showUpgradeUpsell,
  hasLinkedIdp,
  hasIdps,
  subscriptionService,
  avatar,
  guest
}) => {
  const panels = showLoadingPanel ? (
    <LoadingPanel />
  ) : (
    <>
      <DownloadPanel />
      {active && !eduFreeYear && <SubscriptionPanel />}
      {(cancelled || (!businessMember && !hasSubscription && hasPayments)) && (
        <SubscriptionRenewalPanel cancelled={cancelled} />
      )}
      {eduFreeYear && <SubscriptionEduPanel />}
      {(active || isAppStoreSubscription(subscriptionService)) && (
        <PaymentPanel />
      )}
      {businessMember && <SubscriptionBusinessPanel />}
    </>
  )

  return (
    <S.ManageAccountComponent>
      <S.H1>{t('manageAccount')}</S.H1>
      <S.Content>
        <S.StartCol>
          <S.UserInfo>
            <S.ImageAndInfo>
              <AvatarProfileImage avatarUrl={avatar} />
              <S.NameAndEmail>
                <S.Name>
                  <bdi>{name}</bdi>
                </S.Name>
                <S.Email>
                  <bdi>{email}</bdi>
                </S.Email>
              </S.NameAndEmail>
            </S.ImageAndInfo>
            {!guest && (
              <S.EditButtonWrapper>
                <Link href="/edit-account" passHref>
                  <Button variant="secondary" as="a">
                    {t('edit')}
                  </Button>
                </Link>
              </S.EditButtonWrapper>
            )}
          </S.UserInfo>
          <S.EndColMobile>{panels}</S.EndColMobile>
          <S.Links>
            {!hasLinkedIdp && !guest && (
              <S.Li>
                <Link href="/change-password" passHref>
                  <S.A>{t('changePassword')}</S.A>
                </Link>
              </S.Li>
            )}
            {hasIdps && (
              <S.Li>
                <Link href="/connected-apps" passHref>
                  <S.A>{t('account:connectedApps')}</S.A>
                </Link>
              </S.Li>
            )}
            {showUpgradeUpsell && !guest && (
              <S.Li>
                <UpgradeUpsell />
              </S.Li>
            )}
            {(hasSubscription ||
              isAppStoreSubscription(subscriptionService) ||
              businessMember) && (
              <S.Li>
                <GetSupport />
              </S.Li>
            )}
            <S.Li>
              <LogOut />
            </S.Li>
          </S.Links>
        </S.StartCol>
        <S.EndColDesktop>{panels}</S.EndColDesktop>
      </S.Content>
    </S.ManageAccountComponent>
  )
}

export default ManageAccount
