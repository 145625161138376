import styled from 'styled-components'

import { Breakpoints } from 'styles'

export const PaymentHistoryTableComponent = styled.div`
  display: none;

  @media (min-width: ${Breakpoints.tablet}) {
    display: block;
  }
`
