import * as S from './styles'
import { dispatch } from 'store'
import { setToastVisibility } from 'store/toast/slice'

const CloseToast = () => {
  const handleOnClick = () => {
    dispatch(setToastVisibility({ name: null }))
  }

  return (
    <S.CloseToastComponent onClick={handleOnClick}>
      <S.StyledX />
    </S.CloseToastComponent>
  )
}

export default CloseToast
