import styled from 'styled-components'
import { Breakpoints } from 'styles'

export const ChangePasswordComponent = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${Breakpoints.tablet}) {
    flex-direction: row;
  }
`

export const StartCol = styled.div`
  max-width: 540px;
  flex-grow: 1;

  @media (min-width: ${Breakpoints.tablet}) {
    min-width: 375px;
    margin-inline-end: 4.6875rem;
  }

  @media (min-width: ${Breakpoints.desktop}) {
    min-width: 425px;
    margin-inline-end: 6.25rem;
  }
`

export const EndCol = styled.div`
  max-width: 540px;
  margin-top: 2rem;

  @media (min-width: ${Breakpoints.tablet}) {
    max-width: 380px;
    margin-top: 0;
  }
`

export const H1 = styled.h1`
  margin-bottom: 2.5rem;
`
