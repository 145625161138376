const YouTube = (props) => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby="youtube-title"
      {...props}
    >
      <title id="youtube-title">YouTube</title>
      <g clipPath="url(#clip0)">
        <path
          d="M24.7057 9.47363H10.7109C8.72561 9.47363 7.13086 11.0684 7.13086 13.0537V20.5393C7.13086 22.5246 8.72561 24.1193 10.7109 24.1193H24.7057C26.691 24.1193 28.2857 22.5246 28.2857 20.5393V13.0537C28.2857 11.0684 26.691 9.47363 24.7057 9.47363ZM21.8416 16.9267L15.4952 20.1813C15.4301 20.2138 15.3324 20.1813 15.2673 20.1162C15.2673 20.0836 15.2348 20.0836 15.2348 20.0511V13.5419C15.2348 13.4442 15.2999 13.3791 15.3975 13.3791C15.4301 13.3791 15.4626 13.3791 15.4626 13.4117L21.8091 16.6663C21.8742 16.6988 21.9067 16.7965 21.8742 16.8941C21.9067 16.8941 21.8742 16.9267 21.8416 16.9267Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="21.1549"
            height="14.6457"
            fill="white"
            transform="translate(7.13086 9.47363)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default YouTube
