import * as React from 'react'
import { useRouter } from 'next/router'
import noop from 'lodash/noop'

const isBrowser = typeof window !== 'undefined'
const hidePurchaseForAppStoreKey = 'hidePurchaseForAppStore'
const safeSessionStorage = isBrowser
  ? window.sessionStorage
  : {
      getItem: noop,
      setItem: noop
    }

const useHasHideInQuery = () => {
  const router = useRouter()
  const shouldHide = router.query.appleAppStore === 'true'
  if (!isBrowser) return shouldHide
  const fallbackParams = new URLSearchParams(window.location.search)
  const fallbackShouldHide = fallbackParams.get('appleAppStore') === 'true'
  return shouldHide || fallbackShouldHide
}

const HidePurchaseForAppStoreContext = React.createContext(false)

export const useHidePurchaseForAppStore = () => {
  const context = React.useContext(HidePurchaseForAppStoreContext)
  return context
}

export const HidePurchaseForAppStoreProvider = ({ children }) => {
  const shouldHideByQuery = useHasHideInQuery()
  const shouldHideByStorage =
    safeSessionStorage.getItem(hidePurchaseForAppStoreKey) === 'true'

  React.useEffect(() => {
    if (shouldHideByQuery) {
      safeSessionStorage.setItem(hidePurchaseForAppStoreKey, 'true')
    }
  }, [shouldHideByQuery])

  return (
    <HidePurchaseForAppStoreContext.Provider
      value={shouldHideByQuery || shouldHideByStorage}
    >
      {children}
    </HidePurchaseForAppStoreContext.Provider>
  )
}
