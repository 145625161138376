import { Link, useTranslation } from 'i18n'
import {
  BusinessMembersTable,
  BusinessInvitationsTable,
  BusinessApprovedDomains,
  BusinessAvailableDomains
} from 'components'

import * as S from './styles'

const BusinessMembers = ({
  approvedDomains,
  availableDomains,
  business,
  businessPermissions
}) => {
  const { t } = useTranslation(['business', 'common'])

  const memberCount = business.members?.total || business.members?.count
  const inviteCount = business.invitations?.total || business.invitations?.count
  const approvedDomainCount = approvedDomains?.count
  const availableDomainCount = availableDomains?.count

  const canModifyDomains = businessPermissions?.canModifyDomains

  return (
    <>
      <S.TableWrap>
        <S.TableTitle>
          {t('approvedDomainsHeader', { count: approvedDomainCount })}
        </S.TableTitle>
        <BusinessApprovedDomains canAdmin={canModifyDomains} />
      </S.TableWrap>

      {canModifyDomains && (
        <S.TableWrap>
          <S.BorderTableHeading>
            <S.TableTitle>
              {t('availableDomainsHeader', { count: availableDomainCount })}
            </S.TableTitle>
            <BusinessAvailableDomains canAdmin={canModifyDomains} />
          </S.BorderTableHeading>
        </S.TableWrap>
      )}

      <S.TableWrap>
        <S.TableHeading>
          <S.TableTitle>
            {t('invitesCount', { count: inviteCount })}
          </S.TableTitle>
          {inviteCount > 0 && (
            <Link href="/business/invitations" passHref>
              <S.ArrowLink>
                {t('allInvitations')}
                <S.StyledArrow role="presentation" />
              </S.ArrowLink>
            </Link>
          )}
        </S.TableHeading>
        <BusinessInvitationsTable limit={5} minimalNoResults />
      </S.TableWrap>

      <S.TableWrap>
        <S.TableHeading>
          <S.TableTitle>
            {t('membersCount', { count: memberCount })}
          </S.TableTitle>
          {memberCount > 0 && (
            <Link href="/business/all-members" passHref>
              <S.ArrowLink>
                {t('allMembers')}
                <S.StyledArrow role="presentation" />
              </S.ArrowLink>
            </Link>
          )}
        </S.TableHeading>
        <BusinessMembersTable limit={5} />
      </S.TableWrap>
    </>
  )
}

export default BusinessMembers
