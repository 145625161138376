import { useSelector } from 'react-redux'
import { shape, string } from 'prop-types'
import isEqual from 'lodash/isEqual'
import { Button } from '@all-turtles/mmhmm-ui-kit'

import { useTranslation } from 'i18n'
import { MemberActionsContent } from 'components'
import * as S from './styles'

const MemberActions = ({ member }) => {
  const { t } = useTranslation('common')
  const currentUser = useSelector((state) => state.api.user?.me, isEqual)

  const tippyConfig = {
    interactive: true,
    hideOnClick: true,
    trigger: 'click',
    placement: 'right',
    content: <MemberActionsContent member={member} />
  }

  // If member is owner, actions are only available if currentUser is also an owner and not self
  const noAvailableActions =
    member.role === 'owner' &&
    (currentUser?.businessRole !== 'owner' || member.id === currentUser?.id)

  return (
    <div>
      {!noAvailableActions && (
        <S.StyledTooltip tippyConfig={tippyConfig}>
          <Button variant="link" small>
            {t('edit')}
          </Button>
        </S.StyledTooltip>
      )}
    </div>
  )
}

MemberActions.propTypes = {
  /**
   * The user object associated with this member.
   */
  member: shape({
    id: string.isRequired
  })
}

export default MemberActions
