import { useDispatch, useSelector } from 'react-redux'

import { useTranslation } from 'i18n'
import { getBillingInfo, updateBillingInfo } from 'api/business'
import { HandleAction, Loading } from 'components'
import BusinessBillingInfoForm from './BusinessBillingInfoForm'
import * as S from './styles'

const BusinessBillingInfo = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation('account')

  const billingInfo = useSelector((state) => state.api.business?.billingInfo)
  const businessPermissions = useSelector(
    (state) => state.api.user?.me?.businessPermissions
  )
  const canView = businessPermissions?.canViewBilling

  if (!canView) return null

  const initialValues = {
    billingAddress: billingInfo?.billingAddress,
    taxID: billingInfo?.taxID
  }

  const handleSubmit = async (values) => {
    await dispatch(updateBillingInfo(values))
    dispatch(getBillingInfo())
  }

  return (
    <S.ContentWrapper>
      <HandleAction
        resource="business"
        api="updateBillingInfo"
        toastDescription={t('billingInfoSuccess')}
        stayOnPage
      />
      <S.Header>{t('taxIdAddressLong')}</S.Header>
      <S.P>{t('billingInfoDesc')}</S.P>
      {billingInfo ? (
        <BusinessBillingInfoForm
          initialValues={initialValues}
          canModify={businessPermissions?.canModifyBilling}
          onSubmit={handleSubmit}
        />
      ) : (
        <Loading visible />
      )}
    </S.ContentWrapper>
  )
}

export default BusinessBillingInfo
