import arraySort from 'array-sort'
import cloneDeep from 'lodash/cloneDeep'

const sort = (data, prop, sortInverse) => {
  const cloned = cloneDeep(data)
  const sorted = arraySort(cloned, prop, { reverse: sortInverse })

  // Always sort null values at the end
  sorted.sort((a, b) => {
    return (b[prop] != null) - (a[prop] != null)
  })

  return sorted
}

export default sort
