import styled from 'styled-components'

import { Breakpoints } from 'styles'

export const Wrap = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media (min-width: ${Breakpoints.tablet}) {
    flex-wrap: nowrap;
  }
`

export const Header = styled.h1`
  margin-bottom: 2.5rem;
`
