import styled, { css } from 'styled-components'
import { Colors, Type } from 'styles'

export const NavItemsComponent = styled.div``

const padding = '0.5rem'

export const A = styled.a`
  ${Type.cta};
  color: ${Colors.black};
  margin-inline-end: 1rem;
  font-weight: ${Type.fontWeights.bold};
  padding: ${padding};
  position: relative;
  box-sizing: border-box;

  &:after {
    content: '';
    box-sizing: border-box;
    display: block;
    height: 0.125rem;
    width: calc(100% - 1rem);
    background-color: ${Colors.brand};
    position: absolute;
    bottom: 0;
    left: ${padding};
    transition: opacity 2000ms ease;
    opacity: 0;

    ${(props) =>
      props.isSelected &&
      css`
        opacity: 1;
      `}

    [dir='rtl'] & {
      left: unset;
      right: ${padding};
    }
  }

  &:last-child {
    margin-inline-end: -0.5rem;
  }
`
