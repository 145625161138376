import { bool, string } from 'prop-types'
import { Dialog } from 'components'
import { dispatch } from 'store'
import { setDialogVisibility } from 'store/dialog/slice'
import { Trans, useTranslation } from 'i18n'
import * as S from './styles'

const propTypes = {
  visible: bool.isRequired,
  buttonText: string,
  description: string,
  showSupportLink: bool,
  title: string
}

const defaultProps = {
  buttonText: null,
  description: null,
  showSupportLink: false,
  title: null
}

const ErrorDialog = ({
  visible,
  title,
  description,
  buttonText,
  showSupportLink
}) => {
  const { t } = useTranslation('common')
  const supportUrl = t('submitRequestUrl')

  const dismiss = () => {
    dispatch(setDialogVisibility({ name: null }))
  }

  return (
    <Dialog visible={visible} label={title || t('error')}>
      <S.Wrap>
        <S.Title>{title || t('error')}</S.Title>
        {description && <p>{description}</p>}
        {showSupportLink && (
          <S.Support>
            <Trans i18nKey="errorAssistanceLink" t={t}>
              If this continues to happen, please
              <a href={supportUrl} target="_blank" rel="noopener noreferrer">
                submit a request for assistance here
              </a>
              .
            </Trans>
          </S.Support>
        )}
        <S.StyledButtonSecondary onClick={dismiss}>
          {buttonText || t('ok')}
        </S.StyledButtonSecondary>
      </S.Wrap>
    </Dialog>
  )
}

ErrorDialog.propTypes = propTypes
ErrorDialog.defaultProps = defaultProps

export default ErrorDialog
