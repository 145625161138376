import styled, { css } from 'styled-components'

export const inputStyles = css`
  margin-bottom: 2rem;
`

export const SubmitWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
