import styled from 'styled-components'

export const ForgotPasswordComponent = styled.div`
  max-width: 540px;
`

export const H1 = styled.h1`
  margin-bottom: 0.625rem;
`

export const P = styled.p`
  margin-bottom: 2.5rem;
  max-width: 85%;
`
