import ReactGA from 'react-ga'
import mixpanel from 'mixpanel-browser'
import noop from 'lodash/noop'
import { sanitizeURLParams } from 'utilities/sanitize'
import { setUser as setSentryUser } from '@sentry/nextjs'

const mixpanelToken = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN
const gaTrackingId = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_ID

export const eventTypes = {
  click: 'Click',
  cancelSubscriptionReason: 'cancelSubscriptionReason',
  cancelBusinessSubscriptionReason: 'cancelBusinessSubscriptionReason',
  profilePhotoUpload: 'profilePhotoUpload',
  profilePhotoDelete: 'profilePhotoDelete'
}

const createGAService = () => {
  if (gaTrackingId && typeof window !== 'undefined') {
    ReactGA.initialize(gaTrackingId)

    return {
      pageView: () => {
        ReactGA.set({ page: window.location.pathname })
        ReactGA.pageview(window.location.pathname)
      }
    }
  }
  return {
    pageView: noop
  }
}

const createMixpanelService = () => {
  let identified = false

  if (mixpanelToken && typeof window !== 'undefined') {
    mixpanel.init(mixpanelToken, {
      debug: process.env.NODE_ENV === 'development',
      persistence: 'localStorage'
    })

    // Register superproperties
    mixpanel.register({
      app: 'mmhmm.web',
      component: 'account'
    })

    return {
      track: (event, properties) => {
        mixpanel.track(event, {
          $current_url: sanitizeURLParams(window.location.href),
          ...properties
        })
      },
      identify: (userId) => {
        if (!identified) {
          mixpanel.identify(userId)
          identified = true
          // TODO: Move to a separate service
          setSentryUser({ id: userId })
        }
      },
      reset: () => {
        mixpanel.reset()
        identified = false
        // TODO: Move to a separate service
        setSentryUser(null)
      }
    }
  }

  return {
    track: noop,
    identify: noop,
    reset: noop
  }
}

export const gaService = createGAService()
export const mixpanelService = createMixpanelService()
