import { func, shape, string } from 'prop-types'
import { useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'
import { Button } from '@all-turtles/mmhmm-ui-kit'

import apiStatus from 'store/selectors/apiStatus'
import { useTranslation } from 'i18n'
import { billingFields } from 'data'
import { TextArea, TextField, withInformed } from 'components'

const apiStatusSelector = (state) =>
  apiStatus({
    state,
    resource: 'commerce',
    api: 'updateBillingInfo'
  })

const EditBillingForm = () => {
  const { t } = useTranslation(['account', 'common'])

  const status = useSelector(apiStatusSelector, isEqual)

  const { taxID, billingAddress } = billingFields({ t })

  return (
    <>
      <TextField {...taxID} />
      <TextArea {...billingAddress} containerStyles={{ margin: '1.5rem 0' }} />
      <Button type="submit" loading={status.loading}>
        {t('common:save')}
      </Button>
    </>
  )
}

EditBillingForm.propTypes = {
  /* Handled by informed */
  initialValues: shape({
    billingAddress: string,
    taxID: string
  }),
  onSubmit: func
}

export default withInformed(EditBillingForm)
