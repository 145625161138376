import { bool, number, shape, string } from 'prop-types'
import { useFormState } from 'informed'

import { CheckoutTotal } from 'components'

const CheckoutTotalContainer = ({ catalog }) => {
  const taxable = catalog?.taxable
  const formState = useFormState()
  const selectedPlan = formState.values.planId

  const planPrices = catalog?.annualPlan
    ? {
        [catalog.monthlyPlan.id]: { price: catalog.monthlyPlan.displayPrice },
        [catalog.annualPlan.id]: { price: catalog.annualPlan.displayPrice }
      }
    : null

  if (!selectedPlan || !planPrices) return null

  const total = planPrices?.[selectedPlan]?.price

  return <CheckoutTotal taxable={taxable} total={total} />
}

CheckoutTotalContainer.propTypes = {
  catalog: shape({
    taxable: bool,
    monthlyPlan: shape({
      id: string,
      displayPrice: string,
      price: number
    }),
    yearlyPlan: shape({
      id: string,
      displayPrice: string,
      price: number
    })
  })
}

CheckoutTotalContainer.defaultProps = {
  catalog: {}
}

export default CheckoutTotalContainer
