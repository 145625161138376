const getMemberData = ({ column, value }, t) => {
  switch (column) {
    case 'linkedIdPName': {
      return value ?? t('common:password')
    }
    case 'role':
    case 'status': {
      const dataMap = {
        role: {
          member: t('business:member'),
          admin: t('business:admin'),
          owner: t('business:owner')
        },
        status: {
          active: t('business:activeStatus'),
          deactivated: t('business:deactivatedStatus'),
          inactive: t('business:deactivatedStatus'),
          'inactive-business-subscription-canceled': t(
            'business:deactivatedStatus'
          )
        }
      }

      return value ? dataMap[column][value] || value : ''
    }
  }

  return value
}

export default getMemberData
