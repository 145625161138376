import { useState } from 'react'
import { shape, string } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { unwrapResult } from '@reduxjs/toolkit'
import { useRouter } from 'next/router'

import { Trans, useTranslation } from 'i18n'
import { Auth } from 'api'
import { requestUserEmail } from 'api/user'
import { requestInviteFromJoinToken } from 'api/business'
import { setDialogVisibility } from 'store/dialog/slice'
import { setNotificationVisibility } from 'store/notification/slice'
import { setBusinessInfo } from 'store/ui/slice'
import getErrorMessage from 'utilities/getErrorMessage'
import { getSignInAppUrl } from 'utilities/getSignInAppUrl'
import { BusinessEmailForm } from 'components'
import * as S from './styles'

const BusinessEmail = ({ currentUser }) => {
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation(['common', 'business'])
  const router = useRouter()
  const dispatch = useDispatch()

  const businessInfo = useSelector((state) => state.ui?.businessInfo)
  const businessName = businessInfo?.business?.name
  const joinToken = businessInfo?.joinLinkToken

  // Shared join link for auto-approve domain business will contain id query
  const businessId = router.query?.id

  // Track if user arrived via join link
  const viaJoinLink = !!(joinToken || businessId)

  const handleError = (err) => {
    console.error(err)
    const errorMsg = getErrorMessage(err?.code, t)
    dispatch(
      setDialogVisibility({
        name: 'ErrorDialog',
        description: errorMsg || t('somethingWentWrong'),
        showSupportLink: !errorMsg
      })
    )

    setLoading(false)
  }

  const routeOnSuccess = ({ nextStep }) => {
    const routesMap = {
      businessJoinDomain: '/business/verify',
      businessJoinChoiceInvited: '/business/join',
      businessCreateChoiceConvertPersonalAccount: '/business/link-account',
      businessCreate: '/business/purchase'
    }

    router.push(routesMap[nextStep])
  }

  const setResultsAndRoute = ({ email, res }) => {
    const newBusinessInfo = {
      ...businessInfo,
      email,
      emailStatus: res.emailStatus,
      viaJoinLink, // Used on /verify page
      nextStep: res.nextStep, // Used on /verify page
      business: {
        ...businessInfo?.business,
        ...res.business
      }
    }

    dispatch(setBusinessInfo(newBusinessInfo))

    if (res.nextStep === 'businessJoinDomain') {
      //  If user is in auto-approve domain, send verification email
      dispatch(requestUserEmail({ email }))
    } else if (joinToken && res.emailStatus !== 'business') {
      // Send invite email for user with join link then route to /verify;
      // Existing business users should skip this step
      dispatch(requestInviteFromJoinToken({ email, token: joinToken }))
      routeOnSuccess({ nextStep: 'businessJoinDomain' })
      return
    } else if (businessId && res.nextStep !== 'businessLogin') {
      /**
       * If businessId present but nextStep !== businessJoinDomain, user was trying to join
       * with the auto-approve domain join link using an email from an unapproved domain;
       * don't progress, show error instead
       */
      dispatch(
        setNotificationVisibility({
          type: 'error',
          message: t('business:domainDoesntMatch')
        })
      )
      setLoading(false)
      return
    }

    if (res.nextStep === 'businessLogin') {
      const signInURL = getSignInAppUrl({
        path: '/sign-in',
        query: { email },
        context: 'business/purchase'
      })
      router.push(signInURL)
    } else {
      routeOnSuccess(res)
    }
  }

  const handleSubmit = ({ workEmail }) => {
    setLoading(true)
    const data = { email: workEmail }
    dispatch(Auth.checkBusinessEmail(data))
      .then(unwrapResult)
      .then((res) => {
        if (!res || !res.nextStep) {
          setLoading(false)
          return
        }

        setResultsAndRoute({ email: workEmail, res })
      })
      .catch(handleError)
  }

  const header =
    businessName && viaJoinLink ? (
      <Trans i18nKey="business:joinMmhmmFor" t={t} businessName={businessName}>
        Join mmhmm for <bdi>{{ businessName }}</bdi>
      </Trans>
    ) : (
      t('business:businessSignUp')
    )

  const initialValues = {
    workEmail: currentUser?.email
  }

  return (
    <>
      <S.PageHeader>{header}</S.PageHeader>
      <BusinessEmailForm
        onSubmit={handleSubmit}
        loading={loading}
        initialValues={initialValues}
        isJoining={viaJoinLink}
      />
    </>
  )
}

BusinessEmail.propTypes = {
  currentUser: shape({
    email: string
  })
}

BusinessEmail.defaultProps = {
  currentUser: undefined
}

export default BusinessEmail
