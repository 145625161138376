import isEmpty from 'lodash/isEmpty'

import * as S from './styles'
import formatDate from 'utilities/formatDate'

const Cell = (props) => {
  const {
    customCellComponents,
    dateKeys,
    column,
    data,
    rowData,
    dataTransform,
    transformKeys
  } = props

  if (typeof data === 'object' && isEmpty(data)) {
    return ''
  }

  if (customCellComponents?.[column]) {
    return customCellComponents?.[column](rowData)
  }

  if (dateKeys && dateKeys.indexOf(column) >= 0) {
    return formatDate(data).toString()
  } else if (dataTransform && transformKeys.includes(column)) {
    return dataTransform({ column, value: data })
  }

  return data ? data.toString() : ''
}

const TableRow = (props) => {
  const {
    rowData,
    columns,
    customCellComponents,
    dateKeys,
    dataTransform,
    transformKeys
  } = props

  return (
    <S.Row>
      {columns.map((item, index) => (
        <S.Cell key={index}>
          <Cell
            column={item}
            data={rowData[item]}
            customCellComponents={customCellComponents}
            dateKeys={dateKeys}
            rowData={rowData}
            dataTransform={dataTransform}
            transformKeys={transformKeys}
          />
        </S.Cell>
      ))}
    </S.Row>
  )
}

export default TableRow
