import { LazyTippy } from 'components'

const Tooltip = ({ tippyConfig, children, ...rest }) => {
  return (
    <LazyTippy hideOnClick {...tippyConfig} {...rest}>
      {children}
    </LazyTippy>
  )
}

export default Tooltip
