import { EMAIL } from 'utilities/regex'

const businessFields = ({ t }) => ({
  workEmail: {
    field: 'workEmail',
    placeholder: 'name@work-email.com',
    type: 'text',
    errorMessage: t('common:fieldRequired'),
    required: true,
    validateOnBlur: true,
    validate: (value) => {
      if (!EMAIL.test(value)) return t('account:invalidEmail')
    },
    /** Emails should not be reversed */
    dir: 'ltr'
  },
  businessName: {
    field: 'businessName',
    label: t('business:name'),
    placeholder: t('business:businessNamePlaceholder'),
    type: 'text',
    required: t('common:fieldRequired'),
    validateOnBlur: true,
    /** The direction of the input may be different than the selected page language */
    dir: 'auto'
  },
  firstName: {
    field: 'userFirstName',
    label: t('firstName'),
    placeholder: 'firstNamePlaceholder',
    required: t('common:fieldRequired'),
    validateOnBlur: true,
    autocomplete: 'given-name'
  },
  lastName: {
    field: 'userLastName',
    label: t('lastName'),
    placeholder: 'lastNamePlaceholder',
    required: t('common:fieldRequired'),
    validateOnBlur: true,
    autocomplete: 'family-name'
  },
  enableLinksGroup: {
    field: 'enableLinksGroup',
    fields: [
      {
        label: t('business:groupMembers'),
        value: 'everyone',
        id: 'everyJoinLinks'
      },
      { label: t('business:onlyAdmin'), value: 'admins', id: 'adminJoinLinks' },
      { label: t('business:noneAllowed'), value: 'none', id: 'noneJoinLinks' }
    ]
  },
  sendJoinNotificationsGroup: {
    field: 'sendJoinNotifications',
    fields: [
      {
        label: t('business:emailToAdmin'),
        value: true,
        id: 'enableNotifications'
      },
      {
        label: t('business:dontEmail'),
        value: false,
        id: 'disableNotifications'
      }
    ]
  },
  taxID: {
    field: 'taxID',
    label: t('account:taxId'),
    placeholder: t('business:optional'),
    type: 'text',
    noError: true,
    /** Should not be reversed */
    dir: 'ltr'
  },
  billingAddress: {
    field: 'billingAddress',
    label: t('account:billingAddress'),
    placeholder: t('business:optional'),
    type: 'textarea',
    noError: true
  }
})

export default businessFields
