import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as S from './styles'
import { getPayments } from 'api/commerce'
import MobilePaymentHistory from '../MobilePaymentHistory/MobilePaymentHistory'
import PaymentHistoryTable from '../PaymentHistoryTable/PaymentHistoryTable'
import Loading from '../Loading/Loading'
import getPaymentHistory from 'utilities/getPaymentHistory'
import { useTranslation } from 'i18n'

const BillingHistory = () => {
  const dispatch = useDispatch()
  const payments = useSelector((state) => state.api.commerce?.payments)
  const { t } = useTranslation('account')

  useEffect(() => {
    dispatch(getPayments())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!payments) return <Loading visible />

  const paymentHistory = getPaymentHistory({ items: payments.items })

  return (
    <S.BillingHistoryComponent>
      <S.H2>{t('paymentHistory')}</S.H2>
      {paymentHistory ? (
        <>
          <PaymentHistoryTable paymentHistory={paymentHistory} />
          <MobilePaymentHistory data={paymentHistory} />
        </>
      ) : (
        <p>{t('noPayments')}</p>
      )}
    </S.BillingHistoryComponent>
  )
}

export default BillingHistory
