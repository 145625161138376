import * as S from './styles'

const ButtonSecondary = ({ children, ...rest }) => {
  return (
    <S.ButtonSecondaryComponent {...rest}>
      {children}
    </S.ButtonSecondaryComponent>
  )
}

export default ButtonSecondary
