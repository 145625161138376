function useSubscriptionStatus(subscription, payments) {
  const hasPayments = !!payments?.length

  if (!subscription?.status) {
    return {
      hasSubscription: false,
      hasPayments
    }
  }

  const eduFreeYear = subscription.planId === 'premium-edu-1-year'

  return {
    hasSubscription: true,
    active: subscription.status === 'active',
    cancelled: subscription.status === 'non_renewing' && !eduFreeYear,
    hasPayments,
    eduFreeYear
  }
}

export default useSubscriptionStatus
