import { modelName, apiFetch, createAsyncThunk } from './business'

const deleteRoom = createAsyncThunk(
  `${modelName}/deleteRoom`,
  async ({ id }) => {
    const options = {
      path: `/businesses/me/admin/rooms/${id}`,
      method: 'DELETE'
    }
    const response = await apiFetch(options)

    return response
  }
)

deleteRoom.fulfilledReducer = (state, action) => {
  const rooms = state.business.customRooms?.items
  const items = rooms.filter((el) => el.id !== action.meta.arg.id)

  state.business.customRooms = {
    count: items.count,
    items
  }
}

export default deleteRoom
