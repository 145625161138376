import { createSlice } from '@reduxjs/toolkit'

import * as Apis from 'api'
import merge from 'deepmerge'

const extraReducers = {}

Object.keys(Apis).forEach((Api) => {
  Object.keys(Apis[Api]).forEach((key) => {
    // modelName is exported,
    // but it's not an api call so ignore it
    if (key === 'modelName') return

    const storeKey = Apis[Api][key].storeKey || key

    // This needs to be documented.
    extraReducers[Apis[Api][key].fulfilled] = (state, action) => {
      if (!state[Apis[Api].modelName]) {
        state[Apis[Api].modelName] = {}
      }
      state[Apis[Api].modelName][storeKey] = action.payload

      /**
       * If a custom fulfilledReducer exists for this API, execute it.
       */
      if (Apis[Api][key].fulfilledReducer) {
        Apis[Api][key].fulfilledReducer(state, action)
      }
    }

    extraReducers[Apis[Api][key].rejected] = (state, action) => {
      const error = action.error
      // This fixes Sentry logging
      if (error && !(error instanceof Error) && error.message)
        Object.setPrototypeOf(error, Error.prototype)
      const merged = merge(state[Apis[Api].modelName], {
        [storeKey]: {
          error: action.error
        }
      })

      state[Apis[Api].modelName] = merged
    }
  })
})

const slice = createSlice({
  name: 'api',
  initialState: {},
  extraReducers
})

export default slice.reducer
