const FREE_USER = 'free'
const PREMIUM_USER = 'premium'
const TRIAL_USER = 'trial'

// Defined roles for access to specific pages
// Business vs Personal, and then defined role within
// - Business roles (defined via backend `businessRole` payload):
//   - member: member of a business account,
//   - owner: owner of a business account,
//   - admin: admin of a business account
// - Personal roles (defined by subscription level):
//   - premium: paid user
//   - free trial: non subscription user with premium feature set, cannot view billing
//   - free: non subscription user

const userTypeSelector = (state) => {
  let userType = FREE_USER
  const hasBusinessRole = !!state.api?.user?.me?.businessRole

  if (hasBusinessRole) {
    // member, owner or admin
    userType = state.api.user?.me?.businessRole
  } else if (state.api?.user?.me?.premium) {
    userType = state.api.user?.me.freeTrial ? TRIAL_USER : PREMIUM_USER
  }

  return { userType }
}

export const isUserOfTypes = (state, types = []) => {
  return [...types].includes(userTypeSelector(state).userType)
}
