import styled from 'styled-components'
import { Button } from '@all-turtles/mmhmm-ui-kit'

import { Colors } from 'styles'

export const DeleteBusinessInviteButtonComponent = styled(Button)`
  color: ${Colors.red};
  margin-inline-end: 1rem;

  &:hover {
    color: ${Colors.red};
  }
`
