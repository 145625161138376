import { useState } from 'react'
import {
  Disclosure,
  DisclosurePanel,
  DisclosureButton
} from '@reach/disclosure'

import { useTranslation } from 'i18n'
import { TextArea, TextField } from 'components'
import { businessFields } from 'data'
import * as S from './styles'

const CreateBusiness = () => {
  const { t } = useTranslation(['business', 'common', 'account'])

  const [showDisclosure, setShowDisclosure] = useState(false)

  const { taxID, billingAddress, businessName } = businessFields({ t })

  return (
    <S.Container>
      <S.H2>{t('business')}</S.H2>
      <S.Field>
        <TextField {...businessName} dir="auto" />
      </S.Field>
      <Disclosure
        open={showDisclosure}
        onChange={() => setShowDisclosure(!showDisclosure)}
      >
        <DisclosureButton as={S.ToggleButton} variant="link" small>
          {t('account:addTaxIdAddress')}
          <S.Spacer />
          <S.StyledArrow />
        </DisclosureButton>
        <DisclosurePanel as={S.PanelWrapper}>
          <S.DisclosureField>
            <S.Label htmlFor="taxID">{t('account:taxId')}</S.Label>
            <S.Info>{t('account:taxIdInfoDesc')}</S.Info>
            <TextField {...taxID} label={null} />
          </S.DisclosureField>
          <S.DisclosureField>
            <S.Label htmlFor="billingAddress">
              {t('account:billingAddress')}
            </S.Label>
            <S.Info>{t('account:billingAddrInfoDesc')}</S.Info>
            <TextArea {...billingAddress} label={null} />
          </S.DisclosureField>
        </DisclosurePanel>
      </Disclosure>
    </S.Container>
  )
}

export default CreateBusiness
