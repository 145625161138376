import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { captureException } from 'utilities/sentry'
import isEqual from 'lodash/isEqual'

import { getBusinessSettings } from 'api/business'
import {
  Dialog,
  HandleAction,
  InviteMembersEmailTab,
  InviteMembersLinkTab,
  InviteMembersLinkConfirm,
  Tabs
} from 'components'
import { Trans, useTranslation } from 'i18n'
import * as S from './styles'

const InviteMembers = ({ visible }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('business')
  const currentUser = useSelector((state) => state.api.user?.me, isEqual)
  const businessName = currentUser?.businessName

  const [resetSuccess, setResetSuccess] = useState(false)
  const [createNewSuccess, setCreateNewSuccess] = useState(false)

  useEffect(() => {
    dispatch(getBusinessSettings())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (resetSuccess) setResetSuccess(false)
      if (createNewSuccess) setCreateNewSuccess(false)
    }, 5000)

    return () => clearTimeout(timeout)
  }, [resetSuccess, createNewSuccess])

  const handleResetSuccess = () => {
    setResetSuccess(true)
  }

  const handleCreateNewSuccess = () => {
    setCreateNewSuccess(true)
  }

  const [showConfirmView, setShowConfirmView] = useState(false)

  const tabData = [
    {
      label: t('shareLink'),
      content: (
        <InviteMembersLinkTab
          handleConfirmView={setShowConfirmView}
          resetSuccess={resetSuccess}
          createNewSuccess={createNewSuccess}
        />
      )
    },
    { label: t('email'), content: <InviteMembersEmailTab /> }
  ]

  const handleError = (error) => {
    captureException(error, (s) => s.setExtra('confirmView', showConfirmView))
  }

  return (
    <Dialog visible={visible} label={t('inviteMembers')}>
      <HandleAction
        resource="business"
        api="resetAllLinks"
        onSuccess={handleResetSuccess}
        onError={handleError}
        stayOnPage
      />
      <HandleAction
        resource="business"
        api="resetBusinessLink"
        onSuccess={handleCreateNewSuccess}
        onError={handleError}
        stayOnPage
      />
      <S.InviteMembersComponent>
        {showConfirmView ? (
          <InviteMembersLinkConfirm
            view={showConfirmView}
            goBack={() => setShowConfirmView(false)}
          />
        ) : (
          <>
            <S.Title>
              <Trans i18nKey="inviteTo" t={t} businessName={businessName}>
                Invite to <bdi>{{ businessName }}</bdi>
              </Trans>
            </S.Title>
            <Tabs tabData={tabData} />
          </>
        )}
      </S.InviteMembersComponent>
    </Dialog>
  )
}

export default InviteMembers
