import styled, { css } from 'styled-components'

import { Colors } from 'styles'

export const OptionsWrapper = styled.div`
  margin: 2.5rem 0;
  padding: 2rem 0;
  border-bottom: 1px solid ${Colors.gray10};
  border-top: 1px solid ${Colors.gray10};
`

export const StyledRadioPill = css`
  margin-bottom: 0.75rem;
`
