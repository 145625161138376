import { arrayOf, element, oneOfType, shape, string } from 'prop-types'
import { Tabs, TabPanels, TabPanel } from '@reach/tabs'
import '@reach/tabs/styles.css'

import * as S from './styles'

const StyledTabs = ({ tabData }) => {
  return (
    <Tabs>
      <S.StyledTabList>
        {tabData.map((tab, index) => (
          <S.StyledTab key={index}>{tab.label}</S.StyledTab>
        ))}
      </S.StyledTabList>
      <TabPanels>
        {tabData.map((tab, index) => (
          <TabPanel key={index}>{tab.content}</TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  )
}

StyledTabs.propTypes = {
  tabData: arrayOf(
    shape({
      label: oneOfType([string, element]).isRequired,
      content: oneOfType([string, element]).isRequired
    })
  ).isRequired
}

export default StyledTabs
