import styled, { css } from 'styled-components'

import { Colors, Type } from 'styles'

import ButtonPrimitive from '../ButtonPrimitive/ButtonPrimitive'
import Loading from '../Loading/Loading'

const buttonStyles = css`
  background-color: ${Colors.brand};
  border-radius: 1.40625rem; /* 45 / 2 = 22.5px */
  height: 2.8125rem; /* 45px */
  color: ${Colors.white};
  padding: 0 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: ${Type.fontWeights.bold};
  border: 2px solid ${Colors.brand};
  box-sizing: border-box;
  transition: all 200ms ease;
  ${Type.cta};
  flex-shrink: 0;

  ${(props) =>
    props.isLoading &&
    css`
      cursor: initial;
      background-color: ${Colors.gray};
      border: 2px solid ${Colors.gray};
      color: ${Colors.black};
    `}

  &:hover {
    background-color: ${Colors.white};
    color: ${Colors.black};
    border: 2px solid ${Colors.brand};

    ${(props) =>
      props.isLoading &&
      css`
        background-color: ${Colors.gray};
        color: ${Colors.gray30};
        border: 2px solid transparent;
      `}
  }

  &:focus {
    outline: none;
    border: 2px solid ${Colors.brand};
    box-shadow: 0 0 0 3px white, 0 0 0 6px ${Colors.brand};
  }

  &:focus:not(.focus-visible) {
    outline: none;
    border: 2px solid transparent;
    box-shadow: none;
  }
`

export const ButtonComponent = styled(ButtonPrimitive)`
  ${buttonStyles};
`

export const A = styled.a`
  ${buttonStyles};
`

export const StyledLoading = styled(Loading)`
  width: 0;
  height: 1.5rem;
  transition: all 200ms ease;

  ${(props) =>
    props.visible &&
    css`
      width: 1.5rem;
      margin-inline-end: 0.625rem;
    `}
`
