import { useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'

import { useTranslation } from 'i18n'
import { dispatch } from 'store'
import { setDialogVisibility } from 'store/dialog/slice'
import apiStatus from 'store/selectors/apiStatus'
import { Dialog } from 'components'
import VerifyingLoading from './VerifyingLoading'
import VerifyingSuccess from './VerifyingSuccess'

const apiStatusSelector = (state) =>
  apiStatus({
    state,
    resource: 'user',
    api: 'verifyEduEmail'
  })

const VerifyingEduDialog = ({ visible }) => {
  const { t } = useTranslation(['edu', 'common'])

  const status = useSelector(apiStatusSelector, isEqual)
  const eduVerificationStatus = useSelector(
    (state) => state.api.user.verifyEduEmail?.status,
    isEqual
  )
  const isComplete = status.success

  const dismiss = () => {
    dispatch(setDialogVisibility({ name: null }))
  }

  const successContentMap = {
    pending: {
      title: t('common:pending'),
      body: t('edu:eduPending')
    },
    approved: {
      title: t('common:success'),
      body: t('edu:eduApproved')
    }
  }
  const successContent = successContentMap[eduVerificationStatus]

  return (
    <Dialog
      visible={visible}
      label={t('common:success')}
      transparentContent={!isComplete}
      disableClose={!isComplete}
    >
      {isComplete ? (
        <VerifyingSuccess onClick={dismiss} {...successContent} />
      ) : (
        <VerifyingLoading />
      )}
    </Dialog>
  )
}

export default VerifyingEduDialog
