import { forwardRef, useState } from 'react'
import { string, shape, bool } from 'prop-types'
import {
  CardComponent,
  CardNumber,
  CardExpiry,
  CardCVV
} from '@chargebee/chargebee-js-react-wrapper'

import { useTranslation, i18n } from 'i18n'
import { CardIconsDisplay, TextField } from 'components'
import { getAuthFields } from 'data/authFields'

import options from './options'
import langConfig from '../../../locales'
import * as S from './styles'

const CardInputs = forwardRef(({ currencyCode, requiredFields }, ref) => {
  const [error, setError] = useState({})
  const { t } = useTranslation('checkout')
  const { style, classes, placeholder, fonts } = options(t)
  const hasZip = requiredFields?.zipCode

  // Display "Last Name - First Name" for locales with reverseName specified
  const locale = i18n.language
  const localeConfig = langConfig.find((l) => l.id === locale)
  const { reverseName } = localeConfig

  let cardErrors

  const handleChange = (event) => {
    if (event?.field) {
      cardErrors = {
        ...cardErrors,
        [event.field]: event.error?.errorCode
      }

      setError(cardErrors)
    }
  }

  const authFields = getAuthFields(t)
  return (
    <>
      <S.Fields>
        <S.Field hasError>
          <TextField {...authFields[reverseName ? 'lastName' : 'firstName']} />
        </S.Field>
        <S.Spacer />
        <S.Field hasError>
          <TextField {...authFields[reverseName ? 'firstName' : 'lastName']} />
        </S.Field>
      </S.Fields>
      <CardComponent
        ref={ref}
        className="fieldset field"
        styles={style}
        classes={classes}
        locale={locale}
        placeholder={placeholder}
        fonts={fonts}
        onChange={handleChange}
      >
        <S.Field>
          <S.Label>{t('cardNumber')}</S.Label>
          <CardNumber className="ex1-input" />
          {error.number && <S.Error>{t('invalidCard')}</S.Error>}
          <CardIconsDisplay currencyCode={currencyCode} />
        </S.Field>

        <S.FieldsRow>
          <S.Field>
            <S.NoWrapLabel>{t('expiration')}</S.NoWrapLabel>
            <CardExpiry className="ex1-input" />
            {error.expiry && <S.Error>{t('invalidExpiry')}</S.Error>}
          </S.Field>

          <S.Field>
            <S.NoWrapLabel>{t('securityCode')}</S.NoWrapLabel>
            <CardCVV className="ex1-input" />
            {error.cvv && <S.Error>{t('invalidCvc')}</S.Error>}
          </S.Field>

          {hasZip && (
            <S.Field>
              <TextField {...authFields.zipCode} dir="ltr" />
            </S.Field>
          )}
        </S.FieldsRow>
      </CardComponent>
    </>
  )
})

CardInputs.propTypes = {
  currencyCode: string.isRequired,
  requiredFields: shape({
    zip: bool
  })
}

CardInputs.defaultProps = {
  requiredFields: {}
}

export default CardInputs
