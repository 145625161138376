import * as S from './styles'
import { useTranslation } from 'i18n'

const CheckoutTotal = ({ taxable, discount, total }) => {
  const { t } = useTranslation('checkout')
  return (
    <S.CheckoutTotalComponent>
      {discount && (
        <S.LineItem>
          <S.Title>{t('discount')}</S.Title>
          <S.Value>-{discount}</S.Value>
        </S.LineItem>
      )}
      <S.TotalWrap>
        <S.Total>{t('total')}</S.Total>
        <S.TotalAndTax>
          <S.TotalAmount>{total}</S.TotalAmount>
          {taxable && <S.PlusTax>{t('plusTax')}</S.PlusTax>}
        </S.TotalAndTax>
      </S.TotalWrap>
      <S.Hr />
    </S.CheckoutTotalComponent>
  )
}

CheckoutTotal.defaultProps = {
  subtotal: '$119.88',
  taxable: false,
  total: '$100.99'
}

export default CheckoutTotal
