import { modelName, apiFetch, createAsyncThunk } from './commerce'

const getBillingInfo = createAsyncThunk(
  `${modelName}/getBillingInfo`,
  async (body) => {
    const options = {
      path: '/users/me/subscription/billingInfo',
      method: 'GET',
      body
    }
    const response = await apiFetch(options)

    return response
  }
)

getBillingInfo.storeKey = 'billingInfo'

export default getBillingInfo
