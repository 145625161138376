import { bool, func } from 'prop-types'
import { useSelector } from 'react-redux'

import { ShareLinkCopy, ShareLinkSettings } from 'components'

const InviteMembersLinkTab = ({
  createNewSuccess,
  handleConfirmView,
  resetSuccess
}) => {
  const business = useSelector((state) => state.api.business)
  const linksDisabled = !business?.businessSettings?.enableBusinessJoinLink

  return (
    <>
      {!linksDisabled && (
        <ShareLinkCopy
          joinLink={business?.businessSettings?.joinLink}
          handleConfirmView={handleConfirmView}
          createNewSuccess={createNewSuccess}
        />
      )}
      <ShareLinkSettings
        linksDisabled={linksDisabled}
        handleConfirmView={handleConfirmView}
        resetSuccess={resetSuccess}
      />
    </>
  )
}

InviteMembersLinkTab.propTypes = {
  handleConfirmView: func.isRequired,
  createNewSuccess: bool,
  resetSuccess: bool
}

export default InviteMembersLinkTab
