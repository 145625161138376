import { createAsyncThunk } from '@reduxjs/toolkit'

import apiFetch from '../apiFetch'

export const modelName = 'commerce'

export const createSubscription = createAsyncThunk(
  `${modelName}/createSubscription`,
  async (body) => {
    const options = {
      path: '/users/me/subscription',
      method: 'POST',
      body
    }
    const response = await apiFetch(options)

    return response
  }
)

createSubscription.storeKey = 'subscription'

export const getSubscription = createAsyncThunk(
  `${modelName}/getSubscription`,
  async () => {
    const options = {
      path: '/users/me/subscription',
      method: 'GET'
    }
    const response = await apiFetch(options)

    return response
  }
)

getSubscription.storeKey = 'subscription'

export const cancelSubscription = createAsyncThunk(
  `${modelName}/cancelSubscription`,
  async (body) => {
    const options = {
      path: '/users/me/subscription',
      method: 'PATCH',
      body: {
        status: 'non_renewing',
        ...body
      }
    }
    const response = await apiFetch(options)

    return response
  }
)

export const changePlan = createAsyncThunk(
  `${modelName}/changePlan`,
  async (body) => {
    const options = {
      path: '/users/me/subscription',
      method: 'PATCH',
      body
    }
    const response = await apiFetch(options)

    return response
  }
)

export const updatePaymentMethod = createAsyncThunk(
  `${modelName}/updatePaymentMethod`,
  async (body) => {
    const options = {
      path: '/users/me/paymentMethod',
      method: 'POST',
      body
    }
    const response = await apiFetch(options)

    return response
  }
)

export { default as getCatalog } from './getCatalog'
export { default as getPayments } from './getPayments'
export { default as getInvoice } from './getInvoice'
export { default as getPremium } from './getPremium'
export { default as getBillingInfo } from './getBillingInfo'
export { default as updateBillingInfo } from './updateBillingInfo'
