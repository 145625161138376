import {
  /* hygen inject: import */
  SuccessToast
} from 'components'

const ToastList = {
  /* hygen inject: toast list */
  SuccessToast
}

export default ToastList
