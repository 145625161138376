import { Auth } from '@aws-amplify/auth'
import { createAsyncThunk } from '@reduxjs/toolkit'

import { ALL_SPACES } from 'utilities/regex'

export const modelName = 'auth'

export const signIn = createAsyncThunk(
  'auth/signIn',
  async ({ username, password }) => {
    const data = await Auth.signIn({
      username: username.toLowerCase(),
      password
    })

    if (data.challengeName) {
      return { challengeName: data.challengeName }
    }

    return {
      email: data.attributes.email,
      username: data.username,
      id: data.attributes.sub
    }
  }
)

export const forgotPassword = createAsyncThunk(
  'auth/forgotPassword',
  async ({ username }) => {
    const data = await Auth.forgotPassword(username.toLowerCase())

    return data
  }
)

export const forgotPasswordSubmit = createAsyncThunk(
  'auth/forgotPasswordSubmit',
  async ({ username, code, newPassword }) => {
    const userLowercase = username?.toLowerCase()
    const spaceFreeCode = code.replace(ALL_SPACES, '')
    const data = await Auth.forgotPasswordSubmit(
      userLowercase,
      spaceFreeCode,
      newPassword
    )

    return data
  }
)

export const changePassword = createAsyncThunk(
  'auth/changePassword',
  async ({ currentPassword, newPassword }) => {
    const currentUser = await Auth.currentAuthenticatedUser()

    const response = await Auth.changePassword(
      currentUser,
      currentPassword,
      newPassword
    )

    return response
  }
)

export const confirmSignUp = createAsyncThunk(
  'auth/confirmSignUp',
  async ({ username, code }) => {
    return await Auth.confirmSignUp(username.toLowerCase(), code)
  }
)

export { default as checkBusinessEmail } from './checkBusinessEmail'
export { default as createUser } from './createUser'
export { default as verifyEmailWithToken } from './verifyEmailWithToken'
export * from './sso'
