import { RadioGroup, useFormState } from 'informed'

import * as S from './styles'
import { planFields } from 'data'
import { useTranslation } from 'i18n'
import { RadioCard, Back } from 'components'
import { Button } from '@all-turtles/mmhmm-ui-kit'

const ChangePlanForm = ({ isLoading, catalog }) => {
  const { monthly, yearly } = planFields
  const { t } = useTranslation(['checkout', 'common'])
  const formState = useFormState()
  const value = formState.values.planId

  const monthlyPlan = { ...catalog.monthlyPlan }
  const annualPlan = { ...catalog.annualPlan }

  const monthlyAmount = monthlyPlan.displayPrice
  monthly.description = t(planFields.monthly.t, { amount: monthlyAmount })
  monthly.value = monthlyPlan.id
  monthly.title = t('monthlyTitle')

  const yearlyAmount = annualPlan.displayPrice
  yearly.description = t(planFields.yearly.t, { amount: yearlyAmount })
  yearly.value = annualPlan.id
  yearly.title = t('yearlyTitle')

  return (
    <S.ChangePlanFormComponent>
      <RadioGroup field="planId" required>
        <RadioCard
          selected={value === yearly.value}
          {...yearly}
          field="planId"
          containerStyles={S.StyledRadioPill}
        />
        <RadioCard
          selected={value === monthly.value}
          {...monthly}
          field="planId"
          containerStyles={S.StyledRadioPill}
        />
      </RadioGroup>
      <S.BackWrap>
        <Back />
        <Button type="submit" loading={isLoading}>
          {t('common:save')}
        </Button>
      </S.BackWrap>
    </S.ChangePlanFormComponent>
  )
}

export default ChangePlanForm
