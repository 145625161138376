import { bool } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@all-turtles/mmhmm-ui-kit'

import { setDialogVisibility } from 'store/dialog/slice'
import { Trans, useTranslation } from 'i18n'
import { Colors } from 'styles'
import * as S from './styles'
import { getPublicUrl } from 'utilities/getPublicUrl'

const BusinessApprovedDomains = ({ canAdmin }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation('business')

  const business = useSelector((state) => state.api.business)
  const businessName = business?.businessSettings?.name
  const approvedDomains = business?.approvedDomains
  const domainShareLink =
    approvedDomains?.count > 0
      ? `${getPublicUrl()}/business/join/${business?.businessSettings?.id}`
      : null

  const bodyKey =
    approvedDomains?.count > 0 ? 'approvedDomainsBody' : 'noApprovedDomainsBody'

  const handleConfirmRemove = (domain) => {
    dispatch(setDialogVisibility({ name: 'RemoveDomainDialog', domain }))
  }

  return (
    <>
      <S.Body>
        <Trans i18nKey={bodyKey} t={t} businessName={businessName}>
          Anyone that signs up with an approved email domain can automatically
          join <bdi>{{ businessName }}</bdi>.
        </Trans>
      </S.Body>
      {domainShareLink && (
        <S.Body
          as="a"
          target="_blank"
          rel="noopener noreferrer"
          href={domainShareLink}
        >
          {domainShareLink}
        </S.Body>
      )}
      <S.Table>
        {approvedDomains?.count > 0 &&
          approvedDomains.domains.map((domain) => (
            <S.DomainRow key={`approved-${domain}`}>
              <S.Domain>
                <S.StyledCheck color={Colors.success} />
                <p>
                  <bdi>@{domain}</bdi>
                </p>
              </S.Domain>
              {canAdmin && (
                <Button
                  type="button"
                  variant="link"
                  small
                  onClick={() => handleConfirmRemove(domain)}
                >
                  {t('remove')}
                </Button>
              )}
            </S.DomainRow>
          ))}
      </S.Table>
    </>
  )
}

BusinessApprovedDomains.propTypes = {
  canAdmin: bool
}

export default BusinessApprovedDomains
