import { chargebeeInputStyle, chargebeeInvalidStyle } from './styles'

const options = (t) => ({
  // Custom classes - applied on container elements based on field's state
  classes: {
    focus: 'focus',
    invalid: 'invalid',
    empty: 'empty',
    complete: 'complete'
  },
  style: {
    base: chargebeeInputStyle,
    invalid: chargebeeInvalidStyle
  },
  placeholder: {
    number: '0000 0000 0000 0000',
    expiry: t('expirationPlaceholder'),
    cvv: '000'
  },
  fonts: [
    'https://fonts.googleapis.com/css2?family=Mulish:wght@400&display=swap'
  ]
})

export default options
