import { init } from '@sentry/nextjs'
import { sanitizeBreadcrumb, sanitizeEvent } from './src/utilities/sentry'

const dsn = process.env.NEXT_PUBLIC_SENTRY_DSN
const environment = process.env.NEXT_PUBLIC_DEPLOY_ENV

const sentryOptions = {
  dsn,
  environment,
  normalizeDepth: 6,
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
    'originalCreateNotification',
    'canvas.contentDocument',
    'MyApp_RemoveAllHighlights',
    'http://tt.epicplay.com',
    "Can't find variable: ZiteReader",
    'jigsaw is not defined',
    'ComboSearch is not defined',
    'http://loading.retry.widdit.com/',
    'atomicFindClose',
    // Facebook borked
    'fb_xd_fragment',
    // Facebook iOS browser utils
    "Can't find variable: PaymentAutofillConfig",
    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
    // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
    'conduitPage',
    // Generic error code from errors outside the security sandbox
    // You can delete this if using raven.js > 1.0, which ignores these automatically.
    'Script error.',
    // Avast extension error
    '_avast_submit',
    // Ignore network errors
    /^NetworkError/,
    'Network error',
    // Ignore wrong promise null rejection
    'Non-Error promise rejection captured with value: null',
    // Office 365 links checking
    'Non-Error promise rejection captured with value: Object Not Found Matching Id'
  ],
  denyUrls: [
    // Google Adsense
    /pagead\/js/i,
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extension/i,
    /^chrome/i,
    /^chrome-extension/i,
    // Safari extensions
    /^safari-extension/i,
    /^:\/\/hidden/i,
    // Firefox extensions
    /^moz-extension/i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i
  ],
  beforeSend: (event) => sanitizeEvent(event),
  beforeBreadcrumb: (breadcrumb) => sanitizeBreadcrumb(breadcrumb)
}

if (dsn) init(sentryOptions)
