import { css } from 'styled-components'

import { Colors, Type } from 'styles'

export const selectContainer = css`
  margin: 2.5rem 0;
  padding-bottom: 2.75rem;
  border-bottom: 1px solid ${Colors.gray10};
  width: 100%;

  label {
    ${Type.body20};
  }
`
