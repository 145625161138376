import { useRouter } from 'next/router'
import { Trans, useTranslation } from 'next-i18next'
import styled from 'styled-components'

const getNewAccountURL = (path) =>
  `${process.env.NEXT_PUBLIC_OOO_APP_LINK}${path}`

// The new account app doesn't handle logic from those pages
// so to avoid confusion don't display the alert there
const pathsToOmit = [
  '/upgrade',
  '/business',
  '/education',
  '/business/purchase',
  '/business/link-account'
]

export const NewAccountAlert = () => {
  const { t } = useTranslation('common')
  const router = useRouter()

  if (pathsToOmit.includes(router.pathname)) {
    return null
  }

  const href = router.pathname.startsWith('/business')
    ? getNewAccountURL('/account/team/members')
    : getNewAccountURL('/account/edit-profile')

  return (
    <Alert>
      <Trans
        t={t}
        i18nKey="common:newAccountAlert"
        components={{ Link: <Link href={href} /> }}
      />
    </Alert>
  )
}

const Alert = styled.div`
  box-sizing: border-box;
  width: 100%;
  text-align: center;
  font-size: 15px;
  padding: 12px;
  background: #5549f3;
  color: #fff;
`

const Link = styled.a`
  color: #fff;
  text-decoration: underline;

  &:focus {
    outline: 1px solid #fff;
    box-shadow: none;
  }
  &:hover {
    color: #d9d7fc;
  }
`
