import { bool, string } from 'prop-types'

import { useTranslation } from 'i18n'
import * as S from './styles'

const NoResults = ({ subtitle, loading, noText }) => {
  const { t } = useTranslation('business')

  return (
    <S.NoResults minimal={noText}>
      {!noText && !loading && (
        <>
          <strong>{t('noResults')}</strong>
          {subtitle && <p>{subtitle}</p>}
        </>
      )}
    </S.NoResults>
  )
}

NoResults.propTypes = {
  loading: bool.isRequired,
  subtitle: string,
  noText: bool
}

NoResults.defaultProps = {
  noText: false
}

export default NoResults
