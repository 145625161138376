import { number, bool } from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'

import apiStatus from 'store/selectors/apiStatus'
import { setPagination } from 'store/pagination/slice'
import { getMembers } from 'api/business'
import { useTranslation } from 'i18n'
import {
  TableData,
  MemberActions,
  TableSearchBar,
  NameWithAvatar
} from 'components'
import getMemberData from './getMemberData'
import useFetchWithPagination from 'hooks/useFetchWithPagination'
import useDebouncedSearchInput from 'hooks/useDebouncedSearchInput'
import NoResults from './NoResults'

const apiStatusSelector = (state) =>
  apiStatus({
    state,
    resource: 'business',
    api: 'getMembers'
  })

const BusinessMembersTable = ({ limit, withSearch }) => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['common', 'business'])
  const { search, searchInputProps } = useDebouncedSearchInput()
  const businessMembers = useSelector(
    (state) => state.api.business?.members?.items
  )
  const status = useSelector(apiStatusSelector, isEqual)

  useFetchWithPagination({
    fetchAction: getMembers,
    paginationKey: 'getMembers',
    limit,
    search
  })

  if (!businessMembers) return null

  const displayData =
    limit && businessMembers?.length > limit
      ? businessMembers.slice(0, limit)
      : businessMembers

  const handleSort = (orderBy, orderDirection) => {
    // Update stored sort for use in pagination component
    dispatch(
      setPagination({ getMembers: { orderBy, orderDirection, page: 0 } })
    )
  }

  return (
    <>
      {withSearch && (
        <TableSearchBar {...searchInputProps} loading={status.loading} />
      )}
      {displayData.length > 0 ? (
        <TableData
          data={displayData}
          table="business-members"
          itemId="id"
          title="Members"
          dateKeys={['created']}
          includeKeys={[
            'name',
            'email',
            'role',
            'status',
            'linkedIdPName',
            'actions'
          ]}
          transformKeys={['role', 'status', 'linkedIdPName']}
          customCellComponents={{
            actions: (member) => <MemberActions member={member} />,
            name: (member) => {
              const avatarUrl = member.profilePhotoInfo?.urls?.thumbnail || ''
              return <NameWithAvatar avatarUrl={avatarUrl} name={member.name} />
            }
          }}
          headerOverrides={{
            thumbnail: ' ',
            email: t('email'),
            linkedIdPName: t('common:loginMethod')
          }}
          disableSortForColumns={['actions', 'status']}
          dataTransform={(data) => getMemberData(data, t)}
          handleSort={handleSort}
        />
      ) : (
        <NoResults
          subtitle={search ? t('business:noMembersFound') : null}
          loading={status.loading}
        />
      )}
    </>
  )
}

BusinessMembersTable.propTypes = {
  /** How many items should be displayed at a time */
  limit: number,
  withSearch: bool
}

BusinessMembersTable.defaultProps = {
  limit: 15,
  withSearch: false
}

export default BusinessMembersTable
