import { useSelector } from 'react-redux'
import isEqual from 'lodash/isEqual'
import { useRouter } from 'next/router'

import { mixpanelService, eventTypes } from 'analytics'
import { Trans, useTranslation, Link } from 'i18n'
import apiStatus from 'store/selectors/apiStatus'
import { getSignInAppUrl } from 'utilities/getSignInAppUrl'
import navItems from 'data/navItems'
import * as S from './styles'

const apiStatusSelector = (state) =>
  apiStatus({
    state,
    resource: 'user',
    api: 'getMe'
  })

const NavItems = ({ showOnlyItems }) => {
  const { t } = useTranslation(['nav', 'common'])
  const currentUser = useSelector((state) => state.api.user?.me)
  const userStatus = useSelector(apiStatusSelector, isEqual)
  const router = useRouter()
  const payments = useSelector((state) => state.api.commerce?.payments?.items)

  if (!currentUser?.id && !showOnlyItems) {
    /**
     * Don't show "Login" link while auth handoff is happening post-sign in,
     * but do show if user there's an error so user can get to sign in again
     */
    if (userStatus?.error) {
      const signInRedirectUrl = getSignInAppUrl({ path: '/sign-in' })
      return <S.A href={signInRedirectUrl}>{t('common:logIn')}</S.A>
    } else {
      return null
    }
  } else if (
    showOnlyItems?.length === 1 &&
    showOnlyItems[0] === 'downloadLong'
  ) {
    const downloadUrl = t('common:downloadUrl')
    return (
      <p>
        <Trans i18nKey="downloadLong" t={t}>
          Trying to create an account?
          <a href={downloadUrl} target="_blank" rel="noopener noreferrer">
            Download the app
          </a>
        </Trans>
      </p>
    )
  }

  const items = showOnlyItems
    ? navItems.filter((item) => showOnlyItems.indexOf(item.i18nKey) >= 0)
    : navItems

  return (
    <>
      {items.map((item) => {
        const hasHideCheck =
          item.shouldShow && typeof item.shouldShow === 'function'
        if (
          hasHideCheck &&
          !item.shouldShow({
            user: currentUser,
            payments
          })
        ) {
          return null
        }

        const trackClick = () => {
          mixpanelService.track(eventTypes.click, {
            element: `${item.i18nKey || item.name} Link`
          })
        }

        const display = item.dynamicDisplay
          ? item.dynamicDisplay(currentUser)
          : t(item.i18nKey)

        const selected = item.selected
          ? item.selected(router.pathname)
          : item.url === router.pathname

        return item.external ? (
          <S.A
            key={item.url}
            href={item.url}
            onClick={trackClick}
            isSelected={selected}
            target={item.target}
          >
            {display}
          </S.A>
        ) : (
          <Link key={item.url} passHref href={item.url}>
            <S.A
              isSelected={selected}
              target={item.target}
              onClick={trackClick}
            >
              {display}
            </S.A>
          </Link>
        )
      })}
    </>
  )
}

export default NavItems
