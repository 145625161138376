import styled from 'styled-components'

export const ChangePlanComponent = styled.div`
  max-width: 540px;
`

export const P = styled.p`
  margin-bottom: 2rem;
  margin-top: 1rem;
`
