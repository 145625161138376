import { createAsyncThunk } from '@reduxjs/toolkit'

import apiFetch from '../apiFetch'

const fetchUploadUrl = async () => {
  const options = {
    path: '/users/me/photo',
    method: 'POST'
  }
  const response = await apiFetch(options)

  return response
}

const cacheLocalImage = (file) => {
  try {
    const fileReader = new FileReader()
    fileReader.onload = (evt) => {
      const cacheTime = new Date()
      cacheTime.setMinutes(cacheTime.getMinutes() + 3)
      const expiry = cacheTime.toJSON()
      const avatarCache = { file: evt.target.result, expiry }
      window.localStorage.setItem('avatarCache', JSON.stringify(avatarCache))
    }
    fileReader.readAsDataURL(file)
  } catch (e) {
    console.error('Avatar caching error, avatar will not be cached', e)
  }
}

const uploadAvatar = createAsyncThunk('user/uploadAvatar', async ({ file }) => {
  // since the image generation code can take a bit to complete,
  // take the file itself and pop it onto the window to ensure the client sees the newly uploaded photo
  cacheLocalImage(file)

  const { url } = await fetchUploadUrl()

  const response = await apiFetch({
    path: url,
    absolutePath: true,
    method: 'PUT',
    body: file,
    isFile: true,
    noContentAllowed: true,
    customHeaders: {
      'Content-Type': file.type
    },
    authorized: false
  })

  return { lastUpdated: Date.now(), ...response }
})

export default uploadAvatar
