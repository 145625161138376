import styled from 'styled-components'
import { TabList, Tab } from '@reach/tabs'

import { Colors, Type } from 'styles'

export const StyledTabList = styled(TabList)`
  background: transparent;
  margin-bottom: 2rem;
  padding: 0 0.25rem;
  border-bottom: 1px solid ${Colors.gray40};
`

export const StyledTab = styled(Tab)`
  ${Type.header4};
  color: ${Colors.brand};
  padding: 0 0.25rem 1rem;
  margin-inline-end: 3rem;
  border-bottom: 4px solid transparent;

  &:last-of-type {
    margin-inline-end: 0;
  }

  &:focus {
    outline: 2px solid ${Colors.brand};
  }

  &:active,
  &:focus:not(.focus-visible) {
    background: transparent;
    outline: none;
  }
`
