import { useTranslation } from 'i18n'
import { useDispatch } from 'react-redux'

import { requestEduPremium } from 'api/user'
import { VerifyEduForm } from 'components'
import * as S from './styles'

const Education = () => {
  const { t } = useTranslation('edu')
  const dispatch = useDispatch()

  const handleSubmit = (values) => {
    dispatch(requestEduPremium(values))
  }

  return (
    <S.EducationComponent>
      <S.MainPanel>
        <S.Title>{t('educationDetails')}</S.Title>
        <S.Heading>{t('verifyYourEligibility')}</S.Heading>
        <S.Body>{t('eduDescription')}</S.Body>
        <VerifyEduForm onSubmit={handleSubmit} />
      </S.MainPanel>
    </S.EducationComponent>
  )
}

export default Education
