import * as S from './styles'
import { useTranslation } from 'i18n'
import Parser from 'ua-parser-js'

const OrderComplete = () => {
  const { t } = useTranslation('order-complete')
  const parser = new Parser()
  const isDesktop = !parser.getResult()?.device?.type

  return (
    <S.OrderCompleteComponent>
      <S.Hero>
        <S.Content>
          <S.H1>{t('title')}</S.H1>
          <S.P>{t('description')}</S.P>
          {isDesktop && (
            <S.StyledButton el="a" href="mmhmm:///premium/update">
              {t('open')}
            </S.StyledButton>
          )}
        </S.Content>
      </S.Hero>
      <S.StyledYourOrder />
    </S.OrderCompleteComponent>
  )
}

export default OrderComplete
