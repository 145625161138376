const planFields = {
  monthly: {
    t: 'monthly',
    description_i18n: 'monthlyAmount',
    name: 'plan'
  },
  yearly: {
    t: 'yearly',
    description_i18n: 'yearlyAmount',
    name: 'plan'
  },
  cancel: {
    t: 'serviceEndsOn',
    name: 'plan',
    value: 'cancel'
  }
}

export default planFields
