import styled from 'styled-components'
import { Colors, Type } from 'styles'

export const Container = styled.div`
  ${Type.body30};
  box-sizing: border-box;
  width: 100%;
  padding: 1.5rem;
  background: ${Colors.yellowLight};
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Icon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 1.875rem;
  height: 1.875rem;
  border-radius: 100%;
  background: ${Colors.yellow};
`

export const Message = styled.p`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 0.25rem;
`
