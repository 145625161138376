import { modelName, apiFetch, createAsyncThunk } from './commerce'

export const getCatalog = createAsyncThunk(
  `${modelName}/getCatalog`,
  async (body) => {
    const options = {
      path: '/subscription/catalog',
      method: 'GET',
      body
    }
    const response = await apiFetch(options)

    return response
  }
)

getCatalog.storeKey = 'catalog'

export default getCatalog
