import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@all-turtles/mmhmm-ui-kit'

import { getMembers } from 'api/business'
import { setDialogVisibility } from 'store/dialog/slice'
import { Link, Trans, useTranslation } from 'i18n'
import { TextBadge } from 'components'
import { Users } from 'components/Icons'
import * as S from './styles'

const BusinessPanel = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation(['business', 'common'])

  const currentUser = useSelector((state) => state.api.user?.me)
  const canAdmin = currentUser?.businessPermissions?.canAdmin
  const canInvite = currentUser?.businessPermissions?.canInviteMembers
  const role = t(currentUser?.businessRole)

  const userCount = useSelector((state) => state.api.business?.members?.total)

  useEffect(() => {
    dispatch(getMembers())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleInviteClick = () => {
    dispatch(setDialogVisibility({ name: 'InviteMembers' }))
  }

  return (
    <S.Panel>
      <S.Heading>{t('mmhmmBusinessSubscription')}</S.Heading>
      <S.Body>
        <div>
          <S.P>
            <Trans
              i18nKey="businessNameAccount"
              t={t}
              businessName={currentUser?.businessName}
            >
              <bdi>{{ businessName: currentUser?.businessName }}</bdi> account
            </Trans>
          </S.P>
          {canInvite && (
            <S.MemberRow>
              <Users role="presentation" />
              <S.Small>{t('memberCount', { count: userCount })}</S.Small>
            </S.MemberRow>
          )}
        </div>
        <TextBadge>{role}</TextBadge>
      </S.Body>
      {(canAdmin || canInvite) && (
        <S.Ctas>
          {canAdmin && (
            <Link href="/business/members" passHref>
              <a>{t('common:manage')}</a>
            </Link>
          )}
          {canInvite && (
            <Button variant="link" onClick={handleInviteClick}>
              {t('inviteMembers')}
            </Button>
          )}
        </S.Ctas>
      )}
    </S.Panel>
  )
}

export default BusinessPanel
