const Instagram = (props) => {
  return (
    <svg
      width="35"
      height="34"
      viewBox="0 0 35 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-labelledby="instagram-title"
      {...props}
    >
      <title id="instagram-title">Instagram</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4059 21.6596C14.7417 21.6596 12.5507 19.4938 12.5507 16.8044C12.5507 14.1394 14.741 11.9492 17.4059 11.9492C20.0954 11.9492 22.2612 14.1402 22.2612 16.8044C22.2612 19.4931 20.0946 21.6596 17.4059 21.6596ZM17.4059 13.8202C15.7605 13.8202 14.4218 15.1589 14.4218 16.8044C14.4218 18.4499 15.7605 19.7886 17.4059 19.7886C19.0514 19.7886 20.3901 18.4499 20.3901 16.8044C20.3901 15.1589 19.0514 13.8202 17.4059 13.8202Z"
        fill="black"
      />
      <path
        d="M21.9732 12.8965C22.6364 12.8965 23.1811 12.3518 23.1811 11.6886C23.1811 11.0255 22.6364 10.4808 21.9732 10.4808C21.3101 10.4808 20.7653 11.0255 20.7653 11.6886C20.7653 12.3518 21.3101 12.8965 21.9732 12.8965Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.877 26.1596C10.6673 26.1596 8.05078 23.543 8.05078 20.3333V13.2755C8.05078 10.0658 10.6673 7.44922 13.877 7.44922H20.9585C24.1452 7.44922 26.7611 10.0664 26.7611 13.2755V20.357C26.7611 23.5437 24.1439 26.1596 20.9349 26.1596H13.877ZM13.877 9.29657C11.6868 9.29657 9.89813 11.0852 9.89813 13.2755V20.357C9.89813 22.5472 11.6868 24.3359 13.877 24.3359H20.9585C23.1488 24.3359 24.9375 22.5472 24.9375 20.357V13.2755C24.9375 11.0852 23.1488 9.29657 20.9585 9.29657H13.877Z"
        fill="black"
      />
    </svg>
  )
}

export default Instagram
