import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import isEqual from 'lodash/isEqual'
import { useRouter } from 'next/router'

import apiStatus from 'store/selectors/apiStatus'
import { setDialogVisibility } from 'store/dialog/slice'
import { setToastVisibility } from 'store/toast/slice'
import usePrevious from 'hooks/usePrevious'
import { useTranslation } from 'i18n'
import { bool, func, string } from 'prop-types'

const apiStatusSelector =
  ({ resource, api }) =>
  (state) =>
    apiStatus({
      state,
      resource,
      api
    })

const HandleAction = ({
  resource,
  api,
  onError,
  onSuccess,
  toastTitle,
  toastDescription,
  stayOnPage,
  nextRoute,
  closeAllDialogsOnSuccess,
  noToast
}) => {
  const { t } = useTranslation('common')
  const status = useSelector(apiStatusSelector({ resource, api }), isEqual)
  const router = useRouter()
  const prevStatus = usePrevious(status)
  const dispatch = useDispatch()
  const request = useSelector((state) => state.api[resource]?.[api])

  const displayToast = () => {
    dispatch(
      setToastVisibility({
        name: 'SuccessToast',
        title: toastTitle || t('success'),
        description: toastDescription || t('successDescription')
      })
    )
  }

  useEffect(() => {
    if (prevStatus?.loading && status.error) {
      if (onError) {
        onError(request?.error)
      } else {
        const options = {
          name: 'ErrorDialog',
          description: t('somethingWentWrong'),
          showSupportLink: true
        }

        dispatch(setDialogVisibility(options))
      }
    }

    if (prevStatus?.loading && status.success) {
      if (onSuccess) {
        onSuccess()
      } else {
        if (!noToast) displayToast()
        if (closeAllDialogsOnSuccess) {
          dispatch(setDialogVisibility({ name: null }))
        }
        if (!stayOnPage) router.push(nextRoute || '/')
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  return null
}

HandleAction.propTypes = {
  /**
   * The name of the resource.
   */
  resource: string,

  /**
   * The API path.
   */
  api: string,

  /**
   * If there is an error, what should be done?
   * If undefined, show the error dialog.
   */
  onError: func,

  /**
   * When the API request succeeds, what should be done?
   * If undefined, show the success toast and redirect to the root.
   */
  onSuccess: func,

  /**
   * The title of the the toast notification.
   */
  toastTitle: string,

  /**
   * The description of the the toast notification.
   */
  toastDescription: string,

  /**
   * When true, don't show a toast on success
   */
  noToast: bool,

  /**
   * Route to re-direct to on success.
   */
  nextRoute: string,

  /**
   * When true, do not redirect on success.
   */
  stayOnPage: bool,

  /**
   * When true, all dialogs are closed upon successful API call
   */
  closeAllDialogsOnSuccess: bool
}

export default HandleAction
