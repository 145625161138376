import { success } from 'styles/Colors'

const CheckCircle = ({ color = success, ...rest }) => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M39.8203 79.1504C61.3535 79.1504 79.2735 61.2793 79.2735 39.7461C79.2735 18.2129 61.3047 0.341797 39.7715 0.341797C18.2871 0.341797 0.416016 18.2129 0.416016 39.7461C0.416016 61.2793 18.336 79.1504 39.8203 79.1504ZM39.8203 71.7285C22.0957 71.7285 7.93558 57.5195 7.93558 39.7461C7.93558 21.9727 22.0469 7.7637 39.7715 7.7637C57.5449 7.7637 71.7539 21.9727 71.8028 39.7461C71.8516 57.5195 57.5938 71.7285 39.8203 71.7285ZM35.6211 57.9102C37.0371 57.9102 38.2578 57.2266 39.1367 55.8594L55.9336 29.2969C56.4707 28.418 57.0078 27.4414 57.0078 26.4648C57.0078 24.5117 55.2989 23.291 53.4434 23.291C52.3203 23.291 51.2461 23.9258 50.416 25.2441L35.4258 49.5605L28.6875 40.4785C27.6133 39.0625 26.6367 38.7207 25.4649 38.7207C23.5606 38.7207 22.0957 40.2344 22.0957 42.1875C22.0957 43.1152 22.4863 44.043 23.0723 44.873L31.8613 55.8594C32.9844 57.3242 34.1563 57.9102 35.6211 57.9102Z"
        fill={color}
      />
    </svg>
  )
}

export default CheckCircle
