const Google = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 16 16"
      width="16"
      height="16"
      {...props}
    >
      <path
        fill="#4285F4"
        fillRule="evenodd"
        d="M15.68 8.183c0-.567-.05-1.113-.146-1.636H8V9.64h4.306a3.68 3.68 0 0 1-1.597 2.414v2.008h2.585c1.513-1.393 2.386-3.444 2.386-5.88Z"
        clipRule="evenodd"
      />
      <path
        fill="#34A853"
        fillRule="evenodd"
        d="M8 16c2.16 0 3.97-.716 5.294-1.938l-2.585-2.007c-.717.48-1.633.763-2.71.763-2.083 0-3.847-1.407-4.476-3.298H.851v2.073A7.997 7.997 0 0 0 8 16Z"
        clipRule="evenodd"
      />
      <path
        fill="#FBBC05"
        fillRule="evenodd"
        d="M3.524 9.518c-.16-.48-.251-.992-.251-1.52 0-.527.09-1.04.25-1.52V4.407H.852A7.996 7.996 0 0 0 0 7.999c0 1.29.31 2.512.85 3.592l2.674-2.073Z"
        clipRule="evenodd"
      />
      <path
        fill="#EA4335"
        fillRule="evenodd"
        d="M8 3.182c1.174 0 2.229.403 3.058 1.196l2.294-2.294C11.967.793 10.156 0 8 0A7.997 7.997 0 0 0 .85 4.407L3.524 6.48C4.153 4.589 5.916 3.182 8 3.182Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

export default Google
