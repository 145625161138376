/**
 * Design screens 11a/11b
 * User arrives here after creating a member account by joining or creating an org
 * Informational page to check email to verify acct and continue
 */
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouter } from 'next/router'
import isEqual from 'lodash/isEqual'

import { useTranslation } from 'i18n'
import { getMe } from 'api/user'
import { setBusinessInfo } from 'store/ui/slice'
import businessPageTitle from 'utilities/businessPageTitle'
import { Seo, Layout, CheckEmail, NotificationBar } from 'components'
import { defaultGetStaticProps } from 'utilities/next'

export default function BusinessLinkAccountPage() {
  const dispatch = useDispatch()
  const router = useRouter()
  const { t } = useTranslation('business')

  const title = businessPageTitle(t, 'verifyTitle')
  const description = t('verifyDescription')
  const currentUser = useSelector((state) => state.api.user?.me, isEqual)
  const businessInfo = useSelector((state) => state.ui?.businessInfo)

  const checkIfVerificationNeeded = (currentUser) => {
    if (currentUser?.emailVerified) {
      router.push('/')
    }
  }

  useEffect(() => {
    dispatch(getMe())

    // Remove joinLinkToken if user arrived here after Join Login page
    if (businessInfo?.joinLinkToken) {
      dispatch(
        setBusinessInfo({
          ...businessInfo,
          joinLinkToken: null,
          inviteToken: null
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    checkIfVerificationNeeded(currentUser)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  return (
    <>
      <Seo title={title} description={description} />
      <Layout hideNav>
        <NotificationBar />
        <CheckEmail />
      </Layout>
    </>
  )
}

export const getStaticProps = defaultGetStaticProps
