import styled from 'styled-components'

import { Breakpoints } from 'styles'

export const Header = styled.div`
  margin-bottom: 1.5rem;

  @media (min-width: ${Breakpoints.desktop}) {
    margin-bottom: 3.125rem;
  }
`

export const H1 = styled.h1`
  margin-bottom: 0.75rem;
`

export const P = styled.p`
  max-width: 40ch;

  @media (min-width: ${Breakpoints.desktop}) {
    max-width: none;
  }
`
