import {
  /* hygen inject: import */
  LinkAppDialog,
  UnlinkAppDialog,
  BusinessDomainActivateDialog,
  ConfirmResetLinksDialog,
  ReactivateAccountDialog,
  EditRoom,
  RemoveDomainDialog,
  BusinessMemberPermissionsDialog,
  ChangeBusinessMemberEmailDialog,
  DeleteInviteDialog,
  ChangeEmailInfo,
  InviteMembers,
  SuspendAccountDialog,
  ErrorDialog,
  VerifyingEduDialog,
  AvatarUploadDialog,
  AvatarDeleteDialog,
  AccountDeleteDialog
} from 'components'

const DialogList = {
  /* hygen inject: dialog list */
  LinkAppDialog,
  UnlinkAppDialog,
  BusinessDomainActivateDialog,
  ConfirmResetLinksDialog,
  ReactivateAccountDialog,
  EditRoom,
  RemoveDomainDialog,
  BusinessMemberPermissionsDialog,
  ChangeBusinessMemberEmailDialog,
  DeleteInviteDialog,
  ChangeEmailInfo,
  InviteMembers,
  SuspendAccountDialog,
  ErrorDialog,
  VerifyingEduDialog,
  AvatarUploadDialog,
  AvatarDeleteDialog,
  AccountDeleteDialog
}

export default DialogList
