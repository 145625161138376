import { useSelector } from 'react-redux'
import { bool, shape, string } from 'prop-types'
import { RadioGroup, useFormState } from 'informed'
import { Button } from '@all-turtles/mmhmm-ui-kit'

import formatDate from 'utilities/formatDate'
import { useTranslation } from 'i18n'
import { planFields } from 'data'
import { RadioCard, withInformed } from 'components'
import * as S from './styles'

const BusinessSubscriptionForm = ({
  catalog,
  isLoading,
  nextChargeDate,
  isPayByInvoice
}) => {
  const { t } = useTranslation(['business', 'checkout', 'common'])
  const formState = useFormState()
  const value = formState.values.planId
  const businessPermissions = useSelector(
    (state) => state.api.user?.me?.businessPermissions
  )

  const { cancel, monthly, yearly } = planFields

  const monthlyAmount = catalog.monthlyPlan.displayPrice
  monthly.description = t(planFields.monthly.description_i18n, {
    amount: monthlyAmount
  })
  monthly.value = catalog.monthlyPlan.id
  monthly.title = t('checkout:monthlyTitle')

  const yearlyAmount = catalog.annualPlan.displayPrice
  yearly.description = t(planFields.yearly.description_i18n, {
    amount: yearlyAmount
  })
  yearly.value = catalog.annualPlan.id
  yearly.title = t('checkout:yearlyTitle')

  const cancelDate = formatDate(nextChargeDate)
  cancel.description = t(planFields.cancel.t, { date: cancelDate })
  cancel.title = t('cancelTitle')

  return (
    <>
      <S.OptionsWrapper>
        <RadioGroup field="planId" required>
          {!isPayByInvoice && (
            <>
              <RadioCard
                selected={value === yearly.value}
                {...yearly}
                field="planId"
                containerStyles={S.StyledRadioPill}
              />
              <RadioCard
                selected={value === monthly.value}
                {...monthly}
                field="planId"
                containerStyles={S.StyledRadioPill}
              />
            </>
          )}
          <RadioCard
            selected={value === cancel.value}
            {...cancel}
            field="planId"
            containerStyles={S.StyledRadioPill}
          />
        </RadioGroup>
      </S.OptionsWrapper>
      <Button
        type="submit"
        loading={isLoading}
        disabled={!businessPermissions?.canModifyBilling}
      >
        {t('common:save')}
      </Button>
    </>
  )
}

BusinessSubscriptionForm.propTypes = {
  catalog: shape({}),
  isLoading: bool,
  nextChargeDate: string,
  isPayByInvoice: bool
}

export default withInformed(BusinessSubscriptionForm)
