import styled from 'styled-components'
import { Button } from '@all-turtles/mmhmm-ui-kit'

import { Type } from 'styles'

export const FormHeader = styled.label`
  ${Type.header3};
  display: block;
  margin-bottom: 2rem;
`

export const BlockButton = styled(Button)`
  width: 100%;
  margin-top: 1.875rem;
`
